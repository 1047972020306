<!-- <div class="logo-wrapper">
    <div class="logo">
        <div style="display: inline-flex; width: 30vw; justify-content: center; align-items: center;">
            <img src="assets/images/logos/200x200-01.png" />
            <h1>FLIP</h1>
        </div>
    </div>
</div>
<div class="page">
    <div class="gradient-box left"></div>
    <div class="gradient-box right"></div>
    <div class="box" [ngStyle]="{'padding':registerNotSuccessfull?'0px':''}">

        <ng-container *ngIf="registerSuccessfull&& !registerNotSuccessfull">
            <div class="success-icon">
                <i class="ti ti-circle-check"></i>
            </div>
            <div class="header">
                <strong>{{isMessage}}</strong>
            </div>
            <div class="login-btn" (click)="loginPage()">
                <button> Back to login</button>
            </div>
        </ng-container>
        <ng-container *ngIf="registerNotSuccessfull">
            <div class="header">
                <strong>Invalid Link or Expired</strong>
            </div>
        </ng-container>
    </div>
    <div class="brand-logo">
        <img src="assets/images/Logo_transparent.png" alt="Kanerika">
    </div>
    <div class="brand-link">
        A Product of <a href="https://kanerika.com/" target="_blank">Kanerika</a>
    </div>
</div> -->

<div class="main-Div">
  <app-lottie-flip-design></app-lottie-flip-design>
  <div class="right-div-Login">
    <div class="center-div">
      <div
        class="right-div"
        *ngIf="registerSuccessfull && !registerNotSuccessfull">
        <div class="reset-div">
          <img src="../../../assets/Svgs_/Login/EmailVerified.svg" />
          <label class="desc schibsted-grotesk"
            >Your email has been verified successfully. Please login to continue
            using FLIP</label
          >
          <button
            class="button-common gradient schibsted-grotesk"
            (click)="loginPage()">
            Sign In
          </button>
        </div>
      </div>
      <div
        class="right-div"
        *ngIf="!registerSuccessfull && registerNotSuccessfull">
        <div class="reset-div">
          <img src="../../../assets/Svgs_/Login/LinkExpired.svg" />
          <label class="desc schibsted-grotesk"
            >For security reasons, registration links expire after some time.
            You can still registration by requesting a new registration
            link.</label
          >
          <button
            class="button-common gradient schibsted-grotesk"
            (click)="loginPage()">
            Go to Sign In
          </button>
        </div>
      </div>
    </div>
    <div class="footer-div">
      <label class="schibsted-grotesk">
        © Copyright 2024 - FLIP - Kanerika
      </label>
    </div>
  </div>
</div>

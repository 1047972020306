import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { DropdownService } from "../../services/dropdown.service";
import { Subscription } from "rxjs/internal/Subscription";
import {
  DropdownOptions,
  DropdownSearchType,
} from "../../models/globalDropdownOptions.model";
import { filter } from "rxjs/internal/operators/filter";
import { cloneDeep } from "lodash";
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";
import { AppComponent } from "../../../app.component";

@Component({
  selector: "app-dropdown-trigger",
  templateUrl: "./dropdown-trigger.component.html",
  styleUrls: ["./dropdown-trigger.component.scss"],
})
export class DropdownTriggerComponent implements OnInit, OnDestroy {
  public appComponent!: AppComponent;
  public inputValue: string = "";
  public searchValue: string = "";
  public selectValueIndex!: number;
  public multiSelectOptions: any;
  public searchable: boolean = false;
  public isOptionClicked: boolean = false;
  public toggleIconTrigger: boolean = false;
  public isArrowClicked: boolean = false;
  public isClicked: boolean = false;
  public openDropdownStateSubscription?: Subscription;
  public selectedOptionStateSubscription?: Subscription;
  @Input() dropdownOptions!: DropdownOptions;
  @Input() customClass: string = "";
  @Input() triggerId!: string; // Unique identifier for each trigger
  @Output() emitSelectedValue = new EventEmitter();

  constructor(
    public dropdownService: DropdownService,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.openDropdownStateSubscription =
      this.dropdownService.dropdownState$.subscribe((openTriggerId) => {
        this.toggleIconTrigger = openTriggerId === this.triggerId;
      });
    this.selectedOptionStateSubscription =
      this.dropdownService.getSelectedOption$
        .pipe(filter(({ triggerId }) => triggerId === this.triggerId))
        .subscribe((data) => {
          if (data && data?.options) {
            this.getSelectedOptionHelperMethod(data);
          }
        });
    this.inputValue = this.dropdownOptions.inputValue
      ? this.dropdownOptions.inputValue
      : "";
  }

  ngOnDestroy() {
    this.openDropdownStateSubscription?.unsubscribe();
    this.selectedOptionStateSubscription?.unsubscribe();
    this.dropdownService.handleDropdownState("");
  }

  private getSelectedOptionHelperMethod(data: any) {
    this.dropdownOptions.inputValue = data.options.value;
    this.selectValueIndex = data.options.index;
    this.searchValue = data.options.value;
    this.emitSelectedValue.emit(data);
    if (this.dropdownOptions?.multiSelectDropDown) {
      let multiOptions: any[] = this.dropdownOptions.dataOptions.newOptions;
      // Check if `data?.options.value` already exists before adding
      if (multiOptions.includes(data.options.value)) {
        this.removeDragItem(data.options.value);
      } else {
        multiOptions.push(data.options.value);
        multiOptions = [...multiOptions, data.options.value];
      }
    } else {
      this.inputValue = data?.options.value;
    }
  }

  openSelect() {
    let position = this.calcPositionForDropdown();
    if (!this.dropdownOptions["inputValue"]) {
      this.dropdownOptions["inputValue"] = "";
    }
    if (!this.dropdownOptions["searchValue"]) {
      this.dropdownOptions["searchValue"] = "";
    }
    let copy = cloneDeep(this.dropdownOptions);
    this.dropdownService.sendTriggerData(copy, this.triggerId);
    this.dropdownService.handleDropdownState(this.triggerId);
    this.dropdownService.sendPositionUpdate(position);
  }
  calcPositionForDropdown() {
    const elf: HTMLLIElement | any = document.getElementById(this.triggerId);
    if (elf) {
      const { left, top, width, height } = elf.getBoundingClientRect(); // Get the parent's position
      const parentEl = this.elementRef.nativeElement.parentElement;
      const {
        bottom,
        width: parentWidth,
        height: parentHeight,
      } = parentEl.getBoundingClientRect(); // Get the parent's bottom position

      // Get the viewport dimensions
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      // Define a maximum height and width for the dropdown
      const maxDropdownHeight = this.isNormalDropdownPosition(
        this.dropdownOptions
      )
        ? 200
        : 300; // Example: max height for the dropdown
      const maxWidth = 200; // Maximum width for the dropdown

      // Calculate initial position for dropdown
      let dropdownY = bottom; // Default: below the parent
      let dropdownX = left; // Align with the parent's left
      let dropdownHeight = maxDropdownHeight; // Set initial height
      let leftParent = false;
      let belowParent = false;

      // Check if there is enough space to position below the parent
      if (dropdownY + maxDropdownHeight > viewportHeight) {
        // Not enough space below; position it above the parent
        belowParent = true;
        dropdownY = top - maxDropdownHeight - 6; // Position above
        if (dropdownY < 0) {
          // Not enough space above; adjust height to fit the available space
          dropdownHeight = top - 6; // Shrink dropdown to fit above the parent
          dropdownY = 4; // Prevent it from going out of viewport top
        }
      }

      // Check for width overflow
      if (dropdownX + maxWidth > viewportWidth) {
        leftParent = true;
        dropdownX = viewportWidth - maxWidth - 4; // Adjust to keep the dropdown within the viewport
      }
      // Return the calculated position and dimensions
      return {
        x:
          this.isNormalDropdown(this.dropdownOptions) &&
          this.dropdownOptions?.dropdownSearchType !==
            DropdownSearchType.default
            ? left
            : dropdownX,
        y: dropdownY,
        width:
          this.isNormalDropdown(this.dropdownOptions) &&
          this.dropdownOptions?.dropdownSearchType !==
            DropdownSearchType.default
            ? width
            : maxWidth,
        height: dropdownHeight, // Set the dropdown height based on available space
        belowParent: belowParent,
        leftParent: leftParent,
      };
    }
    return { x: 0, y: 0, width: 0 };
  }
  closeSelect() {
    this.dropdownService.handleDropdownState("");
    this.toggleIconTrigger = false;
  }

  onInputBlur() {
    if (this.dropdownOptions.searchable) {
      this.isOptionClicked = false;
      let data = {
        value: this.inputValue,
        index: this.selectValueIndex,
      };
      this.dropdownService.sendSelectedOption(data, this.triggerId);
    }
  }
  onInputChange(event: any) {
    const target = event.target; // Cast to HTMLInputElement
    const value = target.value.trim();
    this.searchValue = value;
    if (value === "") {
      this.dropdownOptions["inputValue"] = "";
    }
    this.dropdownOptions["inputValue"] = value;
    // this.dropdownOptions["dataOptions"]["options"] = this.filterOptions;
    this.dropdownService.sendTriggerData(this.dropdownOptions, this.triggerId);
  }
  get filterOptions(): any {
    let searchValue = this.searchValue.toLowerCase()?.trim();
    if (searchValue) {
      // If input is empty, return the data as is
      return this.dropdownOptions?.["dataOptions"]?.["options"];
    }
    let matchingOptions: any;
    let nonMatchingOptions: any;
    let data: any = this.dropdownOptions?.["dataOptions"]?.["options"];

    // Filter only to identify matching options but keep the full list
    matchingOptions = data?.filter((option) =>
      option.toLowerCase().includes(searchValue)
    );
    // Move the matching options to the top without removing non-matching ones
    nonMatchingOptions = data?.filter(
      (option) => !option.toLowerCase().includes(searchValue)
    );
    const filteredOptions = [...matchingOptions];
    // Return the matching options first, followed by the non-matching options
    return filteredOptions;
  }
  getSelectedItems(): string[] {
    return this.dropdownOptions?.dataOptions?.newOptions || [];
  }

  onDropList(event: any) {
    let data = this.dropdownOptions.dataOptions.newOptions;
    moveItemInArray(data, event.previousIndex, event.currentIndex);
  }

  droppedItem: boolean = false; // Track the dropped item
  onItemDropped(event: CdkDragDrop<any>) {
    // this.droppedItem = event.item.data;
    this.droppedItem = true;
  }
  removeDragItem(item: any, i?: number) {
    let data = this.dropdownOptions.dataOptions.newOptions;
    const index = data.indexOf(item);
    if (index >= 0 || index > -1) {
      data.splice(index, 1);
      this.cd.detectChanges();
      this.dropdownService.sendTriggerData(
        this.dropdownOptions,
        this.triggerId
      );
      setTimeout(() => {
        this.dropdownService.sendPositionUpdate(this.calcPositionForDropdown());
      }, 1);
    }
  }

  isNormalDropdownPosition(dropdownOptions: DropdownOptions) {
    return (
      !dropdownOptions?.multiSelectDropDown &&
      !dropdownOptions.dropdownSearchType
    );
  }
  isNormalDropdown(dropdownOptions: DropdownOptions) {
    return !dropdownOptions?.multiSelectDropDown;
  }
  isInputAsSearchDropdown(dropdownOptions: DropdownOptions) {
    return (
      !dropdownOptions?.multiSelectDropDown &&
      dropdownOptions.dropdownSearchType === DropdownSearchType.default
    );
  }
  isMuiltiSelectDropdown(dropdownOptions: DropdownOptions) {
    return (
      dropdownOptions?.multiSelectDropDown &&
      !dropdownOptions.dropdownSearchType
    );
  }

  // Listen to window resize event
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    if (event) {
      this.dropdownService.handleDropdownState("");
      this.toggleIconTrigger = false;
    }
  }
  @HostListener("document:click", ["$event.target"])
  clickOutside(targetElement: any) {
    setTimeout(() => {
      const clickedInside =
        this.elementRef.nativeElement.contains(targetElement);
      const clickedInsideDropdown = document
        .querySelector(".input-dropdown-container")
        ?.contains(targetElement);
      // If the click is inside the dropdown, do nothing (don't close)
      if (clickedInsideDropdown) {
        return;
      }
      if (
        !clickedInside &&
        this.toggleIconTrigger &&
        !document
          .querySelector(".input-dropdown-container")
          ?.contains(targetElement)
      ) {
        this.closeDropDownTrigger();
      }
    }, 0);
  }
  closeDropDownTrigger() {
    this.dropdownService.handleDropdownState("");
    this.toggleIconTrigger = false;
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
@Component({
  selector: "app-lottie-flip-design",
  templateUrl: "./lottie-flip-design.component.html",
  styleUrls: ["./lottie-flip-design.component.scss"],
})
export class LottieFlipDesignComponent {
  @Input() mobileShow = false;
  @Input() fullScreen = false;
  @Input() showLogo = true;
  constructor() {}

  public lottieOptions: AnimationOptions = {
    path: "assets/lottie/login.json",
  };
}

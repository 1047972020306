import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { HeaderTitleInfo } from "../models/header-title-info.model";
import { ITemplateFlowInfo } from "../models/template-flow-info.model";
import { ToastTimer } from "../models/toast-timer.model";
import { pages, userRoles } from "../models/user-permissions.model";
import { tagType } from "../models/tag-type.model";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private isEventUpdate = new Subject<any>();
  public isEventReadingRulesUpdate = new Subject();
  public isEventDATExtensionUpdate = new Subject();
  public showHideReadRules = new Subject();
  public showhideDAT = new Subject();
  public showHideFilter = new Subject();
  public changeHeaderTitle = new Subject<string | HeaderTitleInfo>();
  public showHideFilterRows = new Subject();
  private currentUserSubject: BehaviorSubject<any>;
  public currentSelectedDepartment: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public currentUser: Observable<any>;
  public currentSettingsSection: BehaviorSubject<string> = new BehaviorSubject(
    "chase"
  );
  public profileInfo = new Subject<any>();
  public profile: any = null;
  public isSessionExpired = false;
  public templateFile = new BehaviorSubject<any>(null);
  public templateFlowInfo = new BehaviorSubject<ITemplateFlowInfo>({
    flowType: "add",
  });
  public isFetchingNewSessionToken = false;
  public sideBarToggle = false;

  public toasts: any[] = [];
  public toast: BehaviorSubject<ToastTimer[]> = new BehaviorSubject<
    ToastTimer[]
  >([]);
  public onHeaderTitleEdit = new BehaviorSubject<HeaderTitleInfo | null>(null);
  public isHeaderTitelError = new BehaviorSubject<boolean>(false);
  public logoutUserSubject = new BehaviorSubject<boolean>(false);
  public logoutUser$ = this.logoutUserSubject.asObservable();
  public preferenceUrl: string = "";
  public addTagInputLength: number = 60;
  getToasts(): Observable<ToastTimer[]> {
    return this.toast.asObservable();
  }

  private randomId() {
    return Math.random().toString(36).substring(2, 15);
  }
  showToast(data: ToastTimer) {
    // Check if there is more than one toast and if any has the same message
    if (this.toasts.length > 0) {
      const existingToastIndex = this.toasts.findIndex(
        (toast) => toast.message === data.message
      );
      if (existingToastIndex !== -1) {
        // Remove the existing toast with the same message
        const removedToast = this.toasts.splice(existingToastIndex, 1)[0];
        if (removedToast) {
          this.removeToast(removedToast.id);
        }
      }
    }
    // Check if the length exceeds 10 and remove the first 5 toasts
    if (this.toasts.length >= 8) {
      const toRemove = Math.min(this.toasts.length - 6, 6);
      for (let i = 0; i < toRemove; i++) {
        const removedToast = this.toasts.shift();
        if (removedToast) {
          this.removeToast(removedToast.id);
        }
      }
    }
    const toast: ToastTimer = {
      ...data,
      id: this.randomId(),
      startTime: Date.now(),
      remainingTime: data.duration,
      timeout: 0,
      // Initial time duration for the toast
    };
    this.toasts.push(toast);
    this.toast.next(this.toasts);
    this.startTimer(toast);
  }

  startTimer(toast: ToastTimer) {
    toast.timeout = setTimeout(() => {
      if (toast.id) this.removeToast(toast.id);
    }, toast.remainingTime);
  }
  pauseTimer(toast: ToastTimer) {
    if (toast.remainingTime) {
      clearTimeout(toast.timeout);
      if (toast.startTime) toast.remainingTime -= Date.now() - toast.startTime; // Update remaining time
      this.toast.next(this.toasts);
    }
  }
  resumeTimer(toast: ToastTimer) {
    if (toast.startTime) toast.startTime = Date.now(); // Reset start timethis.startTimer(toast); // Start with updated remaining time
    this.startTimer(toast);
  }
  removeToast(id: string) {
    this.toasts = this.toasts.filter((t) => t.id !== id);
    this.toast.next(this.toasts);
  }

  public templateData = new BehaviorSubject<any>(null);
  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>({});
    this.currentUser = this.currentUserSubject.asObservable();
  }

  changeCurrentSettingsSection(event: string) {
    this.currentSettingsSection.next(event);
  }

  setReadingRulesEventUpdate(event) {
    this.isEventReadingRulesUpdate.next(event);
  }

  setDATExtentionEventUpdate(event) {
    this.isEventDATExtensionUpdate.next(event);
  }

  setEventUpdate(event) {
    this.isEventUpdate.next(event);
  }

  setNewHeader(event: string | HeaderTitleInfo) {
    this.changeHeaderTitle.next(event);
  }

  setShowHideFiltersRows(event) {
    this.showHideFilterRows.next(event);
  }

  setShowHideFilter(event) {
    this.showHideFilter.next(event);
  }

  showHideReadRuleTab(event) {
    this.showHideReadRules.next(event);
  }

  showHideDatTab(event) {
    this.showhideDAT.next(event);
  }

  clearMessage() {
    this.isEventUpdate.next();
  }

  getEventUpdate(): Observable<any> {
    return this.isEventUpdate.asObservable();
  }

  getNewHeader() {
    return this.changeHeaderTitle.asObservable();
  }

  getReadingRulesEventUpdate(): Observable<any> {
    return this.isEventReadingRulesUpdate.asObservable();
  }

  getDATExtentionEventUpdate(): Observable<any> {
    return this.isEventDATExtensionUpdate.asObservable();
  }

  getShowHideFilterRows(): Observable<any> {
    return this.showHideFilterRows.asObservable();
  }

  getShowHideFilter(): Observable<any> {
    return this.showHideFilter.asObservable();
  }

  getShowHideReadRules(): Observable<any> {
    return this.showHideReadRules.asObservable();
  }

  getShowHideDATTab(): Observable<any> {
    return this.showhideDAT.asObservable();
  }

  setCurrentUser(info) {
    this.currentUserSubject.next(info);
  }

  clearCurrentUser() {
    this.currentUserSubject.next(null);
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.value;
  }

  setcurrentDepartment(dept) {
    this.currentSelectedDepartment.next(dept);
  }

  getProfileInfo(): Observable<any> {
    return this.profileInfo.asObservable();
  }

  setProfileInfo(profileInfo) {
    this.profile = profileInfo;
    this.profileInfo.next(profileInfo);
  }

  canUserNavigate(tab: string) {
    let role = "DEVELOPER";
    // 1 -> USER
    // 2 -> DEVELOPER
    // 3 -> ANALYST
    // 4 -> ADMIN
    if (this.profile) {
      if (this.profile.roles.length > 0) {
        role = this.profile.roles[0].roleName ?? "";
      }
    }
    // Show only when role is ADMIN or DEVELOPER
    if (
      (tab.includes("designer") || tab.includes("pipelines")) &&
      role.toLowerCase() != "admin" &&
      role.toLowerCase() != "developer"
    ) {
      return false;
    }
    // Show to everyone except USER
    if (
      (tab.includes("dashboard") ||
        tab.includes("settings") ||
        tab.includes("monitor")) &&
      role.toLowerCase() == "user"
    ) {
      return false;
    }
    // Show only to ADMIN
    // if (tab.includes("manage-users") && role.toLowerCase() != "admin") {
    //   return false;
    // }
    if (
      (tab === pages.adminConsole || tab.includes("manage-users")) &&
      role !== userRoles.admin
    ) {
      return false;
    }

    return true;
  }

  // APIs
  public updatePipelineTags(data) {
    return this.httpClient
      .put(`${environment.apiUrl}/api/definitions/updatePipelineTag`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public updateUserTags(data) {
    return this.httpClient
      .put(`${environment.apiUrl}/api/definitions/updateUserTag`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getTagSuggestions(tageType: tagType) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/definitions/getTags/${tageType}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getDepartments() {
    return this.httpClient.get(`${environment.apiUrl}/api/getDepartments`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public getFolders() {
    return this.httpClient.get(`${environment.apiUrl}/api/getTeams`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public createFolder(folder: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/createTeam`, folder)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //   public deleteFolder(folderName: string) {
  //     return this.httpClient
  //       .delete(
  //         `${environment.apiUrl}/api/user/deleteTeam/${encodeURI(folderName)}`
  //       )
  //       .pipe(
  //         map((response) => {
  //           return response;
  //         })
  //       );
  //   }

  public getUserDetails() {
    return this.httpClient.get(`${environment.apiUrl}/api/getUserInfo`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public logoutUser() {
    return this.httpClient.get(`${environment.apiUrl}/api/userLogout`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  public isSettingsSystemTabVisible() {
    return this.httpClient
      .get(`${environment.apiUrl}/api/system/visibility`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateUser(data) {
    return this.httpClient
      .put(`${environment.apiUrl}/api/user/update`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getUserData() {
    return this.httpClient.get(`${environment.apiUrl}/api/userInfo`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  public validateRegistration(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/validateRegistration`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public refreshSessionToken(data: { refreshToken: string }) {
    return this.httpClient.post(`${environment.apiUrl}/api/refreshToken`, data);
  }

  public createMigrationPipeline(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/migration/createMigrationPipeline`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateMigrationPipeline(data: any) {
    return this.httpClient
      .put(`${environment.apiUrl}/api/migration/updateMigrationPipeline`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getMigrationDetails(importId: string) {
    return this.httpClient
      .get(
        `${environment.apiUrl}/api/migration/getMigrationPipeline?importId=${importId}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public submitMigration(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/migration/submitMigrationJob`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getKbrJson() {
    return this.httpClient.get("assets/kbr.json");
  }

  isMobileView() {
    // If the max-width is changed here then update the same value
    // in whereever you find MAX_VW_WIDTH_FOR_MOBILE comment
    return window.innerWidth < 600;
  }
  isHideActionIcons() {
    return window.innerWidth < 800;
  }

  isLogoutRequest(val: boolean) {
    this.logoutUserSubject.next(val);
  }

  public setUserPreferences(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/setUserPreference`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}

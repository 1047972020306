import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./pages/login/login.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { RegisterComponent } from "./pages/register/register.component";
import { RegistrationVerificationComponent } from "./pages/registration-verification/registration-verification.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { SetPasswordComponent } from "./pages/set-password/set-password.component";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    RegistrationVerificationComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    RegistrationVerificationComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
  ],
})
export class AuthModule {}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModelCardComponent } from "./model-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
  declarations: [ModelCardComponent],
  imports: [CommonModule],
  exports: [ModelCardComponent],
})
export class ModelCardModule {}

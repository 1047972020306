import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ToastTimer } from "src/app/core/models/toast-timer.model";
import { AppService } from "src/app/core/services/app.service";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent implements OnInit, OnDestroy {
  isOpen = false;
  showTimebar: boolean = false;
  private subscription?: Subscription;
  timebarAnimationState: string = "running";
  public errorType: string = "";
  errorMessage: string = "";
  timer: any;
  remainingTime: number = 5000; // Initial timeout duration in milliseconds
  isMouseOver: boolean = false;
  @ViewChild("timebar") timebar!: ElementRef;
  currentToasts: any[] = [];
  toastHeight = 76;
  @ViewChildren("toastElement") toastElements!: QueryList<ElementRef>;
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.subscription = this.appService.getToasts().subscribe((toasts) => {
      this.currentToasts = toasts;
    });
  }
  closeToast(id: string) {
    this.appService.removeToast(id);
  }

  calculateTopPosition(index: number): number {
    return index * this.toastHeight;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    clearTimeout(this.timer); // Clear the timer on component destruction
  }

  pauseTimebar(toast: ToastTimer) {
    this.appService.pauseTimer(toast);
  }
  resumeTimebar(toast: ToastTimer) {
    this.appService.resumeTimer(toast);
  }
}

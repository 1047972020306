import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { AIModel } from "../../models/ai-model.interface";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ConfirmPopupService } from "src/app/shared/components/confirm-popup/confirmpopup.service";

@Component({
  selector: "app-model-card",
  templateUrl: "./model-card.component.html",
  styleUrls: ["./model-card.component.scss"],
})
export class ModelCardComponent {
  url = environment.apiUrl;
  @Input() modelInfo: AIModel | null = null;
  @Input() cardExternalLink?: string;
  @Input() selectedExternalLink: string | null = null;
  @Output() openExternalFrame = new EventEmitter<any>();
  @Output() closeExternalFrame = new EventEmitter<void>();
  @Input() currentSection: string = "";
  @Output() migrationSelection = new EventEmitter<any>();

  constructor(
    private ConfirmPopupService: ConfirmPopupService,
    private sanitizer: DomSanitizer
  ) {}

  get link() {
    if (this.currentSection === "migrationTool") {
      return "#";
    }
    if (
      this.modelInfo?.externalLink &&
      this.modelInfo.externalLink.startsWith("http")
    ) {
      return this.modelInfo.externalLink;
    }
    const baseUrl = "https://ai-workbench.flipnow.cloud/";
    let parameters = {};
    parameters["model"] = encodeURIComponent(
      this.modelInfo?.modelName || "none"
    );
    parameters["model_code"] = encodeURIComponent(
      this.modelInfo?.modelCode || "none"
    );
    parameters["interaction"] = encodeURIComponent(
      this.modelInfo?.interactionType || "none"
    );
    if (this.modelInfo?.applicationName) {
      if (this.modelInfo.applicationName.startsWith("/")) {
        this.modelInfo.applicationName =
          this.modelInfo.applicationName.substring(1);
      }

      if (!this.modelInfo?.applicationName.endsWith("/")) {
        this.modelInfo.applicationName += "/";
      }
    }

    let query = "";
    if (Object.keys(parameters).length > 0) {
      query =
        "?" +
        Object.keys(parameters)
          .map((key) => `${key}=${parameters[key]}`)
          .join("&");
    }
    return `${baseUrl}${this.modelInfo?.applicationName || ""}${query}`;
  }

  showExternalLink() {
    if (!this.selectedExternalLink) {
      this.openExternalFrame.emit(this.modelInfo);
      if (
        !this.modelInfo!.tags?.split(",").find(
          (tag) => tag.trim().toLowerCase() == "full-screen"
        )
      ) {
        setTimeout(() => {
          document
            .querySelector("#selected-chatbot")
            ?.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 250);
      }
    } else {
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: "Are you sure to switch to another chatbot?",
        confirmButtonText: "Confirm",
        headerText: "Confirmation",
        callback: (val: boolean) => {
          if (val) {
            this.openExternalFrame.emit(this.modelInfo);
          }
        },
      });
    }
  }

  get tags() {
    const specialTags = ["expanded"];
    return (
      this.modelInfo?.tags
        ?.split(",")
        ?.filter((tag) => !specialTags.includes(tag.trim().toLowerCase())) ?? []
    );
  }

  navigateTo(event: any) {
    if (this.currentSection === "migrationTool") {
      event.preventDefault();
      this.migrationSelection.emit(this.modelInfo);
    }
  }

  sanitizeImageUrl(imageUrl: string | null | undefined): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      imageUrl || "assets/images/ai_no_icon.svg"
    );
  }
}

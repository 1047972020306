import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ConfirmationDialogData } from "../confirm-popup/confirmpopup.service";

@Injectable({
  providedIn: "root",
})
export class SheetHeaderService {
  constructor() {}

  public missingSheetHeaders: BehaviorSubject<any> =
    new BehaviorSubject<ConfirmationDialogData>({
      message: "",
    });

  getMissingSheetHeaders(): Observable<ConfirmationDialogData> {
    return this.missingSheetHeaders.asObservable();
  }
}

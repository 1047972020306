import { Injectable } from "@angular/core";
import * as cookie from "cookie";

export interface TokenDetails {
  accessToken: string;
  accessTokenType: string;
  accessTokenExpiresIn: number;
  refreshToken: string;
}

@Injectable({
  providedIn: "root",
})
export class TokenStorageService {
  private token?: TokenDetails;

  constructor() {
    this.readToken();
  }

  readToken() {
    if (localStorage.getItem("token")) {
      this.token = JSON.parse(localStorage.getItem("token")!);
    }
  }

  setToken(token: TokenDetails) {
    const ck = cookie.serialize("jwtToken", token.accessToken, {
      secure: true,
      sameSite: "strict",
      maxAge: 24 * 60 * 60 * 1000,
    });
    this.token = token;
    document.cookie = ck;
    localStorage.setItem("token", JSON.stringify(token));
  }

  hasToken() {
    return this.token?.accessToken && this.token.accessToken.length > 5;
  }

  removeToken() {
    document.cookie = `jwtToken = ; expires=Thu, 1 jan 1990 12:00:00 UTC; path=/`;
    this.token = undefined;
    localStorage.removeItem("token");
  }

  getToken() {
    return this.token?.accessToken;
  }

  getRefreshToken() {
    return this.token?.refreshToken;
  }

  getTokenType() {
    return this.token?.accessTokenType;
  }
}

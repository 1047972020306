import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route, Router } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomPreloadStrategy extends PreloadingStrategy {
  constructor(private router: Router) {
    super();
  }
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (
      !this.router.url.includes("/login") &&
      !this.router.url.includes("/register") &&
      route.data?.preload
    ) {
      return load();
    }

    return of(null);
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ConfirmationDialogData,
  ConfirmPopupService,
} from "../confirm-popup/confirmpopup.service";
import { Subscription, Subject } from "rxjs";
import { SheetHeaderService } from "./sheet-header.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-sheet-header-list-popup",
  templateUrl: "./sheet-header-list-popup.component.html",
  styleUrls: ["./sheet-header-list-popup.component.scss"],
})
export class SheetHeaderListPopupComponent implements OnInit, OnDestroy {
  constructor(private SheetHeaderService: SheetHeaderService) {}

  public subscription?: Subscription;
  public messageObject: ConfirmationDialogData = {
    message: "",
  };
  public confirmButtonText: string = "";
  public cancelButtonText: string = "";
  public headerText: string = "";
  public closeMessage: boolean = false;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  public callback?: (result: boolean) => void;
  public expandedSheets: Set<string> = new Set<string>();
  public selectedHeaders: { [sheetName: string]: string[] } = {};
  public alreadyaddedMissingHeadersList: any[] = [];
  public xlHeaders: any[] = [];

  ngOnInit(): void {
    this.subscription = this.SheetHeaderService.getMissingSheetHeaders()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: ConfirmationDialogData) => {
        if (res["message"] && res["xlHeaders"]) {
          this.messageObject = { message: "" };
          this.messageObject = res;
          this.xlHeaders = [];
          this.selectedHeaders = {};
          this.expandedSheets = new Set<string>();
          this.alreadyaddedMissingHeadersList = [];
          this.xlHeaders = res["xlHeaders"];
          this.confirmButtonText = res.confirmButtonText || "";
          this.cancelButtonText = res.cancelButtonText || "";
          this.headerText = res.headerText || "Missing Sheet Headers";
          this.xlHeaders?.forEach((sheet: any) => {
            this.expandedSheets.add(sheet.sheetName);
          });
          if (res["addedHeaders"] && res["addedHeaders"].length > 0) {
            this.alreadyaddedMissingHeadersList = res["addedHeaders"];
          }
          this.closeMessage = true;
        }
      });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  toggleSheet(sheetName: string): void {
    if (this.expandedSheets.has(sheetName)) {
      this.expandedSheets.delete(sheetName);
    } else {
      this.expandedSheets.add(sheetName);
    }
  }

  isExpanded(sheetName: string): boolean {
    return this.expandedSheets.has(sheetName);
  }

  selectHeader(sheetName: string, header: string): void {
    if (!this.selectedHeaders[sheetName]) {
      this.selectedHeaders[sheetName] = [];
    }
    const selectedArray = this.selectedHeaders[sheetName];
    const index = selectedArray.indexOf(header);

    if (index === -1) {
      selectedArray.push(header);
    } else {
      selectedArray.splice(index, 1);
    }
  }

  isSelected(sheetName: string, header: string): boolean {
    return this.selectedHeaders[sheetName]?.includes(header) || false;
  }

  getUniqueHeaders(headers: string[]): string[] {
    return Array.from(new Set(headers));
  }
  toggleSelectSheetAll(sheetName: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    const isIndeterminate = this.areSomeHeadersSelected(sheetName);

    const allHeaders = this.getUniqueHeaders(
      this.getHeadersForSheet(sheetName)
    );
    const enabledHeaders = allHeaders.filter(
      (header) => !this.isHeaderDisabled(header)
    );

    if (isIndeterminate) {
      // Deselect all enabled headers if the checkbox was in the intermediate state
      this.selectedHeaders[sheetName] = [];
      (event.target as HTMLInputElement).checked = false; // Ensure checkbox remains unchecked
    } else if (isChecked) {
      // Select all enabled headers
      this.selectedHeaders[sheetName] = enabledHeaders;
    } else {
      // Deselect all headers
      this.selectedHeaders[sheetName] = [];
    }
  }

  // Check if all headers are selected for a specific sheet
  areAllHeadersSelected(sheetName: string): any {
    const headers = this.getUniqueHeaders(
      this.xlHeaders.find((sheet) => sheet.sheetName === sheetName)?.headers ||
        []
    );
    const selected = this.selectedHeaders[sheetName] || [];
    return (
      headers.length > 0 && headers.every((header) => selected.includes(header))
    );
  }

  toggleSelectAll(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    this.xlHeaders.forEach((sheet) => {
      const allHeaders = this.getUniqueHeaders(sheet.headers);
      const enabledHeaders = allHeaders.filter(
        (header) => !this.isHeaderDisabled(header)
      );

      if (isChecked) {
        // Only select headers that are not disabled
        this.selectedHeaders[sheet?.sheetName] = enabledHeaders;
      } else {
        // Deselect all headers
        this.selectedHeaders[sheet?.sheetName] = [];
      }
    });

    this.updateSheetCheckboxes(); // Update the UI checkboxes to reflect the changes
  }

  // Check if all headers are selected across all sheets
  areAllSheetsHeadersSelected(): boolean {
    return this.xlHeaders.every((sheet) => {
      const headers = this.getUniqueHeaders(sheet.headers).filter(
        (header) => !this.isHeaderDisabled(header)
      );
      const selected = this.selectedHeaders[sheet.sheetName] || [];
      return (
        headers.length > 0 &&
        headers.every((header) => selected.includes(header))
      );
    });
  }

  areSomeHeadersSelected(sheetName: string): boolean {
    const allHeaders = this.getUniqueHeaders(
      this.getHeadersForSheet(sheetName)
    );
    const enabledHeaders = allHeaders.filter(
      (header) => !this.isHeaderDisabled(header)
    );
    const selected = this.selectedHeaders[sheetName] || [];

    return (
      selected?.length > 0 &&
      selected?.length < enabledHeaders?.length &&
      enabledHeaders.length > 0
    );
  }

  getHeadersForSheet(sheetName: string): string[] {
    const sheet = this.xlHeaders.find((sheet) => sheet.sheetName === sheetName);
    return sheet ? sheet?.headers : [];
  }
  public isHeaderDisabled(header: string): boolean {
    return this.alreadyaddedMissingHeadersList?.includes(header);
  }
  isSheetCheckboxDisabled(sheetName: string): boolean {
    const sheetHeaders = this.getHeadersForSheet(sheetName);
    return sheetHeaders.every((header) =>
      this.alreadyaddedMissingHeadersList.includes(header)
    );
  }

  private updateSheetCheckboxes(): void {
    this.xlHeaders.forEach((sheet) => {
      const headers = this.getUniqueHeaders(sheet.headers);

      // Count the selected headers for the sheet
      const selectedHeadersCount = (this.selectedHeaders[sheet.sheetName] || [])
        .length;

      // Count the total selectable headers, excluding disabled ones
      const totalSelectableHeaders = headers.filter(
        (header) => !this.isHeaderDisabled(header)
      ).length;

      // Get the sheet checkbox element by ID
      const sheetCheckbox = document.getElementById(
        `sheet-checkbox-${sheet.sheetName}`
      ) as HTMLInputElement;

      // Logic to check or set indeterminate state for the sheet checkbox
      if (selectedHeadersCount === totalSelectableHeaders) {
        sheetCheckbox.checked = true;
        sheetCheckbox.indeterminate = false;
      } else if (selectedHeadersCount > 0) {
        sheetCheckbox.checked = false;
        sheetCheckbox.indeterminate = true;
      } else {
        sheetCheckbox.checked = false;
        sheetCheckbox.indeterminate = false;
      }

      // Uncheck any disabled headers explicitly
      headers.forEach((header) => {
        if (this.isHeaderDisabled(header)) {
          const headerCheckbox = document.getElementById(
            `header-checkbox-${sheet.sheetName}-${header}`
          ) as HTMLInputElement;
          if (headerCheckbox) {
            headerCheckbox.checked = false; // Uncheck the disabled checkbox
          }
        }
      });
    });
  }

  isAnyHeaderSelected(): boolean {
    return Object.values(this.selectedHeaders).some(
      (headers) => headers?.length > 0
    );
  }

  closePopup(noCallback = false) {
    this.closeMessage = false;
    if (noCallback) return;
    this.messageObject["isConfirm"] = "false";
    if (this.messageObject["manageCallback"]) {
      this.messageObject.manageCallback({ isConfirm: "false" });
    }
  }
  cancelRequest() {
    this.closeMessage = false;
    this.messageObject["isConfirm"] = "false";
    if (this.messageObject["manageCallback"]) {
      this.messageObject.manageCallback({ isConfirm: "false" });
    }
  }
  addREquest() {
    this.closeMessage = false;
    let data = {
      isConfirm: "true",
      headers: this.selectedHeaders,
      selectAll: true,
    };
    if (this.messageObject["manageCallback"]) {
      this.messageObject.manageCallback(data);
    }
  }
}

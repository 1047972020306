import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @Input() filterArray: any[] = [];
  @Input() showFilter: boolean = false;
  @Input() rows: any[] = [];
  @Input() refreshFilter: boolean = false;
  @Output() applyFilter = new EventEmitter();
  @Output() backToIntitalData = new EventEmitter();
  @Output() startDateChange = new EventEmitter();
  @Output() endDateChange = new EventEmitter();
  @Output() updateFilterData = new EventEmitter();
  public filterSearch: any;
  public customRangeSelected: boolean = false;
  public startDate: string = "";
  public endDate: string = "";
  public initalFilterArray: any[] = [];
  @Input() startDateInputIfGiven: string = ""; // *NOTE: This input is just in case of nested components, no need to pass this as a prop if non-nested components
  @Input() endDateInputIfGiven: string = ""; // *NOTE: This input is just in case of nested components, no need to pass this as a prop if non-nested components
  public filterApplied: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.initalFilterArray = this.filterArray;
    this.filterApplied = this.filterArray.some((filter) => {
      return filter.data.some((data) => {
        if (
          data.id === "custom" &&
          this.startDate !== "" &&
          this.endDate !== ""
        ) {
          return true;
        } else if (data.selected) {
          return true;
        }
        return false;
      });
    });
    this.customRangeSelected = this.filterArray.some((filter) => {
      return filter.data.some((data) => {
        if (data.id === "custom" && data.selected) {
          return true;
        }
        return false;
      });
    });
    if (this.startDateInputIfGiven) {
      this.startDate = this.startDateInputIfGiven;
    }
    if (this.endDateInputIfGiven) {
      this.endDate = this.endDateInputIfGiven;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshFilter && changes.refreshFilter.currentValue) {
      this.resetFiltering();
    }
  }
  showHideFilterIp(): void {
    this.showFilter = !this.showFilter;
    if (!this.showFilter) {
      // Check if any element in the filterArray meets the condition
      this.filterApplied = this.filterArray.some((filter) => {
        return filter.data.some((data) => {
          if (
            data.id === "custom" &&
            this.startDate !== "" &&
            this.endDate !== ""
          ) {
            return true;
          } else if (data.selected) {
            return true;
          }
          return false;
        });
      });
    }
  }
  public clearFilterSearch() {
    this.filterSearch = "";
    this.filterArray = [...this.initalFilterArray];
    return;
  }
  public changeFilterSearch(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.filterSearch = searchTerm;
    this.filterArray = [...this.initalFilterArray];

    if (searchTerm === "") {
      this.closeAllFilters();
      return;
    }
    this.filterArray = this.filterArray.map((currentFilter) => {
      const filteredData = currentFilter.data.filter((element) =>
        element.name.toLowerCase().includes(searchTerm)
      );
      return {
        ...currentFilter,
        data: filteredData,
        opened: true,
      };
    });
    this.updateFilterData.emit(this.filterArray);
  }

  private closeAllFilters() {
    this.filterArray.forEach((item) => (item.opened = false));
  }
  public openAccordion(index) {
    this.filterArray[index]["opened"] = !this.filterArray[index]["opened"];
  }
  public filterCheckbox(name, index) {
    for (const currentFilter of this.filterArray) {
      const foundData = currentFilter.data.find((data) => data.name === name);

      if (foundData) {
        foundData.selected = !foundData.selected;
      }
    }
  }
  public radioButtonsClicked(data, index) {
    let parentIndex = -1;
    let childIndex = index;
    for (let i = 0; i < this.filterArray.length; i++) {
      let currentFilterItem = this.filterArray[i].data;
      if (currentFilterItem === data) {
        parentIndex = i;
      }
      if (this.filterArray[i].id === "date") {
        currentFilterItem.forEach((item) => (item.selected = false));
      }
    }
    this.filterArray[parentIndex].data[childIndex].selected = true;
    this.customRangeSelected = data[index].id === "custom";
  }
  public dateInputsChanged(type, event) {
    if (type === "start") {
      this.startDate = event.target.value;
      this.startDateChange.emit(event.target.value);
    } else if (type === "end") {
      this.endDate = event.target.value;
      this.endDateChange.emit(event.target.value);
    }
  }
  public resetFiltering() {
    this.closeAllFilters();
    this.clearFilterSearch();
    this.filterArray.forEach((filter) => {
      filter.data.forEach((data) => {
        data.selected = false;
      });
    });
    this.customRangeSelected = false;
    this.backToIntitalData.emit();
    this.filterApplied = false;
    this.startDate = "";
    this.endDate = "";
    this.updateFilterData.emit(this.filterArray);
  }
  public isAnyFilterSelected(): boolean {
    return this.filterArray.some((filter) =>
      filter.data.some((item) => item.selected)
    );
  }

  public applyFiltering() {
    this.applyFilter.emit();
    this.updateFilterData.emit(this.filterArray);
    this.showHideFilterIp();
  }
  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest(".filterBySelected")) {
      this.showFilter = false;
    }
    if (targetElement.closest(".filterBySelected")) {
      event.stopPropagation();
    }
  }
}

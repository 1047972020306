import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortOrderBy",
})
export class SortObjectByPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!Array.isArray(array)) {
      return array;
    }
    array.sort((a: any, b: any) => {
      const aField: string = a[field];
      const bField: string = b[field];

      if (aField == null) return 1;
      if (bField == null) return -1;

      return aField.localeCompare(bField);
    });
    return array;
  }
}

<ng-container
  *ngIf="!dropzone && !isloading && row && page === 'Transformation'">
  <div class="flip-card dataPipelines">
    <div class="placeholder" (click)="openMappings()"></div>
    <div class="card-header">
      <div class="profile-details">
        <div class="user-icon">
          {{ getProfileName(row) }}
        </div>
        <div class="card-profile-details">
          <label class="title"
            >{{ row["firstName"] || "-" }} {{ row["lastName"] || "-" }}
          </label>
          <label
            class="createdDate"
            [title]="
              row['modifiedDate']
                ? row['modifiedDate']
                : (row['createdDate'] | date)
            ">
            <ng-container *ngIf="row['modifiedDate']; else showValue2">
              {{ row["modifiedDate"] | date }}
            </ng-container>
            <ng-template #showValue2>
              {{ row["createdDate"] | date }}
            </ng-template></label
          >
        </div>
        <div class="is-active" [ngClass]="{ active: row['activeYn'] === 'Y' }">
          {{ row["activeYn"] === "Y" ? "Active" : "Inactive" }}
        </div>
      </div>
      <div class="menu" [ngClass]="{ open: isMenuOpened }">
        <button
          (click)="showMenu()"
          (blur)="hideMenu()"
          [ngClass]="{ activeOptions: isMenuOpened }">
          <i class="ti ti-dots-vertical"></i>
        </button>
        <div class="options">
          <div
            (click)="sendConfirmMessage(row)"
            class="option active-status-wrapper">
            <div class="activeStatusLabel">Active</div>
            <label class="toggle small">
              <input
                [ngModel]="row['activeYn'] === 'Y' ? true : false"
                class="active-checkbox"
                [checked]="row['activeYn'] === 'Y' ? true : false"
                type="checkbox"
                [value]="row['activeYn'] === 'Y' ? true : false"
                [disabled]="!access" />
              <div>Status</div>
            </label>
          </div>
          <!-- <button class="option" (click)="openMappings(row)">Edit</button> -->
          <!-- <div class="option" (click)="openTags()"><i class="ti ti-square-plus icon-style"></i> Add Tags</div> -->
          <div
            class="option"
            (click)="openConnections()"
            *ngIf="row['importType'] !== 'migration'">
            Connections
          </div>
          <div class="option" (click)="openTags()">Tags</div>
          <div class="option" (click)="exportMap()">Export</div>
          <div class="option" (click)="copyMap()">Copy</div>
          <div class="option" (click)="deleteMap()">Delete</div>
        </div>
      </div>
    </div>
    <div class="card-body" (click)="openMappings()">
      <div
        class="migration-icons"
        *ngIf="row['importType'] === 'migration'"
        [title]="migrationDetails[0]?.name">
        <img
          [src]="
            sanitizeImageUrl(migrationDetails[0]?.sourceFileImage) ||
            'assets/images/ai_no_icon.svg'
          "
          alt="icon" />
        <i class="ti ti-arrow-narrow-right"></i>
        <img
          [src]="
            sanitizeImageUrl(migrationDetails[0]?.targetFileImage) ||
            'assets/images/ai_no_icon.svg'
          "
          alt="icon" />
      </div>
      <img
        src="assets/Svgs_/TransformationStudio/CardIcon.svg"
        draggable="false"
        alt="Mapping"
        class="imgTransformation"
        *ngIf="row['importType'] === 'data' || !row['importType']" />
    </div>
    <div class="card-footer" (click)="openMappings()">
      <div class="sub-footer">
        <div class="heading">
          <a [title]="row['importName']">
            <h2 class="card-title">{{ row["importName"] }}</h2>
          </a>
        </div>
        <div class="tags">
          <ng-container *ngIf="row['attributes']['tags']?.length > 0">
            <div class="tag">{{ row["attributes"]["tags"][0] }}</div>
            <div
              class="tag more-tags"
              *ngIf="row['attributes']['tags']?.length > 1">
              ...
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              row['attributes']['tags']?.length === 0 ||
              !row['attributes']['tags']
            ">
            <div class="tag">No tags</div>
          </ng-container>
        </div>
      </div>
      <div class="sub-footer1" *ngIf="row['importType'] !== 'migration'">
        <label
          class="fileData"
          [title]="row['fileFormat'] ? row['fileFormat'] : 'No File Format'">
          {{ row["fileFormat"] || "No File Format" }}
        </label>
        <label
          class="fileData"
          [title]="row['fileType'] ? row['fileType'] : 'No File Type'">
          {{ row["fileType"] || "No File Type" }}
        </label>
      </div>
      <div class="sub-footer1" *ngIf="row['importType'] === 'migration'">
        <label
          class="fileData"
          [title]="
            migrationDetails[0]?.name
              ? migrationDetails[0]?.name
              : 'No migration Name'
          ">
          {{ migrationDetails[0]?.name || "No migration Name" }}
        </label>
      </div>
    </div>
    <div class="backface">
      <p><strong>Tags</strong></p>
      <div class="line"></div>
      <div class="allTags">
        <div class="tag" *ngFor="let tag of row['attributes']['tags']">
          {{ tag }}
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!dropzone && !isloading && page === 'Monitor'">
  <div class="flip-card dataPipelines">
    <div class="placeholder" (click)="openMappings()"></div>
    <div class="card-header">
      <div class="profile-details">
        <div class="user-icon">
          {{ getMonitorProfileName(row, "icon") }}
        </div>
        <div class="card-profile-details">
          <label class="title"
            >{{ getMonitorProfileName(row, "firstName") || "-" }}
            {{ getMonitorProfileName(row, "lastName") || "-" }}</label
          >
          <label
            class="createdDate"
            [title]="
              row['modifiedDate']
                ? row['modifiedDate']
                : (row['createdDate'] | date)
            ">
            <ng-container *ngIf="row['modifiedDate']; else showValue2">
              {{ row["modifiedDate"] | date }}
            </ng-container>

            <ng-template #showValue2>
              {{ row["createdDate"] | date }}
            </ng-template>
          </label>
        </div>
        <div class="is-active" [ngClass]="row['status']">
          {{ row["status"] }}
        </div>
      </div>
      <div class="menu" [ngClass]="{ open: isMenuOpened }">
        <button
          (click)="showMenu()"
          (blur)="hideMenu()"
          [ngClass]="{ activeOptions: isMenuOpened }">
          <i class="ti ti-dots-vertical"></i>
        </button>
        <div class="options">
          <!-- <button class="option" (click)="openMappings(row)">Edit</button> -->
          <!-- <div class="option" (click)="openTags()"><i class="ti ti-square-plus icon-style"></i> Add Tags</div> -->
          <div class="option" (click)="resolve()">Resolve</div>
          <div class="option" (click)="reprocess()">Reprocess</div>
          <div class="option" (click)="openOverlay(row)">Overview</div>
        </div>
      </div>
    </div>
    <div class="card-body" (click)="openMappings()">
      <img
        class="monitorIcon"
        src="assets/Svgs_/Monitor/CSV.svg"
        alt=""
        *ngIf="row['fileType']?.toLowerCase() === 'csv'"
        style="transform: scale(1.2) translate(-4px, 4px)" />
      <img
        class="monitorIcon"
        src="assets/Svgs_/Monitor/PDF.svg"
        alt=""
        *ngIf="row['fileType']?.toLowerCase() === 'pdf/image'"
        style="transform: scale(1.2) translate(-4px, 4px)" />
      <img
        class="monitorIcon"
        src="assets/Svgs_/Monitor/XLS.svg"
        alt=""
        *ngIf="
          row['fileType']?.toLowerCase() === 'xls' ||
          row['fileType']?.toLowerCase() === 'xlsx'
        "
        style="transform: scale(1.2) translate(-4px, 4px)" />
      <img
        class="monitorIcon"
        src="assets/Svgs_/Monitor/NoFIleType.svg"
        alt=""
        *ngIf="!row['fileType'] || row['fileType'].toLowerCase() === 'null'"
        style="transform: scale(1.2) translate(-4px, 4px)" />
      <img
        class="monitorIcon"
        src="assets/Svgs_/Monitor/TXT.svg"
        alt=""
        *ngIf="row['fileType']?.toLowerCase() === 'txt'"
        style="transform: scale(1.2) translate(-4px, 4px)" />
    </div>
    <div (click)="openMappings()" class="card-footer">
      <div class="sub-footer">
        <div class="heading">
          <a
            [href]="url"
            (click)="$event.stopPropagation()"
            [title]="row['fileName']">
            <h2 class="card-title">{{ row["fileName"] }}</h2>
          </a>
        </div>
        <div class="tags">
          <!-- <div class="tag" *ngFor="let tag of row['tags']">{{tag}}</div> -->
          <ng-container *ngIf="row['tags']?.length > 0">
            <div class="tag">{{ row["tags"][0] }}</div>
            <div class="tag more-tags" *ngIf="row['tags']?.length > 1">...</div>
          </ng-container>
          <ng-container *ngIf="row['tags']?.length === 0 || !row['tags']">
            <div class="tag">No tags</div>
          </ng-container>
        </div>
      </div>
      <div class="sub-footer1">
        <label
          class="fileData"
          [title]="row['importName'] ? row['importName'] : 'No Pipeline Name'">
          {{ row["importName"] || "No Pipeline Name" }}
        </label>
        <label
          class="fileData"
          [title]="row['fileFormat'] ? row['fileFormat'] : 'No File Format'">
          {{ row["fileFormat"] || "No File Format" }}
        </label>
        <label
          class="fileData"
          [title]="row['fileType'] ? row['fileType'] : 'No File Type'">
          {{ row["fileType"] || "No File Type" }}
        </label>
      </div>
    </div>
    <div class="backface">
      <p><strong>Tags</strong></p>
      <div class="line"></div>
      <div class="allTags">
        <div class="tag" *ngFor="let tag of row['tags']">{{ tag }}</div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!dropzone && !isloading && row && page === 'Data Sources'">
  <div class="flip-card">
    <div class="placeholder" (click)="openConnections()"></div>
    <div class="card-header">
      <div class="profile-details">
        <div class="user-icon">
          {{ getProfileName(row) }}
        </div>
        <div class="card-profile-details">
          <label class="title"
            >{{ row["firstName"] || "-" }} {{ row["lastName"] || "-" }}</label
          >
          <label class="createdDate" [title]="row['createdDate'] | date">
            {{ row["createdDate"] | date }}</label
          >
        </div>
        <!-- <div class="is-active" [ngClass]="{ active: row['activeYn'] === 'Y' }">
          {{ row["activeYn"] === "Y" ? "Active" : "Inactive" }}
        </div> -->
      </div>
      <div class="menu" [ngClass]="{ open: isMenuOpened }">
        <button
          (click)="showMenu()"
          (blur)="hideMenu()"
          [ngClass]="{ activeOptions: isMenuOpened }">
          <i class="ti ti-dots-vertical"></i>
        </button>
        <div class="options">
          <div class="option" (click)="copyMap()">Copy</div>
          <div class="option" (click)="deleteMap()">Delete</div>
        </div>
      </div>
    </div>
    <div class="card-body" (click)="openConnections()">
      <img
        [src]="row['icon'] || 'assets/images/data-sources/unidentified.svg'"
        class="dataSourceIcon"
        draggable="false"
        [alt]="row['connectionType']" />
    </div>
    <div (click)="openConnections()" class="card-footer">
      <div class="sub-footer">
        <div class="heading">
          <a [title]="row['connectionName']">
            <h2 class="card-title">{{ row["connectionName"] }}</h2>
          </a>
        </div>
        <div class="tags">
          <div class="tag" [title]="row['label'] || row['connectionType']">
            {{ row["label"] || row["connectionType"] }}
          </div>
        </div>
      </div>
      <div class="sub-footer1" *ngIf="row?.['phiEnabled']">
        <label class="fileData" [title]="'Analytics'"> Analytics </label>
      </div>
    </div>
    <div></div>
  </div>
</ng-container>

<ng-container *ngIf="dropzone && page === 'Transformation'">
  <div class="flip-card transformationStudioCard" (click)="addMap.emit()">
    <div
      class="dropzone"
      [ngClass]="{ 'is-dragging': isDragging }"
      (dragleave)="onDragLeave($event)"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event)">
      <!-- <i (click)="addMap.emit()" class="ti ti-circle-plus middle-icon"></i> -->
      <div class="icon">
        <svg
          style="margin-top: 10px"
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-plus"
          width="80"
          height="80"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
          <path d="M9 12l6 0"></path>
          <path d="M12 9l0 6"></path>
        </svg>
      </div>
      or
      <span>Drop File Here</span>
      <button (click)="$event.stopPropagation(); importMap.emit()">
        <i class="ti ti-file-upload"></i> Choose File
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dropzone && page === 'Data Sources'">
  <div class="flip-card addZone data-source-card" (click)="addMapping()">
    <div class="dropzone line-border">
      <!-- <i (click)="addMap.emit()" class="ti ti-circle-plus middle-icon"></i> -->
      <div class="icon">
        <svg
          style="margin-top: 10px"
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-plus"
          width="80"
          height="80"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
          <path d="M9 12l6 0"></path>
          <path d="M12 9l0 6"></path>
        </svg>
      </div>
      <span>Add Data Source</span>
      <br />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dropzone && page === 'UDF-CONFIG'">
  <div class="card dropzone" (click)="addMap.emit()" style="padding: 12px">
    <div class="dropzone line-border">
      <!-- <i (click)="addMap.emit()" class="ti ti-circle-plus middle-icon"></i> -->
      <div class="icon">
        <svg
          style="margin-top: 10px"
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-plus"
          width="80"
          height="80"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
          <path d="M9 12l6 0"></path>
          <path d="M12 9l0 6"></path>
        </svg>
      </div>
      <span>Add Function</span>
      <br />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!dropzone && page === 'UDF-CONFIG'">
  <div class="card">
    <div class="placeholder" (click)="editCardConfigFun()"></div>
    <div class="config" (click)="editCardConfigFun()">
      <div class="config-icon">
        <i class="ti ti-code"></i>
      </div>
      <h2 class="config-title">
        <a>{{ row["name"].toUpperCase() }}</a>
      </h2>
      <div class="config-description">
        {{ row["description"] }}
      </div>
    </div>
    <div class="menu" [ngClass]="{ open: isMenuOpened }">
      <button (click)="showMenu()" (blur)="hideMenu()">
        <i class="ti ti-dots"></i>
      </button>
      <div class="options">
        <div
          class="option"
          (click)="$event.stopPropagation(); deleteConfigFun()">
          <i class="ti ti-trash icon-style"></i> Delete
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!dropzone && !isFolderloading && page === 'Folder'">
  <div class="flip-folder-card" (click)="openFolders()">
    <div class="folder-icon">
      <img
        alt=""
        src="assets/Svgs_/Monitor/folder-big.svg"
        *ngIf="!row?.hasSubFolderOrPipeline" />
      <img
        alt=""
        src="assets/Svgs_/Monitor/folder-filled.svg"
        *ngIf="row?.hasSubFolderOrPipeline" />
      <ng-container style="position: relative">
        <div class="menu folders" [ngClass]="{ open: isMenuOpened }">
          <button
            [ngClass]="{ activeOptions: isMenuOpened }"
            (click)="showFolderMenu($event)"
            (blur)="hideMenu()">
            <i class="ti ti-dots-vertical"></i>
          </button>
          <div class="options">
            <div
              class="option"
              (click)="shareSelectedFolder($event)"
              *ngIf="!row?.parentFolderId">
              Share
            </div>
            <div class="option" (click)="enableRename($event)">Rename</div>
            <div class="option" (click)="folderDeletion($event)">Delete</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="folder-name" title="{{ row?.folderName }}">
      <div class="name" *ngIf="!renameFolderRef">{{ row?.folderName }}</div>
      <input
        class="name-input"
        #renameInput
        *ngIf="renameFolderRef"
        spellcheck="false"
        [(ngModel)]="row.folderName"
        (blur)="disableRename($event)"
        (keydown)="disableRename($event)"
        (click)="$event.stopPropagation()" />
      <img
        src="assets//Svgs_/TransformationStudio/shared-asset.svg"
        *ngIf="isShared && !isNew" />
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="
    !dropzone &&
    isloading &&
    (page === 'Data Sources' || page === 'Monitor' || page === 'Transformation')
  ">
  <div class="flip-card skeleton-box-Div">
    <div class="skeleton-header">
      <div class="headerPart">
        <div class="skeletion-userProfile skeleton"></div>
        <div class="skeletion-userProfileDetails">
          <div class="skeletion-userName skeleton"></div>
          <div class="skeletion-date skeleton"></div>
        </div>
        <div class="skeletion-status skeleton"></div>
      </div>
      <div class="skeletion-options skeleton"></div>
    </div>
    <div class="skeleton-body">
      <div class="skeleton-icon skeleton"></div>
    </div>
    <div class="skeleton-footer">
      <div class="sub-footer1">
        <div class="skeletion-userName skeleton"></div>
        <div class="skeletion-tags skeleton"></div>
      </div>
      <div class="sub-footer2">
        <div
          class="skeletion-tags skeleton"
          *ngIf="page === 'Data Sources'"></div>
        <div
          class="skeletion-tags skeleton"
          *ngIf="page !== 'Data Sources'"></div>
        <div class="skeletion-tags skeleton" *ngIf="page === 'Monitor'"></div>
      </div>
      <div></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!dropzone && isFolderloading && page === 'Folder'">
  <div class="flip-folder-card">
    <div class="skeleton folder-skeleton"></div>
  </div>
</ng-container>

<div class="container">
  <div class="box">
    <div class="header">
      <strong>Password</strong>
    </div>
    <form
      class="form-wrapper"
      [formGroup]="changePasswordForm"
      (ngSubmit)="changePassword()">
      <label for="old_password">Current Password</label>
      <div class="login-input">
        <div class="login-input">
          <input
            [type]="isPasswordVisible['old_password'] ? 'text' : 'password'"
            formControlName="old_password"
            name="old_password" />
          <div class="pass-icon">
            <ng-container *ngIf="isPasswordVisible['old_password']">
              <img
                src="assets/Svgs_/Login/HidePassword.svg"
                alt="show"
                (click)="togglePasswordVisibility('old_password')" />
            </ng-container>
            <ng-container *ngIf="!isPasswordVisible['old_password']">
              <img
                src="assets/Svgs_/Login/ShowPassword.svg"
                alt="Hide"
                (click)="togglePasswordVisibility('old_password')" />
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="old_password!.touched && old_password?.errors"
          class="errors">
          <img src="assets/Svgs_/SideBar/Info.svg" alt="" />Old Password is
          Required
        </div>
      </div>
      <div style="margin-bottom: 5px"></div>
      <div>
        <label for="new_password">New Password</label>
        <div class="login-input">
          <input
            [type]="isPasswordVisible['new_password'] ? 'text' : 'password'"
            name="new_password"
            formControlName="new_password"
            [ngClass]="{
              'error-border':
                confirm_new_password?.dirty &&
                !confirm_new_password?.errors &&
                new_password?.value !== confirm_new_password?.value
            }" />

          <div class="pass-icon">
            <ng-container *ngIf="isPasswordVisible['new_password']">
              <img
                src="assets/Svgs_/Login/HidePassword.svg"
                alt="show"
                (click)="togglePasswordVisibility('new_password')" />
            </ng-container>
            <ng-container *ngIf="!isPasswordVisible['new_password']">
              <img
                src="assets/Svgs_/Login/ShowPassword.svg"
                alt="Hide"
                (click)="togglePasswordVisibility('new_password')" />
            </ng-container>
          </div>
        </div>
        <div *ngIf="new_password?.dirty" class="errors">
          <ng-container *ngIf="new_password?.errors">
            <label *ngIf="new_password?.errors?.required" class="errors"
              ><img src="assets/Svgs_/SideBar/Info.svg" alt="" />New Password is
              Required</label
            >
            <label *ngIf="new_password?.errors?.pattern" class="errors">
              <img src="assets/Svgs_/SideBar/Info.svg" alt="" />
              Password must be 8-16 chars, with upper, lower, number, and
              special symbol</label
            >
          </ng-container>
          <label
            class="errors"
            *ngIf="
              !new_password?.errors &&
              new_password?.value === old_password?.value
            ">
            <img src="assets/Svgs_/SideBar/Info.svg" alt="" /> New password
            shouldn't be same as old password!</label
          >
        </div>
      </div>
      <div style="margin-bottom: 5px"></div>
      <div>
        <label for="confirm_new_password">Confirm Password</label>
        <div class="login-input">
          <input
            [type]="
              isPasswordVisible['confirm_new_password'] ? 'text' : 'password'
            "
            formControlName="confirm_new_password"
            [ngClass]="{
              'error-border':
                confirm_new_password?.dirty &&
                !confirm_new_password?.errors &&
                new_password?.value !== confirm_new_password?.value
            }"
            (keyup)="onPasswordChange($event)" />
          <div class="pass-icon">
            <ng-container *ngIf="isPasswordVisible['confirm_new_password']">
              <img
                src="assets/Svgs_/Login/HidePassword.svg"
                alt="show"
                (click)="togglePasswordVisibility('confirm_new_password')" />
            </ng-container>
            <ng-container *ngIf="!isPasswordVisible['confirm_new_password']">
              <img
                src="assets/Svgs_/Login/ShowPassword.svg"
                alt="Hide"
                (click)="togglePasswordVisibility('confirm_new_password')" />
            </ng-container>
          </div>
        </div>
        <!-- <div class="errors"><strong>{{error}}</strong></div> -->
        <div *ngIf="confirm_new_password?.dirty" class="errors">
          <ng-container *ngIf="confirm_new_password?.errors">
            <label
              *ngIf="confirm_new_password?.errors?.required"
              class="errors">
              <img src="assets/Svgs_/SideBar/Info.svg" alt="" />Confirm Password
              is Required</label
            >
          </ng-container>
          <label
            *ngIf="
              !confirm_new_password?.errors &&
              (new_password?.value !== confirm_new_password?.value || false)
            "
            class="errors">
            <img src="assets/Svgs_/SideBar/Info.svg" alt="" /> New passwords do
            not match
          </label>
        </div>
      </div>
      <div class="buttons">
        <div class="show-pass">
          <input
            class="globalCheckbox"
            type="checkbox"
            (change)="toggleAllPasswordsVisibility($event)" /><span
            style="vertical-align: middle"
            >Show all passwords</span
          >
        </div>
        <div class="btnDiv">
          <button
            class="button-common transparent-button cancel-button"
            [disabled]="!changePasswordForm.touched"
            (click)="cancel()">
            Discard
          </button>
          <button
            class="button-common save-button"
            [disabled]="
              changePasswordForm.invalid ||
              new_password?.value === old_password?.value ||
              new_password?.value !== confirm_new_password?.value
            ">
            Save Changes
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

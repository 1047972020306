import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppService } from "src/app/core/services/app.service";
import { OnDestroy } from "@angular/core";
import { ConnectionsService } from "src/app/features/settings/services/connections.service";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-migration-attributes",
  templateUrl: "./migration-attributes.component.html",
  styleUrls: ["./migration-attributes.component.scss"],
})
export class MigrationAttributesComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() migrationDetails: any;
  @Input() migrationattributes: any;
  @Output() migrationSourceFileUpload = new EventEmitter();
  @Output() emitFormValues = new EventEmitter();
  @Output() emitTargetFormValues = new EventEmitter();
  @Output() valueCleared = new EventEmitter<string>();
  @Output() formUpdated = new EventEmitter<boolean>();
  @ViewChild("fileSelectInputDialog") fileSelectInputDialog: ElementRef | any;
  public showMigrationDetails: boolean = true;
  public showEmailDetails: boolean = true;
  public showTargetDetails: boolean = true;
  public connectionData: any = [];
  public isDragging: boolean = false;
  public sourceFileUpload: any = {};
  public migrationForm: FormGroup = this.fb.group({});
  public migrationSourceFile: any = {};
  public formDetails: any = {};
  private previousValues: any = {};
  public dynamicFormDetails: FormGroup = this.fb.group({});
  public sourceConnectionAdditionalForm: any;
  public targetConnectionAdditionalForm: any;
  public disableSoureFile: boolean = false;
  public additionalFormDetails: any;
  public allConnectionTypes: any;
  public params: any;
  public queryParamsSubscription?: Subscription;
  @ViewChild("fileInput") fileInput!: ElementRef<HTMLInputElement>;
  public hideSourceAndTargetConnectionsSelectDropdown = [
    {
      name: "ONEDRIVE",
      label: "OneDrive",
    },
    {
      name: "AMAZON S3",
      label: "Amazon S3",
    },
    {
      name: "SFTP",
      label: "SFTP",
    },
    {
      name: "AZURE BLOB STORAGE",
      label: "Azure Blob Storage",
    },
    {
      name: "BOX",
      label: "BOX",
    },
    {
      name: "GOOGLE CLOUD STORAGE",
      label: "Google Cloud Storage",
    },
    {
      name: "GOOGLEDRIVE",
      label: "Google Drive",
    },
    {
      name: "MICROSOFT MAIL",
      label: "Microsoft Mail",
    },
  ];
  constructor(
    private fb: FormBuilder,
    private appSerivice: AppService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {
    this.migrationForm = this.fb.group({
      sourceConnection: [""],
      targetConnection: [""],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s*,\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/
          ),
        ],
      ],
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (this.migrationForm) {
        this.emitFormValues.emit(this.migrationForm);
      }
      if (this.connectionParametersForm) {
        this.emitTargetFormValues.emit(this.connectionParametersForm);
      }
      if (changes["migrationForm"]) {
        this.previousValues = changes["migrationForm"].previousValue;
      }
      if (this.migrationattributes) {
        this.migrationAttributeEvent();
      }
    }
  }
  ngOnInit(): void {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.params = params;
      }
    );
    this.connectionData = JSON.parse(
      sessionStorage.getItem("createdConnections") ?? "[]"
    );
    if (this.params["newPipeline"]) {
      if (!this.migrationattributes["importEmailNotifications"]) {
        this.migrationattributes["importEmailNotifications"] = {};
      }
      this.migrationattributes["importEmailNotifications"]["successEmail"] =
        "support@kanerika.com";
    }
    this.migrationAttributeEvent();
  }

  get connectionParametersForm() {
    return this.dynamicFormDetails.get("connectionParameters") as FormGroup;
  }
  zipFileValidator(control: any) {
    const file = control.value;
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (extension !== "zip") {
        return { invalidFileType: true };
      }
    }
    return null;
  }

  showMigration() {
    this.showMigrationDetails = !this.showMigrationDetails;
  }
  showEmail() {
    this.showEmailDetails = !this.showEmailDetails;
  }
  showTarget() {
    this.showTargetDetails = !this.showTargetDetails;
  }

  onDragOver(event: any) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    this.isDragging = false;
  }

  fileDrop(event: any) {
    event.preventDefault();
    this.isDragging = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.handleFile(file);
    }
    if (this.fileInput) this.fileInput.nativeElement.value = "";
  }

  handleFile(file: File) {
    const maxSizeInMB = 25;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (file && this.validateZipFile(file)) {
      if (file.size >= maxSizeInBytes) {
        this.appSerivice.showToast({
          message: `File size exceeds the 25 MB limit. Please use connection for larger files!`,
          type: "alert",
          duration: 3000,
        });
      } else {
        this.migrationSourceFile = file;
        this.migrationSourceFileUpload.emit(file);
        this.migrationForm.get("sourceConnection")?.disable();
        this.emitFormValues.emit(this.migrationForm);
      }
    }
  }

  handleRemoveSourceFile() {
    this.migrationSourceFile = {};
    this.migrationForm.get("sourceConnection")?.enable();
    this.disableSTFileInputs(this.migrationDetails);
    this.migrationSourceFileUpload.emit(null);
  }

  validateZipFile(file: File): boolean {
    const extension = file.name.split(".").pop()?.toLowerCase();
    return extension === "zip";
  }
  migrationAttributeEvent() {
    this.allConnectionTypes = JSON.parse(
      sessionStorage.getItem("connectionTypes") || "[]"
    );
    this.connectionData = JSON.parse(
      sessionStorage.getItem("createdConnections") || "[]"
    );
    let getSourceId;
    let getTargetId;
    if (this.migrationattributes?.["migrationRules"]?.["fileName"]) {
      this.migrationSourceFile["name"] =
        this.migrationattributes["migrationRules"]["fileName"];
      this.migrationForm.get("sourceConnection")?.disable();
    } else {
      getSourceId =
        this.migrationattributes["importProgramDefinitions"]?.["attributes"][
          "connections"
        ]["sourceConnectionId"];
      this.migrationForm.patchValue({
        sourceConnection: getSourceId || null,
      });
    }
    getTargetId =
      this.migrationattributes["importProgramDefinitions"]?.["attributes"][
        "connections"
      ]["targetConnectionId"];
    this.migrationForm.patchValue({
      targetConnection: getTargetId || null,
      email:
        this.migrationattributes["importEmailNotifications"]?.["successEmail"],
    });
    this.initializeFormControls(
      this.migrationDetails,
      this.migrationattributes?.["migrationRules"]?.["targetMigrationDetails"]
    );
    if (getSourceId) {
      this.getAdditionalForm(
        "source",
        this.migrationattributes["importProgramDefinitions"]?.["attributes"][
          "connections"
        ]
      );
    }
    if (getTargetId) {
      this.getAdditionalForm(
        "target",
        this.migrationattributes["importProgramDefinitions"]?.["attributes"][
          "connections"
        ]
      );
    }
    this.disableSTFileInputs(this.migrationDetails);
    this.emitFormValues.emit(this.migrationForm);
    if (this.connectionParametersForm)
      this.emitTargetFormValues.emit(this.connectionParametersForm);
    if (this.migrationForm.get("sourceConnection")?.value) {
      this.disableSoureFile = true;
    }
  }

  emitUpdateStatus() {
    this.formUpdated.emit(true);
    this.emitFormValues.emit(this.migrationForm);
  }

  getAdditionalForm(isFor: "source" | "target", updatedValues?: any) {
    let id: any = null;
    if (isFor == "source") {
      id = this.migrationForm.get("sourceConnection")?.value;
    } else {
      id = this.migrationForm.get("targetConnection")?.value;
    }
    if (isFor == "source" && (!id || id === "")) {
      this.removeControlsFromForm(this.sourceConnectionAdditionalForm);
      this.sourceConnectionAdditionalForm = null;
      this.emitFormValues.emit(this.migrationForm);
      this.disableSoureFile = false;
      const mform =
        JSON.parse(this.migrationDetails?.["migrationFormAttributes"]) ?? {};
      this.disableSoureFile =
        mform["sourceFile"] === "enable"
          ? false
          : mform["sourceFile"] === "disable"
            ? true
            : false;
      return;
    } else if (isFor == "target" && (!id || id === "")) {
      this.removeControlsFromForm(this.targetConnectionAdditionalForm);
      this.targetConnectionAdditionalForm = null;
      this.emitFormValues.emit(this.migrationForm);
      return;
    }
    let connection = this.connectionData.find((conn: any) => conn["id"] == id);
    let additionalForm: any = sessionStorage.getItem("connectionTypes");
    this.allConnectionTypes = JSON.parse(additionalForm);
    let adf: any;
    let modifiedForm;
    if (connection) {
      if (additionalForm && additionalForm !== "undefined") {
        additionalForm = JSON.parse(additionalForm);
        adf = additionalForm.filter(
          (con) =>
            con.name.toUpperCase() ===
            connection["connectionType"].toUpperCase()
        );
      }
      if (adf[0] && adf[0]["connectionAdditionalFormAttributes"]) {
        this.additionalFormDetails = null;
        this.additionalFormDetails = updatedValues
          ? this.migrationattributes?.["importProgramDefinitions"]?.[
              "attributes"
            ]?.["connections"]
          : connection?.["connectionParameters"];
        modifiedForm = JSON.parse(adf[0]["connectionAdditionalFormAttributes"]);
        for (let field of modifiedForm["form"]) {
          field["id"] = isFor + field["id"];
        }
        modifiedForm = JSON.stringify(modifiedForm);
        if (isFor == "source") {
          this.sourceConnectionAdditionalForm = null;
          this.sourceConnectionAdditionalForm = modifiedForm;
          this.disableSoureFile = true;
        } else {
          this.targetConnectionAdditionalForm = null;
          this.targetConnectionAdditionalForm = modifiedForm;
        }
      } else {
      }
    } else {
    }
    this.migrationForm.updateValueAndValidity();
    setTimeout(() => {
      this.emitFormValues.emit(this.migrationForm);
    }, 10);
  }
  emitTemplateValues() {
    this.emitFormValues.emit(this.migrationForm);
  }
  removeControlsFromForm(formConfig: any) {
    let formvalues = JSON.parse(formConfig);
    if (formvalues && formvalues["form"]) {
      for (let field of formvalues["form"]) {
        const controlName = field["id"];
        if (this.migrationForm.contains(controlName)) {
          this.migrationForm.removeControl(controlName);
        }
      }
    }
  }

  disableSTFileInputs(details) {
    this.formDetails = details?.["migrationAdditionalFormAttributes"] ?? "";
    const mform =
      JSON.parse(this.migrationDetails?.["migrationFormAttributes"]) ?? {};
    if (Object.keys(mform!).length > 0) {
      this.disableSoureFile =
        mform["sourceFile"] === "enable" &&
        this.migrationForm.get("sourceConnection")?.value
          ? false
          : mform["sourceFile"] === "disable"
            ? true
            : mform["sourceFile"] === "optional" &&
                this.migrationForm.get("sourceConnection")?.value
              ? true
              : false;

      Object.keys(mform).forEach((key) => {
        if (this.migrationForm.contains(key)) {
          const control = this.migrationForm.get(key);

          switch (mform[key]) {
            case "enable":
              control?.setValidators(Validators.required);
              control?.enable();
              break;
            case "disable":
              control?.disable();
              break;
            case "optional":
            default:
              control?.clearValidators();
              control?.enable();
              break;
          }
          control?.updateValueAndValidity(); // Update validation status
        }
      });
    }
  }

  initializeFormControls(details: any, values: any) {
    if (this.dynamicFormDetails) {
      this.dynamicFormDetails.reset();
      Object.keys(this.dynamicFormDetails.controls).forEach((controlName) => {
        if (this.dynamicFormDetails.contains(controlName)) {
          this.dynamicFormDetails.removeControl(controlName);
        }
      });
    }
    const render = this.formDetails
      ? JSON.parse(details?.["migrationAdditionalFormAttributes"])?.["form"] ??
        []
      : [];
    const valuesMap = values && typeof values === "object" ? values : {};
    if (render && render.length > 0) {
      let tempGroup: FormGroup = this.fb.group({});
      for (let field of render) {
        let validators: any[] = [];
        if (field?.validators?.required) validators.push(Validators.required);
        if (field?.validators?.minlength)
          validators.push(Validators.minLength(field?.validators?.minlength));
        if (field?.validators?.maxlength)
          validators.push(Validators.maxLength(field?.validators?.maxlength));
        let value = valuesMap.hasOwnProperty(field.id)
          ? valuesMap[field.id]
          : "";
        tempGroup.addControl(
          field?.id,
          this.fb.control(
            field?.type === "checkbox" ? !!value : value,
            validators
          )
        );
      }
      this.dynamicFormDetails.addControl("connectionParameters", tempGroup);
    }
  }

  isFormGroupEmpty(formGroup: FormGroup): boolean {
    return !formGroup || Object.keys(formGroup?.controls)?.length === 0;
  }
  onDynamicFormChanged() {
    this.emitTargetFormValues.emit(this.connectionParametersForm);
  }
  sanitizeImageUrl(imageUrl: string | null | undefined): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      imageUrl || "assets/images/ai_no_icon.svg"
    );
  }

  connectionsOf(usage: "source" | "target") {
    return this.connectionData?.filter((conn) => {
      const connectionType = this.getConnectionTypeDetails(
        conn["connectionType"]
      );
      if (connectionType) {
        const isInDropdown =
          this.hideSourceAndTargetConnectionsSelectDropdown.some(
            (dropdown) =>
              dropdown.name === connectionType.name &&
              dropdown.label === connectionType.label
          );
        if (connectionType.usage) {
          // (connectionType?.usage.toLowerCase()?.includes(usage) ||
          // connectionType?.usage.toLowerCase()?.includes("both")) &&
          return isInDropdown;
        }
        return true;
      }
      return false;
    });
  }
  getConnectionTypeDetails(connectionType: any): any {
    const connections: any[] = Object.values(this.allConnectionTypes);
    return connections.find((conn) =>
      [conn.name, conn.label].includes(connectionType)
    );
  }
  isRequired(controlName: string): boolean {
    const control = this.migrationForm.get(controlName) as FormControl;
    if (control) {
      const validator = control.validator
        ? control.validator({} as AbstractControl)
        : null;
      return validator && validator.required;
    }
    return false;
  }

  //   handleFormErrors(errors) {
  // const errorMessages = this.formatErrors(errors);
  // this.appSerivice.showToast({
  //   duration: 3000,
  //   type: "error",
  //   message: errorMessages,
  // });
  //   }
  //   private formatErrors(errors: any): string[] {
  //     const messages: string[] = [];
  //     Object.keys(errors).forEach((key) => {
  //       const fieldErrors = errors[key];
  //       if (fieldErrors["required"]) {
  //         messages.push(`${key} is required`);
  //       }
  //       if (fieldErrors["minlength"]) {
  //         messages.push(
  //           `Min length of ${key} should be ${fieldErrors["minlength"]["requiredLength"]}`
  //         );
  //       }
  //       if (fieldErrors["maxlength"]) {
  //         messages.push(
  //           `Max length of ${key} should be ${fieldErrors["maxlength"]["requiredLength"]}`
  //         );
  //       }
  //       if (fieldErrors["min"] || fieldErrors["max"]) {
  //         messages.push(
  //           `Value of ${key} should be within ${fieldErrors["min"] ?? "Infinite"} - ${fieldErrors["max"] ?? "Infinite"}`
  //         );
  //       }
  //     });
  //   dsad
  //     return messages;
  //   }

  ngOnDestroy() {
    this.queryParamsSubscription?.unsubscribe();
    this.migrationDetails = null;
    this.migrationattributes = null;
    this.dynamicFormDetails.reset();
    this.migrationForm.reset();
    this.connectionData = [];
    this.sourceConnectionAdditionalForm = null;
    this.targetConnectionAdditionalForm = null;
    this.formDetails = {};
    this.sourceFileUpload = {};
  }
}

<div class="main-Div">
  <div class="left-div-Login" [ngClass]="{ 'full-screen': fullScreen }">
    <div class="title" *ngIf="showLogo">
      <img src="assets/images/logos/new-blue-logo.svg" />
      <!-- FLIP -->
    </div>
    <div
      class="design"
      [ngClass]="{ 'mobile-show': mobileShow, 'full-screen': fullScreen }">
      <ng-lottie [options]="lottieOptions"></ng-lottie>
    </div>
  </div>
</div>

<!-- <p style="margin-top: 30vh; font-size: 50px; text-align: center">Home</p> -->

<div class="wrap">
  <img
    src="assets/images/logos/200x200-01.png"
    height="10%"
    alt="FLIP"
    style="
      margin-top: 40px;
      margin-left: 100px;
      position: relative;
      display: none;
    " />
  <!-- <h2><strong>Variable Inbound Processing and Exception Reporting</strong></h2> -->
  <!-- <p>Welcome</p> -->
</div>

<!-- height="10%" -->

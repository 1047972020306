import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./core/services/auth.service";
import { CustomPreloadStrategy } from "./core/services/preload-strategy.service";
import { ChangePasswordComponent } from "./features/auth/pages/change-password/change-password.component";
import { ForgotPasswordComponent } from "./features/auth/pages/forgot-password/forgot-password.component";
import { LoginComponent } from "./features/auth/pages/login/login.component";
import { RegisterComponent } from "./features/auth/pages/register/register.component";
import { RegistrationVerificationComponent } from "./features/auth/pages/registration-verification/registration-verification.component";
import { ResetPasswordComponent } from "./features/auth/pages/reset-password/reset-password.component";
import { SetPasswordComponent } from "./features/auth/pages/set-password/set-password.component";
import { HomeComponent } from "./features/home/components/main/home.component";
import { ManageComponent } from "./features/pipelines/pages/data-pipelines/manage-mapping/manage.component";
import { UnsavedChangesGuard } from "./shared/guards/unsaved-changes.guard";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "login", component: LoginComponent, data: { title: "FLIP - Login" } },
  {
    path: "completeUserRegistration/:id",
    component: SetPasswordComponent,
    data: { title: "FLIP - Set Password" },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: { title: "FLIP - Register" },
  },
  {
    path: "forgot",
    component: ForgotPasswordComponent,
    data: { title: "FLIP - Forgot Password" },
  },
  {
    path: "validate-user",
    component: RegistrationVerificationComponent,
    data: { title: "FLIP - Validate User" },
  },
  {
    path: "home",
    component: HomeComponent,
    data: { title: "FLIP - Home" },
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./features/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    data: { title: "FLIP - Dashboard" },
    canActivate: [AuthGuardService],
  },
  {
    path: "pipelines",
    loadChildren: () =>
      import("./features/pipelines/pipelines.module").then(
        (m) => m.PipelinesModule
      ),
    data: { title: "FLIP - Pipelines", preload: true },
    canActivate: [AuthGuardService],
  },
  // {
  //   path: "pipelines/manage",
  //   component: ManageComponent,
  //   canDeactivate: [UnsavedChangesGuard],
  //   data: { title: "FLIP - Pipelines Attribute" },
  //   canActivate: [AuthGuardService],
  // },
  {
    path: "monitor",
    loadChildren: () =>
      import("./features/monitor/monitor.module").then((m) => m.MonitorModule),
    data: { title: "FLIP - Monitor", preload: true },
    canActivate: [AuthGuardService],
  },
  // {
  //   path: "monitor/viewer",
  //   component: ViewerComponent,
  //   data: { title: "FLIP - Viewer" },
  //   canActivate: [AuthGuardService],
  // },
  {
    path: "settings",
    loadChildren: () =>
      import("./features/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
    data: { title: "FLIP - Settings", preload: true },
    canActivate: [AuthGuardService],
  },
  {
    path: "template-modelling",
    loadChildren: () =>
      import("./features/template-modelling/template-modelling.module").then(
        (m) => m.TemplateModellingModule
      ),
    data: { title: "FLIP - Template Modelling" },
    canActivate: [AuthGuardService],
  },
  {
    path: "user-profile",
    loadChildren: () =>
      import("./features/user/user.module").then((m) => m.UserModule),
    data: { title: "FLIP - User Profile" },
    canActivate: [AuthGuardService],
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    data: { title: "FLIP - Change Password" },
    canActivate: [AuthGuardService],
  },
  {
    path: "manage-users",
    loadChildren: () =>
      import("./features/admin/admin.module").then((m) => m.AdminModule),
    data: { title: "FLIP - Manage Users" },
    canActivate: [AuthGuardService],
  },
  {
    path: "ai-workbench",
    loadChildren: () =>
      import("./features/ai-workbench/ai-workbench.module").then(
        (m) => m.AiWorkbenchModule
      ),
    data: { title: `FLIP - AI Workbench` },
    canActivate: [AuthGuardService],
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: { title: "FLIP - Reset Password" },
  },
  // {
  //   path: "pipelines/migration",
  //   component: MigrationWorkbenchComponent,
  //   data: { title: "FLIP - Migration Workbench" },
  //   canActivate: [AuthGuardService],
  // },
  {
    path: "pipelines/manage",
    component: ManageComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: { title: "FLIP - Migration Workbench Attribute" },
    canActivate: [AuthGuardService],
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      useHash: true,
      preloadingStrategy: CustomPreloadStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

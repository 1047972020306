import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";

export interface ITourStep {
  selector: string;
  title: string;
  message: string;
  options?: ITourStepOptions;
  imagePath?: string;
  videoPath?: string;
}

export interface ITourStepOptions {
  placement?: "top" | "bottom" | "left" | "right";
  showBackdrop?: boolean;
  showNext?: boolean;
  showMessage?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class TourService {
  public steps: ITourStep[] = [];
  public currentStep?: ITourStep;
  public steps$ = new BehaviorSubject(this.steps);
  public stepOptions?: ITourStepOptions;
  public actionEmitter = new EventEmitter<string>();

  constructor() {}

  initialize(steps: ITourStep[], options?: ITourStepOptions) {
    this.steps = steps;
    this.steps$.next(steps);
    this.stepOptions = options;
  }

  start() {
    this.actionEmitter.emit("start");
  }

  close() {
    this.actionEmitter.emit("close");
  }

  next() {
    this.actionEmitter.emit("next");
  }

  back() {
    this.actionEmitter.emit("back");
  }

  resume() {
    this.actionEmitter.emit("resume");
  }
}

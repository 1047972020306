import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getObjectKeys'
})
export class GetObjectKeysPipe implements PipeTransform {

  transform(obj: any): any {
    return (obj)? Object.keys(obj) : [];
  }

}

import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { LoaderService } from "./loader.service";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import { SessionService } from "./session.service";
import { TokenDetails, TokenStorageService } from "./token.service";
import { AppService } from "src/app/core/services/app.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public loaderService: LoaderService,
    public sessionService: SessionService,
    private tokenService: TokenStorageService,
    private appService: AppService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes("/api/dashboard/")) {
      this.loaderService.loader.next(true);
    }

    if (this.shouldHideLoader(req.url)) {
      this.loaderService.loader.next(false);
    }

    const token = this.tokenService.getToken();

    let tokenedReq = this.addTokenHeader(req, token);

    return next.handle(tokenedReq).pipe(
      finalize(() => {
        setTimeout(() => {
          if (this.loaderService.loader.value) {
            this.loaderService.loader.next(false);
          }
        }, 100);
      }),
      catchError((err) => {
        if (
          (err["status"] == 403 || err["status"] == 0) &&
          !req.url.endsWith("/api/refreshToken")
        ) {
          if (req.url.endsWith("/api/userLogout")) {
            this.tokenService.removeToken();
            return throwError(err);
          }
          return this.handleSessionExpiredError(tokenedReq, next);
        }
        setTimeout(() => {
          if (this.loaderService.loader.value) {
            this.loaderService.loader.next(false);
          }
        }, 100);
        return throwError(err);
      })
    );
  }

  private shouldHideLoader(url: string) {
    const endpoints = [
      "/api/connection/connectionTypes",
      "/api/getMonitorEvents",
      "api/definitions/getTags",
      // "api/schedules",
      "/api/getAllRegisteredTables",
      "/api/aiWorkbench/getAIModels",
      "api/fileMappingEdit",
      "/api/getSchedules",
      "api/getTableInfo?tableName=",
      "/api/userInfo",
      "api/definitions/getPipelines",
      "api/getAllUsers",
      "api/getAutoMappingData?importId",
      "api/fetchElementsFromTargetConnection",
      "/api/autoMappingData",
      "/api/getSourceElements",
      "/api/getAllMappingElementsPerPage",
      "/api/configureConnection",
      "/api/getAllMappingElementsPerPage",
    ];

    return endpoints.some((endpoint) => url.includes(endpoint));
  }

  private addTokenHeader(req: HttpRequest<any>, token?: string) {
    const noCredentialsRequired = [
      "/signupUser",
      "/forgotPassword",
      "/unstructured-extraction/template/extractElements",
      "/unstructured-extraction/template/addElement",
      "/unstructured-extraction/template/extractTablesFromBbox",
      "/unstructured-extraction/template/createTemplate",
      "/unstructured-extraction/template/updateTemplate",
      "/unstructured-extraction/template/identifyLineItemMapping",
      "/unstructured-extraction/pdf",
    ];
    const noTokenRequiredUrls = [
      "/login",
      "/register",
      ...noCredentialsRequired,
    ];
    const path = req.url;

    return req.clone({
      withCredentials: !noCredentialsRequired.find((url) => path.includes(url)),
      setHeaders: {
        "ngrok-skip-browser-warning": "true",
        ...(!noTokenRequiredUrls.find((url) => path.includes(url)) &&
        this.tokenService.hasToken()
          ? {
              Authorization: `${this.tokenService.getTokenType() || "Bearer"} ${token}`,
            }
          : {}),
      },
    });
  }

  private handleSessionExpiredError(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.tokenService.getRefreshToken();

      if (refreshToken)
        return this.appService.refreshSessionToken({ refreshToken }).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            let tokenDetails = token as TokenDetails;

            this.tokenService.setToken(tokenDetails);
            this.refreshTokenSubject.next(tokenDetails.accessToken);

            return next.handle(
              this.addTokenHeader(request, tokenDetails.accessToken)
            );
          }),
          catchError((err) => {
            this.appService.isSessionExpired = true;
            this.sessionService.isExpired.next(true);
            this.tokenService.removeToken();
            this.isRefreshing = false;
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
}

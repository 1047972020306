import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { GetPartnersInfoParams } from "../models/get-partner-info.model";
import { ShareFolder } from "../models/share-folder.model";
import { UpdateSharedFolderPermissions } from "../models/update-shared-folder-permission.model";
import { CopyMappingProgramDefinition } from "../models/copy-mapping.model";
import { ExportMappingProgramDefinition } from "../models/export-mapping.model";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs";
import { GetMappingElementsResponse } from "../models/mapping-element.model";

@Injectable({
  providedIn: "root",
})
export class ImportDesignerService {
  public unsavedMappingData: any;
  public functions: any;
  public pipelineAccess: boolean = false;
  // public aiMappingsStore: any[] = [];
  public selectedLineIndex: number = -1;
  private navigateToMigrationListSubject = new BehaviorSubject<string>("");
  public migrationListSubject$ =
    this.navigateToMigrationListSubject.asObservable();
  public csvHeaderLine: string = "";

  toggleMigrationNavigation(value: string) {
    this.navigateToMigrationListSubject.next(value);
  }
  public updateFunctions(newValue: any) {
    this.functions = newValue;
  }
  public setPipelineAccess(newValue: boolean) {
    this.pipelineAccess = newValue;
  }
  public getPipelineAccess() {
    return this.pipelineAccess;
  }
  constructor(private httpClient: HttpClient) {}

  // public login(data) {
  //   return this.httpClient
  //     .post(`${environment.apiUrl}/api/loginSecure`, data)
  //     .pipe(
  //       map((response) => {
  //         return response;
  //       })
  //     );
  // }

  public getPartnersInfo(options: GetPartnersInfoParams) {
    const { folderId, isEditAllowed, isReadAllowed } = options;
    let url = `${environment.apiUrl}/api/definitions/getPipelines`;
    if (folderId) {
      url += `?folderId=${folderId}`;
    }
    if (isEditAllowed != null && folderId) {
      url += `&isEditAllowed=${isEditAllowed}`;
    }
    if (isReadAllowed != null && folderId) {
      url += `&isReadAllowed=${isReadAllowed}`;
    }
    return this.httpClient.post(url, {}).pipe(
      map((response) => {
        return response;
      })
    );
  }
  public addFolder(data: {
    folderName: string;
    parentFolderId?: string | null;
  }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/folders/addFolder`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public shareFolder(data: ShareFolder) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/folders/shareFolder`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public getSharedUsers(folderId: string) {
    return this.httpClient
      .get(
        `${environment.apiUrl}/api/folders/getSharedUsers?objectId=${folderId}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public getAllUsers() {
    return this.httpClient.get(`${environment.apiUrl}/api/getAllUsers`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  public updatePermissions(data: UpdateSharedFolderPermissions) {
    return this.httpClient
      .put(`${environment.apiUrl}/api/userPermissions/updatePermissions`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public updateFolder(data: any) {
    return this.httpClient
      .put(`${environment.apiUrl}/api/folders/updateFolder`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // public getPartners() {
  //   return this.httpClient
  //     .get(`${environment.apiUrl}/api/getPartners`, {
  //       withCredentials: true,
  //     })
  //     .pipe(
  //       map((response) => {
  //         return response;
  //       })
  //     );
  // }

  // public getLobs() {
  //   return this.httpClient
  //     .get(`${environment.apiUrl}/api/getProgramNames`)
  //     .pipe(
  //       map((response) => {
  //         return response;
  //       })
  //     );
  // }

  public getAllUnmanagedMasterReports() {
    return this.httpClient
      .get(`${environment.apiUrl}/api/getAllUnmanagedMasterReports`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //   public addMapping(data) {
  //     return this.httpClient
  //       .post(`${environment.apiUrl}/api/definitions/addMapping`, data)
  //       .pipe(
  //         map((response) => {
  //           return response;
  //         })
  //       );
  //   }

  public copyMapping(data: CopyMappingProgramDefinition) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/definitions/copyMapping`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public exportMapping(data: ExportMappingProgramDefinition) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/exportMetadata`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public importMapping(data: {
    encodeFile: any;
    parentFolderId?: string | null;
  }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/importMetadata`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getSheetName(data: {
    importId: string;
    sourceType: string;
    readRulesSheetNames?: any;
  }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/getDefaultSheetNames`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public deleteMapping(data: any[]) {
    const httpOptions: any = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: data,
      responseType: "text",
    };
    return this.httpClient
      .delete(
        `${environment.apiUrl}/api/definitions/deletePipeline`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public deleteFolder(folderId: string) {
    return this.httpClient
      .delete(`${environment.apiUrl}/api/folders/deleteFolder/${folderId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public deletePermissions(payload: {
    objectId: string;
    userId: string;
    objectType: string;
  }) {
    return this.httpClient
      .put(
        `${environment.apiUrl}/api/userPermissions/deletePermissions`,
        payload
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public getMappingDetails(data: { importId: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/fileMappingEdit`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getReadRulesDetails(data: { importId: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/fileReadRulesEdit`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getDATDetails(data: { importId: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/datFileMappingEdit`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateAttributes(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/fileAttributeUpdate`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public setMapStatus(data: { importId: string; status: string }) {
    return this.httpClient
      .get(
        `${environment.apiUrl}/api/activeStatus?importId=${data.importId}&mapStatus=${data.status}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //   public updateMappings(data) {
  //     return this.httpClient
  //       .post(`${environment.apiUrl}/api/fileMappingUpdate`, data)
  //       .pipe(
  //         map((response) => {
  //           return response;
  //         })
  //       );
  //   }

  public getSheetMapping(data: { importId: string; lineType: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/getSheetMapping`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getSheetReadRules(data: { importId: string; sheetName: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/getReadRules`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getSheetMappingDAT(data: { importId: string; sheetName: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/getDatFileSheetMapping`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateSheetMapping(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/updateSheetMapping`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateReadRules(data: { importId: string; rules: any }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/updateReadRules`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateDATMapping(data: {
    importId: string;
    sheetName: string;
    datFileMapping: any;
  }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/updateDatFileSheetMapping`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public deleteSheetMapping(data: { importId: string; lineType: string }) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: data,
    };
    return this.httpClient
      .delete(`${environment.apiUrl}/api/deleteSheetMapping`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public deleteReadRulesMapping(data: { importId: string; sheetName: string }) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: data,
    };
    return this.httpClient
      .delete(`${environment.apiUrl}/api/deleteReadRules`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public deleteDATMapping(data: { importId: string; sheetName: string }) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: data,
    };
    return this.httpClient
      .delete(
        `${environment.apiUrl}/api/deleteDatFileSheetMapping`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public configureConnections(data: { importId: string; connections: any }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/configureConnectionsAndSchedule`, data, {
        responseType: "text",
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public clearConnections(data: { importId: string }) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/unassignConnections`,
      data
    );
  }

  public saveNewMappingField(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/addMappingElement`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateMappingField(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/updateMappingElement`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public importSourceFile(data: FormData) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/importSourceFile`,
      data
    );
  }

  public getSchedules() {
    return this.httpClient.get(`${environment.apiUrl}/api/schedules`);
  }
  public getSourceElements(data: {
    importId: string;
    sourceFileType: string;
    sourceFileFormat: string;
  }) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/getSourceElements`,
      data
    );
  }
  public getTransformationFunctions() {
    return this.httpClient.get(
      `${environment.apiUrl}/api/getTransformationFunctions`
    );
  }
  public getAutoMappingsWithAi(data: any, importId?: string) {
    const sheetHeaderList: any = {};
    return this.httpClient
      .post(`${environment.apiUrl}/api/autoMappingData`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getTemplatePdfFile(importId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/api/definitions/getTemplatePDFPipeline?importId=${importId}`,
      { responseType: "arraybuffer" }
    );
  }

  addMulitpleMappingElements(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/addMultipleMappingElements`,
      data
    );
  }

  async getPredefinedRegexExpressions() {
    try {
      const response = await this.httpClient
        .get(`${environment.apiUrl}/api/getPredefinedRegexExpressions`)
        .toPromise();
      return response;
    } catch (error) {
      return error;
    }
  }

  async getMigrationPipelines(): Promise<any> {
    try {
      const response = await this.httpClient
        .get(`${environment.apiUrl}/api/migration/getMigrationTypes`)
        .toPromise();
      sessionStorage.setItem(
        "migrationList",
        JSON.stringify(response["migrationPipelinesList"])
      );
      return { res: response, error: null };
    } catch (error) {
      return { data: null, error: error };
    }
  }
  async setMigrationModelDetails(
    selectedRow: any,
    migrationType: any
  ): Promise<void> {
    let sessionMigrationDetails: any = sessionStorage.getItem("migrationList");
    if (!sessionMigrationDetails || sessionMigrationDetails.length === 0) {
      try {
        const result = await this.getMigrationPipelines();
        if (result.error) {
          return;
        }
        sessionMigrationDetails = result?.["res"]?.["migrationPipelinesList"];
        if (!sessionMigrationDetails) {
        }
        sessionStorage.setItem(
          "migrationList",
          JSON.stringify(sessionMigrationDetails)
        );
      } catch (error) {
        return;
      }
    } else {
      if (sessionMigrationDetails) {
        sessionMigrationDetails = JSON.parse(sessionMigrationDetails);
      }
    }
    const areKeysGreaterThan = sessionMigrationDetails.some(
      (obj: any) => Object.keys(obj).length > 0
    );
    if (areKeysGreaterThan) {
      const migrationItem = sessionMigrationDetails.filter((item: any) => {
        let migrateType = selectedRow["migrationType"]?.replace(" - ", "to");
        return item?.name?.toLowerCase() === migrateType?.toLowerCase();
      });
      if (migrationItem.length > 0) {
        localStorage.setItem("migrationType", JSON.stringify(migrationItem[0]));
      }
      localStorage.setItem("migration", migrationType);
    }
  }

  public getAutoTargetElements(data) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/fetchElementsFromTargetConnection`,
      data
    );
  }

  public getAllMappingElements() {
    return this.httpClient.get(
      `${environment.apiUrl}/api/getAllMappingElements`
    ) as Observable<GetMappingElementsResponse>;
  }

  public getAllMappingElementsPerPage(payload: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/getAllMappingElementsPerPage`,
      payload
    ) as Observable<GetMappingElementsResponse>;
  }
  public configureConnectionForAutoElements(data: any, type: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/configureConnection?mode=${type}`,
      data
    );
  }
}

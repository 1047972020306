import { AppService } from "src/app/core/services/app.service";
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ImportDesignerService } from "../../../../services/import-designer.service";
import { ValidationService } from "../../../../services/validation.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoaderService } from "src/app/core/services/loader.service";
import { ConnectionsService } from "src/app/features/settings/services/connections.service";
import { ConfirmPopupService } from "src/app/shared/components/confirm-popup/confirmpopup.service";

enum attributeSections {
  templates = "templates",
  headerSettings = "headerSettings",
  fileSettings = "fileSettings",
  emailSettings = "emailSettings",
}

@Component({
  selector: "app-attributes",
  templateUrl: "./attributes.component.html",
  styleUrls: ["./attributes.component.scss"],
})
export class AttributesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() importId: any;
  @Input() attributes: any;
  @Input() lobs: any;
  @Input() isMapSaved?: boolean;
  @Input() updateListener?: BehaviorSubject<boolean>;
  @Input() toggleDisableForUpdate?: BehaviorSubject<boolean>;
  @Input() access: any;
  @Output() updateAttributes = new EventEmitter();
  @Output() isUpdated = new EventEmitter();
  @Output() apiSuccess = new EventEmitter<boolean>();
  public formatIndicatorForm: any;
  public multipleSheetForm: any;
  public validationForm: any;
  //   public dataFileForm: any;
  public pdfProcessDataTypeForm: any;
  public disableDelimiter: boolean = false;
  public disableSheetName: boolean = false;
  // public disableFortegraFormatIndicator: boolean = false;
  public disableMultipleSheet: boolean = false;
  public disableFileType: boolean = false;
  public disableHeaderSettingsUpdate: boolean = true;
  public disableFileSettingsUpdate: boolean = true;
  public disableEmailSettingsUpdate: boolean = true;
  public disableConnectionSettingsUpdate: boolean = true;
  public readRuleFlag: boolean = false;
  public isSafari: boolean = false;
  public username: any;
  public connections: any[] = [];
  public isEmptyDatabaseTableName = false;
  public isTableNameReadOnly = false;
  public _showErrorInputs = false;
  public _showEmailError = false;
  public _showEmailErrorTouched = false;
  public unsubscribe$ = new Subject();
  public disableFileFormat: boolean = false;
  public isSectionExpanded = {
    [attributeSections.templates]: true,
    [attributeSections.headerSettings]: true,
    [attributeSections.fileSettings]: true,
    [attributeSections.emailSettings]: true,
  };
  public attributeSections = attributeSections;
  public ispdfProcessTypeSelected: boolean = false;
  public isPdfProcessEditMode: boolean = false;
  public templateModeFile: any;
  @ViewChild("fileSelectInputDialog") fileSelectInputDialog: ElementRef | any;
  private queryParamsSubscription?: Subscription;
  public params: any;
  public sourceFileTypes: string[] = [
    "Custom",
    "Data File",
    "Education Certificate/Licenses",
    "Fortegra Bordereau",
    "Insurance",
    "Invoice",
    "Legal",
    "Lloyds Bordereau",
    "Policies",
    "Purchase Order",
    "Voucher",
    "Work Order",
  ];
  public folderId: string = "";
  private headerSubscription?: Subscription;
  constructor(
    private importDesignerService: ImportDesignerService,
    private validationService: ValidationService,
    private appService: AppService,
    private loaderService: LoaderService,
    private connectionService: ConnectionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private confirmPopupService: ConfirmPopupService
  ) {
    let userAgentString = navigator.userAgent;
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    this.isSafari = userAgentString.indexOf("Safari") > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && this.isSafari) this.isSafari = false;
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription?.unsubscribe();
    this.headerSubscription?.unsubscribe();
    if (this.importDesignerService.unsavedMappingData)
      this.importDesignerService.unsavedMappingData = null;
    this.emitUpdateStatus();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.importDesignerService.csvHeaderLine = "";
  }

  getUserName() {
    let username: any = localStorage.getItem("currentUser");
    this.username = JSON.parse(username).username;
  }

  ngOnInit(): void {
    this._showEmailError = false;
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.params = params;
        this.folderId = this.params.folderId;
      }
    );
    if (this.params["newPipeline"]) {
      this.ispdfProcessTypeSelected = false;
      this.isPdfProcessEditMode = true;
      this.attributes["emails"]["successEmail"] = "support@kanerika.com";
    }
    if (this.params["unsaved"]) {
      this.attributes["emails"]["successEmail"] = "support@kanerika.com";
    }
    // this.attributes["importReadRules"]["pdfTemplateKey"] = "";
    this.getUserName();
    this.loaderService.loader.next(true);
    this.pdfProcessDataTypeForm = this.fb.group({
      processingMode: [
        this.attributes["importReadRules"]["processingMode"] || "",
        Validators.required,
      ],
    });

    // this.formatIndicatorForm = new FormGroup({
    //   fortegraFormatIndicator: new FormControl("Y"),
    // });

    this.updateListener
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe((res) => {
        if (res) {
          this._showErrorInputs = true;
          this.updateAllAttributes();
        }
      });
    // this.dataFileForm = new FormGroup({
    //   dataFile: new FormControl(
    //     this.attributes["importReadRules"]["dataFile"]
    //       ? this.attributes["importReadRules"]["dataFile"]
    //       : "N"
    //   ),
    // });
    this.multipleSheetForm = new FormGroup({
      multipleSheet: new FormControl("N"),
    });

    // this.connectionService.getConnections().subscribe((conns: any) => {
    //   if(conns.status.toLowerCase() == "success"){
    //     this.connections = conns.connections;
    //   }
    // })
    this.checkToDisplayReadRulesTab();
    // this.connectionService
    //   .getConnectionsByUsername({})
    //   .subscribe((response) => {
    //     if (response["status"].toLowerCase() == "success") {
    //       this.connections = response["connections"] ?? [];
    //     }
    //   });

    if (!this.attributes["connections"]) {
      this.attributes["connections"] = {
        sourceConnectionId: null,
        targetConnectionId: null,
      };
    } else {
      if (typeof this.attributes["connections"] == "string") {
        this.attributes["connections"] = JSON.parse(
          this.attributes["connections"]
        );
      }
      if (this.attributes["connections"]["datastoreTableName"]) {
        this.isTableNameReadOnly = true;
      }
    }
    this.getSourceFileTypes();
    this.checkFileType();
    this.checkMultiSheet();
    this.checkpdfProcessModeType();
    let existingFolderName =
      sessionStorage.getItem("currentFolderName") ?? "Pipelines";
    let currentHeaderTitle = "";
    if (existingFolderName) {
      let pathList = existingFolderName.split("/");
      let latestPath = pathList[pathList.length - 1];
      currentHeaderTitle = latestPath.trim() + " / ";
      if (pathList.length > 1) {
        currentHeaderTitle = ".../" + currentHeaderTitle;
      }
    }
    this.appService.setNewHeader(
      this.attributes["fileHeaders"]["importName"]
        ? {
            title:
              currentHeaderTitle + this.attributes["fileHeaders"]["importName"],
            page: "pipelines/manage",
            isEvent: false,
            eventId: this.importId,
          }
        : {
            title: currentHeaderTitle + "Unsaved Pipeline",
            page: this.router.url,
            isEvent: true,
          }
    );
    this.headerSubscription = this.appService.onHeaderTitleEdit
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (
          res &&
          res.isEvent &&
          this.router.url.includes(res.page) &&
          res.title !== this.attributes["fileHeaders"]["importName"]
        ) {
          if (res.eventId && res.eventId != this.importId) return;
          this.attributes["fileHeaders"]["importName"] = res.title;
          const validation = this.validationService.fileHeader(
            this.attributes["fileHeaders"]
          );
          if (validation !== true) {
            this.appService.isHeaderTitelError.next(true);
            this.appService.showToast({
              duration: 3000,
              message: validation,
              type: "error",
            });
            return;
          }
          if (res.eventId) {
            this.appService.isHeaderTitelError.next(false);
            this.updateSections(["fileHeaders"]);
          } else {
            this.disableHeaderSettingsUpdate = false;
            this.emitUpdateStatus();
          }
        }
      });
    this.checkToDisplayReadRulesTab();
    // this.appService.showHideReadRuleTab(this.attributes['importReadRules'].fileType == 'DAT' || this.attributes['importReadRules'].fileType == 'CSV' ? 'Y' : this.attributes['importReadRules']['fortegraFormatIndicator']);
    // this.attributes["importReadRules"].fileType == "DAT"
    //   ? this.appService.showHideDatTab(true)
    //   : this.appService.showHideDatTab(false);
    // if (this.attributes["fileHeaders"].dataFile == "Y") {
    //   this.attributes["importReadRules"]["fileAttributes"][
    //     "multipleSheetIndicator"
    //   ] = "N";
    //   this.attributes["fileTypes"].push("DAT");
    //   this.attributes["importReadRules"].fileType = "DAT";
    //   this.disableFileType = true;
    //   this.disableSheetName = true;
    //   this.disableDelimiter = false;
    //   // this.formatIndicatorForm.disable();
    //   this.multipleSheetForm.disable();
    //   // this.disableFortegraFormatIndicator = true;
    //   this.disableMultipleSheet = true;
    //   this.appService.showHideReadRuleTab("Y");
    //   this.appService.showHideDatTab(true);
    // } else if (this.attributes["fileHeaders"].dataFile == "N") {
    //   this.attributes["fileTypes"] = this.attributes["fileTypes"].filter(
    //     (item) => item != "DAT"
    //   );
    //   // this.attributes['importReadRules'].fileType = ''
    //   this.attributes["importReadRules"]["fileAttributes"].fileDelimiter = "";
    //   // this.formatIndicatorForm.enable();
    //   this.multipleSheetForm.enable();
    //   this.disableDelimiter = false;
    //   this.disableSheetName = false;
    //   this.disableFileType = false;
    //   this.appService.showHideDatTab(false);
    //   this.checkToDisplayReadRulesTab();
    //   // this.appService.showHideReadRuleTab(this.attributes['importReadRules']['fortegraFormatIndicator'])
    // }

    if (
      this.attributes["importReadRules"] &&
      this.attributes["importReadRules"]["fileType"] &&
      (this.attributes["importReadRules"]["fileType"].toLowerCase() == "xls" ||
        this.attributes["importReadRules"]["fileType"].toLowerCase() ==
          "pdf/image")
    ) {
      this.disableDelimiter = true;
      if (
        this.attributes["importReadRules"]["fileType"].toLowerCase() == "xls"
      ) {
        // this.multipleSheetForm.disable();
      }
      this.attributes["importReadRules"]["fileAttributes"]["fileDelimiter"] =
        "";
    } else if (
      this.attributes["importReadRules"] &&
      this.attributes["importReadRules"]["fileType"] &&
      (this.attributes["importReadRules"]["fileType"] == "CSV" ||
        this.attributes["importReadRules"]["fileType"] == "TXT")
    ) {
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] = "N";
      this.disableMultipleSheet = true;
      this.disableSheetName = true;
      this.multipleSheetForm.disable();
    } else {
      this.disableDelimiter = false;
    }
    this.checkMultiSheet();
    this.loaderService.loader.next(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.loaderService.loader.next(true);
    if (changes["attributes"]) {
      // this.disableFileSettingsUpdate = true;
      if (Object.keys(changes["attributes"]["currentValue"]).length) {
        this.checkFileType();
        this.checkMultiSheet();
        this.getSourceFileTypes();
      }
    }

    if (
      this.attributes["importReadRules"] &&
      this.attributes["importReadRules"]["fileType"] &&
      (this.attributes["importReadRules"]["fileType"].toLowerCase() == "xls" ||
        this.attributes["importReadRules"]["fileType"].toLowerCase() ==
          "pdf/image")
    ) {
      this.disableDelimiter = true;
      this.attributes["importReadRules"]["fileAttributes"]["fileDelimiter"] =
        "";
    } else {
      this.disableDelimiter = false;
    }
    // this.loaderService.loader.next(false);
    if (typeof this.attributes.connections == "string") {
      this.attributes.connections = JSON.parse(this.attributes.connections);
    }
  }

  // hideShowReadRules(value) {
  //   if (this.attributes['importReadRules'].fileType == 'CSV') {
  //     this.appService.showHideReadRuleTab('Y');
  //   }
  //   else if (this.attributes['importReadRules'].fileType != 'DAT') {
  //     this.appService.showHideReadRuleTab(value);
  //   }
  // }

  getSourceFileTypes() {
    if (this.attributes["sourceFileTypes"]) {
      this.sourceFileTypes = this.attributes["sourceFileTypes"];
    }
  }

  updateFileHeaders() {
    // let validateFileHeader = this.validationService.fileHeader(
    //   this.attributes["fileHeaders"]
    // );
    // if (validateFileHeader == true) {
    let data = this.getUpdateRequest("fileHeaders");
    // data["userName"] = this.userName;
    data["isMapSaved"] = this.isMapSaved;
    data["teamId"] = this.appService.currentSelectedDepartment.value.selected;
    if (this.attributes["sourceFileHeaders"] && !this.isMapSaved) {
      data["sourceFileHeaders"] = this.attributes["sourceFileHeaders"];
    }
    if (data["importReadRules"]["sheetMetadata"]) {
      // data["importReadRules"]["sheetMetadata"] = {
      //   sheetNames: data["importReadRules"]["sheetMetadata"],
      // };
    }

    this.importDesignerService.updateAttributes(data).subscribe({
      next: (response) => {
        this.updateAttributesObj();
        if (response) {
          let data = {
            duration: 3000,
            type: "success",
            message: "Header settings updated.",
          };
          this.appService.showToast(data);

          this.disableHeaderSettingsUpdate = true;
          if (!this.isMapSaved) {
            this.isMapSaved = true;
            this.importDesignerService.unsavedMappingData = null;
            const existingFolderName =
              sessionStorage.getItem("currentFolderName") ?? "Pipelines";
            let currentHeaderTitle = "";
            if (existingFolderName) {
              let pathList = existingFolderName.split("/");
              let latestPath = pathList[pathList.length - 1];
              currentHeaderTitle =
                latestPath.trim() +
                " / " +
                this.attributes["fileHeaders"]["importName"];
              if (pathList.length > 1) {
                currentHeaderTitle = ".../" + currentHeaderTitle;
              }
            }
            this.appService.setNewHeader(currentHeaderTitle);
            this.router.navigate(["pipelines/manage"], {
              queryParams: {
                importId: response["importId"],
              },
            });
          }
        } else {
          let data = {
            duration: 3000,
            type: "error",
            message: response["message"],
          };
          this.appService.showToast(data);
        }
        this.emitUpdateStatus();
      },
      error: (err) => {
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data);
        }
      },
    });
    // } else {
    //   this.notification.showErrorNotification("Error", validateFileHeader);
    // }
  }

  updateimportReadRules() {
    if (!this.isMapSaved) {
      let data = {
        duration: 3000,
        type: "alert",
        message: "Please update Header Settings before updating File Settings",
      };
      this.appService.showToast(data);
      return;
    }
    // this.attributes["importReadRules"]["dataFile"] =
    //   this.dataFileForm.value.dataFile;
    // let validateAttribute = this.validationService.importReadSettings(
    //   this.attributes["importReadRules"]
    // );
    // if (validateAttribute == true) {
    let data = this.getUpdateRequest("importReadRules");
    // data["userName"] = this.userName;
    this.importDesignerService.updateAttributes(data).subscribe({
      next: (response) => {
        this.updateAttributesObj();
        if (response) {
          let data = {
            duration: 3000,
            type: "Success",
            message: "File settings updated.",
          };
          this.appService.showToast(data);
          this.disableFileSettingsUpdate = true;
        }
        this.emitUpdateStatus();
      },
      error: (err) => {
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data);
        }
      },
    });
    // } else {
    //   this.notification.showErrorNotification("Error", validateAttribute);
    // }
  }

  updateEmails() {
    if (!this.isMapSaved) {
      let data = {
        duration: 3000,
        type: "alert",
        message: "Please update Header Settings before updating Email Settings",
      };
      this.appService.showToast(data);
      return;
    }
    // var validateEmails = this.validationService.emailSettings(
    //   this.attributes["emails"]
    // );
    // if (validateEmails == true) {
    let data = this.getUpdateRequest("emails");
    // data["userName"] = this.userName;
    this.importDesignerService.updateAttributes(data).subscribe({
      next: (response) => {
        this.updateAttributesObj();
        if (response) {
          let data = {
            duration: 3000,
            type: "success",
            message: "Email settings updated.",
          };
          this.appService.showToast(data);
          this.disableEmailSettingsUpdate = true;
          this.emitUpdateStatus();
        }
      },
      error: (err) => {
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data);
        }
      },
    });
    this.emitUpdateStatus();
    // } else {
    //   this.notification.showErrorNotification("Error", validateEmails);
    // }
  }

  updateSections(sections: string[]) {
    // please add this line (this.apiSuccess.emit(false)) if any "if" condition fails and adds a return for switching the  tabs between for auto save
    if (!this.access) {
      let data = {
        duration: 3000,
        type: "alert",
        message: "Access Denied: You do not have permission for this action.",
      };
      this.appService.showToast(data);
      this.apiSuccess.emit(false);
      return;
    }
    let data = this.getUpdateRequest(sections);
    data["isMapSaved"] = this.isMapSaved;
    // data["autoMappingElements"] = this.importDesignerService.aiMappingsStore;
    // data["teamId"] = this.appService.currentSelectedDepartment.value.selected;
    if (this.attributes["sourceFileHeaders"] && !this.isMapSaved) {
      data["sourceFileHeaders"] = this.attributes["sourceFileHeaders"];
    }
    if (data["importReadRules"]["sheetMetadata"]) {
      // data["importReadRules"]["sheetMetadata"] = {
      //   sheetNames: data["importReadRules"]["sheetMetadata"],
      // };
    } else {
      // data["importReadRules"]["sheetMetadata"] =
      //   data["importReadRules"]["sheetMetadata"];
    }
    // delete data["importReadRules"]["processingMode"];
    if (data) {
      const folderIds = this.folderId;
      if (folderIds) {
        const parentFolderId = folderIds?.split("_");
        data["parentFolderId"] =
          parentFolderId?.length > 0
            ? parentFolderId[parentFolderId.length - 1]
            : null;
        data.fileHeaders.parentFolderId =
          parentFolderId?.length > 0
            ? parentFolderId[parentFolderId.length - 1]
            : null;
      } else {
        data["parentFolderId"] = null;
      }
    }
    data["fileHeaders"]["importType"] = "data";
    if (this.importDesignerService.csvHeaderLine) {
      data["csvHeaderLine"] = this.importDesignerService.csvHeaderLine;
    }
    this.importDesignerService.updateAttributes(data).subscribe({
      next: (response) => {
        if (response) {
          let data = {
            duration: 3000,
            type: "success",
            message: response["message"],
          };
          this.appService.showToast(data);
          if (sections.includes("fileHeaders")) {
            this.disableHeaderSettingsUpdate = true;
          }
          if (sections.includes("importReadRules")) {
            this.disableFileSettingsUpdate = true;
          }
          if (sections.includes("emails")) {
            this.disableEmailSettingsUpdate = true;
          }
          if (!this.isMapSaved) {
            this.isMapSaved = true;
            this.importDesignerService.unsavedMappingData = null;
            this.router.navigate(["pipelines/manage"], {
              queryParams: {
                importId: response["importId"],
                folderId: this.folderId,
              },
            });
          }
          const existingFolderName =
            sessionStorage.getItem("currentFolderName") || "Pipelines";
          let currentHeaderTitle = "";
          if (existingFolderName) {
            let pathList = existingFolderName.split("/");
            let latestPath = pathList[pathList.length - 1];
            currentHeaderTitle =
              latestPath.trim() +
              " / " +
              this.attributes["fileHeaders"]["importName"];
            if (pathList.length > 1) {
              currentHeaderTitle = ".../" + currentHeaderTitle;
            }
          }
          this.appService.setNewHeader({
            title: currentHeaderTitle,
            page: "pipelines/manage",
            isEvent: false,
            eventId: this.importId,
          });
          this._showErrorInputs = false;
        } else {
          let data = {
            duration: 3000,
            type: "Error",
            message: response["message"],
          };
          this.appService.showToast(data);
        }
        this.apiSuccess.emit(true);
        this.emitUpdateStatus();
      },
      error: (err) => {
        this.apiSuccess.emit(false);
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data);
        }
      },
    });
    this.updateAttributesObj();
  }

  updateAttributesObj() {
    this.updateAttributes.emit(this.attributes);
  }

  checkToDisplayReadRulesTab() {
    let visibleReadRulesFor = ["Custom"];
    if (
      visibleReadRulesFor.includes(
        this.attributes["importReadRules"]["fileAttributes"].fileFormat
      )
    ) {
      this.appService.showHideReadRuleTab("Y");
    } else {
      this.appService.showHideReadRuleTab("N");
    }
  }

  checkFileType() {
    // this.loaderService.loader.next(true);
    if (this.attributes["importReadRules"]["fileType"]) {
      if (
        this.attributes["importReadRules"]["fileType"].toLowerCase() === "xls"
      ) {
        this.disableDelimiter = true;
        this.attributes["importReadRules"]["fileAttributes"]["fileDelimiter"] =
          "";
        this.disableMultipleSheet = false;
        this.multipleSheetForm?.enable();
      } else if (
        this.attributes["importReadRules"]["fileType"].toLowerCase() === "xlsx"
      ) {
        this.attributes["importReadRules"]["fileAttributes"]["fileDelimiter"] =
          "";
        this.disableMultipleSheet = false;
        this.multipleSheetForm?.enable();
      } else if (
        this.attributes["importReadRules"]["fileType"].toLowerCase() ===
        "pdf/image"
      ) {
        this.disableDelimiter = true;
        this.attributes["importReadRules"]["fileAttributes"]["fileDelimiter"] =
          "";
        this.attributes["importReadRules"]["fileAttributes"][
          "multipleSheetIndicator"
        ] = "N";
        this.disableSheetName = true;
        this.disableMultipleSheet = true;
        this.multipleSheetForm?.disable();
      } else if (
        this.attributes["importReadRules"]["fileType"].toLowerCase() ===
          "csv" ||
        this.attributes["importReadRules"]["fileType"].toLowerCase() === "txt"
      ) {
        this.disableDelimiter = false;
      }
    } else {
      this.disableDelimiter = false;
    }

    // if (this.attributes['importReadRules'].fileType == 'CSV') {
    //   this.appService.showHideReadRuleTab('Y')
    // } else {
    //   this.appService.showHideReadRuleTab(this.attributes['importReadRules']['fortegraFormatIndicator'])
    // }
    this.checkToDisplayReadRulesTab();
    // this.loaderService.loader.next(false);
  }

  checkMultiSheetOption(indicator?: string, sheetNames?: string) {
    if (indicator) {
      this.confirmPopupService.confirmMessageEvent.next({
        message: "Are you sure you want to proceed with this change?",
        name: "clear",
        note: "This will remove the current source mapping, and the first sheet's headers will be used by default.",
        confirmButtonText: "Confirm",
        headerText: "Multi-sheet to Single sheet",
        manageCallback: (value) => {
          if (value && value["isConfirm"] === "true") {
          }
          if (value && value["isConfirm"] === "false") {
            if (indicator === "N")
              this.attributes["importReadRules"][
                "fileAttributes"
              ].multipleSheetIndicator = "Y";
            this.attributes["importReadRules"]["sheetMetadata"]["sheetNames"] =
              sheetNames;
          }
        },
      });
    } else {
      let data = {
        duration: 3000,
        type: "info",
        message:
          "Please update the Source Mappings before processing the files!",
      };
      this.appService.showToast(data);
    }
  }

  checkMultiSheet() {
    // this.loaderService.loader.next(true);
    if (
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] !== null
    ) {
      if (
        this.attributes["importReadRules"]["fileAttributes"][
          "multipleSheetIndicator"
        ].toLowerCase() === "n"
      ) {
        this.disableSheetName = true;
        this.attributes["importReadRules"]["sheetMetadata"]["sheetNames"] = "";
      } else {
        this.disableSheetName = false;
      }
      // this.loaderService.loader.next(false);
    } else if (
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] === null &&
      this.attributes["importReadRules"]["sheetMetadata"]["sheetNames"]
    ) {
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] = "Y";
    } else if (
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] === null &&
      !this.attributes["importReadRules"]["sheetMetadata"]["sheetNames"]
    ) {
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] = "N";
    }
    if (
      ["Vector Structured", "Vector UnStructured"].includes(
        this.attributes["importReadRules"]["fileAttributes"].fileFormat
      )
    ) {
      this.attributes["importReadRules"]["fileType"] = "";
      this.disableFileType = true;
      this.disableFileFormat = true;
      this.disableDelimiter = false;
      this.disableSheetName = false;
      this.disableFileSettingsUpdate = false;
      this.toggleDisableForUpdate?.next(true);
    } else {
      this.disableFileType = false;
      this.disableFileFormat = false;
      this.disableDelimiter = false;
      this.disableSheetName = false;
      //   this.disableFileSettingsUpdate = false;
      //   this.toggleDisableForUpdate?.next(false);
    }
  }

  getUpdateRequest(value) {
    let importReadRules = this.attributes["importReadRules"];
    importReadRules["importId"] ??= this.importId;
    return {
      importId: this.importId,
      updateSection: value,
      fileHeaders: this.attributes["fileHeaders"],
      importReadRules: importReadRules,
      emails: this.attributes["emails"],
      connections: this.attributes["connections"],
    };
  }

  disableUpdate(targetElement: any, type: string, selectedFileType?: any) {
    switch (type) {
      case "header":
        this.disableHeaderSettingsUpdate = false;
        break;
      case "file":
        this.disableFileSettingsUpdate = false;
        break;
      case "email":
        if (
          this._showEmailErrorTouched &&
          this.validationService.emailSettings({
            successEmail: targetElement.value,
          }) !== true
        ) {
          this._showEmailError = true;
        } else {
          this._showEmailError = false;
        }
        this.disableEmailSettingsUpdate = false;
        break;
      case "connection":
        this.disableConnectionSettingsUpdate = false;
        break;
    }
    // }
    if (this.attributes["importReadRules"].fileType === "XLS") {
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] =
        this.attributes["importReadRules"]["fileAttributes"][
          "multipleSheetIndicator"
        ] || "N";
      this.disableSheetName = false;
      this.disableMultipleSheet = false;
      this.multipleSheetForm.enable();
    } else if (
      this.attributes["importReadRules"].fileType === "CSV" ||
      this.attributes["importReadRules"].fileType === "TXT"
    ) {
      this.attributes["importReadRules"]["fileAttributes"][
        "multipleSheetIndicator"
      ] = "N";
      this.disableMultipleSheet = true;
      this.disableSheetName = true;
      this.multipleSheetForm.disable();
    }
    // selectedFileType === "DAT"
    //   ? (this.readRuleFlag = true)
    //   : (this.readRuleFlag = false);
    // if (selectedFileType === "DAT") {
    //   this.attributes["importReadRules"]["fileAttributes"][
    //     "multipleSheetIndicator"
    //   ] = "N";
    //   this.attributes["fileTypes"].push("DAT");
    //   this.attributes["importReadRules"].fileType = "DAT";
    //   this.attributes["importReadRules"]["sheetMetadata"]["sheetNames"] = "";
    //   this.attributes["importReadRules"][
    //     "fileAttributes"
    //   ].fortegraFormatIndicator = "N";
    //   this.formatIndicatorForm.disable();
    //   this.multipleSheetForm.disable();
    //   // this.hideShowReadRules('N');
    //   this.disableFileType = true;
    //   this.disableSheetName = true;
    //   this.disableDelimiter = false;
    //   // this.disableFortegraFormatIndicator = true;
    //   this.disableMultipleSheet = true;
    //   this.disableFileSettingsUpdate = false;
    //   this.appService.showHideReadRuleTab("Y");
    //   this.appService.showHideDatTab(true);
    // }
    // else
    if (selectedFileType === "!DAT") {
      this.attributes["fileTypes"] = this.attributes["fileTypes"].filter(
        (item) => item !== "DAT"
      );
      this.attributes["importReadRules"].fileType = "";
      this.attributes["importReadRules"]["fileAttributes"].fileDelimiter = "";
      this.formatIndicatorForm.enable();
      this.multipleSheetForm.enable();
      this.disableDelimiter = false;
      this.disableSheetName = false;
      this.disableFileType = false;
      this.disableFileSettingsUpdate = false;
      this.appService.showHideDatTab(false);
      // if (this.attributes['importReadRules'].fileType == 'CSV') {
      //   this.appService.showHideReadRuleTab('Y')
      // } else {
      //   this.appService.showHideReadRuleTab(this.attributes['importReadRules']['fortegraFormatIndicator'])
      // }
      this.checkToDisplayReadRulesTab();
    }
    // else {
    //   this.appService.showHideDatTab(false);
    //   this.appService.showHideReadRuleTab(this.attributes['importReadRules']['fortegraFormatIndicator'])
    // }
    if (this.attributes["importReadRules"]["fileType"] !== "PDF/IMAGE") {
      this.pdfProcessDataTypeForm.reset();
      this.attributes["importReadRules"]["processingMode"] = "";
      this.ispdfProcessTypeSelected = false;
    }
    if (
      this.attributes["importReadRules"]["fileAttributes"].fileFormat !==
      "Invoice"
    ) {
      this.pdfProcessDataTypeForm.reset();
      this.attributes["importReadRules"]["processingMode"] = "";
      this.ispdfProcessTypeSelected = false;
    }
    this.checkMultiSheet();
    this.checkpdfProcessModeType();
    this.emitUpdateStatus();
  }

  emitUpdateStatus() {
    if (
      !this.disableHeaderSettingsUpdate ||
      !this.disableEmailSettingsUpdate ||
      !this.disableFileSettingsUpdate ||
      !this.disableConnectionSettingsUpdate
    ) {
      this.isUpdated.emit({ attributes: true });
      this.toggleDisableForUpdate?.next(false);
    } else {
      this.isUpdated.emit({ attributes: false });
      this.toggleDisableForUpdate?.next(true);
    }
  }

  invalidNoOfLinesInputValue(event) {
    const inputChar = event.key;
    // Allow numbers, backspace, and arrow keys
    if (
      !/^[0-9]$/.test(inputChar) &&
      inputChar !== "Backspace" &&
      inputChar !== "ArrowLeft" &&
      inputChar !== "ArrowRight" &&
      inputChar !== "Delete"
    ) {
      event.preventDefault();
    }
  }

  toggleSectionVisibility(section: attributeSections) {
    this.isSectionExpanded[section] = !this.isSectionExpanded[section];
  }

  isUpdateButtonEnabled() {
    return (
      !this.disableHeaderSettingsUpdate ||
      !this.disableEmailSettingsUpdate ||
      !this.disableFileSettingsUpdate
    );
  }

  updateAllAttributes() {
    this._showEmailError = false;
    this._showEmailErrorTouched = true;
    let validateFileHeader = this.validationService.fileHeader(
      this.attributes["fileHeaders"]
    );
    let validateAttribute = this.validationService.importReadSettings(
      this.attributes["importReadRules"]
    );
    let validateEmails = this.validationService.emailSettings(
      this.attributes["emails"]
    );

    if (validateFileHeader !== true) {
      this.appService.isHeaderTitelError.next(true);
      if (validateAttribute === true && validateEmails === true) {
        this.appService.showToast({
          duration: 3000,
          message: validateFileHeader,
          type: "error",
        });
        this.apiSuccess.emit(false);
        return;
      }
    }

    if (
      validateAttribute !== true &&
      !["Vector Structured", "Vector UnStructured"].includes(
        this.attributes["importReadRules"]["fileAttributes"].fileFormat
      )
    ) {
      this._showErrorInputs = true;
      let data = {
        duration: 3000,
        type: "Error",
        message: "Please fill all highlighted fields.",
      };
      this.appService.showToast(data);
      this.apiSuccess.emit(false);
      return;
    }
    if (validateEmails !== true) {
      this._showEmailError = true;
      let data = {
        duration: 3000,
        type: "Error",
        message: "Please fill all highlighted fields.",
      };
      if (validateAttribute === true && validateEmails) {
        data.message = validateEmails;
      }
      this.appService.showToast(data);
      this.apiSuccess.emit(false);
      return;
    } else {
      this._showEmailError = false;
      if (
        this.attributes["importReadRules"]["processingMode"] === "template" &&
        this.attributes["importReadRules"]["fileType"].toLowerCase() ===
          "pdf/image" &&
        this.params["newPipeline"] &&
        this.attributes["importReadRules"]["fileAttributes"]["fileFormat"] ===
          "Invoice"
      ) {
        let data = {
          duration: 3000,
          type: "alert",
          message: "Upload source template to proceed.",
        };
        this.appService.showToast(data);
        this.isUpdated.emit({ attributes: false });
        this.apiSuccess.emit(false);
        return;
      }
    }
    this.appService.isHeaderTitelError.next(false);
    let sections: string[] = [];
    if (!this.disableHeaderSettingsUpdate) {
      // this.updateFileHeaders();
      sections.push("fileHeaders");
    }

    if (!this.disableFileSettingsUpdate) {
      // this.updateimportReadRules();
      sections.push("importReadRules");
    }

    if (!this.disableEmailSettingsUpdate) {
      // this.updateEmails();
      sections.push("emails");
    }

    if (sections.length > 0) {
      this.updateSections(sections);
      return;
    } else {
      this.apiSuccess.emit(true);
    }

    this._showErrorInputs = false;
  }

  showErrorFields() {
    this._showErrorInputs = true;
  }

  setAttributes(attributes: any) {
    this.attributes = JSON.parse(JSON.stringify(attributes));
  }
  checkpdfProcessModeType(event?: any) {
    if (
      this.attributes["importReadRules"]["fileType"].toLowerCase() ===
        "pdf/image" &&
      this.attributes["importReadRules"]["fileAttributes"]["fileFormat"] ===
        "Invoice"
    ) {
      if (this.pdfProcessDataTypeForm.get("processingMode").value === "ai") {
        this.attributes["importReadRules"]["processingMode"] =
          this.pdfProcessDataTypeForm.get("processingMode").value;
        this.ispdfProcessTypeSelected = false;
      } else if (
        this.pdfProcessDataTypeForm.get("processingMode").value === "template"
      ) {
        this.attributes["importReadRules"]["processingMode"] =
          this.pdfProcessDataTypeForm.get("processingMode").value;
        this.ispdfProcessTypeSelected = true;
      }
    } else {
      this.ispdfProcessTypeSelected = false;
    }
  }

  isTemplatePdfProcess(event?) {
    let validateFileHeader = this.validationService.fileHeader(
      this.attributes["fileHeaders"]
    );
    let validateAttribute = this.validationService.importReadSettings(
      this.attributes["importReadRules"]
    );
    let validateEmails = this.validationService.emailSettings(
      this.attributes["emails"]
    );
    if (validateFileHeader !== true) {
      this.appService.isHeaderTitelError.next(true);
      if (validateAttribute === true && validateEmails === true) {
        this.appService.showToast({
          duration: 3000,
          message: validateFileHeader,
          type: "error",
        });
        return;
      }
    }
    if (validateAttribute !== true || validateEmails !== true) {
      this._showErrorInputs = true;
      if (!this.templateModeFile) {
        let data = {
          duration: 3000,
          type: "error",
          message: "Please define the template for this pipline",
        };
        this.appService.showToast(data);
      }
      return;
    }
    if (this.params["newPipeline"]) {
      this.appService.isHeaderTitelError.next(false);
      const e: HTMLElement = this.fileSelectInputDialog.nativeElement;
      e.click();
      if (!event) return;
      const reader = new FileReader();
      const [file] = event.target.files;
      this.templateModeFile = file;
      if (file.name.endsWith(".pdf")) {
        this.importMapFromFileClick(file);
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileSelectInputDialog.nativeElement.value = [];
        let result = String(reader.result)
          .slice(String(reader.result).indexOf(",") + 1)
          .trim();
        let data = {
          filePath: result,
        };
      };
    } else {
      this.importDesignerService.getTemplatePdfFile(this.importId).subscribe({
        next: (res) => {
          let blob = new Blob([res], {
            type: "application/octet-stream",
          });
          let file = new File([blob], "sample.pdf", {
            type: "application/octet-stream",
          });
          this.appService.templateFlowInfo.next({
            flowType: "edit",
            importId: this.importId,
            templatePath: this.attributes["templateInfo"]?.["templateFilePath"],
          });
          this.importMapFromFileClick(file);
        },
        error: (err) => {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data);
        },
      });
      // let data = {
      //   duration: 3000,
      //   type: "alert",
      //   message: "Template not found",
      // };
      // this.appService.showToast(data);
    }
  }

  importMapFromFileClick(file: File) {
    this.isUpdated.emit({ attributes: false });
    this.toggleDisableForUpdate?.next(true);
    this.appService.templateFile.next(file);
    this.appService.templateData.next(this.attributes);
    this.router.navigate(["template-modelling"], {
      queryParams: {
        redirectTo: this.router.url,
      },
    });
  }
  validateEmailInput(value) {
    const valid = this.validationService.emailSettings(value);
    if (valid) {
      return true;
    }
    return false;
  }
}

import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/core/services/app.service";
import { AuthPageService } from "../../services/auth-page.service";
import { ToastService } from "src/app/shared/services/toast.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetForm = this.fb.group({});
  public isPasswordVisible = false;
  public isConfirmPasswordVisible = false;
  public errorText = "";
  public successText = "";
  public isConfirmationIdValid: boolean = true;
  public loading = true;
  public confirmationCode: string = "";
  public minError = "";
  public maxError = "";
  public linkExpired = false;
  constructor(
    private appService: AppService,
    private fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private authPageService: AuthPageService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    let currentUser: any = this.appService.getCurrentUser();
    if (
      currentUser?.username ||
      localStorage.getItem("currentUser")?.includes("username")
    ) {
      this.router.navigate(["home"]);
      return;
    }
    let params = this.router.parseUrl(this.router.url).queryParams;
    if (params.confirmationId) {
      let id = params.confirmationId;
      this.confirmationCode = id;
      this.authPageService
        .userValidateResetPassword({ confirmation_id: id })
        .subscribe((res: any) => {
          if (res["success"] === "true") {
            this.isConfirmationIdValid = true;
            this.linkExpired = false;
          } else {
            if (res["success"] === "false") {
              this.isConfirmationIdValid = false;
              this.linkExpired = true;
              this.errorText = res["message"];
            }
          }
          this.loading = false;
        });
    } else {
      this.loading = false;
      this.linkExpired = true;
      this.errorText = "Link is invalid or expired";
      this.isConfirmationIdValid = false;
    }
    this.resetForm = this.fb.group({
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
          ),
        ]),
      ],
      confirmPassword: ["", Validators.compose([Validators.required])],
    });
  }

  handleInvalidConfirmationCode() {
    this.loading = false;
    this.isConfirmationIdValid = false;
  }
  get password() {
    let p = this.resetForm.get("password")?.value.length;
    return this.resetForm.get("password");
  }
  get confirmPassword() {
    return this.resetForm.get("confirmPassword");
  }
  clearValue() {
    if (this.errorText && this.password?.value && this.confirmPassword?.value) {
      this.errorText = "";
    }
  }
  reset() {
    if (this.resetForm.invalid) {
      if (
        this.password?.errors?.required ||
        this.confirmPassword?.errors?.required
      ) {
        this.errorText = "Fill all input fields";
      } else if (this.password?.errors?.pattern) {
        this.errorText = "Invalid password";
      } else {
        this.errorText = "Invalid credentials";
      }
      return;
    } else if (this.confirmPassword?.value !== this.password?.value) {
      this.errorText = "Password does not match";
    } else {
      if (
        this.resetForm.valid &&
        this.resetForm.value.password === this.resetForm.value.confirmPassword
      ) {
        let resetForm = {
          confirmation_id: this.confirmationCode,
          new_password: this.resetForm.value.password,
          confirm_new_password: this.resetForm.value.confirmPassword,
        };
        this.authPageService.resetPassword(resetForm).subscribe({
          next: (response) => {
            if (response["success"] === "true") {
              this.successText = response["message"];
              this.errorText = "Fill all input fields";
              this.confirmationCode = "";
              this.linkExpired = false;
              setTimeout(() => {
                this.router.navigate(["login"]);
              }, 1000);
              this.toastService.commonToastMethod(
                response["message"] || "Password updated successfully",
                "success"
              );
            } else if (response["success"] === "false") {
              this.errorText = response["message"];
              if (
                response["message"].toLowerCase().includes("invalid") &&
                response["message"].toLowerCase().includes("expired")
              ) {
                this.linkExpired = true;
                this.isConfirmationIdValid = false;
              }
            }
          },
          error: (err) => {
            this.errorText = err["message"];
            if (err.status !== 403) {
              let data = {
                duration: 3000,
                type: "error",
                message: err["error"]?.["message"] || err["message"] || err,
              };
              this.appService.showToast(data);
              this.toastService.commonToastMethod(
                err["error"]?.["message"] || err["message"] || err,
                "error"
              );
            }
          },
        });
      }
    }
  }

  isforgotPage() {
    this.router.navigate(["forgot"]);
  }

  login() {
    this.router.navigate(["login"]);
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }
}

<ng-container *ngFor="let toast of currentToasts; let i = index">
  <div class="toast-div">
    <div
      class="component-b"
      (mouseenter)="pauseTimebar(toast)"
      (mouseleave)="resumeTimebar(toast)"
      (click)="closeToast(toast.id)"
      [style.top.px]="calculateTopPosition(i)"
      [ngClass]="{
        'alert-error': toast.type.toLowerCase() === 'error',
        'alert-success': toast.type.toLowerCase() === 'success',
        'alert-info': toast.type.toLowerCase() === 'info',
        'alert-warning': toast.type.toLowerCase() === 'alert'
      }">
      <div class="toast-type">
        <img
          src="assets/Svgs_/Toast/Alert.svg"
          alt="Alert"
          *ngIf="toast.type && toast.type.toLowerCase() === 'alert'" />
        <img
          src="assets/Svgs_/Toast/Error.svg"
          alt="Error"
          *ngIf="toast.type && toast.type.toLowerCase() === 'error'" />
        <img
          src="assets/Svgs_/Toast/Info.svg"
          alt="Info"
          *ngIf="toast.type && toast.type.toLowerCase() === 'info'" />
        <img
          src="assets/Svgs_/Toast/Success.svg"
          alt="Success"
          *ngIf="toast.type && toast.type.toLowerCase() === 'success'" />
      </div>
      <div class="wrapper">
        <!-- <p class="type">{{errorType}}</p> -->
        <div class="errorMessage" [title]="toast?.message">
          {{ toast?.message }}
        </div>
      </div>
      <div class="cancelDiv" (click)="closeToast(toast.id)">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.9123 3.0876C11.1401 3.31541 11.1401 3.68475 10.9123 3.91256L3.91232 10.9126C3.68451 11.1404 3.31516 11.1404 3.08736 10.9126C2.85955 10.6848 2.85955 10.3154 3.08736 10.0876L10.0874 3.0876C10.3152 2.8598 10.6845 2.8598 10.9123 3.0876Z"
            fill="#70798C" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.08736 3.0876C3.31516 2.8598 3.68451 2.8598 3.91232 3.0876L10.9123 10.0876C11.1401 10.3154 11.1401 10.6848 10.9123 10.9126C10.6845 11.1404 10.3152 11.1404 10.0874 10.9126L3.08736 3.91256C2.85955 3.68475 2.85955 3.31541 3.08736 3.0876Z"
            fill="#70798C" />
        </svg>
        <!-- <img src="assets/Svgs_/Toast/AlertCancel.svg" alt="Alert" *ngIf="errorType && errorType.toLowerCase() ==='alert'">
        <img src="assets/Svgs_/Toast/ErrorCancel.svg" alt="Error" *ngIf="errorType && errorType.toLowerCase() ==='error'">
        <img src="assets/Svgs_/Toast/InfoCancel.svg" alt="Info" *ngIf="errorType && errorType.toLowerCase() ==='info'">
        <img src="assets/Svgs_/Toast/SuccessCancel.svg" alt="Success" *ngIf="errorType && errorType.toLowerCase() ==='success'">  -->
      </div>
    </div>
  </div>
</ng-container>

<!-- <div
    (mouseenter)="pauseTimebar()"
    (mouseleave)="resumeTimebar()"
    (click)="closeToast(toast.id)"
    [ngClass]="{
      'alert-error': toast.type === 'Error',
      'alert-success': toast.type === 'Success'
    }">
    <div class="timebar-container">
      <div
        class="timebar"
        #timebar
        [ngStyle]="{
          'animation-play-state': timebarAnimationState,
         
        }"></div>
    </div>
    <div class="wrapper">
      <p class="type">{{ toast.type }}</p>
    </div>
    <div class="errorMessage">{{ toast.message }}</div>
  </div>
</ng-container> -->

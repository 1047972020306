<ng-container *ngIf="messageObject['message'] && closeMessage">
  <div class="confirm-popup">
    <div class="main">
      <div class="modal-header">
        <div class="select-connection">
          <strong>{{ headerText === "" ? "Confirmation" : headerText }}</strong>
          <div
            class="tooltip-common icon-tooltip"
            *ngIf="!connectionType && headerText === 'File Upload'">
            <i class="flip-icons flip-info info-text"></i>
            <div class="tooltip-utils center info-tooltip">
              {{
                type === "target"
                  ? "Use a connection or choose a file to populate " +
                    type +
                    " elements."
                  : "Use a connection or choose a file to map with source."
              }}
            </div>
          </div>
        </div>
        <div class="tooltip-common close-popUp">
          <img
            src="/assets/Svgs_/Dashboard/CrossForModel.svg"
            (click)="closePopup(true)" />
          <span class="tooltip-utils center">Close</span>
        </div>
      </div>
      <div
        class="modal-body confirmation-body"
        style="max-height: 470px; max-width: 440px"
        *ngIf="!fileUpload">
        <div
          class="userDetails"
          *ngIf="userName && (userFirstName || userlastName)">
          <div class="user-icon">
            {{ getProfileName(userFirstName, userlastName) }}
          </div>
          <div class="user">
            <p class="username">
              {{ userFirstName }}
              {{ userlastName }}
            </p>
            <p class="userMail">{{ userName }}</p>
          </div>
        </div>
        <span *ngIf="messageObject && !isCopyDataPipelines">{{
          messageObject["message"]
        }}</span>
        <div *ngIf="isCopyDataPipelines">
          <div class="wrapper">
            <label *ngIf="messageObject['message'] === 'copy pipelines'"
              >Pipeline Name</label
            >
            <label *ngIf="messageObject['message'] === 'Add Sheet'"
              >Sheet Name</label
            >
            <form (ngSubmit)="confirmPopup()">
              <input
                type="text"
                name="copyImportName"
                [(ngModel)]="isCopyImportName"
                (change)="copyImportChange($event)" />
              <div
                class="errorField sheetNameError"
                *ngIf="showError && !showDuplicateError">
                <img src="assets/Svgs_/SideBar/Info.svg" alt="_" />
                {{
                  messageObject["message"] === "copy pipelines"
                    ? "Please enter the Pipeline name"
                    : ""
                }}
                {{
                  messageObject["message"] === "Add Sheet" ||
                  messageObject["message"] === "Rename Sheet"
                    ? "Please enter the Sheet name"
                    : ""
                }}
              </div>
              <div class="errorField sheetNameError" *ngIf="showDuplicateError">
                <img src="assets/Svgs_/SideBar/Info.svg" alt="_" />
                {{
                  messageObject["message"] === "Add Sheet" ||
                  messageObject["message"] === "Rename Sheet"
                    ? "Sheet name already exists!"
                    : ""
                }}
              </div>
            </form>
          </div>
        </div>
        <ng-container *ngIf="messageObject['note']">
          <div class="noteMessage">Note : {{ messageObject["note"] }}</div>
        </ng-container>
      </div>
      <div class="modal-body fileUpload-confirmation-body" *ngIf="fileUpload">
        <div class="connection-type" *ngIf="!connectionType">
          <!-- <div>Please provide a {{ type }} database connection</div> -->

          <form [formGroup]="connectionForm">
            <ng-template #connectionField let-field>
              <tr class="ConnectionTr">
                <td class="left">
                  <div class="audit-key">
                    {{ field.label
                    }}<ng-container *ngIf="field.isRequired"
                      ><span class="required-star">*</span></ng-container
                    >:
                  </div>
                </td>
                <td class="right">
                  <div class="audit-value">
                    <div class="input-wrapper">
                      <ng-container
                        *ngTemplateOutlet="field.formField"></ng-container>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <div class="connection-div">
              <label class="audit-key">Select {{ type }} connection</label>
              <select
                formControlName="connectionId"
                (change)="getAdditionalForm(type)">
                <option
                  *ngFor="let conn of createdConnection"
                  [value]="conn.id">
                  {{ conn["connectionName"] }}
                  |
                  {{
                    getConnectionTypeDetails(conn["connectionType"])?.label ||
                      conn["connectionType"]
                  }}
                </option>
              </select>
            </div>
            <app-dynamic-form
              *ngIf="connectionAdditionalForm"
              [form]="connectionAdditionalForm"
              [prefixNewControlNames]="type"
              [existingValues]="{}"
              [formGroup]="connectionForm"
              (formChanged)="emitTemplateValues()"
              [fieldTemplate]="connectionField"></app-dynamic-form>
          </form>
          <div *ngIf="!selectedConnection">or</div>
        </div>
        <div
          class="placeholder-Div"
          (click)="fileInput.click()"
          (dragleave)="onDragLeave($event)"
          (dragover)="onDragOver($event)"
          (drop)="fileDrop($event)"
          *ngIf="!selectedConnection"
          name="migrationSourceFile">
          <p class="desc">{{ messageObject["message"] }}</p>
          <p class="desc">or</p>
          <div class="button-wrapper new-folder-pipeline">
            <button name="migrationSourceFile" class="button-common addButton">
              <i class="ti ti-file-upload"></i>
              Choose File
            </button>
            <input
              #fileInput
              type="file"
              name="migrationSourceFile"
              [accept]="handleGetFileType()"
              (change)="onFileSelected($event)"
              style="display: none" />
          </div>
        </div>
      </div>
      <div class="modal-footer confirmation">
        <div>
          <!-- <button
        *ngIf="selectedConnection"
        class="clear-connection-details"
        type="button"
        (click)="clearConnection()">
         Clear connection
      </button> -->
        </div>
        <div>
          <button
            class="confirmation-outlined-btn"
            type="button"
            (click)="closePopup()">
            {{ cancelButtonText || "Cancel" }}
          </button>
          <button
            class="confirmation-primary-btn"
            type="submit"
            (click)="confirmPopup()"
            [disabled]="disabledConfirm()">
            {{ confirmButtonText || "Confirm" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="triggerId && dropdownOptions">
  <div
    class="dropdown-trigger"
    (click)="openSelect()"
    [id]="triggerId"
    [ngClass]="customClass">
    <!-- Input for Normal and Input-as-Search Dropdowns -->
    <ng-container
      *ngIf="
        isNormalDropdown(dropdownOptions) ||
        isInputAsSearchDropdown(dropdownOptions)
      ">
      <input
        type="text"
        [(ngModel)]="inputValue"
        (blur)="onInputBlur()"
        [placeholder]="dropdownOptions.placeholder"
        (input)="onInputChange($event)"
        [ngStyle]="{
          'margin-top': dropdownOptions.isInputAsSearch ? '0px' : ''
        }"
        [readOnly]="
          !dropdownOptions.searchable && isNormalDropdown(dropdownOptions)
        "
        required />
    </ng-container>

    <!-- Multi-Select Dropdown -->
    <ng-container *ngIf="isMuiltiSelectDropdown(dropdownOptions)">
      <div class="drop-list-box">
        <span *ngIf="getSelectedItems().length === 0">{{
          dropdownOptions.placeholder
        }}</span>
        <div
          *ngFor="let item of getSelectedItems(); let i = index"
          class="itemdrag">
          <span class="item-box">
            {{ i + 1 }}. {{ item }}
            <i
              class="ti ti-x"
              (click)="$event.stopPropagation(); removeDragItem(item, i)">
            </i>
          </span>
        </div>
      </div>
    </ng-container>

    <!-- Dropdown Icon -->
    <div class="dropdown-icon">
      <i
        class="ti"
        [ngClass]="{
          'ti-chevron-down': !toggleIconTrigger,
          'ti-chevron-up': toggleIconTrigger
        }"
        (click)="openSelect()">
      </i>
    </div>
  </div>
</ng-container>
<!-- (cdkDropListDropped)="onItemDropped($event)" -->
<!-- [cdkDropListData]="filterOptions" -->
<!-- cdkDropList -->
<!-- (cdkDropListDropped)="onDropList($event)" -->

<ng-container *ngIf="backupForm !== null">
  <ng-container [formGroup]="formGroup">
    <ng-container *ngFor="let field of renderableFields">
      <ng-container
        *ngTemplateOutlet="
          fieldTemplate || defaultFieldTemplate;
          context: {
            $implicit: {
              label: field.name,
              isRequired: field.validators.required,
              formField: fieldTypeTemplate,
              type: field.type,
              tooltip: field.tooltip
            }
          }
        "></ng-container>
      <ng-template #fieldTypeTemplate>
        <ng-container
          *ngIf="
            ![
              'radio',
              'select',
              'textarea',
              'checkbox',
              'multiselect',
              'multitext',
              'number',
              'switch'
            ].includes(field.type)
          ">
          <input
            [type]="field.type"
            [formControlName]="field.id"
            [name]="field.id" />
        </ng-container>
        <ng-container *ngIf="field.type === 'number'">
          <input
            [type]="field.type"
            [formControlName]="field.id"
            [min]="field.validators?.min"
            [max]="field.validators?.max"
            [name]="field.id"
            [placeholder]="getNumberPlaceholder(field)"
        /></ng-container>
        <ng-container *ngIf="field.type === 'file'">
          <label>
            <input
              [type]="field.type"
              class="file-choose-btn"
              (change)="onFileSelect($event, field.id)"
              [name]="field.id" />
            <div class="choose-btn">Choose File</div>
            <span>{{ getSelectedFileName(field.id) }}</span>
          </label>
        </ng-container>
        <ng-container *ngIf="field.type === 'radio'">
          <div class="radio-inline">
            <ng-container *ngFor="let option of field.options; let i = index">
              <input
                [type]="field.type"
                [value]="option"
                [checked]="formGroup.get(field.id)?.value === option"
                [name]="field.id"
                [formControlName]="field.id"
                (click)="onRadioValueChange(field.id, option)" />
              <label [for]="option">{{ option }}</label>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="field.type === 'select'">
          <select
            [name]="field.id"
            [formControlName]="field.id"
            (change)="onSelectValueChange(field.id, $event)">
            <option *ngFor="let opt of field.options" [value]="opt">
              {{ opt }}
            </option>
          </select>
        </ng-container>
        <ng-container *ngIf="field.type === 'textarea'">
          <textarea
            [name]="field.id"
            [formControlName]="field.id"
            rows="3"></textarea>
        </ng-container>
        <ng-container *ngIf="field.type === 'checkbox'">
          <div class="checkbox-wrapper">
            <input
              type="checkbox"
              class="globalCheckbox"
              [checked]="formControlNode(field.id)?.value"
              (change)="onFieldValueChange($event, field)"
              [name]="field.id"
              [formControlName]="field.id" />
            <ng-container *ngIf="field.name">
              <label [for]="field.name"
                >{{ field.name }}
                <span class="required-star" *ngIf="field.validators.required">
                  *</span
                >
              </label>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="field.type === 'multiselect'">
          <mat-form-field appearance="outline">
            <mat-select [formControlName]="field.id" multiple>
              <mat-option
                *ngFor="let option of field.options"
                [value]="option"
                >{{ option }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="field.type === 'multitext'">
          <ng-container
            *ngFor="let text of multitextFields[field.id]; let i = index">
            <div class="flex">
              <input
                type="text"
                [name]="field.name"
                [value]="this.multitextFields[field.id][i]"
                (input)="markMultiTextAsTouched(field.id)"
                (change)="onMultiTextValueChange($event, field.id, i)" />
              <button
                class="multitext btn"
                *ngIf="i > 0"
                (click)="removeMultiTextValue(field.id, i)">
                -
              </button>
              <button
                class="multitext btn"
                *ngIf="i === 0"
                (click)="addMultiTextValue(field.id)">
                +
              </button>
            </div>
          </ng-container>
        </ng-container>
        <div *ngIf="field.type === 'switch'" class="confirmfields">
          <div class="toggleTypeSwitch">
            <label class="toggle small">
              <input
                type="checkbox"
                style="vertical-align: middle; margin-right: 5px"
                [checked]="formControlNode(field.id)?.value"
                (change)="onFieldValueChange($event, field)"
                [name]="field.id"
                [formControlName]="field.id"
                class="active-checkbox" />
              <div>Status</div>
            </label>
            <ng-container *ngIf="field.name">
              <label [for]="field.name" class="toggle-label"
                >{{ field.name }}
                <span class="required-star" *ngIf="field.validators.required">
                  *</span
                >
              </label>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="
            formControlNode(field.id)?.touched &&
            formControlNode(field.id)?.errors
          "
          style="
            color: red;
            font-size: 11px;
            font-family: sans-serif;
            margin-top: 3px;
          ">
          <div *ngIf="formControlNode(field.id)?.errors?.['required']">
            {{ field.name }} is required
          </div>
          <div *ngIf="formControlNode(field.id)?.errors?.['minlength']">
            Min length should be
            {{
              formControlNode(field.id)?.errors?.["minlength"]["requiredLength"]
            }}
          </div>
          <div *ngIf="formControlNode(field.id)?.errors?.['maxlength']">
            Max length should be
            {{
              formControlNode(field.id)?.errors?.["maxlength"]["requiredLength"]
            }}
          </div>
          <div
            *ngIf="
              formControlNode(field.id)?.errors?.['min'] ||
              formControlNode(field.id)?.errors?.['max']
            ">
            Value should be within
            {{ field.validators.min ?? "Infinite " }}-{{
              field.validators.max ?? " Infinite"
            }}
          </div>
        </div>
      </ng-template>
      <ng-template #defaultFieldTemplate>
        <div
          *ngIf="
            ![
              'radio',
              'select',
              'textarea',
              'checkbox',
              'multiselect',
              'multitext',
              'switch'
            ].includes(field.type)
          "
          id="details"
          class="confirmfields">
          <label [for]="field.name"
            >{{ field.name
            }}<span class="required-star" *ngIf="field.validators.required"
              >*</span
            >
            <div class="tooltip-common form-tooltip" *ngIf="field.tooltip">
              <i class="ti ti-help-circle"></i>
              <div class="tooltip-utils center">
                {{ field.tooltip }}
              </div>
            </div>
          </label>
          <input
            [type]="field.type"
            *ngIf="field.type !== 'file' && field.type !== 'number'"
            [formControlName]="field.id"
            [name]="field.id" />
          <input
            [type]="field.type"
            *ngIf="field.type === 'number'"
            [formControlName]="field.id"
            [placeholder]="getNumberPlaceholder(field)"
            [min]="field.validators?.min"
            [max]="field.validators?.max"
            [name]="field.id" />
          <label *ngIf="field.type === 'file'">
            <input
              [type]="field.type"
              class="file-choose-btn"
              (change)="onFileSelect($event, field.id)"
              [name]="field.id" />
            <div class="choose-btn">Choose File</div>
            <span>{{ getSelectedFileName(field.id) }}</span>
          </label>
        </div>
        <div *ngIf="field.type === 'radio'">
          <ng-container *ngIf="field.name">
            <label [for]="field.name"
              >{{ field.name }}
              <span class="required-star" *ngIf="field.validators.required">
                *</span
              >
            </label>
            <br />
          </ng-container>
          <div class="radio-inline">
            <div *ngFor="let option of field.options; let i = index">
              <input
                [type]="field.type"
                [value]="option"
                [checked]="formGroup.get(field.id)?.value === option"
                [name]="field.id"
                [formControlName]="field.id"
                (click)="onRadioValueChange(field.id, option)" />
              <label [for]="option">{{ option }}</label>
            </div>
          </div>
        </div>
        <div *ngIf="field.type === 'select'" class="selectDropdowm">
          <ng-container *ngIf="field.name">
            <label [for]="field.name"
              >{{ field.name }}
              <span class="required-star" *ngIf="field.validators.required">
                *</span
              >
            </label>
            <!-- <br /> -->
          </ng-container>
          <select
            [name]="field.id"
            [formControlName]="field.id"
            (change)="onSelectValueChange(field.id, $event)">
            <option *ngFor="let opt of field.options" [value]="opt">
              {{ opt }}
            </option>
          </select>
        </div>
        <div *ngIf="field.type === 'textarea'">
          <ng-container *ngIf="field.name">
            <label [for]="field.name"
              >{{ field.name }}
              <span class="required-star" *ngIf="field.validators.required">
                *</span
              >
            </label>
            <br />
          </ng-container>
          <textarea
            [name]="field.id"
            [formControlName]="field.id"
            rows="3"></textarea>
        </div>
        <div *ngIf="field.type === 'checkbox'" style="margin-top: 7px">
          <input
            type="checkbox"
            style="vertical-align: middle; margin-right: 5px"
            [checked]="formControlNode(field.id)?.value"
            (change)="onFieldValueChange($event, field)"
            [name]="field.id"
            [formControlName]="field.id" />
          <ng-container *ngIf="field.name">
            <label [for]="field.name"
              >{{ field.name }}
              <span class="required-star" *ngIf="field.validators.required">
                *</span
              >
            </label>
          </ng-container>
        </div>
        <ng-container *ngIf="field.type === 'multiselect'">
          <mat-form-field appearance="outline">
            <mat-select [formControlName]="field.id" multiple>
              <mat-option
                *ngFor="let option of field.options"
                [value]="option"
                >{{ option }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="field.type === 'multitext'">
          <ng-container
            *ngFor="let text of multitextFields[field.id]; let i = index">
            <div class="flex">
              <input
                type="text"
                [name]="field.name"
                [value]="this.multitextFields[field.id][i]"
                (change)="onMultiTextValueChange($event, field.id, i)" />
              <button
                class="multitext btn"
                *ngIf="i > 0"
                (click)="removeMultiTextValue(field.id, i)">
                -
              </button>
              <button
                class="multitext btn"
                *ngIf="i === 0"
                (click)="addMultiTextValue(field.id)">
                +
              </button>
            </div>
          </ng-container>
          <button
            class="multitext plus-btn"
            (click)="addMultiTextValue(field.id)">
            +
          </button>
        </ng-container>
        <div *ngIf="field.type === 'switch'" class="confirmfields">
          <div class="toggleTypeSwitch">
            <label class="toggle small">
              <input
                type="checkbox"
                style="vertical-align: middle; margin-right: 5px"
                [checked]="formControlNode(field.id)?.value"
                (change)="onFieldValueChange($event, field)"
                [name]="field.id"
                [formControlName]="field.id"
                class="active-checkbox" />
              <div>Status</div>
            </label>
            <ng-container *ngIf="field.name">
              <label [for]="field.name" class="toggle-label"
                >{{ field.name }}
                <span class="required-star" *ngIf="field.validators.required">
                  *</span
                >
              </label>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="
            formControlNode(field.id)?.touched &&
            formControlNode(field.id)?.errors
          "
          style="
            color: red;
            font-size: 11px;
            font-family: sans-serif;
            margin-top: 3px;
          ">
          <div *ngIf="formControlNode(field.id)?.errors?.['required']">
            {{ field.name }} is required
          </div>
          <div *ngIf="formControlNode(field.id)?.errors?.['minlength']">
            Min length should be
            {{
              formControlNode(field.id)?.errors?.["minlength"]["requiredLength"]
            }}
          </div>
          <div *ngIf="formControlNode(field.id)?.errors?.['maxlength']">
            Max length should be
            {{
              formControlNode(field.id)?.errors?.["maxlength"]["requiredLength"]
            }}
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

<!-- <div>

</div> -->
<div class="custom-height-box" [ngClass]="getDynamicClass()">
  <div class="box" (scroll)="onScroll($event)" id="managePageScroll">
    <table class="data-table" #dataTable>
      <thead *ngIf="columns && columns?.length !== 0 && !isloading">
        <tr>
          <th class="sticky-header" style="width: 0px">
            <!-- <ng-container>
                        <div class="my-checkbox">
                            <input type="checkbox" class="globalCheckbox" (change)="selectAll($event)" />
                            <label for="my-checkbox" class="my-checkbox__label"></label>
                        </div>
                    </ng-container> -->
          </th>
          <th
            *ngFor="let c of columns; let i = index"
            (click)="sortTableData(c.id, c.type)"
            #headerColumn
            style="width: 50px"
            (mouseenter)="hoveredColumnIndex = i"
            (mouseleave)="hoveredColumnIndex = null"
            class="sticky-header">
            <span> {{ c?.headerText }}</span>
            <ng-container
              *ngIf="
                sortField === c.id &&
                sortDirection === 'desc' &&
                hoveredColumnIndex === i
              ">
              <i class="flip-icons flip-sort-asc"></i>
            </ng-container>
            <ng-container
              *ngIf="
                sortField === c.id &&
                sortDirection === 'asc' &&
                hoveredColumnIndex === i
              ">
              <i class="flip-icons flip-sort-desc"></i>
            </ng-container>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!isloading || isMonitorPageload">
        <tr
          *ngFor="
            let r of currentsection !== 'monitor'
              ? (filteredItems
                | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: currentPageNumber,
                      totalItems: filteredItems?.length
                    })
              : filteredItems;
            let j = index
          ">
          <ng-container *ngIf="tableOptions.checkbox">
            <td
              class="checkbox-td"
              [ngStyle]="{
                'text-align': tableOptions.allCheck ? 'center' : ''
              }">
              <div class="my-checkbox">
                <input
                  class="globalCheckbox"
                  type="checkbox"
                  [name]="r.fileName"
                  [(ngModel)]="r.checked"
                  (change)="rowItemClicked(r, r.checked)" />
                <label for=" my-checkbox" class="my-checkbox__label"></label>
              </div>
            </td>
          </ng-container>
          <td *ngFor="let c of columns; let i = index" (click)="openOverlay(r)">
            <div class="tag-flex">
              <div>
                <ng-container
                  *ngIf="isValueArray(r?.[c.id] || r['attributes']?.[c['id']])">
                  <ng-container
                    *ngIf="
                      c?.id === 'tags' || c?.id === 'userTags';
                      else noEllipsis
                    ">
                    <ng-container *ngIf="currentsection === 'datapipelines'">
                      <div class="row-data tags-pipeline">
                        <ng-container
                          *ngIf="r['attributes']?.[c['id']]?.length <= 3">
                          <span matTooltip="{{ r['attributes']?.[c['id']] }}">
                            {{ r["attributes"]?.[c["id"]] }}
                          </span>
                        </ng-container>
                        <ng-container
                          *ngIf="r['attributes']?.[c['id']]?.length > 3">
                          <span matTooltip="{{ r['attributes']?.[c['id']] }}">
                            {{
                              r["attributes"]?.[c["id"]]
                                ?.slice(0, 3)
                                .join(",") + "..."
                            }}
                          </span>
                        </ng-container>
                        <!-- <ng-container *ngIf="
							  r['attributes']?.[c['id']] === '' ||
							  !r['attributes']?.[c['id']]
							">
												  -
											  </ng-container> -->
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="currentsection !== 'datapipelines' && r?.[c.id]">
                      <div class="row-data">
                        <ng-container *ngIf="r?.[c.id]?.length < 3">
                          <span matTooltip="{{ r?.[c.id] }}">
                            {{ r?.[c["id"]] }}
                          </span>
                        </ng-container>
                        <ng-container *ngIf="r?.[c.id]?.length >= 3">
                          <span matTooltip="{{ r?.[c.id] }}">
                            {{ r?.[c["id"]]?.slice(0, 3).join(",") }}...
                          </span>
                        </ng-container>
                        <!-- <ng-container *ngIf="r?.[c.id] === ''"> - </ng-container> -->
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #noEllipsis>
                    <ng-container
                      *ngIf="isHtmlContent(r?.[c.id]); else textContent">
                      <div class="icon-cell" [innerHTML]="r?.[c.id]"></div>
                    </ng-container>
                    <ng-template #textContent>
                      <div class="row-data" matTooltip="{{ r?.[c.id] }}">
                        {{ r?.[c["id"]]?.join(",") }}
                      </div>
                    </ng-template>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="!isValueArray(r?.[c.id])">
                  <ng-container *ngIf="isHtmlContent(r?.[c.id])">
                    <!-- Render HTML content using innerHTML -->
                    <div class="icon-cell" [innerHTML]="r?.[c.id]"></div>
                  </ng-container>
                  <ng-container *ngIf="!isHtmlContent(r?.[c.id])">
                    <!-- Handle non-HTML content -->
                    <div
                      class="row-data"
                      [ngClass]="getColor(r?.[c['id']], c?.['id'])">
                      <ng-container *ngIf="isDateFormat(r?.[c['id']])">
                        <ng-container *ngIf="c?.headerText !== 'Details'">
                          <div
                            *ngIf="r?.[c.id]"
                            class="dateColumn"
                            matTooltip="{{
                              isValidDate(r?.[c.id])
                                ? (r?.[c.id] | date)
                                : r?.[c.id]
                            }}">
                            <!-- Display image if the value matches the specified date format -->
                            <img src="assets/images/settings/date.svg" />
                            {{
                              r?.[c["id"]]?.length > 30
                                ? r?.[c["id"]]?.substring(0, 30) + "..."
                                : isValidDate(r?.[c["id"]])
                                  ? (r?.[c.id] | date)
                                  : r?.[c.id]
                            }}
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            currentsection === 'connections' &&
                            c.headerText === 'Details'
                          ">
                          <div
                            class="dateColumn"
                            (click)="
                              redirectThroughCustomTableButtons(
                                $event,
                                'edit',
                                r
                              )
                            ">
                            <!-- <i class="ti ti-menu"></i> -->
                            <svg
                              width="14"
                              height="6"
                              viewBox="0 0 14 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 5C0 4.44772 0.447715 4 1 4H13C13.5523 4 14 4.44772 14 5C14 5.55228 13.5523 6 13 6H1C0.447715 6 0 5.55228 0 5Z"
                                fill="#4A5264" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1C14 1.55228 13.5523 2 13 2H1C0.447715 2 0 1.55228 0 1Z"
                                fill="#4A5264" />
                            </svg>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!isDateFormat(r?.[c['id']])">
                        <ng-container *ngIf="c?.id !== 'scheduleConfig'">
                          <div
                            matTooltip="{{ r?.[c.id] }}"
                            [ngClass]="{
                              underlineLink:
                                c?.headerText === 'File Name' ||
                                c?.id === 'importName'
                            }"
                            (click)="redirect($event, r)">
                            <!-- Display regular content if the value doesn't match the specified date format -->
                            <ng-container
                              *ngIf="
                                c.id !== 'scheduleConfig' &&
                                c.id !== 'roles' &&
                                c.id !== 'verified' &&
                                c.id !== 'expired' &&
                                c.id !== 'userDepartment'
                              ">
                              <ng-container *ngIf="c.id !== 'tags'">
                                <ng-container *ngIf="c.id !== 'activeYn'">
                                  <ng-container *ngIf="c.type !== 'UserInfo'">
                                    <span matTooltip="{{ r?.[c.id] }}">
                                      {{
                                        r[c["id"]]?.length > 30
                                          ? r[c["id"]]?.substring(0, 30) + "..."
                                          : r[c["id"]]
                                      }}
                                    </span>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="c.id === 'activeYn'">
                              <ng-container *ngIf="r['activeYn'] === 'N'">
                                <div class="status" matTooltip="INACTIVE">
                                  INACTIVE
                                </div>
                              </ng-container>
                              <ng-container *ngIf="r?.['activeYn'] === 'Y'">
                                <div class="status" matTooltip="ACTIVE">
                                  ACTIVE
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                          <ng-container
                            *ngIf="
                              c.id === 'createdBy' && c.type === 'UserInfo'
                            ">
                            <div
                              class="createdInfo"
                              matTooltip="{{ r['firstName'] }} {{
                                r['lastName']
                              }}">
                              <div class="user-icon table">
                                {{
                                  currentsection === "monitor"
                                    ? getMonitorProfileName(r, "icon")
                                    : getProfileName(r) || "--"
                                }}
                              </div>
                              {{
                                currentsection === "monitor"
                                  ? getMonitorProfileName(r, "firstName")
                                  : r["firstName"] || "-"
                              }}
                              {{
                                currentsection === "monitor"
                                  ? getMonitorProfileName(r, "lastName")
                                  : r["lastName"] || "-"
                              }}
                            </div></ng-container
                          >
                        </ng-container>
                        <ng-container
                          *ngIf="
                            currentsection === 'manageUsers' &&
                            (c.id === 'roles' || c.id === 'userDepartment')
                          ">
                          <ng-container *ngIf="c.id !== 'tags'">
                            <ng-container>
                              <div class="userRole" *ngIf="r?.[c.id]">
                                <span matTooltip="{{ r?.[c.id] }}">
                                  {{
                                    r[c["id"]]?.length > 30
                                      ? r[c["id"]]?.substring(0, 30) + "..."
                                      : r[c["id"]]
                                  }}</span
                                >
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            currentsection === 'manageUsers' &&
                            c.id === 'verified'
                          ">
                          <div
                            class="verification"
                            [ngClass]="{
                              inActiveUser: r['verified'] === false
                            }">
                            {{ r["verified"] ? "Verifed" : "Not Verified" }}
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            currentsection === 'schedules' && c.id === 'expired'
                          ">
                          <div
                            class="verification"
                            [ngClass]="{
                              inActiveUser: r['expired']
                            }">
                            {{ r["expired"] ? "Expired" : "Active" }}
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            currentsection === 'chase' &&
                            c.id === 'scheduleConfig'
                          ">
                          <div
                            class="dateColumn"
                            (click)="
                              redirectThroughCustomTableButtons(
                                $event,
                                'chase',
                                r
                              )
                            ">
                            <!-- Display image if the value matches the specified date format -->
                            <img
                              src="assets/images/settings/date.svg" />Add/Edit
                            Schedule
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            c.id === 'tags' &&
                            currentsection === 'master-tables'
                          ">
                          <span matTooltip="{{ r?.[c.id] }}">{{
                            r[c["id"]]?.length > 30
                              ? r[c["id"]]?.substring(0, 30) + "..."
                              : r[c["id"]]
                          }}</span>
                        </ng-container>
                        <!-- <ng-container *ngIf="
							  (r?.[c.id] === '' || !r?.[c.id]) && c?.id !== 'tags'
							"><span>-</span></ng-container> -->
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="isEmptyRow(r, c)">
                  <div class="row-data">
                    <span>-</span>
                  </div>
                </ng-container>
              </div>
              <ng-container
                *ngIf="
                  currentsection === 'connections' &&
                  r?.['phiEnabled'] &&
                  c?.headerText === 'Type'
                ">
                <div class="phi-indicator">
                  <label for="" class="fileData">Analytics</label>
                </div>
              </ng-container>
              <ng-container
                *ngIf="currentsection === 'manageUsers' && c.id === 'verified'">
                <div
                  class="sentVerification"
                  (click)="handleVerification($event, r)"
                  *ngIf="r['verified'] === false">
                  Send verification
                </div>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="isloading || isMonitorPageload">
        <tr *ngFor="let r of skeletonRowNum">
          <th style="width: 0.5%">
            <div
              class="skeleton table-checkbox"
              *ngIf="isloading || isMonitorPageload"></div>
          </th>
          <th *ngFor="let c of skeletonColNum">
            <div
              class="skeleton table-data"
              *ngIf="isloading || isMonitorPageload"></div>
          </th>
        </tr>
      </tbody>
    </table>
    <div *ngIf="filteredItems?.length === 0 && !isloading" class="EmptyData">
      Empty
    </div>
  </div>
  <div
    class="pagination-div"
    *ngIf="filteredItems?.length !== 0 && currentsection !== 'monitor'"
    [ngClass]="{ manageUSers: currentsection === 'manageUsers' }">
    <!-- <div class="left-pagination">
            <div *ngIf="itemsPerPage >= filteredItems.length"> Showing {{currentPageNumber}} of
                {{filteredItems?.length}}
                of {{ filteredItems?.length }} entries</div>
            <div *ngIf="itemsPerPage < filteredItems.length"> Showing {{currentPageNumber}} of {{itemsPerPage}} of {{
                filteredItems?.length }} entries</div>
        </div>
		"Displaying 1–12 of 15 results."
	-->
    <div class="left-pagination">
      <div *ngIf="itemsPerPage >= filteredItems?.length">
        Displaying
        <span class="pagination-number-value"
          >{{ currentPageNumber + "-" }}{{ filteredItems?.length }}</span
        >
        of
        <span class="pagination-number-value">{{ filteredItems?.length }}</span>
        results.
      </div>
      <div *ngIf="itemsPerPage < filteredItems?.length">
        Displaying
        <span class="pagination-number-value"
          >{{ currentPageNumber + "-" }} {{ getPaginationText() }}</span
        >
        of
        <span class="pagination-number-value">{{ filteredItems?.length }}</span>
        results.
      </div>
    </div>
    <div class="right-pagination">
      <pagination-controls
        (pageChange)="currentPageNumber = $event"
        previousLabel="<"
        nextLabel=">"></pagination-controls>
    </div>
  </div>
</div>

<!-- <ng-container *ngIf="c.id === 'tags'">
                                        <div class="icon-cell">
                                            <div [innerHTML]="getTagsColumnContent(r)"></div>
                                        </div>
                                    </ng-container> -->

<!--  <tr *ngFor="let r of sortedTableData;let j=index">
                    <td *ngIf="tableOptions.checkbox">
                        <input type="checkbox" [name]="r.fileName" [(ngModel)]="r.checked" (change)="rowItemClicked(j)">
                    </td>
                    <td *ngFor="let c of columns;let i=index" style="padding: 5px;">
                         {{r[c.id].length>20?r[c.id].substring(0,10)+'....':r[c.id]}}
                        {{ getArrayData(c,r)}}
                        {{r[c.id]}}
                    </td>
                </tr>


            <tr *ngFor="let r of sortedTableData; let j = index">
  <td *ngIf="tableOptions.checkbox">
    <input type="checkbox" [name]="r.fileName" [(ngModel)]="r.checked" (change)="rowItemClicked(j)">
  </td>
  <td *ngFor="let c of columns; let i = index" style="padding: 5px;">
    <ng-container *ngIf="isValueArray(r[c.id])">
      <ng-container *ngIf="r[c.id].length > 3; else noEllipsis">
        {{ r[c.id].slice(0, 3).join(',') }}...
      </ng-container>
      <ng-template #noEllipsis>
        {{ r[c.id].join(',') }}
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!isValueArray(r[c.id])">
      {{ r[c.id] }}
    </ng-container>
  </td>
</tr>





            -->

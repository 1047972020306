import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
export interface ConfirmationDialogData {
  message: string;
  callback?: Function;
  headerText?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  manageCallback?: Function;
  [key: string]: any;
}
@Injectable({
  providedIn: "root",
})
export class ConfirmPopupService {
  public confirmMessageEvent: BehaviorSubject<ConfirmationDialogData> =
    new BehaviorSubject<ConfirmationDialogData>({ message: "" });
  public confirmClickedEvent: BehaviorSubject<ConfirmationDialogData> =
    new BehaviorSubject<ConfirmationDialogData>({ message: "" });

  constructor() {}
  getconfirmMessage(): Observable<ConfirmationDialogData> {
    return this.confirmMessageEvent.asObservable();
  }

  getConfirmClicked(): Observable<ConfirmationDialogData> {
    return this.confirmClickedEvent.asObservable();
  }
}

<ng-container *ngIf="messageObject['message'] && closeMessage">
  <div class="confirm-popup">
    <div class="main">
      <div class="modal-header">
        <strong>{{ headerText === "" ? "Confirmation" : headerText }}</strong>
        <div class="tooltip-common">
          <img
            src="/assets/Svgs_/Dashboard/CrossForModel.svg"
            (click)="closePopup()" />
          <span class="tooltip-utils center">Close</span>
        </div>
      </div>
      <div class="modal-body confirmation-body">
        <div class="search-content">
          <app-dropdown-trigger
            [dropdownOptions]="getDropdownOptionsForUsersList()"
            customClass="shareduserlist-dropdown"
            triggerId="userSelectList"
            (emitSelectedValue)="changeUser($event)"></app-dropdown-trigger>
          <div class="sortBy">
            <app-dropdown-trigger
              [dropdownOptions]="getDropdownOptions()"
              triggerId="readAccessList"
              customClass="sharedTypedropdown"
              (emitSelectedValue)="
                changePermission($event)
              "></app-dropdown-trigger>
          </div>
        </div>
        <div class="peopleWithAccess">
          <p class="people">People with access</p>
          <div class="users">
            <div class="user">
              <div class="userDetails">
                <div
                  class="user-icon"
                  style="cursor: auto; background-color: #f36f21">
                  {{ getProfileName(owner) }}
                </div>
                {{ owner.firstName + " " + owner.lastName }}
              </div>
              <div class="permission">{{ owner.permission }}</div>
            </div>
            <div
              class="user"
              *ngFor="let user of usersWithAccess; let i = index">
              <div class="userDetails">
                <div
                  class="user-icon"
                  style="cursor: auto; background-color: #f36f21">
                  {{ getProfileName(user) }}
                </div>
                {{ user.firstName + " " + user.lastName }}
              </div>
              <div class="permission">
                <app-dropdown-trigger
                  [dropdownOptions]="getDropdownOptionsForAddedUsers(user)"
                  customClass="accessPeopleDropdown"
                  [triggerId]="'peopleccess' + i"
                  (emitSelectedValue)="
                    changeExistingPermission($event, i)
                  "></app-dropdown-trigger>

                <img
                  src="assets/Svgs_/Dashboard/Delete.svg"
                  (click)="deleteSharedAccess(i)"
                  class="deleteAccess" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer confirmation">
        <!-- <button
			class="confirmation-outlined-btn"
			type="button"
			(click)="closePopup()">
			Cancel
		  </button> -->
        <button
          class="confirmation-primary-btn"
          type="button"
          (click)="confirmPopup()">
          {{ buttonText || "Confirm" }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<div
  class="sub-nav"
  [ngClass]="{
    'attributes-sub-nav': currentStep === 'attributes',
    'mappings-sub-nav': currentStep === 'mappings'
  }">
  <div>
    <!-- <div class="tooltip">
      <i class="far fa-arrow-alt-circle-left icon-style" style="padding: 10px 10px 10px 34px;" (click)="navigateTo('pipelines')"></i>
      <span id="back" class="backTooltipText">Back</span>
    </div> -->
    <div class="backButtonGroup">
      <div class="backIcon" (click)="backToPipelines()">
        <img src="assets/Svgs_/Monitor/Viewer/BackArrow.svg" />
      </div>
      <!-- <span>Back</span> -->
    </div>
    <!-- <div class="backIcon"><img src="assets/Svgs_/Monitor/Viewer/BackArrow.svg" (click)="navigateTo('pipelines')"/></div>
    <span>Back</span>
</div> -->
  </div>
  <ul class="tabs">
    <li
      *ngFor="let subNavTab of subNavTabs"
      [ngClass]="{ active: currentStep === subNavTab.value }"
      (click)="selectTab(subNavTab.value)">
      <ng-container *ngIf="subNavTab.name === 'Overview'">
        <ng-container *ngIf="currentStep !== subNavTab.value">
          <img src="assets/Svgs_/TransformationStudio/Overview.svg" />
        </ng-container>
        <ng-container *ngIf="currentStep === subNavTab.value">
          <img src="assets/Svgs_/TransformationStudio/ActiveOverview.svg" />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="subNavTab.name === 'Mappings'">
        <ng-container *ngIf="currentStep === subNavTab.value">
          <img src="assets/Svgs_/TransformationStudio/ActiveMappings.svg" />
        </ng-container>
        <ng-container *ngIf="currentStep !== subNavTab.value">
          <img src="assets/Svgs_/TransformationStudio/Mappings.svg" />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="subNavTab.name === 'Read Rules'">
        <ng-container *ngIf="currentStep === subNavTab.value">
          <img src="assets/Svgs_/TransformationStudio/ReadRulesActive.svg" />
        </ng-container>
        <ng-container *ngIf="currentStep !== subNavTab.value">
          <img src="assets/Svgs_/TransformationStudio/ReadRules.svg" />
        </ng-container>
      </ng-container>
      {{ subNavTab.name }}
    </li>
  </ul>

  <div class="actions-wrapper">
    <div
      *ngIf="currentStep === 'attributes'"
      class="active-btn"
      [ngClass]="{ migrationActive: migrationImportId }">
      <div *ngIf="(attributes && params['importId']) || migrationImportId">
        <div style="font-size: 14px; color: #70798c">Active</div>
        <div class="tooltip-common">
          <label class="toggle small">
            <input
              [(ngModel)]="activeStatus"
              (click)="sendConfirmMessage($event)"
              class="active-checkbox"
              [checked]="activeStatus"
              type="checkbox"
              [value]="activeStatus"
              [disabled]="!access" />
            <div>Status</div>
          </label>
          <div class="tooltip-utils right" *ngIf="!access">
            You don't have enough permissions
          </div>
        </div>
      </div>
      <div
        class="tooltip-common"
        *ngIf="currentStep === 'attributes' && !migrationType">
        <button
          class="button-common primary-btn"
          (click)="updateAllAttributes()"
          [disabled]="updateAttributesDisabled.value || !access"
          [title]="!access ? 'You have read only access' : 'Save'">
          Save
        </button>
        <div class="tooltip-utils right" *ngIf="!access">
          You don't have enough permissions
        </div>
      </div>
    </div>
    <div
      class="migrationActions"
      *ngIf="currentStep === 'attributes' && migrationType">
      <button
        class="button-common primary-btn"
        (click)="submitMigration()"
        [disabled]="disableSubmitMigration()"
        *ngIf="currentStep === 'attributes' && migrationType">
        Submit
      </button>
      <button
        class="button-common primary-btn"
        (click)="migrationImportId ? updateMigration() : createMigration()"
        [disabled]="handleDisableSaveMigration()"
        *ngIf="currentStep === 'attributes' && this.migrationType">
        Save
      </button>
    </div>
    <ng-container *ngIf="currentStep === 'mappings'">
      <!-- <img
        class="mt-3"
        src="assets/images/Button Update-blue.svg"
        (click)="updateRequest('update')"
      />
      <img
        class="mt-3"
        src="assets/images/Button Erase-blue.svg"
        (click)="updateRequest('clear')"
      /> -->
      <button
        class="button-common addButton ai-button"
        (click)="updateRequest('mappingWithAi')"
        [disabled]="isAutoMappingDisabled()">
        <i class="ti ti-sparkles"></i>
        Auto Map With AI
      </button>
      <button
        class="button-common transparent-button secondary-btn"
        (click)="updateRequest('clear')"
        [disabled]="disableClear()"
        [title]="!access ? 'You have read only access' : 'Clear'">
        Clear
      </button>
      <button
        class="button-common primary-btn"
        (click)="updateRequest('update')"
        [disabled]="disableClear()"
        [title]="!access ? 'You have read only access' : 'Save'">
        Save
      </button>
    </ng-container>
    <div class="btns-wrapper">
      <ng-container *ngIf="currentStep === 'readingRules'">
        <!-- <button
          class="hbtn small green"
          (click)="updateReadingRulesRequest('update')"
        >
          Update
        </button> -->
        <!-- <img
          class="mt-3"
          src="assets/images/Button Update-blue.svg"
          (click)="updateReadingRulesRequest('update')"
        />
        <img
          class="mt-3"
          src="assets/images/Button Erase-blue.svg"
          (click)="updateReadingRulesRequest('clear')"
        /> -->
        <!-- <button
          class="hbtn small green"
          (click)="updateReadingRulesRequest('clear')"
        >
          Clear
        </button> -->
      </ng-container>
      <!-- <button class="hbtn small green" (click)="navigateTo('pipelines')">
        Back
      </button> -->
    </div>
  </div>
</div>

<div
  *ngIf="currentStep === 'attributes' && attributes && !this.migrationType"
  style="margin-left: 16px"
  class="flex box transparent column">
  <app-attributes
    #attributesComp
    *ngIf="attributes"
    [importId]="params['importId']"
    [lobs]="lobs"
    [toggleDisableForUpdate]="updateAttributesDisabled"
    [attributes]="attributes"
    (isUpdated)="getupdateStatus($event)"
    (updateAttributes)="updateAttributes($event)"
    [isMapSaved]="isMapSaved"
    [updateListener]="updateEmitter"
    [access]="access"
    (apiSuccess)="onApiSuccess($event)"></app-attributes>
</div>

<div
  *ngIf="currentStep === 'attributes' && this.migrationType"
  style="margin-left: 16px"
  class="flex box transparent column">
  <app-migration-attributes
    [migrationDetails]="migrationType"
    (migrationSourceFileUpload)="migrationSourceFiles($event)"
    (emitFormValues)="migrationValues($event)"
    (emitTargetFormValues)="targetMigrationValues($event)"
    [migrationattributes]="migrationAttributes"
    (formUpdated)="onFormUpdate($event)">
  </app-migration-attributes>
</div>

<div *ngIf="currentStep === 'mappings'" style="height: 100%" class="flex">
  <app-mappings
    *ngIf="mappings"
    (isUpdated)="getupdateStatus($event)"
    [importId]="params['importId']"
    [mappings]="mappings"
    [assetsUrl]="assetsUrl"
    (disableClearButton)="disableClearButton($event)"
    (disableAutoMappingButton)="disableAutoMappingButton($event)"
    [access]="access"
    (mappingsApiSuccess)="onApiSuccess($event)"
    (isUpdateMappings)="updateMappings($event)">
  </app-mappings>
</div>

<div
  *ngIf="currentStep === 'readingRules'"
  style="height: 100%; padding: 10px 40px"
  class="flex">
  <!-- <app-reading-rules
    *ngIf="mappings"
    (isUpdated)="getupdateStatus($event)"
    [importId]="params['importId']"
    [mappings]="readRules"></app-reading-rules> -->
</div>

<div class="loading-view" *ngIf="!attributes && !this.migrationType">
  <div class="skeleton-overview">
    <div class="overview-skeleton-tab">
      <div class="skeleton skeleton-header"></div>
      <div class="skeleton-body">
        <div class="skeleton-flex-item">
          <div class="skeleton-items" *ngFor="let item of noOfOverviewSkeleton">
            <label class="skeleton skeleton-label"></label>
            <label class="skeleton skeleton-input"></label>
          </div>
        </div>
        <div class="skeleton-flex-item">
          <div class="skeleton-items">
            <label class="skeleton skeleton-label"></label>
            <label class="skeleton skeleton-input"></label>
          </div>
        </div>
      </div>
    </div>
    <div class="overview-skeleton-tab height">
      <div class="skeleton skeleton-header"></div>
      <div class="skeleton-body">
        <div class="skeleton-flex-item">
          <div class="skeleton-items">
            <label class="skeleton skeleton-label"></label>
            <label class="skeleton skeleton-input"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="currentStep === 'datExtention'"
  style="height: 100%; padding: 10px 40px"
  class="flex">
  <!-- <app-dat-extension
    *ngIf="mappings"
    [importId]="params['importId']"
    [mappings]="datDetails"
    (isUpdated)="getupdateStatus($event)"></app-dat-extension> -->
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numToAlpha'
})
export class NumToAlphaPipe implements PipeTransform {

  transform(num: number) {
      let s = '', t;

      while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = (num - t)/26 | 0;
      }
      return s || undefined;
    }
}

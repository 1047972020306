<div
  *ngFor="let group of groups; let groupIndex = index"
  class="group-container">
  <div
    class="condition"
    *ngIf="groups.length > 1 && groupIndex > 0"
    style="margin-bottom: 8px">
    <select class="groupSelect" [(ngModel)]="group.operator">
      <option *ngFor="let operator of conditionOperators" [value]="operator">
        {{ operator }}
      </option>
    </select>
  </div>
  <div class="query-builder">
    <div class="group-conditions">
      <div class="vertical-line"></div>
      <div
        *ngFor="let condition of group.conditions; let conditionIndex = index"
        [ngClass]="{ 'condition-wrapper': group.conditions.length > 1 }">
        <div class="conditionDiv">
          <div
            class="condition"
            *ngIf="group.conditions.length > 1 && conditionIndex > 0">
            <select [(ngModel)]="condition.operator">
              <option
                *ngFor="let operator of conditionOperators"
                [value]="operator">
                {{ operator }}
              </option>
            </select>
          </div>
          <!-- <app-custom-select
            [searchable]="true"
            [data]="customRegexOptions"
            [inputValue]="condition.customRegex"
            [placeholder]="'Custom Regex'"
            [type]="'none'"
            [isEmptyValues]="isEmptyValues"
            (valueChange)="checkCustomRegex($event, groupIndex, conditionIndex)"
            [nestedData]="true"></app-custom-select> -->
          <app-dropdown-trigger
            [dropdownOptions]="
              customRegexInputValue(groupIndex, conditionIndex, condition)
            "
            (emitSelectedValue)="
              checkCustomRegex($event, groupIndex, conditionIndex)
            "
            [triggerId]="'regex' + groupIndex + conditionIndex"
            customClass="custom-dropdown-trigger"></app-dropdown-trigger>

          <input
            type="text"
            #sourceMapping="ngModel"
            required
            (blur)="updateQueryValues()"
            [(ngModel)]="condition.sourceMapping"
            [ngClass]="{
              'error-border':
                (sourceMapping.touched || isEmptyValues) &&
                isInvalid(condition.sourceMapping)
            }"
            placeholder="Source Mapping" />
          <i
            class="ti ti-x"
            (click)="deleteCondition(groupIndex, conditionIndex)"
            [ngClass]="{
              defaultSheetIcon:
                group.conditions.length === 1 && groups.length === 1
            }"></i>
        </div>
      </div>
    </div>
    <div class="buttonDiv">
      <div></div>
      <button (click)="addCondition(groupIndex)">Add Condition</button>
      <i
        class="ti ti-trash"
        (click)="deleteGroup(groupIndex)"
        [ngClass]="{ defaultSheetIcon: groups.length === 1 }"></i>
    </div>
  </div>
</div>
<div class="buttonDiv">
  <div></div>
  <button (click)="addGroup()">Add Group</button>
  <div></div>
</div>

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/core/services/app.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { AuthPageService } from "../../services/auth-page.service";

@Component({
  selector: "app-registration-verification",
  templateUrl: "./registration-verification.component.html",
  styleUrls: ["./registration-verification.component.scss"],
})
export class RegistrationVerificationComponent implements OnInit {
  public errorText = "";
  public successText = "";
  public username = "";
  public url: any;
  public loading = false;
  public registerSuccessfull: boolean = false;
  public registerNotSuccessfull: boolean = false;
  public isNotRegistered: boolean = true;
  public registerId: string = "";
  public isMessage: string = "";
  constructor(
    private appService: AppService,
    public router: Router,
    private loaderservice: LoaderService,
    private authPageService: AuthPageService
  ) {}
  ngOnInit(): void {
    this.loaderservice.loader.next(true);
    let params = this.router.parseUrl(this.router.url).queryParams;
    if (params.register_id) {
      let id = params.register_id;
      this.authPageService.validateRegistration({ register_id: id }).subscribe({
        next: (res: any) => {
          if (res) {
            this.registerSuccessfull = true;
            this.registerNotSuccessfull = false;
            this.isMessage = res;
          }
        },
        error: (err) => {
          this.errorText = err;
          this.registerNotSuccessfull = true;
          this.registerSuccessfull = false;
          this.isMessage = err;
        },
      });
    } else {
      this.registerNotSuccessfull = true;
      this.registerSuccessfull = false;
      this.isMessage = "";
    }
    this.loaderservice.loader.next(false);
  }

  loginPage() {
    this.router.navigate(["login"]);
  }
  registerPage() {
    this.router.navigate(["register"]);
  }
}

export interface NewMappingElement {}

export interface MappingElement extends NewMappingElement {
  elementId: number;
  elementName: string;
  elementLabel: string;
  datatype: string;
  required: string | null;
  isNewField?: boolean;
  function: string | null;
  functionParameters: string | null;
  isEditing?: boolean;
  elementLevel: string | null;
  elementAttributes: string | null;
  dataLength: number | null;
  description: string | null;
  createdBy: string | null;
  createdDate: string | null;
  modifiedBy: string | null;
  modifiedDate: string | null;
  sourceFileColumnNumber: string | null;
  defaultValue: string | null;
  validationExpression: string | null;
  orderNumber: number | null;
  startPosition: number | null;
  endPosition: number | null;
}

export interface GetMappingElementsResponse {
  allMappingElements: MappingElement[];
  message: string;
}

export const sourceSupportedConnection: any = [
  "NET SUITE",
  "GOOGLE ADS",
  "DATABASE",
];
export const targetSupportedConnection: any = ["DATABASE"];

<header>
  <div
    [ngClass]="{
      'header-wrapper': !sideBarToggle || appService.isMobileView(),
      'header-wrapper-maximized': sideBarToggle && !appService.isMobileView()
    }"
    class="header-wrapper">
    <div class="header-title" [title]="headerTitleTooltip">
      <button
        class="menu-toggle"
        *ngIf="appService.isMobileView() && !appService.sideBarToggle"
        (click)="toggleSidebar()">
        <i class="flip-icons flip-menu"></i>
      </button>
      <!-- <div *ngIf="router.url.includes('data-pipelines/manage?')" class="teamchanger">
        <button class="team-selected" (click)="showFoldersDropDown = true" (blur)="closeDropDown()">
          {{ selectedDeptName }}
        </button> -->
      <!-- <div class="select" *ngIf="showFoldersDropDown">
          <div
            class="option"
            *ngFor="let dept of allFolders"
            (click)="selectedDept = dept['id']; deptSelected()"
            [ngClass]="{ active: dept['id'] == selectedDept }"
          >
            {{ dept["name"] }}
          </div>
        </div> -->
      <!-- <div class="slash"><i class="ti ti-slash"></i></div>
      </div> -->
      <div class="tooltip-common">
        <div class="sub-header">
          <div class="header-text" *ngIf="!isEditableHeader() || !isEditing">
            {{ headerTitle ? headerTitle : "FLIP" }}
          </div>
          <div
            class="editable-pipeline-header"
            *ngIf="pipelinePage && isEditableHeader() && isEditing">
            <div>{{ pipelinePath }}</div>
            <div
              class="header-text is-editing"
              #headerEditBox
              spellcheck="false"
              [ngClass]="{
                error: isHeaderError,
                editHeader: isEditableHeader()
              }"
              (keydown)="onHeaderEdit($event)"
              (blur)="confirmEditing()"
              [ngStyle]="{ direction: pipelinePage ? 'ltr' : 'rtl' }"
              [contentEditable]="isEditing">
              {{ pipelineName }}
            </div>
          </div>
        </div>
        <span
          class="tooltip-utils center"
          *ngIf="
            headerTitle &&
            headerTitle?.length > 33 &&
            isEditableHeader() &&
            !isEditing &&
            !appService.isMobileView()
          "
          >{{ headerTitle }}</span
        >
      </div>
      <ng-container *ngIf="isEditableHeader() && !isEditing">
        <img
          (click)="editHeaderTitle()"
          class="header-edit-icon"
          src="assets/Svgs_/MasterData/Edit.svg"
          alt="Edit Icon" />
      </ng-container>
      <!-- <ng-container *ngIf="isEditableHeader() && isEditing">
        <i class="ti ti-x action-btn" (click)="closeEditing()"></i>
        <i class="ti ti-check action-btn" (click)="confirmEditing()"></i>
      </ng-container> -->
    </div>
    <div class="userProfile" (click)="$event.stopPropagation()">
      <div
        *ngIf="!profile?.image"
        class="user-icon"
        (click)="toggleSubmenu($event)"
        [ngClass]="{ active: toggleUserProfile }"
        style="background-color: #f36f21">
        {{ getProfileName(profile) }}
      </div>
      <img
        *ngIf="profile?.image"
        class="user-icon"
        [ngClass]="{ active: toggleUserProfile }"
        width="40px"
        height="40px"
        (click)="toggleSubmenu($event)"
        [src]="profile?.image"
        alt="Profile Picture" />

      <div (click)="toggleSubmenu($event)">
        <i *ngIf="!toggleUserProfile" class="ti ti-chevron-down icon"></i>
        <i
          *ngIf="toggleUserProfile"
          class="ti ti-chevron-up icon activeToggle"></i>
      </div>
      <div *ngIf="toggleUserProfile" class="button-details">
        <div class="userDetails">
          <div
            *ngIf="!profile?.image"
            class="user-icon"
            (click)="toggleSubmenu($event)"
            style="cursor: auto; background-color: #f36f21">
            {{ getProfileName(profile) }}
          </div>
          <img
            *ngIf="profile?.image"
            class="user-icon"
            width="40px"
            height="40px"
            (click)="toggleSubmenu($event)"
            [src]="profile?.image"
            alt="Profile Picture"
            style="cursor: auto" />
          <div class="user">
            <p class="userName">
              {{ profile?.firstName ?? "---" }} {{ profile?.lastName ?? "---" }}
            </p>
            <p class="userMail">{{ profile?.username ?? "---" }}</p>
          </div>
        </div>
        <div>
          <div class="role-box">
            <p
              class="organization-name"
              *ngIf="profile?.organizationName"
              title="Organization Name">
              {{ profile?.organizationName ?? "---" }}
            </p>
            <p class="userRole" title="User Role">
              {{ profile?.roles[0]?.roleName ?? "---" }}
            </p>
          </div>
        </div>
        <div *ngFor="let sub of submenus">
          <div (click)="navigate(sub, 'subMenu')" class="userOptions">
            {{ sub.name }}
            <img
              *ngIf="sub.name === 'Logout'"
              src="assets/Svgs_/Dashboard/Logout.svg"
              alt="logout" />
          </div>
        </div>
        <!-- <ul class="sub-menu">
          <li
            style="cursor: pointer !important"
            *ngFor="let sub of submenus"
            class="sub-menu-item"
            [ngClass]="{ 'active-sub-menu': checkActiveSubmenu(sub.name) }"
            (click)="navigate(sub, 'subMenu')"
          >
            {{ sub.name }}
          </li>
        </ul> -->
      </div>
    </div>
    <!-- <div class="sections" *ngIf="headerTitle == 'Settings'">
      <button *ngIf="isTabVisible('chase')" [class]="{'active': settingsSection == 'chase'}" (click)="changeSection('chase')">Chase</button>
      <button *ngIf="isTabVisible('schedules')" [class]="{'active': settingsSection == 'schedules'}" (click)="changeSection('schedules')">Schedules</button>
      <button *ngIf="isTabVisible('connections')" [class]="{'active': settingsSection == 'connections'}" (click)="changeSection('connections')">Data Sources</button>
      <button *ngIf="isTabVisible('system')" [class]="{'active': settingsSection == 'system'}" (click)="changeSection('system')">System</button>
      <button *ngIf="isTabVisible('master-tables')" [class]="{'active': settingsSection == 'master-tables'}" (click)="changeSection('master-tables')">Master Data</button>
      <button *ngIf="isTabVisible('udf-config')" [class]="{'active': settingsSection == 'udf-config'}"
      (click)="changeSection('udf-config')">Config</button>
    </div> -->
    <!-- <ng-container *ngIf="headerTitle == 'Monitor'">
      <div class="header-title select-dept">
        <ng-select placeholder="Select Department" (change)="deptSelected()"
        [clearable]="false" [(ngModel)]="selectedDept" name="Department">
          <ng-option *ngFor="let dept of departmentList" [value]="dept.id">
            {{dept.name}}
          </ng-option>
        </ng-select>
      </div>
    </ng-container> -->
  </div>
  <!-- <div *ngIf="headerTitle === 'HOME'" class="header-logo">
    <img src="{{ assetsUrl }}/assets/images/Logo_transparent.png" />
  </div> -->
</header>

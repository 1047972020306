import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import {
  ConfirmationDialogData,
  ConfirmPopupService,
} from "./confirmpopup.service";
import { filter, takeUntil } from "rxjs/operators";
import { ImportDesignerService } from "src/app/features/pipelines/services/import-designer.service";
import { AppService } from "src/app/core/services/app.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import {
  sourceSupportedConnection,
  targetSupportedConnection,
} from "src/app/features/pipelines/models/mapping-element.model";

@Component({
  selector: "app-confirm-popup",
  templateUrl: "./confirm-popup.component.html",
  styleUrls: ["./confirm-popup.component.scss"],
})
export class ConfirmPopupComponent implements OnInit, OnDestroy {
  public subscription?: Subscription;
  public messageObject: ConfirmationDialogData = {
    message: "",
  };
  public confirmButtonText: string = "";
  public cancelButtonText: string = "";
  public headerText: string = "";
  public closeMessage: boolean = false;
  public isCopyDataPipelines: boolean = false;
  public isCopyImportName: string = "";
  public showError: boolean = false;
  public sheetList: any = {};
  public showDuplicateError: boolean = false;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  public callback?: (result: boolean) => void;
  public userFirstName: string = "";
  public userlastName: string = "";
  public userName: string = "";

  public fileUpload: boolean = false;
  public isDragging: boolean = false;
  public file: any;
  public fileType: any;
  public connectionType: any;
  public type: string = "";

  public createdConnection: any;
  public allConnectionTypes: any;
  public connectionForm: FormGroup = new FormGroup({});
  public connectionAdditionalForm: any;
  public selectedConnection: any;
  public routeChangeFlag: boolean = false;
  private routerSubscription?: Subscription;
  private queryParamSubscription?: Subscription;

  constructor(
    private ConfirmPopupService: ConfirmPopupService,
    public ImportDesignerService: ImportDesignerService,
    public appService: AppService,
    public connectionFormBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // Subscribe to router events
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // This will trigger whenever the route changes, including parameters
        this.closeMessage = false;
        this.messageObject = { message: "" };
      }
    });
    // Subscribe to query parameters changes
    this.queryParamSubscription = this.activatedRoute.queryParams.subscribe(
      (queryParams) => {
        if (queryParams) {
          // This will trigger whenever the route changes, including parameters
          this.closeMessage = false;
          this.messageObject = { message: "" };
        }
      }
    );
    this.subscription = this.ConfirmPopupService.getconfirmMessage()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: ConfirmationDialogData) => {
        if (res) {
          this.confirmHelperMethod(res);
        }
      });
  }
  private confirmHelperMethod(res) {
    if (res) {
      this.messageObject = { message: "" };
      this.messageObject = res;
      this.closeMessage = true;
      this.confirmButtonText = res?.confirmButtonText || "";
      this.cancelButtonText = res?.cancelButtonText || "";
      this.headerText = res?.headerText || "Confirmation";
      this.isCopyDataPipelines = res?.isDataPipelines;
      this.isCopyImportName = res?.isName;
      this.sheetList = res?.sheetList;
      this.userlastName = res?.lastName;
      this.userFirstName = res?.firstName;
      this.userName = res?.userName;
      this.fileUpload = res?.fileUpload;
      this.fileType = res?.fileType?.toLowerCase();
      (this.connectionType = res.connectionType), (this.type = res.type);
      if (res.fileUpload) {
        this.getSessionData();
        this.connectionForm = this.connectionFormBuilder.group({
          connectionId: ["", Validators.required],
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
    this.ngUnsubscribe$.next();
    this.closeMessage = false;
    this.ngUnsubscribe$.complete();
    this.ConfirmPopupService.confirmClickedEvent.complete();
    this.routerSubscription?.unsubscribe();
    this.queryParamSubscription?.unsubscribe();
  }

  getSessionData() {
    let connectionType: any = sessionStorage.getItem("connectionTypes");
    let connections: any = sessionStorage.getItem("createdConnections");
    this.allConnectionTypes = JSON.parse(connectionType);
    let supporttedConnections: any;
    if (this.type === "source") {
      supporttedConnections = sourceSupportedConnection;
    } else if (this.type === "target") {
      supporttedConnections = targetSupportedConnection;
    }
    this.createdConnection = JSON.parse(connections).filter((item: any) =>
      supporttedConnections?.includes(item["connectionType"])
    );
  }

  copyImportChange(val: any) {
    this.isCopyImportName = val["target"]["value"];
    if (val["target"]["value"] === "") {
      this.showError = true;
      this.showDuplicateError = false;
    } else {
      this.showError = false;
      this.showDuplicateError = false;
    }
  }

  confirmPopup() {
    if (
      this.isCopyImportName?.trim() !== "" &&
      (this.messageObject["message"] === "Add Sheet" ||
        this.messageObject["message"] === "Rename Sheet")
    ) {
      const sheetName = this.isCopyImportName.toLowerCase();
      if (
        this.sheetList
          .map((lineType: string) => lineType.toLowerCase())
          .indexOf(sheetName) == -1
      ) {
        this.closeMessage = false;
        this.isCopyDataPipelines = false;
        this.showDuplicateError = false;
        this.messageObject["isConfirm"] = "true";
        this.showError = false;
        this.messageObject["sheetName"] = this.isCopyImportName;
        if (this.messageObject["callback"]) {
          this.messageObject.callback(true);
        }
        if (this.messageObject["manageCallback"]) {
          this.messageObject.manageCallback(this.messageObject);
        }
      } else {
        this.showDuplicateError = true;
      }
    } else if (
      this.isCopyImportName?.trim() !== "" &&
      this.isCopyDataPipelines &&
      this.messageObject["message"] === "copy pipelines"
    ) {
      this.closeMessage = false;
      this.isCopyDataPipelines = false;
      this.messageObject["isConfirm"] = "true";
      this.showError = false;
      this.messageObject["copyName"] = this.isCopyImportName;
      if (this.messageObject["callback"]) {
        this.messageObject.callback(true);
      }
      if (this.messageObject["manageCallback"]) {
        this.messageObject.manageCallback(this.messageObject);
      }
    } else if (!this.isCopyDataPipelines) {
      if (this.fileUpload) {
        this.messageObject["targetFile"] = this.file;
        this.messageObject["connectionDetails"] = {
          ...this.connectionForm.value,
        };
        setTimeout(() => {
          this.clearConnection();
        }, 3000);
      }
      this.closeMessage = false;
      this.messageObject["isConfirm"] = "true";
      if (this.messageObject["callback"]) {
        this.messageObject.callback(true);
      }
      if (this.messageObject["manageCallback"]) {
        this.messageObject.manageCallback(this.messageObject);
      }
    } else if (this.isCopyImportName?.trim() === "") {
      this.showError = true;
    }
  }

  closePopup(noCallback = false) {
    this.showError = false;
    this.closeMessage = false;
    this.showDuplicateError = false;
    this.isCopyDataPipelines = false;
    // if (noCallback) return;
    this.messageObject["isConfirm"] = "false";
    if (this.messageObject["callback"]) {
      this.messageObject.callback(false);
    }
    if (this.messageObject["manageCallback"]) {
      this.messageObject.manageCallback(this.messageObject);
    }
    if (this.type) {
      this.messageObject = {
        message: "",
      };
      this.clearConnection();
    }
  }
  getProfileName(firstName: string, lastName: string) {
    if (firstName || lastName) {
      const updatedUser =
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
      return updatedUser;
    } else {
      return "";
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    this.isDragging = false;
  }

  fileDrop(event: any) {
    event.preventDefault();
    this.isDragging = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      this.handleFile(file);
    }
  }

  handleFile(file: File) {
    const fileExtension: any = file.name.split(".").pop()?.toLowerCase();
    if (fileExtension.toLowerCase() !== this.fileType.toLowerCase()) {
      if (
        !(
          ["xls", "xlsx"].includes(fileExtension.toLowerCase()) &&
          ["xls", "xlsx"].includes(this.fileType.toLowerCase()) ===
            ["xls", "xlsx"].includes(fileExtension.toLowerCase())
        )
      ) {
        let data = {
          duration: 3000,
          type: "alert",
          message: `Invalid file type. Please upload a .${this.fileType} file!`,
        };
        this.appService.showToast(data);
        return;
      }
    }
    const maxSizeInBytes = 25 * 1024 * 1024;
    if (file.size >= maxSizeInBytes) {
      let data = {
        duration: 3000,
        type: "alert",
        message: `File size exceeds the 25 MB limit!`,
      };
      this.appService.showToast(data);
      return;
    }
    this.file = file;
    this.confirmPopup();
  }
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.handleFile(file);
    }
  }

  handleGetFileType() {
    if (this.fileType === "xls" || this.fileType === "xlsx") {
      return ".xls,.xlsx";
    } else if (this.fileType === "csv") {
      return ".csv";
    } else if (this.fileType === "txt") {
      return ".txt";
    }
    return null;
  }

  getAdditionalForm(type: string) {
    let id: any = null;
    if (type) id = this.connectionForm.get("connectionId")?.value;
    let connection = this.createdConnection.find(
      (conn: any) => conn["id"] == id
    );
    this.selectedConnection = connection;
    if (connection) {
      const realConnection = this.allConnectionTypes.find(
        (conn: any) => conn["name"] === connection["connectionType"]
      );
      if (
        realConnection &&
        realConnection["connectionAdditionalFormAttributes"]
      ) {
        let modifiedForm = JSON.parse(
          realConnection["connectionAdditionalFormAttributes"]
        );
        for (let field of modifiedForm["form"]) {
          field["id"] = type + field["id"];
        }
        modifiedForm = JSON.stringify(modifiedForm);

        if (type) {
          this.connectionAdditionalForm = modifiedForm;
        }
      }
    }
  }

  getConnectionTypeDetails(connectionType: any): any {
    const connections: any[] = Object.values(this.allConnectionTypes);
    const supportConnections = ["DATABASE"];
    return connections.find(
      (conn: any) =>
        [conn.name, conn.label].includes(connectionType) &&
        supportConnections.includes(conn.connectionType)
    );
  }

  emitTemplateValues() {}

  disabledConfirm() {
    if (this.isCopyDataPipelines) {
      return !this.isCopyImportName?.trim();
    } else {
      return (
        this.connectionForm.status.toLowerCase() === "invalid" &&
        this.fileUpload
      );
    }
  }

  clearConnection() {
    this.selectedConnection = null;
    this.connectionForm.reset();
    this.connectionAdditionalForm = null;
    this.file = null;
  }
}

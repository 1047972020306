<ng-container *ngIf="showing">
  <div
    class="box"
    *ngIf="
      currentStep?.options?.showBackdrop ??
      tourService.stepOptions?.showBackdrop ??
      true
    "
    [style]="{
      left: left + 'px',
      top: top + 'px',
      width: width + 'px',
      height: height + 'px'
    }"></div>

  <ng-container *ngFor="let step of steps; let i = index">
    <div
      class="tour-box"
      *ngIf="
        step === currentStep &&
        (step.options?.showMessage ??
          tourService.stepOptions?.showMessage ??
          true)
      "
      [ngClass]="
        step.options?.placement ?? tourService.stepOptions?.placement ?? ''
      "
      [ngStyle]="getTourBoxStyle()">
      <div class="close" (click)="close()"><i class="ti ti-x"></i></div>
      <div class="header-image" *ngIf="step.imagePath">
        <img [src]="step.imagePath" alt="Help" />
        <div
          class="full-screen-button"
          (click)="openImageInFullScreen(step.imagePath)">
          <i class="ti ti-arrows-diagonal"></i>
        </div>
      </div>

      <div class="header-video" *ngIf="step.videoPath">
        <video [src]="step.videoPath" loop autoplay></video>
        <div
          class="full-screen-button"
          (click)="openVideoInFullScreen(step.videoPath)">
          <i class="ti ti-arrows-diagonal"></i>
        </div>
      </div>
      <h2 class="title">{{ step.title }}</h2>
      <p class="message" [innerHTML]="step.message"></p>
      <br />
      <div class="stretch">
        <button (click)="back()" class="btn" *ngIf="i > 0">
          <i class="ti ti-chevron-left"></i>Back
        </button>
        <div *ngIf="i === 0"></div>
        <div>Step {{ i + 1 }}/{{ steps.length }}</div>
        <button
          *ngIf="
            (step.options?.showNext ??
              tourService.stepOptions?.showNext ??
              true) &&
            i < steps.length - 1
          "
          (click)="next()"
          class="btn active">
          Next<i class="ti ti-chevron-right"></i>
        </button>
        <button
          (click)="close()"
          class="btn active"
          *ngIf="i === steps.length - 1">
          Close
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="fullScreenImage || fullScreenVideo">
    <div class="full-screen-image">
      <img [src]="fullScreenImage" *ngIf="fullScreenImage" alt="Help" />
      <video
        [src]="fullScreenVideo"
        loop
        autoplay
        *ngIf="fullScreenVideo"
        controls></video>
      <div
        class="close-btn"
        (click)="
          fullScreenImage ? closeFullScreenImage() : closeFullScreenVideo()
        ">
        <i class="ti ti-x"></i>
      </div>
    </div>
  </ng-container>
</ng-container>

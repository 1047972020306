import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
export interface ShareFolderDialogData {
  message: string;
  [key: string]: any;
  callback?: Function;
}
@Injectable({
  providedIn: "root",
})
export class ShareFolderPopupService {
  public shareFolderEvent: BehaviorSubject<ShareFolderDialogData> =
    new BehaviorSubject<ShareFolderDialogData>({ message: "" });
  public confirmClickedEvent: BehaviorSubject<ShareFolderDialogData> =
    new BehaviorSubject<ShareFolderDialogData>({ message: "" });

  constructor() {}
  getconfirmMessage(): Observable<ShareFolderDialogData> {
    return this.shareFolderEvent.asObservable();
  }

  getConfirmClicked(): Observable<ShareFolderDialogData> {
    return this.confirmClickedEvent.asObservable();
  }
}

<ng-template #cardContent>
  <div class="icon" *ngIf="currentSection !== 'migrationTool'">
    <img [src]="modelInfo?.icon || 'assets/images/ai_no_icon.svg'" alt="icon" />
  </div>
  <ng-container *ngIf="currentSection === 'migrationTool'">
    <div class="migration-body">
      <div class="migration-icons">
        <img
          [src]="
            sanitizeImageUrl(modelInfo?.['sourceFileImage']) ||
            'assets/images/ai_no_icon.svg'
          "
          alt="icon" />
        <i class="ti ti-arrow-narrow-right"></i>
        <img
          [src]="
            sanitizeImageUrl(modelInfo?.['targetFileImage']) ||
            'assets/images/ai_no_icon.svg'
          "
          alt="icon" />
      </div>
    </div>
  </ng-container>
  <div
    class="text"
    [ngClass]="{ migrationText: currentSection === 'migrationTool' }">
    <div class="title">{{ modelInfo?.["name"] || modelInfo?.modelName }}</div>
    <div
      class="description"
      [title]="modelInfo?.description"
      [ngClass]="{ migrationDescription: currentSection === 'migrationTool' }">
      {{ modelInfo?.description || "No Description" }}
    </div>
    <ng-container *ngIf="tags.length">
      <div class="tags">
        <div class="tag" *ngFor="let tag of tags | slice: 0 : 2">{{ tag }}</div>
        <div class="tag more-tags" *ngIf="tags.length > 2">...</div>
      </div>
    </ng-container>
  </div>
  <div class="backface">
    <p><strong>Tags</strong></p>
    <div class="line"></div>
    <div class="tag" *ngFor="let tag of tags">{{ tag }}</div>
  </div>
</ng-template>

<a
  [href]="link"
  (click)="navigateTo($event)"
  target="_blank"
  *ngIf="
    !['copilot', 'chatbot'].includes(
      modelInfo?.interactionType?.toLowerCase() || ''
    )
  ">
  <div
    class="card"
    [ngClass]="{ migrationCard: currentSection === 'migrationTool' }">
    <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
  </div>
</a>

<ng-container
  *ngIf="
    ['copilot', 'chatbot'].includes(
      modelInfo?.interactionType?.toLowerCase() || ''
    )
  ">
  <div
    class="card"
    *ngIf="!cardExternalLink || cardExternalLink !== selectedExternalLink"
    (click)="showExternalLink()">
    <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
  </div>

  <div
    class="card"
    *ngIf="cardExternalLink && cardExternalLink === selectedExternalLink"
    id="selected-chatbot">
    <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
    <div class="copilot-overlay">
      <div class="icon">
        <img
          [src]="modelInfo?.icon || 'assets/images/ai_no_icon.svg'"
          alt="icon" />
      </div>
      <div class="text">
        <div class="title">{{ modelInfo?.modelName }}</div>
        <button class="button-common" (click)="closeExternalFrame.emit()">
          Close
        </button>
      </div>
      <!-- <button>Start Over</button> -->
    </div>
  </div>
</ng-container>

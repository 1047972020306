import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CardComponent } from "./components/card/card.component";
import { ConfirmPopupComponent } from "./components/confirm-popup/confirm-popup.component";
import { CustomDatatableComponent } from "./components/custom-datatable/custom-datatable.component";
import { CustomSelectComponent } from "./components/custom-select/custom-select.component";
import { DynamicFormComponent } from "./components/dynamic-form/dynamic-form.component";
import { ExcelViewerComponent } from "./components/excel-viewer/excel-viewer.component";
import { FilterComponent } from "./components/filter/filter.component";
import { HeaderComponent } from "./components/header/header.component";
import { InvoiceViewerComponent } from "./components/invoice-viewer/invoice-viewer.component";
import { LottieFlipDesignComponent } from "./components/lottie-flip-design/lottie-flip-design.component";
import { QeuryBuilderComponent } from "./components/qeury-builder/qeury-builder.component";
import { SectionViewerComponent } from "./components/section-viewer/section-viewer.component";
import { ShareFolderPopupComponent } from "./components/share-folder-popup/share-folder-popup.component";
import { SheetHeaderListPopupComponent } from "./components/sheet-header-list-popup/sheet-header-list-popup.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ToastComponent } from "./components/toast/toast.component";
import { TourComponent } from "./components/tour/tour.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxJsonViewerModule } from "ngx-json-viewer-ng";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgxPaginationModule } from "ngx-pagination";
import { MatTooltipModule } from "@angular/material/tooltip";
import player from "lottie-web";
import { LottieModule } from "ngx-lottie";
import { FilterDropdownOptionElementPipe } from "./pipes/filter-dropdown-option-element.pipe";
import { EmaildomainSplitterPipe } from "./pipes/emaildomain-splitter.pipe";
import { FiterImportTagsPipe } from "./pipes/fiter-import-tags.pipe";
import { GetObjectKeysPipe } from "./pipes/get-object-keys.pipe";
import { NumToAlphaPipe } from "./pipes/num-to-alpha.pipe";
import { RemoveDuplicatePipe } from "./pipes/remove-duplicate.pipe";
import { SortObjectByPipe } from "./pipes/sort-object-by.pipe";
import { DsableRemoveDeptTagPipe } from "./pipes/dsable-remove-dept-tag.pipe";
import { DatatableComponent } from "./components/datatable/datatable.component";
import { MatSelectModule } from "@angular/material/select";
import { GlobalDropdownComponent } from "./components/global-dropdown/global-dropdown.component";
import { DropdownTriggerComponent } from "./components/dropdown-trigger/dropdown-trigger.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    CardComponent,
    ConfirmPopupComponent,
    CustomDatatableComponent,
    CustomSelectComponent,
    DynamicFormComponent,
    ExcelViewerComponent,
    FilterComponent,
    HeaderComponent,
    InvoiceViewerComponent,
    LottieFlipDesignComponent,
    QeuryBuilderComponent,
    SectionViewerComponent,
    ShareFolderPopupComponent,
    SheetHeaderListPopupComponent,
    SidebarComponent,
    ToastComponent,
    TourComponent,
    FilterDropdownOptionElementPipe,
    EmaildomainSplitterPipe,
    FiterImportTagsPipe,
    GetObjectKeysPipe,
    NumToAlphaPipe,
    RemoveDuplicatePipe,
    SortObjectByPipe,
    DsableRemoveDeptTagPipe,
    DatatableComponent,
    GlobalDropdownComponent,
    DropdownTriggerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule,
    MatOptionModule,
    MatFormFieldModule,
    NgxPaginationModule,
    MatTooltipModule,
    MatSelectModule,
    LottieModule.forRoot({ player: playerFactory }),
    DragDropModule,
  ],
  exports: [
    CardComponent,
    ConfirmPopupComponent,
    CustomDatatableComponent,
    CustomSelectComponent,
    DynamicFormComponent,
    ExcelViewerComponent,
    FilterComponent,
    HeaderComponent,
    InvoiceViewerComponent,
    LottieFlipDesignComponent,
    QeuryBuilderComponent,
    SectionViewerComponent,
    ShareFolderPopupComponent,
    SheetHeaderListPopupComponent,
    SidebarComponent,
    ToastComponent,
    TourComponent,
    FilterDropdownOptionElementPipe,
    EmaildomainSplitterPipe,
    FiterImportTagsPipe,
    GetObjectKeysPipe,
    NumToAlphaPipe,
    RemoveDuplicatePipe,
    SortObjectByPipe,
    DsableRemoveDeptTagPipe,
    DatatableComponent,
    GlobalDropdownComponent,
    DropdownTriggerComponent,
  ],
})
export class SharedModule {}

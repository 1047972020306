<div class="banner" *ngIf="isBannerVisible">
  <p>{{ bannerLabel }}</p>
</div>
<ng-container *ngIf="isPDF; else excel">
  <ng-container *ngFor="let section of sections; let i = index">
    <div
      class="section"
      [ngClass]="{ expanded: getSectionExpandInfo(i)?.totalExpanded }">
      <div class="section-header">
        <p [contentEditable]="isEditable" [ngClass]="{ editable: isEditable }">
          {{ section["sectionHeading"] || "Untitled Section" }}
        </p>
        <span *ngIf="section['pageNumber']"
          >Page No: {{ section["pageNumber"] }}</span
        >
        <i
          class="ti ti-caret-up-filled caret"
          *ngIf="getSectionExpandInfo(i)?.totalExpanded"
          (click)="collapseSection(i)"></i>
        <i
          class="ti ti-caret-down-filled caret"
          *ngIf="!getSectionExpandInfo(i)?.totalExpanded"
          (click)="expandSection(i)"></i>
      </div>
      <div class="section-body" *ngIf="getSectionExpandInfo(i)?.totalExpanded">
        <div class="inner-section">
          <div class="inner-section-header">
            AI Section Summary
            <i class="ti ti-sparkles" style="color: #f36f21"></i>
            <i
              class="ti ti-minus caret"
              *ngIf="getSectionExpandInfo(i)?.summaryExpanded"
              (click)="collapseInnerSection(i, 'summary')"></i>
            <i
              class="ti ti-plus caret"
              *ngIf="!getSectionExpandInfo(i)?.summaryExpanded"
              (click)="expandInnerSection(i, 'summary')"></i>
          </div>
          <div
            class="inner-section-body"
            *ngIf="getSectionExpandInfo(i)?.summaryExpanded">
            <p>{{ section["sectionSummary"] || "No summary" }}</p>
          </div>
        </div>
        <div class="inner-section">
          <div class="inner-section-header">
            Paragraphs
            <i
              class="ti ti-minus caret"
              *ngIf="getSectionExpandInfo(i)?.paragraphsExpanded"
              (click)="collapseInnerSection(i, 'paragraphs')"></i>
            <i
              class="ti ti-plus caret"
              *ngIf="!getSectionExpandInfo(i)?.paragraphsExpanded"
              (click)="expandInnerSection(i, 'paragraphs')"></i>
          </div>
          <div
            class="inner-section-body"
            *ngIf="getSectionExpandInfo(i)?.paragraphsExpanded">
            <p>{{ section["paragraphs"] || "No content" }}</p>
          </div>
        </div>
        <div class="inner-section">
          <div class="inner-section-header">
            Tables
            <i
              class="ti ti-minus caret"
              *ngIf="getSectionExpandInfo(i)?.tablesExpanded"
              (click)="collapseInnerSection(i, 'tables')"></i>
            <i
              class="ti ti-plus caret"
              *ngIf="!getSectionExpandInfo(i)?.tablesExpanded"
              (click)="expandInnerSection(i, 'tables')"></i>
          </div>
          <div
            class="inner-section-body"
            *ngIf="getSectionExpandInfo(i)?.tablesExpanded">
            <ng-container *ngFor="let tables of getTables(i); let j = index">
              <ng-container *ngFor="let table of tables">
                <ng-container *ngIf="section['tables'][j]['tableDescription']">
                  <br />
                  <span>{{ section["tables"][j]["tableDescription"] }}</span>
                </ng-container>
                <table>
                  <tr *ngFor="let row of tableDataToMatrix(table)">
                    <ng-container *ngIf="row.rowType === 'header'">
                      <th *ngFor="let cell of row.cells">
                        {{ cell }}
                      </th>
                    </ng-container>

                    <ng-container *ngIf="row.rowType === 'body'">
                      <td *ngFor="let cell of row.cells">
                        {{ cell }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="getTables(i).length === 0">
              <p>No tables</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #excel>
  <div class="section" *ngFor="let sheet of getSheetNames(); let i = index">
    <div class="section-header">
      <p>{{ sheet }}</p>
      <i
        class="ti ti-caret-up-filled caret"
        *ngIf="getSectionExpandInfo(i)?.totalExpanded"
        (click)="collapseSection(i)"></i>
      <i
        class="ti ti-caret-down-filled caret"
        *ngIf="!getSectionExpandInfo(i)?.totalExpanded"
        (click)="expandSection(i)"></i>
    </div>
    <div class="section-body" *ngIf="getSectionExpandInfo(i)?.totalExpanded">
      <!-- <div class="inner-section"></div> -->
      <div
        class="inner-section"
        *ngFor="let table of getTablesFromSheets(sheet); let j = index">
        <div class="inner-section-header">
          Table {{ j + 1 }}
          <i
            class="ti ti-minus caret"
            *ngIf="
              getSectionExpandInfo(i)?.['subSectionsExpanded']?.[
                'Table ' + (j + 1)
              ]
            "
            (click)="collapseExcelInnerSection(i, 'Table ' + (j + 1))"></i>
          <i
            class="ti ti-plus caret"
            *ngIf="
              !getSectionExpandInfo(i)?.['subSectionsExpanded']?.[
                'Table ' + (j + 1)
              ]
            "
            (click)="expandExcelInnerSection(i, 'Table ' + (j + 1))"></i>
        </div>
        <div
          class="inner-section-body"
          *ngIf="
            getSectionExpandInfo(i)?.['subSectionsExpanded']?.[
              'Table ' + (j + 1)
            ]
          ">
          <div class="inner-section" *ngFor="let key of getObjectKeys(table)">
            <div class="inner-section-header" style="color: #272b34">
              {{ key }}
              <i
                class="ti ti-minus caret"
                *ngIf="
                  getSectionExpandInfo(i)?.['subSectionsExpanded']?.[
                    sheet + key
                  ]
                "
                (click)="collapseExcelInnerSection(i, sheet + key)"></i>
              <i
                class="ti ti-plus caret"
                *ngIf="
                  !getSectionExpandInfo(i)?.['subSectionsExpanded']?.[
                    sheet + key
                  ]
                "
                (click)="expandExcelInnerSection(i, sheet + key)"></i>
            </div>
            <div
              class="inner-section-body"
              style="margin-top: 4px"
              *ngIf="
                getSectionExpandInfo(i)?.['subSectionsExpanded']?.[sheet + key]
              ">
              <ngx-json-viewer
                [json]="table[key]['data']"
                [expanded]="false"></ngx-json-viewer>
            </div>
          </div>
          <ng-container *ngIf="getObjectKeys(table).length === 0">
            <p>No content</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

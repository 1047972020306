export interface DropdownOptions {
  searchable: boolean;
  placeholder: string;
  inputValue?: string;
  dataOptions: any; //object
  searchValue?: string;
  multiSelectDropDown?: boolean;
  isSeparateSearch?: boolean;
  isInputAsSearch?: boolean;
  dropdownSearchType?: DropdownSearchType;
}
export enum DropdownSearchType {
  default = "default",
  insideDropdown = "insideDropdown",
}

// for muiltiselect add newOptions array in the dataOptions object

<div class="main-Div">
  <app-lottie-flip-design></app-lottie-flip-design>
  <div class="right-div-Login">
    <div class="center-div">
      <div class="right-div">
        <div class="header-div1">
          <label class="title space-grotesk">Welcome back</label>
          <div class="desc-div">
            <label class="desc schibsted-grotesk"
              >Enter the email and password for your account</label
            >
          </div>
        </div>
        <div class="fields-div">
          <form [formGroup]="loginForm" (ngSubmit)="login()" class="form-div">
            <div *ngIf="loginError" class="errorField">
              <img src="assets/Svgs_/SideBar/Info.svg" />{{ loginError }}
            </div>
            <div class="login-input">
              <label for="userName" class="title schibsted-grotesk"
                >Username</label
              >
              <input
                class="inputFields schibsted-grotesk"
                type="text"
                name="userName"
                formControlName="username"
                placeholder="username@company.com" />
              <div
                *ngIf="username?.touched && username?.errors"
                class="errorField">
                <img src="assets/Svgs_/SideBar/Info.svg" />Username is required
              </div>
            </div>
            <div class="login-input">
              <div class="titleDiv">
                <label for="password" class="title schibsted-grotesk"
                  >Password</label
                >
                <label class="forgetPassword" tabindex="-1"
                  ><a
                    routerLink="/forgot"
                    class="schibsted-grotesk"
                    tabindex="-1"
                    >Forgot Password?</a
                  ></label
                >
              </div>
              <div class="input-div">
                <input
                  class="inputFields schibsted-grotesk"
                  [type]="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  formControlName="password"
                  placeholder="*************"
                  autocomplete="new-password" />
                <div class="pass-icon">
                  <ng-container *ngIf="isPasswordVisible">
                    <img
                      src="assets/Svgs_/Login/HidePassword.svg"
                      alt="show"
                      (click)="togglePasswordVisibility()" />
                  </ng-container>
                  <ng-container *ngIf="!isPasswordVisible">
                    <img
                      src="assets/Svgs_/Login/ShowPassword.svg"
                      alt="Hide"
                      (click)="togglePasswordVisibility()" />
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="password?.touched && password?.errors"
                class="errorField">
                <img src="assets/Svgs_/SideBar/Info.svg" />Password is required
              </div>
            </div>
            <!-- <div class="login-input">
              <label for="userName" class="title">Tenant</label>
              <input
                class="inputFields"
                type="text"
                name="userName"
                formControlName="tenant"
                placeholder="E.g. Kanerika"
                autocomplete="new-password"
              />
              <div
                *ngIf="tenant?.touched && tenant?.errors"
                class="errorField"
              >
                <img src="assets/Svgs_/SideBar/Info.svg" />Tenant is required
              </div>
            </div> -->
            <button class="button-common gradient schibsted-grotesk">
              Sign In
            </button>
            <div class="context">
              <label class="schibsted-grotesk">Not registered yet ?</label>
              <a routerLink="/register" class="schibsted-grotesk"
                >Create an account</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="footer-div">
      <div class="desc-div">
        <label class="desc schibsted-grotesk">
          © Copyright 2024 - FLIP -
          <a href="https://kanerika.com/" target="_blank">Kanerika</a>
          -
          <a href="https://kanerika.com/privacy-policy/" target="_blank"
            >Privacy Policy</a
          >
          -
          <a [href]="authPageService.termsOfServiceLink" target="_blank"
            >Terms of Service</a
          >.
        </label>
      </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDropdownOptionElement'
})
export class FilterDropdownOptionElementPipe implements PipeTransform {

  transform(list: any, value: any) {
    if(list){
      return list.filter(x=>x.reference_element_label==value)
    }
  }

}

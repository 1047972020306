import { Component, HostListener, OnInit } from "@angular/core";
import { ITourStep, TourService } from "./tour.service";

@Component({
  selector: "app-tour",
  templateUrl: "./tour.component.html",
  styleUrls: ["./tour.component.scss"],
})
export class TourComponent implements OnInit {
  public steps: ITourStep[] = [];
  public showing: boolean = false;
  public left = 100;
  public top = 100;
  public width = 100;
  public height = 100;
  public fullScreenImage?: string;
  public fullScreenVideo?: string;
  constructor(public tourService: TourService) {}

  ngOnInit(): void {
    this.tourService.steps$.subscribe((res) => {
      this.steps = res;
    });
    this.tourService.actionEmitter.subscribe((res) => {
      switch (res) {
        case "start":
          this.start();
          break;
        case "close":
          this.close();
          break;
        case "next":
          this.next();
          break;
        case "back":
          this.back();
          break;
        case "resume":
          this.resume();
          break;
      }
    });
    window.onwheel = () => {
      if (this.currentStep && this.showing) {
        this.fetchElementsCoordinates(this.currentStep.selector);
      }
    };

    window.onresize = () => {
      if (this.currentStep && this.showing) {
        this.fetchElementsCoordinates(this.currentStep.selector);
      }
    };
  }

  start() {
    this.tourService.currentStep = this.steps[0];
    this.fetchElementsCoordinates(this.steps[0].selector);
    this.showing = true;
  }

  fetchElementsCoordinates(selector: string) {
    const el = document.querySelector(selector);
    if (el) {
      const bodyRect = document.body.getBoundingClientRect();
      const elRect = el.getBoundingClientRect();
      this.top = elRect.top - 8;
      this.left = elRect.left - 8;
      this.width = elRect.width + 16;
      this.height = elRect.height + 16;
    }
  }

  getTourBoxStyle() {
    const placement =
      this.currentStep?.options?.placement ??
      this.tourService.stepOptions?.placement ??
      "right";
    switch (placement) {
      case "left":
        return {
          left: `${this.left - 285}px`,
          top: `${this.top + this.height / 2}px`,
        };
      case "top":
        return {
          left: `${Math.min(this.left, window.innerWidth - 285)}px`,
          top: `${this.top}px`,
        };
      case "bottom":
        return {
          left: `${this.left}px`,
          top: `${this.top + this.height + 15}px`,
        };
      default:
        return {
          left: `${this.left + this.width + 15}px`,
          top: `${this.top + this.height / 2}px`,
        }; // right
    }
  }

  get currentStep() {
    return this.tourService.currentStep;
  }

  close() {
    this.showing = false;
  }

  next() {
    if (this.currentStep) {
      const index = this.steps.indexOf(this.currentStep);
      if (index == this.steps.length - 1) {
        this.close();
      } else {
        this.tourService.currentStep = this.steps[index + 1];
        this.fetchElementsCoordinates(this.steps[index + 1].selector);
      }
    }
  }

  back() {
    if (this.currentStep) {
      const index = this.steps.indexOf(this.currentStep);
      if (index == 0) {
        this.close();
      } else {
        this.tourService.currentStep = this.steps[index - 1];
        this.fetchElementsCoordinates(this.steps[index - 1].selector);
      }
    }
  }

  resume() {
    this.showing = true;
  }

  openImageInFullScreen(path: string) {
    this.fullScreenImage = path;
  }

  openVideoInFullScreen(path: string) {
    this.fullScreenVideo = path;
  }

  closeFullScreenImage() {
    this.fullScreenImage = undefined;
  }

  closeFullScreenVideo() {
    this.fullScreenVideo = undefined;
  }
}

import { ToastService } from "./../../../../shared/services/toast.service";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/core/services/app.service";
import { FormBuilder } from "@angular/forms";
import { AuthPageService } from "../../services/auth-page.service";

@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.scss"],
})
export class SetPasswordComponent implements OnInit {
  public setPasswordForm = this.fb.group({
    username: ["", Validators.required],
    password: [
      "",
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
        ),
      ],
    ],
    confirmPassword: ["", Validators.required],
  });
  public isPasswordVisible: boolean = false;
  public isConfirmPasswordVisible: boolean = false;
  public extractToken: string = "";
  public linkExpired: boolean = false;
  public linkInvalid: boolean = false;
  public updatedPassword: boolean = false;
  public isNotSamePasswords: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public router: Router,
    private appService: AppService,
    private authPageService: AuthPageService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    const currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      this.router.navigate(["home"]);
      this.toastService.commonToastMethod(
        "User already logged in. Log out first and try again!",
        "info"
      );
    } else {
      const url = this.route.snapshot.url.join("/");
      const index = url.indexOf("completeUserRegistration/");
      this.setPasswordForm.get("username")?.disable();
      if (index !== -1) {
        this.extractToken = url.substring(
          index + "completeUserRegistration/".length
        );
      }
      this.authPageService.getSetPasswordData(this.extractToken).subscribe({
        next: (response) => {
          this.setPasswordForm.patchValue({ username: response["username"] });
          this.setPasswordForm.get("username")?.disable();
          this.linkExpired = false;
        },
        error: (err) => {
          if (err.status === 498) {
            this.linkExpired = true;
            this.setPasswordForm.patchValue({
              username: err["error"]?.["username"] || err["username"],
            });
            this.toastService.commonToastMethod(
              err["error"]?.["message"] || err["message"] || err,
              "error"
            );
          }
          if (err.status === 500 || err.status === 401) {
            this.linkInvalid = true;
            this.toastService.commonToastMethod(
              err["error"]?.["message"] || err["message"] || err,
              "error"
            );
          }
          if (err.status === 403) {
            this.toastService.commonToastMethod(
              err["error"]?.["message"] || err["message"] || err,
              "error"
            );
            this.router.navigate(["login"]);
          }
        },
      });
    }
  }
  setPassword() {
    if (this.setPasswordForm.pristine || this.setPasswordForm.invalid) {
      this.setPasswordForm.markAllAsTouched();
      return;
    }
    let rawValue = this.setPasswordForm?.getRawValue();
    if (this.setPasswordForm.invalid || !rawValue.username) {
      return;
    }
    const passwordInfo = {
      username: rawValue?.username,
      password: rawValue?.password,
    };
    passwordInfo["token"] = this.extractToken;
    this.authPageService.setPasswordUpdate(passwordInfo).subscribe({
      next: (response) => {
        this.toastService.commonToastMethod(response["message"], "success");
        this.linkExpired = false;
        this.updatedPassword = true;
      },
      error: (err) => {
        this.linkExpired = true;
        if (err.status !== 403) {
          this.linkExpired = true;
          this.toastService.commonToastMethod(
            err["error"]?.["message"] || err["message"] || err,
            "error"
          );
        }
      },
    });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  get username() {
    return this.setPasswordForm.get("username");
  }

  get password() {
    return this.setPasswordForm.get("password");
  }

  get confirmPassword() {
    return this.setPasswordForm.get("confirmPassword");
  }

  onPasswordChange(event: any) {
    this.isNotSamePasswords =
      this.password?.value &&
      this.password.value != this.confirmPassword?.value;
  }
  requestForNewLink() {
    const payload = {
      username: this.setPasswordForm.getRawValue().username,
    };
    this.authPageService.sendRequestNewLink(payload).subscribe({
      next: (response) => {
        this.toastService.commonToastMethod(response["message"], "success");
      },
      error: (err) => {
        if (err.status !== 403) {
          this.toastService.commonToastMethod(
            err["error"]?.["message"] || err["message"] || err,
            "error"
          );
        }
      },
    });
  }

  disableConfirmButton() {
    return (
      this.setPasswordForm.invalid ||
      this.password?.value !== this.confirmPassword?.value
    );
  }
}

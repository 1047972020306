import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fiterImportTags',
})
export class FiterImportTagsPipe implements PipeTransform {
  transform(selectedTagObjects, pipeRefreshCounter): String[] {
    if (selectedTagObjects && selectedTagObjects.length) {
      if (selectedTagObjects.length == 1) {
        return selectedTagObjects[0].tags;
      }

      let allTags = selectedTagObjects.map((m) => m.tags || []);

      var result = allTags.shift().filter(function (v) {
        return allTags.every(function (a) {
          return a.indexOf(v) !== -1;
        });
      });

      return result;
    }
    return [];
  }
}

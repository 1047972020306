<ng-container *ngIf="!nestedData">
  <div
    class="custom-select"
    [class.disabled]="disabled"
    (click)="!disabled && toggleDropdown()">
    <div class="current-select">
      <div class="element-name" [title]="inputValue" *ngIf="inputValue">
        {{ inputValuedisplayName(inputValue) }}
      </div>
      <div class="element-name" [title]="placeholder" *ngIf="!inputValue">
        {{ placeholder }}
      </div>
      <img
        alt=""
        src="assets/Svgs_/TransformationStudio/down-icon.svg"
        class="toggle-icon"
        *ngIf="!isDropdownOpened" />

      <img
        alt=""
        src="assets/Svgs_/TransformationStudio/up-icon.svg"
        class="toggle-icon"
        *ngIf="isDropdownOpened" />
    </div>
    <div
      class="custom-select-content open-downward"
      id="customSelectContent"
      *ngIf="isDropdownOpened"
      (click)="$event.stopPropagation()">
      <div class="search" *ngIf="searchable">
        <div class="global-search-content filter-search">
          <img src="assets/Svgs_/Monitor/Search.svg" />
          <input
            #searchInputRef
            type="search"
            class="search"
            placeholder="Search..."
            style="font-size: 12px; font-weight: 400"
            [ngModel]="searchInput"
            (input)="onSearch($event)" />
        </div>
      </div>
      <div
        class="group"
        *ngFor="let group of groups; let i = index"
        (click)="toggleSubDropdown(i)">
        <span>
          <div *ngIf="!isGroupNameEmpty(group.group)">
            {{ group.group }}
          </div>
          <div *ngIf="isGroupNameEmpty(group.group)">Other</div>
          <img
            alt=""
            src="assets/Svgs_/TransformationStudio/down-icon.svg"
            class="toggle-icon"
            *ngIf="!group.opened" />
          <img
            alt=""
            src="assets/Svgs_/TransformationStudio/up-icon.svg"
            class="toggle-icon"
            *ngIf="group.opened" />
        </span>
        <div class="line" *ngIf="group.opened"></div>
        <div class="group-values" *ngIf="group.opened">
          <div
            class="values"
            *ngFor="let function of getFunctions(group.group)"
            [ngClass]="{ 'values-active': function.name === inputValue }"
            (click)="$event.stopPropagation(); inputChanged(function, $event)">
            {{ function.displayName || function.name }}
            <i
              class="flip-icons flip-x"
              *ngIf="function.name === inputValue"
              (click)="
                $event.stopPropagation();
                handleClearTransformationFunction(inputValue)
              "></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- <ng-container *ngIf="nestedData">
  <div class="input-dropdown-container">
    <input
      #customRegexInput="ngModel"
      type="text"
      [(ngModel)]="inputValue"
      [ngClass]="{
        'error-border':
          (customRegexInput.touched || isEmptyValues) && isInvalid(inputValue)
      }"
      (focus)="onInputFocus()"
      (blur)="onInputBlur()"
      [placeholder]="placeholder"
      (input)="onInputChange(inputValue)"
      required />
    <div class="dropdown-menu" *ngIf="showOptions">
      <div
        class="dropdown-item"
        *ngFor="let option of filterOptions"
        [ngClass]="{
          highlighted:
            inputValue &&
            option.toLowerCase().includes(inputValue.toLowerCase())
        }"
        (mousedown)="onOptionMouseDown()"
        (click)="onSelectChange(option)">
        {{ option }}
      </div>
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="data && triggerPosition && isOpen">
  <div class="input-dropdown-container">
    <div
      class="dropdown-menu"
      [ngStyle]="getDropdownStyles()"
      [id]="'child' + triggerId">
      <!-- Separate Search Input -->
      <ng-container *ngIf="data?.dropdownSearchType === 'insideDropdown'">
        <input
          type="text"
          [(ngModel)]="searchValue"
          (focus)="onInputFocus()"
          placeholder="Search by name"
          (input)="onInputChange($event)"
          required />
      </ng-container>

      <!-- Dropdown Options -->
      <ng-container *ngIf="filterOptions.length > 0; else emptyOption">
        <ng-container [ngSwitch]="dropdownType(data)">
          <!-- Normal Dropdown -->
          <ng-container *ngSwitchCase="'normal'">
            <div
              class="dropdown-item"
              *ngFor="let option of filterOptions; let i = index"
              [ngClass]="{ highlighted: isHighlighted(option) }"
              (mousedown)="onOptionMouseDown()"
              (click)="onSelectChange(option, i, $event)">
              {{ option }}
            </div>
          </ng-container>

          <!-- Multi-Select Dropdown -->
          <ng-container *ngSwitchCase="'multiSelect'">
            <div class="selectBg">
              <div class="dropdown-item-List-select">
                <input
                  type="text"
                  [(ngModel)]="searchValue"
                  (focus)="onInputFocus()"
                  [placeholder]="data.placeholder"
                  (input)="onInputChange($event)"
                  required />
                <div
                  *ngFor="let item of filterOptions; let i = index"
                  class="itemList"
                  [ngClass]="{ highlighted: isHighlighted(item) }">
                  <label class="checkbox-list">
                    <input
                      type="checkbox"
                      [checked]="isSelected(item)"
                      (click)="onSelectChange(item, i, $event)" />
                    &nbsp;
                    {{ item }}
                  </label>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- Input-as-Search Dropdown -->
          <ng-container *ngSwitchCase="'inputSearch'">
            <div
              class="dropdown-item"
              *ngFor="let option of filterOptions; let i = index"
              [ngClass]="{ highlighted: isHighlighted(option) }"
              (mousedown)="onOptionMouseDown()"
              (click)="onSelectChange(option, i, $event)">
              {{ option }}
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Empty Option -->
      <ng-template #emptyOption>
        <div class="dropdown-item">No options available</div>
      </ng-template>
    </div>
  </div>
</ng-container>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ImportDesignerService } from "src/app/features/pipelines/services/import-designer.service";
import {
  DropdownOptions,
  DropdownSearchType,
} from "../../models/globalDropdownOptions.model";
export interface IfExistCondition {
  customRegex: string;
  operator: "AND" | "OR" | "";
  sourceMapping: string;
  sequenceNumber: number;
}
export interface IfExistConditionGroup {
  conditions: IfExistCondition[];
  operator: "AND" | "OR" | "";
  sequenceNumber: number;
}

@Component({
  selector: "app-qeury-builder",
  templateUrl: "./qeury-builder.component.html",
  styleUrls: ["./qeury-builder.component.scss"],
})
export class QeuryBuilderComponent implements OnInit {
  conditionOperators: string[] = ["AND", "OR"];
  @Input() groups: IfExistConditionGroup[] = [];
  @Input() isEmptyValues: boolean = false;
  @Output() updateValues = new EventEmitter();
  customRegexOptions = [];
  constructor(private importDesignerService: ImportDesignerService) {}
  async ngOnInit() {
    let sessionRegexDetails: any = sessionStorage.getItem(
      "predefinedRegexExpressionList"
    );
    if (!sessionRegexDetails || sessionRegexDetails.length === 0) {
      try {
        await this.getPredefinedRegexExpressions();
      } catch (error) {
        return;
      }
    } else {
      if (sessionRegexDetails) {
        sessionRegexDetails = JSON.parse(sessionRegexDetails);
        this.customRegexOptions = sessionRegexDetails;
      }
    }
  }

  async getPredefinedRegexExpressions() {
    let response: any =
      await this.importDesignerService.getPredefinedRegexExpressions();
    if (response) {
      sessionStorage.setItem(
        "predefinedRegexExpressionList",
        JSON.stringify(response["predefinedRegexExpressionList"])
      );
      this.customRegexOptions = response["predefinedRegexExpressionList"];
    }
  }

  addCondition(groupIndex: number): void {
    const conditionIndex = this.groups[groupIndex].conditions.length;
    this.groups[groupIndex].conditions.push({
      customRegex: "",
      operator: "AND",
      sourceMapping: "",
      sequenceNumber: conditionIndex + 1,
    });
  }

  addGroup(): void {
    const groupIndex = this.groups.length;
    this.groups.push({
      conditions: [
        {
          customRegex: "",
          operator: "AND",
          sourceMapping: "",
          sequenceNumber: 1,
        },
      ],
      operator: "AND",
      sequenceNumber: groupIndex + 1,
    });
  }

  deleteCondition(groupIndex: number, conditionIndex: number): void {
    if (this.groups[groupIndex].conditions.length > 1) {
      this.groups[groupIndex].conditions.splice(conditionIndex, 1);
      this.groups[groupIndex].conditions.forEach((condition, index) => {
        condition.sequenceNumber = index + 1;
      });
    } else if (
      this.groups[groupIndex].conditions.length === 1 &&
      this.groups.length > 1
    ) {
      this.groups.splice(groupIndex, 1);
      this.groups.forEach((group, index) => {
        group.sequenceNumber = index + 1;
      });
    }
  }

  deleteGroup(groupIndex: number): void {
    if (this.groups.length > 1) {
      this.groups.splice(groupIndex, 1);
      this.groups.forEach((group, index) => {
        group.sequenceNumber = index + 1;
      });
    }
  }
  isInvalid(value: string): boolean {
    return !value || value.trim() === "";
  }
  checkCustomRegex(event: any, groupIndex: number, conditionIndex: number) {
    this.groups[groupIndex].conditions[conditionIndex]["customRegex"] =
      event.options.value;
    this.updateQueryValues();
  }
  updateQueryValues() {
    let data: any = this.groups;
    this.updateValues.emit(data);
  }
  public dropdownOptions1: DropdownOptions = {
    searchable: true,
    placeholder: "Custom Regex",
    inputValue: "",
    multiSelectDropDown: false,
    dropdownSearchType: DropdownSearchType.default,
    dataOptions: {
      options: this.customRegexOptions,
      isEmptyValues: this.isEmptyValues,
    },
  };
  customRegexInputValue(groupIndex, conditionIndex, value) {
    return {
      ...this.dropdownOptions1,
      dataOptions: {
        options: this.customRegexOptions,
        isEmptyValues: this.isEmptyValues,
      },
      inputValue: value.customRegex ? value.customRegex : "",
    };
  }
}

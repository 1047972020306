<div class="main-pdf-wrapper">
  <div class="title">
    <img src="assets/images/Small_logo.png" alt="Kanerika" draggable="false" />
  </div>
  <div class="invoice-wrapper">
    <div class="invoice-header">
      <div class="invoice-label">Invoice</div>
      <span>{{ vendorName }}</span>
    </div>
    <div class="grid">
      <div class="placeholder"></div>
      <div class="summary-wrapper">
        <div class="summary-inputs">
          <label for="">Invoice No</label>
          <!-- <input type="text" formControlName="InvoiceId" [ngClass]="{'disable-formgroup':!isEditable}"
                        [placeholder]="emptyPlaceholder"> -->
          <div
            class="editable-div"
            [ngClass]="{ editing: isEditable }"
            [contentEditable]="isEditable"
            [innerText]="editableData['InvoiceId']?.['value']"
            (blur)="changeValue($event, 'InvoiceId')"></div>
          <label for="">Invoice Date</label>
          <!-- <input type="text" formControlName="InvoiceDate" [placeholder]="emptyPlaceholder"
                        [ngClass]="{'disable-formgroup':!isEditable,'calendar-hidden': !isEditable}"> -->
          <div
            class="editable-div"
            [ngClass]="{ editing: isEditable }"
            [contentEditable]="isEditable"
            [innerText]="editableData['InvoiceDate']?.['value']"
            (blur)="changeValue($event, 'InvoiceDate')"></div>
          <label for="">PO No</label>
          <!-- <input type="text" formControlName="PurchaseOrder" [ngClass]="{'disable-formgroup':!isEditable}"
                        [placeholder]="emptyPlaceholder"> -->
          <div
            class="editable-div"
            [ngClass]="{ editing: isEditable }"
            [contentEditable]="isEditable"
            [innerText]="editableData['PurchaseOrder']?.['value']"
            (blur)="changeValue($event, 'PurchaseOrder')"></div>
          <label for="">Due Date</label>
          <div
            class="editable-div"
            [ngClass]="{ editing: isEditable }"
            [contentEditable]="isEditable"
            [innerText]="editableData['DueDate']?.['value']"
            (blur)="changeValue($event, 'DueDate')"></div>
          <!-- <input type="text" formControlName="DueDate"
                        [ngClass]="{'disable-formgroup':!isEditable,'calendar-hidden': !isEditable}"
                        [placeholder]="emptyPlaceholder"> -->
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="invoice-address">
      <div class="st">
        <label>Ship To</label>
        <div
          [contentEditable]="isEditable"
          class="editable-div"
          [ngClass]="{ editing: isEditable }"
          [innerText]="editableData['ShippingAddress']?.['value']"
          (blur)="changeValue($event, 'ShippingAddress')">
          <!-- <textarea cols="30" rows="5" formControlName="ShippingAddress" placeholder="No Value"
                        [ngClass]="{'disable-formgroup':!isEditable}"></textarea> -->
        </div>
      </div>
      <div class="st">
        <label for="">Bill To</label>
        <div
          [contentEditable]="isEditable"
          class="editable-div"
          [ngClass]="{ editing: isEditable }"
          [innerText]="editableData['BillingAddress']?.['value']"
          (blur)="changeValue($event, 'BillingAddress')">
          <!-- <textarea cols="30" rows="5" formControlName="BillingAddress" placeholder="No Value"
                        [ngClass]="{'disable-formgroup':!isEditable}"></textarea> -->
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="dynamic-inputs-wrapper" [formGroup]="dynamicInputFormGroup">
      <ng-container
        *ngFor="let controlName of dynamicInputFormGroup.controls | keyvalue">
        <div class="dynamic-inputs">
          <label
            [title]="
              staticKeys[controlName.key] || splitCamelCase(controlName.key)
            "
            >{{
              staticKeys[controlName.key] || splitCamelCase(controlName.key)
            }}</label
          >
          <div
            [contentEditable]="isEditable"
            class="editable-div"
            spellcheck="false"
            [ngClass]="{ editing: isEditable }"
            [innerText]="editableData[controlName.key]?.['value'] || ''"
            (blur)="changeValue($event, controlName.key)"></div>
          <!-- <input type="text" [formControlName]="controlName.key" [ngClass]="{'disable-formgroup':!isEditable}"
                        [ngStyle]="{'border':dynamicInputFormGroup.get(controlName.key)?.value==='' && dynamicInputFormGroup.get(controlName.key)?.touched?'solid 1.5px red':''}"
                        [placeholder]="emptyPlaceholder"> -->
        </div>
      </ng-container>

      <!-- <form [formGroup]="dynamicInputFormGroup">
                <div *ngFor="let controlName of dynamicInputFormGroup.controls | keyvalue">
                    <label>{{ controlName.key }}</label>
                    <input type="text" [formControlName]="controlName.key">
                    <div
                        *ngIf="dynamicInputFormGroup.get(controlName.key)?.invalid && dynamicInputFormGroup.get(controlName.key)?.touched">
                        <div *ngIf="dynamicInputFormGroup.get(controlName.key)?.hasError('required')">This field is
                            required.</div>
                    </div>
                </div>
            </form> -->
    </div>
    <div class="line"></div>
    <div class="dynamic-table-wrapper">
      <ng-container *ngIf="itemsData.length === 0">
        <div
          style="
            margin: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          No table data
        </div>
      </ng-container>
      <div class="table-wrapper" *ngIf="itemsData.length > 0">
        <form>
          <div class="table">
            <div class="tr thead">
              <div class="th">#</div>
              <div class="th" *ngFor="let key of itemKeys">
                {{ itemKeysToDisplayName[key] || key }}
              </div>
            </div>
            <ng-container>
              <div class="tr" *ngFor="let item of itemsData; let i = index">
                <div class="td">{{ i + 1 }}</div>
                <div class="td" *ngFor="let key of itemKeys">
                  <div
                    class="editable-div"
                    [ngClass]="{ editing: isEditable }"
                    [contentEditable]="isEditable"
                    [innerText]="item[key]?.value || ''"
                    (blur)="changeValue($event, key, i)"></div>
                </div>
                <div class="td float left drag" *ngIf="isEditable">
                  <i class="ti ti-grip-horizontal"></i>
                </div>
                <div
                  class="td float right trash"
                  *ngIf="isEditable"
                  (click)="deleteLineItem(i)">
                  <i class="ti ti-trash"></i>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- <table>
                        <thead>
                            <tr style="border-bottom: solid 1.5px black;position: sticky;top: 0;background: white;">
                                <th *ngFor="let key of itemKeys">{{ key }}</th>
                            </tr>
                            <tr>
                            </tr>
                        </thead>
                        <tbody formArrayName="itemList">
                            <tr *ngFor="let item of itemsData; let i = index;">
                                <td *ngFor="let key of itemKeys">
                                    <div [attr.contenteditable]="isEditable ? 'true' : null"
                                        (input)="onCellEdit($event, i, key)" [innerText]="item[key].value||''"
                                        [ngClass]="{'editable-div':isEditable,'iseditable-div':!isEditable}"></div>
                                </td>
                                <td (click)="deleteItemFormGroup(item,i)" *ngIf="isEditable" class="delete-row-icon"
                                    [ngStyle]="{'cursor':isEditable?'':'not-allowed'}">
                                    <i class="ti ti-trash"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
        </form>
      </div>
    </div>
    <div class="line"></div>
    <div class="table-add-row" (click)="addNewRow()" *ngIf="isEditable">
      <i class="ti ti-plus"></i>
      <span class="add-row-button">Add new row</span>
    </div>

    <div class="summary-box">
      <div class="placeholder"></div>
      <div class="summary">
        <div class="summary-head">
          <span>Invoice Summary</span>
        </div>
        <div class="summary-wrapper">
          <div class="summary-inputs">
            <label>Sub Total</label>
            <div
              class="editable-div"
              [ngClass]="{ editing: isEditable }"
              [contentEditable]="isEditable"
              [innerText]="
                removeNewLinesFromString(editableData['SubTotal']?.['value'])
              "
              (blur)="changeValue($event, 'SubTotal')"></div>
            <ng-container
              *ngIf="editableData && checkGstKeysPresent(editableData)">
              <ng-container
                *ngFor="let key of getGstKeysWithValues(editableData)">
                <label>{{ getLabelForGstKey(key) }}</label>
                <div
                  class="editable-div"
                  [ngClass]="{ editing: isEditable }"
                  [contentEditable]="isEditable"
                  [innerText]="
                    removeNewLinesFromString(editableData[key]?.['value'])
                  "
                  (blur)="changeValue($event, key)"></div>
              </ng-container>
            </ng-container>
            <!-- <input type="text" formControlName="SubTotal" [ngClass]="{'disable-formgroup':!isEditable}"
                            [placeholder]="emptyPlaceholder"> -->
            <label>Tax</label>
            <div
              class="editable-div"
              [ngClass]="{ editing: isEditable }"
              [contentEditable]="isEditable"
              [innerText]="
                removeNewLinesFromString(editableData['TotalTax']?.['value'])
              "
              (blur)="changeValue($event, 'TotalTax')"></div>
            <!-- <input type="text" formControlName="TotalTax" [ngClass]="{'disable-formgroup':!isEditable}"
                            [placeholder]="emptyPlaceholder"> -->
            <label>Discount</label>
            <div
              class="editable-div"
              [ngClass]="{ editing: isEditable }"
              [contentEditable]="isEditable"
              [innerText]="
                removeNewLinesFromString(editableData['Discount']?.['value'])
              "
              (blur)="changeValue($event, 'Discount')"></div>
            <!-- <input type="text" formControlName="discount" [ngClass]="{'disable-formgroup':!isEditable}"
                            [placeholder]="emptyPlaceholder"> -->
            <label>Total</label>
            <div
              class="editable-div total"
              [ngClass]="{ editing: isEditable }"
              [contentEditable]="isEditable"
              [innerText]="
                removeNewLinesFromString(
                  editableData['InvoiceTotal']?.['value']
                )
              "
              (blur)="changeValue($event, 'InvoiceTotal')"></div>
            <!-- <input type="text" formControlName="InvoiceTotal" [placeholder]="emptyPlaceholder"
                            style="background-color: #FA5F00;color: white;border: none;"> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div style="border: 1px solid #c6c6c6;margin: 20px 0px;"></div> -->
    <!-- <div class="dynamic-table-wrapper">
            <div class="table-wrapper">
                <form [formGroup]="dynamicTableFormGroup">
                    <div *ngFor="let table of tableData;let i=index">
                        <table>
                            <thead>
                                <tr style="border-bottom: solid 1.5px black;">
                                    <th>
                                        #
                                    </th>
                                    <th *ngFor="let t of generateTableHeaders(table);let i=index">
                                        {{t}}
                                    </th>
                                </tr>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>

            </div>
        </div> -->
  </div>
  <div class="title" style="margin-top: 20px">
    <img
      src="assets/images/Logo_orange.png"
      alt=""
      style="visibility: hidden" />
  </div>
</div>

<div class="wrapper">
  <form [formGroup]="migrationForm">
    <div class="box">
      <div
        class="box-header"
        [ngClass]="{ active: showMigrationDetails }"
        (click)="showMigration()">
        Migration Details
        <i class="ti ti-chevron-up" *ngIf="showMigrationDetails"></i>
        <i class="ti ti-chevron-down" *ngIf="!showMigrationDetails"></i>
      </div>
      <div class="box-content" *ngIf="showMigrationDetails">
        <div class="left-box-content">
          <div class="input-wrapper">
            <div class="input-label">
              Source File Selection
              <span *ngIf="isRequired('sourceConnection')" class="required-star"
                >*</span
              >
            </div>
            <div
              class="placeholder-Div"
              (click)="!disableSoureFile ? fileInput.click() : null"
              (dragleave)="onDragLeave($event)"
              (dragover)="onDragOver($event)"
              (drop)="fileDrop($event)"
              name="migrationSourceFile"
              [title]="
                disableSoureFile
                  ? 'Action disabled due to the selected source connection!'
                  : ''
              "
              [ngClass]="{
                isActive: isDragging,
                inActive: disableSoureFile
              }">
              <p class="desc">Drop source file to migrate or</p>
              <div class="button-wrapper new-folder-pipeline">
                <button
                  name="migrationSourceFile"
                  class="button-common addButton"
                  [disabled]="disableSoureFile">
                  <i class="ti ti-file-upload"></i>
                  Choose File
                </button>
                <input
                  #fileInput
                  type="file"
                  name="migrationSourceFile"
                  accept=".zip"
                  (change)="onFileSelected($event)"
                  [disabled]="disableSoureFile"
                  style="display: none" />
              </div>
            </div>
            <div class="SourceFileName" *ngIf="migrationSourceFile">
              <div>
                <i class="ti ti-file-zip" *ngIf="migrationSourceFile?.name"></i>
                {{ migrationSourceFile?.name }}
              </div>
              <i
                class="flip-icons flip-x removeFile"
                *ngIf="migrationSourceFile?.name"
                (click)="handleRemoveSourceFile()"></i>
            </div>
          </div>
          <div class="or-text">Or</div>
          <ng-template #connectionField let-field>
            <div class="input-label" style="margin-top: 6px">
              {{ field.label
              }}<ng-container *ngIf="field.isRequired"
                ><span class="required-star">*</span></ng-container
              >:
            </div>
            <div class="input-group">
              <ng-container *ngTemplateOutlet="field.formField"></ng-container>
            </div>
          </ng-template>
          <div class="input-wrapper">
            <div for="sourceConnection" class="input-label">
              Source Connection
              <span *ngIf="isRequired('sourceConnection')" class="required-star"
                >*</span
              >
            </div>
            <select
              name="sourceConnection"
              formControlName="sourceConnection"
              (change)="emitUpdateStatus(); getAdditionalForm('source')"
              [title]="
                migrationSourceFile?.name
                  ? 'Action disabled due to the selected source file!'
                  : ''
              ">
              <option [ngValue]="null">No option</option>
              <option
                *ngFor="let connection of connectionsOf('source')"
                [ngValue]="connection?.['id']">
                {{ connection?.connectionName }}
              </option>
            </select>
            <app-dynamic-form
              *ngIf="sourceConnectionAdditionalForm"
              [form]="sourceConnectionAdditionalForm"
              prefixNewControlNames="source"
              [formGroup]="migrationForm"
              (formChanged)="emitTemplateValues()"
              [existingValues]="additionalFormDetails"
              [fieldTemplate]="connectionField"></app-dynamic-form>
          </div>
          <div class="input-wrapper">
            <div for="targetConnection" class="input-label">
              Target Connection
              <span *ngIf="isRequired('targetConnection')" class="required-star"
                >*</span
              >
            </div>
            <select
              name="targetConnection"
              formControlName="targetConnection"
              [ngStyle]="{
                border:
                  migrationForm.get('targetConnection')?.invalid &&
                  migrationForm.get('targetConnection')?.touched
                    ? 'solid 1px red'
                    : ''
              }"
              (change)="emitUpdateStatus(); getAdditionalForm('target')">
              <option [ngValue]="null">No option</option>
              <option
                *ngFor="let connection of connectionsOf('target')"
                [ngValue]="connection?.['id']">
                {{ connection?.connectionName }}
              </option>
            </select>
            <app-dynamic-form
              *ngIf="targetConnectionAdditionalForm"
              [form]="targetConnectionAdditionalForm"
              prefixNewControlNames="target"
              [formGroup]="migrationForm"
              (formChanged)="emitTemplateValues()"
              [existingValues]="additionalFormDetails"
              [fieldTemplate]="connectionField"></app-dynamic-form>
          </div>
        </div>
        <div class="right-box-content" *ngIf="migrationDetails">
          <div class="box-details">
            <div class="box-icons">
              <img
                [src]="
                  sanitizeImageUrl(migrationDetails?.sourceFileImage) ||
                  'assets/images/ai_no_icon.svg'
                "
                alt="icon" />
              <i class="ti ti-arrow-narrow-right"></i>
              <img
                [src]="
                  sanitizeImageUrl(migrationDetails?.targetFileImage) ||
                  'assets/images/ai_no_icon.svg'
                "
                alt="icon" />
            </div>
            <div class="box-title">{{ migrationDetails?.name }}</div>
            <div class="box-desc">
              {{ migrationDetails?.description || "No Description" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isFormGroupEmpty(connectionParametersForm)">
      <div class="box">
        <div
          class="box-header"
          [ngClass]="{ active: showTargetDetails }"
          (click)="showTarget()">
          Additional Details
          <i class="ti ti-chevron-up" *ngIf="showTargetDetails"></i>
          <i class="ti ti-chevron-down" *ngIf="!showTargetDetails"></i>
        </div>
        <div class="box-content" *ngIf="showTargetDetails">
          <div class="input-wrapper">
            <div class="input-group">
              <app-dynamic-form
                [formGroup]="connectionParametersForm"
                [form]="formDetails"
                (formChanged)="onDynamicFormChanged()"
                [showErrors]="false"></app-dynamic-form>
              <!-- (formErrors)="handleFormErrors($event)" -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="box">
      <div
        class="box-header"
        [ngClass]="{ active: showEmailDetails }"
        (click)="showEmail()">
        EMail Details
        <i class="ti ti-chevron-up" *ngIf="showEmailDetails"></i>
        <i class="ti ti-chevron-down" *ngIf="!showEmailDetails"></i>
      </div>
      <div class="box-content" *ngIf="showEmailDetails">
        <div>
          <div class="input-wrapper">
            <div for="email" class="input-label">E-mail Recipient</div>
            <input
              name="email"
              formControlName="email"
              type="text"
              (input)="emitUpdateStatus()" />
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </form>
</div>

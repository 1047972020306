<div
  (click)="$event.stopPropagation()"
  [ngClass]="{ filterBySelected: showFilter, filterBy: !showFilter }"
  style="border: none">
  <div
    [ngClass]="{ filterBySelected: filterApplied, filterBy: !filterApplied }"
    (click)="showHideFilterIp()">
    <ng-container *ngIf="!filterApplied">
      <img
        src="assets/Svgs_/Monitor/Filter.svg"
        alt=""
        height="16px"
        width="16px" />
    </ng-container>
    <ng-container *ngIf="filterApplied">
      <img
        src="assets/images/settings/filterSelected.svg"
        alt=""
        height="16px"
        width="16px" />
    </ng-container>
    <p [ngStyle]="{ color: filterApplied ? '#F36F21' : '' }">Filter</p>
  </div>
  <ng-container *ngIf="showFilter">
    <img src="assets/images/settings/triangle.svg" class="triangle" />
    <div class="filterBox">
      <div class="filterSection">
        <div class="heading">
          <div style="display: flex; align-items: center">
            <p>Filter</p>
            <!-- <ng-container *ngIf="highlightSelectedType().length > 0">
              <div class="tooltip-common">
                <div class="selectedOptions">
                  (
                  <ng-container *ngFor="let option of highlightSelectedType()">
                    <span> {{ option }}</span>
                  </ng-container>
                  )
                </div>
                <span class="tooltip-utils center helperTool-tip">{{
                  selectedTooltip()
                }}</span>
              </div>
            </ng-container> -->
          </div>
          <div class="tooltip-common">
            <img
              src="assets/images/settings/close.svg"
              (click)="showHideFilterIp()" />
            <span class="tooltip-utils right">Close</span>
          </div>
        </div>
        <div class="search">
          <div class="searchText">
            <h1>Search by Keywords</h1>
          </div>
          <div class="global-search-content filter-search">
            <img src="assets/Svgs_/Monitor/Search.svg" />
            <input
              type="search"
              class="search"
              placeholder="Search here"
              style="font-size: 12px; font-weight: 400"
              [(ngModel)]="filterSearch"
              (input)="changeFilterSearch($event)" />
          </div>
        </div>
        <div class="filterOptions">
          <div
            *ngFor="
              let filterOption of filterArray;
              let i = index;
              trackBy: trackByFn
            ">
            <div
              [ngClass]="{
                accordionOpened: filterOption.opened,
                filterAccordion: !filterOption.opened
              }"
              class="filterAccordion"
              (click)="openAccordion(i)">
              <span [ngClass]="{ 'dot-selected': isHighlighted(filterOption) }">
                {{ filterOption.name }}
              </span>
              <i
                class="flip-icons"
                [ngClass]="{
                  'flip-chevron-down': !filterOption.opened,
                  'flip-chevron-up': filterOption.opened,
                  highlightedChevron: isHighlighted(filterOption)
                }"></i>
            </div>
            <div *ngIf="filterOption.opened" class="accordionData">
              <div *ngIf="filterOption.data.length > 0; else noResults">
                <ng-container
                  *ngFor="let data of filterOption.data; let j = index">
                  <div class="data" (click)="filterCheckbox(data.name, j)">
                    <div
                      class="checkboxIcon"
                      *ngIf="filterOption.name !== 'Date'">
                      <img
                        *ngIf="data.selected"
                        src="assets/Svgs_/Monitor/Checkbox-Select.svg" />
                      <img
                        *ngIf="!data.selected"
                        src="assets/Svgs_/Monitor/CheckBox-UnSelected.svg" />
                    </div>
                    <div
                      *ngIf="filterOption.name === 'Date'"
                      class="radioButtonGroup">
                      <input
                        type="radio"
                        [checked]="data.selected"
                        (input)="radioButtonsClicked(filterOption.data, j)" />
                    </div>
                    <p>
                      {{ data.name }}
                    </p>
                  </div>
                </ng-container>
                <div *ngIf="filterOption.id === 'date'" class="customDateGroup">
                  <div *ngIf="customRangeSelected" class="customDate">
                    <label>Start Date</label>
                    <input
                      type="date"
                      [ngModel]="startDate"
                      (input)="dateInputsChanged('start', $event)" />
                  </div>
                  <div *ngIf="customRangeSelected" class="customDate">
                    <label>End Date</label>
                    <input
                      type="date"
                      [ngModel]="endDate"
                      (input)="dateInputsChanged('end', $event)" />
                  </div>
                </div>
              </div>
              <ng-template #noResults>
                <p>No search results</p>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="submitSection">
        <button class="button-common secondary-btn" (click)="resetFiltering()">
          Reset
        </button>
        <button
          class="button-common primary-btn"
          (click)="applyFiltering()"
          [disabled]="!isAnyFilterSelected()">
          Apply
        </button>
      </div>
    </div>
  </ng-container>
</div>

import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { UnsavedChangesGuard } from "./shared/guards/unsaved-changes.guard";
import { AuthGuardService } from "./core/services/auth.service";
import { InterceptorService } from "./core/services/interceptor.service";
import { ConfirmPopupComponent } from "./shared/components/confirm-popup/confirm-popup.component";
import { ConfirmPopupService } from "./shared/components/confirm-popup/confirmpopup.service";
import { PopupModalComponent } from "./shared/components/popup-modal/popup-modal.component";
import { SortObjectByPipe } from "./shared/pipes/sort-object-by.pipe";
import { ModelCardModule } from "./features/ai-workbench/components/model-card/model-card.module";
import { SharedModule } from "./shared/shared.module";
import { AuthModule } from "./features/auth/auth.module";
@NgModule({
  declarations: [AppComponent, PopupModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModelCardModule,
    SharedModule,
    AuthModule,
  ],
  exports: [SharedModule],
  providers: [
    UnsavedChangesGuard,
    SortObjectByPipe,
    ConfirmPopupComponent,
    ConfirmPopupService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    AuthGuardService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

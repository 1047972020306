import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dsableRemoveDeptTag'
})
export class DsableRemoveDeptTagPipe implements PipeTransform {

  transform(value: String, departmentList: any[], selectedTagObject: any): unknown {
    if(value && departmentList && departmentList.length){
      if(selectedTagObject && selectedTagObject.length && departmentList.filter(f => selectedTagObject.includes(f.name)).length > 1){
        return true;
      }
      return !(departmentList.filter(f => f.name == value).length);
    }
    return true;
  }

}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AppService } from "./core/services/app.service";
import { environment } from "../environments/environment";
import { Title } from "@angular/platform-browser";
import { filter, takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { SessionService } from "./core/services/session.service";
import { LoaderService } from "./core/services/loader.service";
import { TokenStorageService } from "./core/services/token.service";
// import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy, OnInit {
  public title: any;
  public headerTitle: any;
  public menus: any = [];
  public profileName: any;
  public loader: boolean = false;
  public isLoggedIn: boolean = false;
  public loginSub: any;
  public assetsUrl: any;
  // public sideBarToggle: boolean = false;

  public departmentList: any[] = [];
  public selectedDept;
  public profile: any = {};
  public profileLoaded = false;
  public params: any;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private logoutSubcription?: Subscription;
  constructor(
    public router: Router,
    public loaderService: LoaderService,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public sessionService: SessionService,
    private tokenService: TokenStorageService
    // private _snackBar: MatSnackBar
  ) {
    router.events.subscribe((val) => {
      if (val && val instanceof NavigationEnd) {
        const routes = {
          "/home": "Home",
          "/dashboard": "Dashboard",
          "/pipelines": "Pipelines",
          "/monitor/viewer": "Viewer",
          "/monitor": "Monitor",
          "/settings": "Settings",
          "/user-profile": "User Profile",
          "/change-password": "Change Password",
          "/manage-users": "Admin Console",
          "/template-modelling": "Model Studio",
          "/ai-workbench": "AI Workbench",
          "/migration-workbench": "Migration Workbench",
        };
        const route = Object.keys(routes).find((key) => val.url.includes(key));
        if (route) {
          this.headerTitle = routes[route];
        } else {
          this.headerTitle = "HOME";
        }
      }
    });
    this.appService.currentUser.subscribe((response) => {
      if (response) {
        this.profileName = response["username"];
        this.isLoggedIn = true;
        this.setSidebar();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.logoutSubcription?.unsubscribe();
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.params = null;
      this.params = queryParams;
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        var rt = this.getChild(this.activatedRoute);
        if (!event.url.includes("pipelines")) {
          sessionStorage.removeItem("currentFolderName");
        }
        rt.data.subscribe((data) => {
          this.titleService.setTitle(data.title);
        });
      });
    const currentUser = this.appService.getCurrentUser();
    let user: any = localStorage.getItem("currentUser");
    if (currentUser["username"] || (user && JSON.parse(user)["username"])) {
      this.appService.setCurrentUser({
        username: JSON.parse(user)["username"],
      });
      if (this.router.url.includes("/login") && this.params["redirectUrl"]) {
        window.open(this.params["redirectUrl"], "_self");
      } else {
        this.profileName = user["username"] || JSON.parse(user)["username"];
        this.isLoggedIn = true;
      }
    } else {
      this.isLoggedIn = false;
      if (
        !window.location.href.includes("completeUserRegistration") &&
        !window.location.href.includes("login")
      ) {
        let path = window.location.hash;
        if (path) {
          this.router.navigateByUrl("login?redirectUrl=" + path);
        } else {
          this.router.navigate(["login"]);
        }
      }
    }

    if (!localStorage.getItem("token")) {
      localStorage.removeItem("currentUser");
      this.isLoggedIn = false;
      this.appService.clearCurrentUser();
      if (
        !window.location.href.includes("completeUserRegistration") &&
        !window.location.href.includes("login")
      ) {
        let path = window.location.hash;
        if (path) {
          this.router.navigateByUrl("login?redirectUrl=" + path);
        } else {
          this.router.navigate(["login"]);
        }
      }
    }
    this.assetsUrl = environment["assetsUrl"];
    this.setSidebar();
    this.watchUserData();

    user = JSON.parse(user || "{}");

    // this.sessionService.isExpired.subscribe(res => {
    //   if (res) {
    //     const token = user['refresh_token'];
    //     if(token){
    //       if(!this.appService.isFetchingNewSessionToken){
    //         this.appService.isFetchingNewSessionToken = true;
    //         this.appService.refreshSessionToken({refresh_token: token}).subscribe({next: res => {
    //           if(res['token']){
    //             this.jwtService.setToken(res['token']);
    //             this._snackBar.open("Request failed! Please try again or refresh the page", "Ok", {duration: 3500});
    //           }
    //           this.appService.isFetchingNewSessionToken = false;
    //         },
    //         error: (error) => {
    //           this.appService.isSessionExpired = res;
    //           this.appService.isFetchingNewSessionToken = false;
    //         }
    //         });
    //       }
    //     }else{
    //       this.appService.isSessionExpired = res;
    //       this.appService.isFetchingNewSessionToken = false;
    //     }
    //   }
    // })

    window.addEventListener("storage", () => {
      window.location.reload();
    });

    this.logoutSubcription = this.appService.logoutUser$.subscribe((res) => {
      if (res) {
        this.logoutAndLogin();
      }
    });
  }
  changeSideBar() {
    this.appService.sideBarToggle = !this.appService.sideBarToggle;
  }
  getUserInfo() {
    this.appService
      .getUserDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          if (res && res["loginStatus"]) {
            let user = {
              username: res["username"],
            };
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.appService.setCurrentUser(user);

            this.getDepartmentsForUser();
          } else {
            // window.location.replace(`${environment.apiUrl}/api/`)
          }
        },
        error: (err) => {
          // window.location.replace(`${environment.apiUrl}/api/`)
        },
      });
  }

  getDepartmentsForUser() {
    this.appService
      .getDepartments()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          if (res) {
            if (res["departments"] && res["departments"].length == 2) {
              this.departmentList = res["departments"].filter(
                (f) => f.name !== "ALL"
              );
              this.selectedDept = this.departmentList[0].id;
              this.appService.setcurrentDepartment({
                selected: this.selectedDept,
                departmentList: this.departmentList,
              });
            } else {
              this.departmentList = res["departments"];
              const all = this.departmentList
                .filter((f) => f.name === "ALL")
                .map((m) => m.id);
              if (all && all.length) {
                this.selectedDept = all[0];
                this.appService.setcurrentDepartment({
                  selected: this.selectedDept,
                  departmentList: this.departmentList,
                });
              }
            }
          }
        },
        error: (err) => {
          if (err.status !== 403) {
            let data = {
              duration: 3000,
              type: "error",
              message: err["error"]?.["message"] || err["message"] || err,
            };
            this.appService.showToast(data);
          }
        },
      });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  watchUserData() {
    this.appService.getProfileInfo().subscribe({
      next: (res) => {
        this.profile = res;
        this.profileLoaded = true;
        this.setSidebar();
      },
      error: (err) => {},
    });
  }

  setSidebar() {
    this.title = "Dashboard";
    this.menus = {
      top: [
        {
          key: "toggle",
          value: "toggle",
          name: "Toggle",
          icon: `${this.assetsUrl}/assets/Svgs_/SideBar/MenuToggle.svg`,
          activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveMenuToggle.svg`,
        },
        ...(this.appService.canUserNavigate("")
          ? [
              {
                key: "logo",
                value: "Home",
                name: "Home",
                icon: `${this.assetsUrl}/assets/Svgs_/SideBar/Home.svg`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveHome.svg`,
              },
            ]
          : []),
        ...(this.appService.canUserNavigate("dashboard")
          ? [
              {
                key: "dashboard",
                value: "Dashboard",
                name: "FLIP Dashboard",
                icon: `${this.assetsUrl}/assets/Svgs_/SideBar/Transformtion.svg`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveTransform.svg`,
              },
            ]
          : []),
        ...(this.appService.canUserNavigate("designer")
          ? [
              {
                key: "designer",
                value: "Pipelines",
                name: "Pipelines",
                icon: `${this.assetsUrl}/assets/Svgs_/Transfermation.svg`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/TransformationStudioActive.svg`,
              },
            ]
          : []),
        ...(this.appService.canUserNavigate("monitor")
          ? [
              {
                key: "monitor",
                value: "Monitor",
                name: "FLIP Monitor",
                icon: `${this.assetsUrl}/assets/Svgs_/SideBar/Monitor.svg`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveMonitor.svg`,
              },
            ]
          : []),
        ...(this.appService.canUserNavigate("manage-users")
          ? [
              {
                key: "manage-users",
                value: "Admin Console",
                name: "FLIP Admin Console",
                icon: `${this.assetsUrl}/assets/Svgs_/SideBar/ManageUser.svg`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveManageUser.svg`,
              },
            ]
          : []),
        ...(this.appService.canUserNavigate("ai-workbench")
          ? [
              {
                key: "ai-workbench",
                value: "AI Workbench",
                name: `FLIP AI Workbench`,
                icon: `${this.assetsUrl}/assets/Svgs_/SideBar/Aiworkbench.svg`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveAiWorkbench.svg`,
              },
            ]
          : []),
        ...(this.appService.canUserNavigate("")
          ? [
              {
                key: "phi",
                value: "Phi",
                name: "Phi",
                icon: `${this.assetsUrl}/assets/phi/phi-logo.png`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveHome.svg`,
                newTabLink: `${environment.apiUrl}/phi/#/dashboard/reports`,
              },
            ]
          : []),
        ...(this.appService.canUserNavigate("settings")
          ? [
              {
                key: "settings",
                value: "Settings",
                name: "FLIP Settings",
                icon: `${this.assetsUrl}/assets/Svgs_/SideBar/Setting.svg`,
                activeIcon: `${this.assetsUrl}/assets/Svgs_/SideBar/ActiveSetting.svg`,
              },
            ]
          : []),
      ],
      bottom: [
        {
          key: "help",
          value: "Help",
          name: "Help",
          icon: `${this.assetsUrl}/assets/Svgs_/SideBar/Helper.svg`,
        },
        // {
        //   key: 'user',
        //   value: 'User',
        //   name: 'User',
        //   icon: this.profileName
        //     ?.split(' ')
        //     .map((n) => n[0])
        //     .join(''),
        //   submenus: [
        //     { key: 'profile', name: 'Profile' },
        //     { key: 'changePassword', name: 'Change Password' },
        //     { key: 'logout', name: 'Logout' },
        //   ],
        // },
      ],
    };
  }

  sideMenuAction(menuInfo) {
    if (menuInfo["action"] == "mainMenu") {
      this.navigateToApp(menuInfo["menu"]);
    } else {
      this.performSubmenuAction(menuInfo["menu"]);
    }
  }

  navigateToApp(menu: any) {
    this.headerTitle = menu["name"];
    if (menu["key"] == "logo") {
      this.router.navigate(["home"]);
    } else if (menu["key"] == "dashboard") {
      this.router.navigate(["dashboard"]);
    } else if (menu["key"] == "designer") {
      this.router.navigate(["pipelines"]);
    } else if (menu["key"] == "monitor") {
      this.router.navigate(["monitor"]);
    } else if (menu["key"] == "settings") {
      this.router.navigate(["settings"]);
    } else if (menu["key"] == "manage-users") {
      this.router.navigate(["manage-users"]);
    } else if (menu["key"] == "ai-workbench") {
      this.router.navigate(["ai-workbench"]);
    } else if (menu["key"] == "migration workbench") {
      this.router.navigate(["migration-workbench"]);
    }
    if (this.appService.isMobileView()) {
      this.appService.sideBarToggle = false;
    }
  }

  performSubmenuAction(menu: any) {
    if (menu["key"] == "logout") {
      this.logoutAndLogin();
      this.appService.sideBarToggle = false;
    } else if (menu["key"] === "profile") {
      this.router.navigate(["user-profile"]);
    } else if (menu["key"] == "changePassword") {
      this.router.navigate(["change-password"]);
    }
    if (this.appService.isMobileView()) {
      this.appService.sideBarToggle = false;
    }
  }

  logoutAndLogin() {
    this.appService.logoutUser().subscribe({
      next: (res) => {
        this.clearUserData();
      },
      error: (err) => {
        this.clearUserData();
      },
    });
  }

  clearUserData() {
    localStorage.removeItem("currentUser");
    this.isLoggedIn = false;
    this.appService.clearCurrentUser();
    this.router.navigate(["login"]);
    this.sessionService.isExpired.next(false);
    sessionStorage.clear();
    let templateTours = localStorage.getItem("showFirstTimeTemplateTours");
    localStorage.clear();
    if (templateTours) {
      localStorage.setItem("showFirstTimeTemplateTours", templateTours);
    }
  }

  canShowHeader() {
    const titles = [
      "Pipelines",
      "Settings",
      "Monitor",
      "Dashboard",
      "Admin Console",
      "User Profile",
      "Change Password",
      "Model Studio",
      "AI Workbench",
      "Migration Workbench",
    ];
    return (
      titles.includes(this.headerTitle) &&
      !this.router.url.includes("/pipelines/manage")
    );
  }
}

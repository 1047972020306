import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  UserRegistrationData,
  AdminRegistrationData,
} from "../models/auth-info.model";
import { LoginInfo, UsernameOnly } from "../models/login-info.model";
import { ChangePasswordInfo } from "../models/change-password-info.model";
import { ResetFormInfo } from "../models/reset-form-info.model";
import { UserCallbackInfo } from "../models/user-call-back-info.model";
@Injectable({
  providedIn: "root",
})
export class AuthPageService {
  constructor(private httpClient: HttpClient) {}
  public termsOfServiceLink = "https://flipnow.cloud/legal/tnc.pdf";
  public login(data: LoginInfo) {
    return this.httpClient.post(`${environment.apiUrl}/api/login`, data).pipe(
      map((response) => {
        return response;
      })
    );
  }
  public register(data: AdminRegistrationData | UserRegistrationData) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/create`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public sendRequestNewLink(data: UsernameOnly) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/resendEmail`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public validateRegistration(data: { register_id: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/validateRegistration`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public resetPassword(data: ResetFormInfo) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/resetPassword`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public userValidateResetPassword(data: { confirmation_id: string }) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/validateResetPassword`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public changePassword(data: ChangePasswordInfo) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/changePassword`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public forgotPassword(data: UserCallbackInfo) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/forgotPassword`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public setPasswordUpdate(data: LoginInfo) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/completeUserRegistration`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getSetPasswordData(token: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/user/verifyUserToken/${token}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}

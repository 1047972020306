import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { AppService } from "src/app/core/services/app.service";
import { environment } from "src/environments/environment";
import { AuthGuardService } from "../../../../core/services/auth.service";
import { AuthPageService } from "../../services/auth-page.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  formGroup = new FormGroup({});
  registerError = "";
  isNotSamePasswords = false;
  isPasswordsVisible = [false, false];
  isRegisteredSuccess = false;
  public url: string = "";
  public lottieOptions: AnimationOptions = {
    path: "assets/lottie/login.json",
  };
  public termsOfServiceLink = `${environment.apiUrl}/legal/tnc.pdf`;
  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private router: Router,
    public authPageService: AuthPageService
  ) {}

  ngOnInit(): void {
    let url = `${window.location.protocol}//${window.location.host}/#/validate-user`;
    this.url = url;
    let currentUser: any = this.appService.getCurrentUser();
    if (
      currentUser?.username ||
      localStorage.getItem("currentUser")?.includes("username")
    ) {
      this.router.navigate(["home"]);
      return;
    }
    this.formGroup = this.fb.group({
      username: ["", [Validators.required, Validators.email]],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
          ),
        ]),
      ],
      confirmPassword: ["", Validators.required],
      tenant: ["", Validators.required],
    });
  }

  onSubmit() {
    if (this.formGroup.pristine || this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.formGroup.invalid || this.isNotSamePasswords) {
      return;
    }

    let registerInfo = JSON.parse(JSON.stringify(this.formGroup.value));
    registerInfo["registrationUrl"] = this.url;
    delete registerInfo["confirmPassword"];
    registerInfo["departmentNames"] = [];
    if (!this.isRegisteredSuccess) {
      this.authPageService.register(registerInfo).subscribe({
        next: (response) => {
          if (response) {
            this.isRegisteredSuccess = true;
          }
        },
        error: (err) => {
          this.registerError = err;
          this.isRegisteredSuccess = false;
        },
      });
    }
  }

  get username() {
    return this.formGroup.get("username");
  }

  get firstName() {
    return this.formGroup.get("firstName");
  }

  get lastName() {
    return this.formGroup.get("lastName");
  }

  get password() {
    return this.formGroup.get("password");
  }

  get confirmPassword() {
    return this.formGroup.get("confirmPassword");
  }
  get tenant() {
    return this.formGroup.get("tenant");
  }

  onPasswordChange(event: any) {
    this.isNotSamePasswords =
      this.password?.value &&
      this.password.value != this.confirmPassword?.value;
  }

  togglePasswordVisibility(index: number) {
    this.isPasswordsVisible[index] = !this.isPasswordsVisible[index];
  }
}

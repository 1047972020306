import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppService } from "src/app/core/services/app.service";
import { Router } from "@angular/router";
import { AuthPageService } from "../../services/auth-page.service";
import { ToastService } from "src/app/shared/services/toast.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent {
  areAllPasswordsVisible = false;
  isPasswordVisible = {
    old_password: false,
    new_password: false,
    confirm_new_password: false,
  };
  error = "";
  checkbox = false;
  showPassword = false;
  isPasswords = false;
  isNotSamePasswords = false;

  public changePasswordForm = new FormGroup({
    old_password: new FormControl("", [Validators.required]),
    new_password: new FormControl("", [
      Validators.required,
      Validators.pattern(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&\.]{8,16}$/
      ),
    ]),
    confirm_new_password: new FormControl("", [Validators.required]),
  });

  constructor(
    private appService: AppService,
    private router: Router,
    private authPageService: AuthPageService,
    private toastService: ToastService
  ) {}

  toggleAllPasswordsVisibility(event: any) {
    for (let key of Object.keys(this.isPasswordVisible)) {
      this.isPasswordVisible[key] = event.target.checked;
    }
  }

  togglePasswordVisibility(field: string) {
    this.isPasswordVisible[field] = !this.isPasswordVisible[field];
  }

  changePassword() {
    if (this.isNotSamePasswords || this.isPasswords) {
      return;
    }
    let storedUser = localStorage.getItem("currentUser");
    let userId = null;
    if (storedUser) {
      let currentUser = JSON.parse(storedUser);
      userId = currentUser.userId;
    }

    let changePasswordInfo = {
      oldPassword: this.changePasswordForm.value.old_password,
      newPassword: this.changePasswordForm.value.new_password,
      userId: userId,
    };

    this.authPageService.changePassword(changePasswordInfo).subscribe({
      next: (res) => {
        if (res) {
          this.toastService.commonToastMethod(res["message"], "success");
          // this.router.navigate(["/login"]);
        } else {
          this.toastService.commonToastMethod(res["message"], "error");
        }
      },
      error: (err) => {
        if (err.status !== 403) {
          this.toastService.commonToastMethod(
            err["error"]?.["message"] || err["message"] || err,
            "error"
          );
        }
      },
    });
  }

  get old_password() {
    return this.changePasswordForm.get("old_password");
  }

  get new_password() {
    return this.changePasswordForm.get("new_password");
  }
  get confirm_new_password() {
    return this.changePasswordForm.get("confirm_new_password");
  }

  cancel() {
    this.changePasswordForm.reset();
  }

  onPasswordChange(event: any) {
    this.isNotSamePasswords =
      this.new_password?.value !== this.confirm_new_password?.value;
  }

  compareOldNewPasswords() {
    this.isPasswords = this.old_password?.value == this.new_password?.value;
  }
}

<!-- <p>attributes works!</p> -->

<div class="wrapper">
  <ng-container *ngIf="ispdfProcessTypeSelected">
    <div class="box">
      <div
        class="box-header"
        (click)="toggleSectionVisibility(attributeSections.templates)"
        [ngClass]="{ 'box-headerActive': isSectionExpanded.headerSettings }">
        Templates
        <button class="expand-toggler">
          <i
            [class]="
              'ti ' +
              (isSectionExpanded.headerSettings
                ? 'ti-chevron-up'
                : 'ti-chevron-down')
            "></i>
        </button>
      </div>
      <div
        class="box-content"
        *ngIf="isSectionExpanded.templates"
        style="padding: 0 20px">
        <ng-container
          *ngIf="
            attributes['importReadRules']['fileType']?.includes('PDF');
            else notPdf
          ">
          <div class="template-box" #templatebox>
            <div class="img" #my_point_3>
              <img
                [src]="
                  attributes['templateInfo']?.['pdfFileThumbnail']
                    ? 'data:image/png;base64,' +
                      attributes['templateInfo']['pdfFileThumbnail']
                    : 'assets/images/PDF-File.svg'
                "
                alt="PDF Preview" />
            </div>
            <div class="text">Source Template</div>
            <div class="tooltip-common edit">
              <input
                #FileSelectInputDialog
                type="file"
                accept=".pdf"
                style="display: none"
                (change)="isTemplatePdfProcess($event)" />
              <i
                class="ti ti-file-upload"
                *ngIf="isPdfProcessEditMode && ispdfProcessTypeSelected"></i>
              <span
                id="back"
                class="tooltip-utils center"
                *ngIf="isPdfProcessEditMode"
                >Upload</span
              >
              <i
                class="ti ti-pencil"
                (click)="isTemplatePdfProcess()"
                *ngIf="!isPdfProcessEditMode && ispdfProcessTypeSelected"></i>
              <span
                id="back"
                class="tooltip-utils center"
                *ngIf="!isPdfProcessEditMode"
                >Edit</span
              >
            </div>
          </div>
        </ng-container>
        <ng-template #notPdf>
          <div class="no-templates">No Templates</div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <!-- <div class="box">
    <div
      class="box-header"
      (click)="toggleSectionVisibility(attributeSections.headerSettings)"
      [ngClass]="{ 'box-headerActive': isSectionExpanded.headerSettings }">
      Header Settings
      <button class="expand-toggler">
        <i
          [class]="
            'ti ' +
            (isSectionExpanded.headerSettings
              ? 'ti-chevron-up'
              : 'ti-chevron-down')
          "></i>
      </button>
    </div>
    <div class="box-content" *ngIf="isSectionExpanded.headerSettings">
      <div class="box-content-right">
        <div class="input-wrapper">
          <div class="input-label">Pipeline Name</div>
          <input
            type="text"
            maxlength="60"
            [(ngModel)]="attributes['fileHeaders']['importName']"
            (ngModelChange)="emitUpdateStatus()"
            (input)="disableUpdate($event.target, 'header')"
            [ngClass]="{
              'error-border':
                _showErrorInputs && !attributes['fileHeaders']['importName']
            }" />
        </div>
      </div>
    </div>
  </div> -->
  <div class="box">
    <div
      class="box-header"
      (click)="toggleSectionVisibility(attributeSections.fileSettings)"
      [ngClass]="{ 'box-headerActive': isSectionExpanded.fileSettings }">
      File Settings
      <button class="expand-toggler">
        <i
          [class]="
            'ti ' +
            (isSectionExpanded.fileSettings
              ? 'ti-chevron-up'
              : 'ti-chevron-down')
          "></i>
      </button>
    </div>
    <div class="box-content" *ngIf="isSectionExpanded.fileSettings">
      <!-- <div class="box-content-left"> -->
      <!-- <div class="input-label">Standard Format</div> -->
      <!-- <div class="input-label">File Format</div>
        <div class="input-label">File Type</div>
        <div class="input-label">Multiple Sheet</div>
        <div class="input-label">Sheet Names</div>
        <div class="input-label">Delimiter</div>
        <div class="input-label">Target Format</div> -->
      <!-- <div class="input-label">Data File</div> -->
      <!-- <div class="input-label">No Of Lines To Skip</div>
      </div> -->
      <div class="box-content-right">
        <div class="vertical-fill">
          <div class="input-wrapper">
            <div class="input-label">Source File Type</div>
            <select
              [(ngModel)]="
                attributes['importReadRules']['fileAttributes'].fileFormat
              "
              (ngModelChange)="checkToDisplayReadRulesTab(); emitUpdateStatus()"
              [ngClass]="{
                'error-border':
                  _showErrorInputs &&
                  !attributes['importReadRules']['fileAttributes'].fileFormat
              }"
              (change)="disableUpdate($event.target, 'file')">
              <option *ngFor="let type of sourceFileTypes" [value]="type">
                {{ type }}
              </option>
              <!-- <option value="Vector Structured">Vector Structured</option>
              <option value="Vector UnStructured">Vector UnStructured</option> -->
              <!-- <option value="Custom">Custom</option>
              <option value="Data File">Data File</option>
              <option value="Education Certificate/Licenses">
                Education Certificate/Licenses
              </option>
              <option value="Fortegra Bordereau">Fortegra Bordereau</option>
              <option value="Insurance">Insurance</option>
              <option value="Invoice">Invoice</option>
              <option value="Legal">Legal</option>
              <option value="Lloyds Bordereau">Lloyds Bordereau</option>
              <option value="Policies">Policies</option>
              <option value="Purchase Order">Purchase Order</option>
              <option value="Voucher">Voucher</option>
              <option value="Work Order">Work Order</option> -->
            </select>
          </div>
          <div class="input-wrapper">
            <div class="input-label">Source File Format</div>
            <select
              [(ngModel)]="attributes['importReadRules']['fileType']"
              [disabled]="disableFileType"
              (ngModelChange)="checkFileType(); emitUpdateStatus()"
              (change)="disableUpdate($event.target, 'file')"
              [ngClass]="{
                'error-border':
                  _showErrorInputs && !attributes['importReadRules']['fileType']
              }">
              <option
                *ngFor="let fileType of attributes['fileTypes']"
                [value]="fileType">
                {{ fileType }}
              </option>
            </select>
          </div>
          <div class="input-wrapper">
            <div class="input-label">Target Format</div>
            <select
              [(ngModel)]="
                attributes['importReadRules']['fileAttributes'].targetFormat
              "
              (change)="disableUpdate($event.target, 'file')"
              (ngModelChange)="emitUpdateStatus()"
              [ngClass]="{
                'error-border':
                  _showErrorInputs &&
                  !attributes['importReadRules']['fileAttributes'].targetFormat
              }"
              [disabled]="disableFileFormat">
              <option
                *ngFor="let format of attributes['targetFormats']"
                [value]="format">
                {{ format }}
              </option>
            </select>
          </div>
        </div>

        <!-- <div class="input-wrapper">
          <form [formGroup]="dataFileForm" class="radio-group">
            <input type="radio" formControlName="dataFile" value="Y"
              (input)="disableUpdate($event.target,'file','DAT')" /> Yes
            <input type="radio" formControlName="dataFile" value="N"
              (input)="disableUpdate($event.target,'file','!DAT')" />No
          </form>
        </div> -->
        <div class="vertical-fill">
          <div
            class="input-wrapper"
            *ngIf="
              ['XLS', 'XLSX'].includes(
                attributes['importReadRules']['fileType']
              )
            ">
            <div class="input-label">Multiple Sheet</div>
            <form [formGroup]="multipleSheetForm" class="radio-group">
              <input
                type="radio"
                formControlName="multipleSheet"
                [disabled]="disableMultipleSheet"
                (ngModelChange)="checkMultiSheet()"
                [(ngModel)]="
                  attributes['importReadRules']['fileAttributes']
                    .multipleSheetIndicator
                "
                value="Y"
                (click)="checkMultiSheetOption()"
                (input)="disableUpdate($event.target, 'file')" />
              Yes
              <input
                type="radio"
                formControlName="multipleSheet"
                [disabled]="disableMultipleSheet"
                (ngModelChange)="checkMultiSheet()"
                [(ngModel)]="
                  attributes['importReadRules']['fileAttributes']
                    .multipleSheetIndicator
                "
                value="N"
                (click)="
                  checkMultiSheetOption(
                    'N',
                    attributes['importReadRules']['sheetMetadata']['sheetNames']
                  )
                "
                (input)="disableUpdate($event.target, 'file')" />
              No
            </form>
          </div>
          <div
            class="input-wrapper"
            *ngIf="
              ['TXT', 'CSV'].includes(attributes['importReadRules']['fileType'])
            ">
            <div class="input-label">Delimiter</div>
            <select
              [(ngModel)]="
                attributes['importReadRules']['fileAttributes'].fileDelimiter
              "
              [disabled]="disableDelimiter"
              (change)="disableUpdate($event.target, 'file')"
              (ngModelChange)="emitUpdateStatus()"
              [ngClass]="{
                'error-border':
                  !disableDelimiter &&
                  _showErrorInputs &&
                  !attributes['importReadRules']['fileAttributes'].fileDelimiter
              }">
              <option
                *ngFor="let delimiter of attributes['fileDelimiters']"
                [value]="delimiter">
                {{ delimiter }}
              </option>
            </select>
          </div>
          <div
            class="input-wrapper"
            *ngIf="
              ['XLS', 'XLSX'].includes(
                attributes['importReadRules']['fileType']
              ) &&
              attributes['importReadRules']['fileAttributes']
                ?.multipleSheetIndicator === 'Y'
            ">
            <div class="input-label">Sheet Names</div>
            <input
              type="text"
              [disabled]="disableSheetName"
              [(ngModel)]="
                attributes['importReadRules']['sheetMetadata']['sheetNames']
              "
              (input)="disableUpdate($event.target, 'file')"
              (ngModelChange)="emitUpdateStatus()"
              [ngClass]="{
                'error-border':
                  !disableSheetName &&
                  _showErrorInputs &&
                  !attributes['importReadRules']['sheetMetadata']['sheetNames']
              }" />
          </div>
          <div
            class="input-wrapper"
            *ngIf="
              attributes['importReadRules']['fileType'] === 'CSV' ||
              attributes['importReadRules']['fileType'] === 'TXT'
            ">
            <div class="input-label">No Of Lines To Skip (Source)</div>
            <input
              type="number"
              min="0"
              [(ngModel)]="
                attributes['importReadRules']['fileAttributes'].noOfLinesToSkip
              "
              (input)="disableUpdate($event.target, 'file')"
              pattern="^[0-9]*$"
              (keydown)="invalidNoOfLinesInputValue($event)"
              [ngClass]="{
                'error-border':
                  _showErrorInputs &&
                  attributes['importReadRules']['fileAttributes']
                    .noOfLinesToSkip === null
              }" />
          </div>
          <div
            class="input-wrapper"
            *ngIf="
              attributes['importReadRules']['fileType'] === 'PDF/IMAGE' &&
              attributes['importReadRules']['fileAttributes'].fileFormat ===
                'Invoice'
            ">
            <form action="" [formGroup]="pdfProcessDataTypeForm">
              <div class="input-label">Processing Mode</div>
              <select
                (change)="
                  checkpdfProcessModeType($event);
                  disableUpdate($event.target, 'file')
                "
                formControlName="processingMode"
                [ngClass]="{
                  'error-border':
                    _showErrorInputs &&
                    !attributes['importReadRules']['processingMode']
                }"
                (ngModelChange)="emitUpdateStatus()">
                <option value="ai">AI Based</option>
                <option value="template">Template Based</option>
              </select>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box-footer">
      <button (click)="updateimportReadRules()" [disabled]="disableFileSettingsUpdate"
        [ngClass]="{'btn-disable':disableFileSettingsUpdate}">
        Update
      </button>
    </div> -->
  </div>
  <div class="box">
    <div
      class="box-header"
      (click)="toggleSectionVisibility(attributeSections.emailSettings)"
      [ngClass]="{ 'box-headerActive': isSectionExpanded.emailSettings }">
      Email Settings
      <button class="expand-toggler">
        <i
          [class]="
            'ti ' +
            (isSectionExpanded.emailSettings
              ? 'ti-chevron-up'
              : 'ti-chevron-down')
          "></i>
      </button>
    </div>
    <div class="box-content" *ngIf="isSectionExpanded.emailSettings">
      <!-- <div class="box-content-left">
        <div class="input-label">E-mail Recipient</div>
      </div> -->
      <div class="box-content-right">
        <div class="input-wrapper">
          <div class="input-label">E-mail Recipient</div>
          <input
            type="text"
            [(ngModel)]="attributes['emails']['successEmail']"
            (input)="disableUpdate($event.target, 'email')"
            (ngModelChange)="emitUpdateStatus()"
            [ngClass]="{
              'error-border': _showEmailError
            }" />
        </div>
      </div>
    </div>
    <!-- <div class="box-footer">
        <button (click)="updateEmails()" [disabled]="disableEmailSettingsUpdate"
          [ngClass]="{'btn-disable':disableEmailSettingsUpdate}">
          Update
        </button>
      </div> -->
  </div>
</div>

import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { AppService } from "src/app/core/services/app.service";
import { ConfirmPopupService } from "../components/confirm-popup/confirmpopup.service";

export interface ComponentCanDeactivate {
  canDeactivate: boolean;
}

@Injectable()
export class UnsavedChangesGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(
    private appService: AppService,
    private ConfirmPopupService: ConfirmPopupService
  ) {}
  canDeactivate(component: ComponentCanDeactivate): Promise<boolean> | boolean {
    if (component.canDeactivate || this.appService.isSessionExpired) {
      return true;
    } else {
      return new Promise(async (resolve, reject) => {
        this.ConfirmPopupService.confirmMessageEvent.next({
          message:
            "You have unsaved changes. Are you sure you want to continue?",
          headerText: "Confirmation",
          confirmButtonText: "Confirm",
          callback: function (val: boolean) {
            resolve(val);
          },
        });
      });
    }
  }

  confirmFirst() {
    return new Promise((resolve, reject) => {});
  }
}

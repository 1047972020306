import {
  Component,
  OnDestroy,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ImportDesignerService } from "../../../services/import-designer.service";
import { ValidationService } from "../../../services/validation.service";
import { cloneDeep } from "lodash";
import { ComponentCanDeactivate } from "src/app/shared/guards/unsaved-changes.guard";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { AttributesComponent } from "./attributes/attributes.component";
import { takeUntil } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { AppService } from "src/app/core/services/app.service";
import { ConfirmPopupService } from "src/app/shared/components/confirm-popup/confirmpopup.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manage-mapping",
  templateUrl: "./manage.component.html",
  styleUrls: ["./manage.component.scss"],
})
export class ManageComponent
  implements OnInit, ComponentCanDeactivate, OnDestroy
{
  public currentStep;
  public activeStatus: boolean = false;
  public paramsSub: any;
  public params: any;
  public folderId: string = "";
  public access: boolean = false;
  public mainObj: any;
  public attributes: any;
  public mappings: any;
  public readRules: any;
  public datDetails: any;
  public subNavTabs: any = [];
  public lobs: any = [];
  public assetsUrl: any;
  public readRuleFlag: boolean = false;
  // public DATExtentionFlag: boolean = false;
  public canDeactivate: boolean = true;
  public navigateMessage?: string | null;
  public isUpdated = {
    attributes: false,
    mappings: false,
    datExtension: false,
    readRule: false,
  };
  public isClearButtonDisabled = false;
  public isMapSaved = true;
  public allSheetMappings: any[] = [];
  public updateEmitter = new BehaviorSubject<boolean>(false);
  public updateAttributesDisabled = new BehaviorSubject<boolean>(true);
  public confirmPopupSubscription?: Subscription;
  public importId: string = "";
  public aiElementIds: any[] = [];
  public disableAutoMapping: boolean = false;
  private apiCompleteSubscription?: Subscription;
  private unsubscribe$ = new Subject<void>();
  apiCallCompleted = false;
  apiCallSuccess = false;
  @ViewChild("attributesComp") attributesComp?: AttributesComponent;
  @ViewChild("confirmationPopModal") confirmationPopModal: ElementRef | any;
  @ViewChild("navigatePopModal") navigatePopModal: ElementRef | any;
  public tab = "";
  public disableSubmitButton: boolean = false;
  public migrationType: any = null;
  public migrationSourceFile: any = null;
  private headerSubscription?: Subscription;
  public migrationFormDetails: FormGroup = this.fb.group({});
  public targetFormDetails: FormGroup = this.fb.group({});
  public migrationHeaderDetails: any = {};
  public migrationStatus: boolean = false;
  public migrationAttributesUpdated: boolean = false;
  public migrationAttributes: any = {};
  public migrationImportId: string = "";
  public totalFolderIds: any = null;
  public currentHeader: string = "";
  public migrationSourceFileSubmit: boolean = false;
  public noOfOverviewSkeleton: any = Array.from({ length: 3 }, (_, i) => i);
  public enableSaveButton: boolean = false;
  public showMigrationAlert: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private importDesignerService: ImportDesignerService,
    private validationService: ValidationService,
    private fb: FormBuilder,

    private appService: AppService,
    private loaderService: LoaderService,
    private ConfirmPopupService: ConfirmPopupService
  ) {}

  ngOnInit(): void {
    // this.loaderService.loader.next(true);
    this.paramsSub = this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;
      if (params["importId"]) {
        this.importId = params["importId"];
      }
      this.access = JSON.parse(
        localStorage.getItem("pipelineaccess") || "false"
      );
      if (params["folderId"]) {
        this.folderId = this.params.folderId;
        this.totalFolderIds = params["folderId"].split("_");
      }
    });
    this.subNavTabs = [
      { name: "Overview", value: "attributes" },
      // { name: 'Read Rules', value: 'readingRules' },
      // { name: 'Data Definitions', value: 'datExtention' },
      { name: "Mappings", value: "mappings" },
    ];
    this.assetsUrl = environment["assetsUrl"];
    this.appService.getShowHideReadRules().subscribe({
      next: async (response) => {
        if (response == "N") {
          this.subNavTabs = this.subNavTabs.filter(
            (x) => x.value != "readingRules"
          );
        } else if (response == "Y") {
          let itm = this.subNavTabs.filter((x) => x.value == "readingRules");
          if (itm && !itm.length) {
            this.subNavTabs.splice(1, 0, {
              name: "Read Rules",
              value: "readingRules",
            });
          }
        }
      },
      error: (err) => {
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          };
          this.appService.showToast(data);
        }
      },
    });
    this.appService.getShowHideDATTab().subscribe(async (response) => {
      if (response == true) {
        let itm = this.subNavTabs.filter((x) => x.value == "datExtention");
        if (itm && !itm.length) {
          this.subNavTabs.splice(1, 0, {
            name: "Data Definitions",
            value: "datExtention",
          });
        }
      } else {
        this.subNavTabs = this.subNavTabs.filter(
          (x) => x.value != "datExtention"
        );
      }
    });
    this.currentStep = this.subNavTabs[0].value;
    const existingFolderName = sessionStorage.getItem("currentFolderName");
    if (existingFolderName) {
      this.appService.setNewHeader(existingFolderName);
    }

    // this.getReadingRules();
    // this.getDATDetails();
    // this.loaderService.loader.next(false);

    // this.confirmPopupSubscription = this.ConfirmPopupService.getConfirmClicked()
    // 	.subscribe((res) => {
    // 		if (res) {
    // 			if (res["isConfirm"] === "true" && res["activeStatus"]) {
    // 				this.setActiveStatus();
    // 			}
    // 			if (res["isConfirm"] === "false" && res["activeStatus"]) {
    // 				this.activeStatus = !this.activeStatus;
    // 			}
    // 			if (res["isConfirm"] === "true" && res["url"]) {
    // 				this.confirmNavigationTo(res["url"]);
    // 			}
    // 			if (res["isConfirm"] === "false" && res["url"]) {
    // 				this.confirmNavigationTo();
    // 			}
    // 			if (res["isConfirm"] === "true" && res["selectTab"]) {
    // 				this.cosnfirmSelectTab(res["selectTab"]);
    // 			}
    // 			if (res["isConfirm"] === "false" && res["selectTab"]) {
    // 				this.cancelNavigation();
    // 			}
    // 		}
    // 	});
    if (
      localStorage.getItem("migrationType") &&
      localStorage.getItem("migration")
    ) {
      const migrate = localStorage.getItem("migrationType") || "";
      this.migrationType = JSON.parse(migrate);
      this.subNavTabs = [{ name: "Overview", value: "attributes" }];
    }
    if (this.migrationType) {
      this.getMigrationHeader();
    }

    if (!this.migrationType) {
      this.getMappingDetails();
    }
  }

  async getMigrationHeader() {
    if (this.importId) {
      this.migrationImportId = this.importId;
      await this.getMigrationDetails();
    }
    let existingFolderNames =
      sessionStorage.getItem("currentFolderName") ?? "Pipelines";
    let currentHeaderTitle = "";
    if (existingFolderNames) {
      let pathList = existingFolderNames.split("/");
      let latestPath = pathList[pathList.length - 1];
      currentHeaderTitle = latestPath.trim() + " / ";
      if (pathList.length > 1) {
        currentHeaderTitle = ".../" + currentHeaderTitle;
      }
    }
    this.currentHeader = currentHeaderTitle;
    this.appService.setNewHeader(
      this.migrationImportId
        ? {
            title:
              currentHeaderTitle +
              this.migrationAttributes["importProgramDefinitions"]?.[
                "importName"
              ],
            page: "pipelines/manage",
            isEvent: false,
            eventId: this.migrationImportId,
          }
        : {
            title: currentHeaderTitle + "Unsaved Pipeline",
            page: this.router.url,
            isEvent: true,
          }
    );
    this.getHeaderTitle();
  }

  disableClear() {
    if (
      this.mappings["mapping"]["lines"][
        this.importDesignerService?.selectedLineIndex
      ]?.["mappingElements"]?.length === 0 ||
      !this.access
    ) {
      return true;
    }
    return false;
  }

  isAutoMappingDisabled(): boolean {
    // Enable the button if the fileFormat is "KBR Proposal", regardless of other conditions
    if (
      this.mappings["importReadRules"]["fileAttributes"]?.["fileFormat"] ===
      "KBR Proposal"
    ) {
      return false;
    }
    // Otherwise, disable the button based on the other conditions
    return this.disableAutoMapping;
  }

  // Create a subject to emit a signal for unsubscribe
  sendConfirmMessage(event) {
    this.ConfirmPopupService.confirmMessageEvent.next({
      message: `Are you sure you want to ${this.activeStatus ? "disable" : "enable"} the mapping ?`,
      activeStatus: "true",
      headerText: `${this.activeStatus ? "Disable" : "Enable"} mapping`,
      confirmButtonText: `${this.activeStatus ? "Disable" : "Enable"}`,
      manageCallback: (value) => {
        if (value && value["isConfirm"] === "true") {
          this.setActiveStatus();
        }
        if (value && value["isConfirm"] === "false") {
          this.activeStatus = !this.activeStatus;
        }
      },
    });
  }

  async getMappingDetails(): Promise<void> {
    if (!this.params["unsaved"]) {
      const importId: string | null = this.params["importId"];

      try {
        const response = await this.importDesignerService
          .getMappingDetails({ importId: importId || "" })
          .toPromise();
        if (response) {
          this.mainObj = response["data"];
          this.attributes = JSON.parse(JSON.stringify(this.mainObj));

          if (this.attributes["disableAutoMapped"]) {
            if (typeof this.attributes["disableAutoMapped"] === "boolean") {
              this.disableAutoMapping = this.attributes["disableAutoMapped"];
            } else {
              this.disableAutoMapping = JSON.parse(
                this.attributes["disableAutoMapped"]
              );
            }
          }

          this.attributes["fileHeaders"] ??= {};
          this.attributes["emails"] ??= {};
          this.mappings = JSON.parse(JSON.stringify(this.mainObj));
          this.activeStatus =
            this.attributes["fileHeaders"]["activeYn"] === "N" ? false : true;

          this.isMapSaved = !!importId;
          this.navigateMessage = null;
        }
      } catch (err: any) {
        if (err.status !== 403) {
          const data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          };
          this.appService.showToast(data);
        }
      }
    } else {
      if (!this.importDesignerService.unsavedMappingData) {
        this.navigateTo("pipelines");
        return;
      }
      this.mainObj = this.importDesignerService.unsavedMappingData;
      this.attributes = JSON.parse(JSON.stringify(this.mainObj));
      this.attributes["fileHeaders"] ??= {};
      this.attributes["emails"] ??= {};
      this.mappings = JSON.parse(JSON.stringify(this.mainObj));
      this.activeStatus =
        this.attributes["fileHeaders"]["activeYn"] === "N" ? false : true;
      this.isMapSaved = false;
      this.canDeactivate = false;
      this.navigateMessage =
        "Mapping will be lost, Are you sure you want to continue?";
    }
  }

  checkToDisplayReadRulesTab() {
    let visibleReadRulesFor = ["Custom"];
    if (
      visibleReadRulesFor.includes(
        this.attributes["importReadRules"]["fileAttributes"].fileFormat
      )
    ) {
      this.appService.showHideReadRuleTab("Y");
    } else {
      this.appService.showHideReadRuleTab("N");
    }
  }

  getReadingRules() {
    this.importDesignerService
      .getReadRulesDetails({ importId: this.importId })
      .subscribe({
        next: (response) => {
          this.mainObj = response;
          this.readRules = JSON.parse(JSON.stringify(this.mainObj));
        },
        error: (err) => {
          if (err.status !== 403) {
            let data = {
              duration: 3000,
              type: "error",
              message: err["error"]?.["message"] || err["message"] || err,
            };
            this.appService.showToast(data);
          }
        },
      });
  }

  getDATDetails() {
    // this.importDesignerService
    //   .getDATDetails({ importId: this.params['importId'] })
    //   .subscribe((response) => {
    //     this.mainObj = response;
    //     this.datDetails = JSON.parse(JSON.stringify(this.mainObj));
    //   });
  }

  cancelNavigation() {
    // (this.navigatePopModal as any).hide();
  }

  selectTab(tab) {
    this.tab = tab;
    if (tab == this.currentStep) return;
    // this.confirmSelectTab(tab);
    if (this.isUpdated[this.currentStep]) {
      //   this.ConfirmPopupService.confirmMessageEvent.next({
      //     message: "You have unsaved changes, Are you sure you want to continue?",
      //     selectTab: tab,
      //     headerText: "Confirmation",
      //     confirmButtonText: "Confirm",
      //     manageCallback: (value) => {
      //       if (value && value["isConfirm"] === "true") {
      //         this.confirmSelectTab(value["selectTab"]);
      //       }
      //       if (value && value["isConfirm"] === "false") {
      //         this.cancelNavigation();
      //       }
      //     },
      //   });
    } else {
    }
    this.navigateIt(tab);
  }

  confirmSelectTab(tab: any) {
    this.isUpdated[this.currentStep] = false;
    if (
      this.currentStep == "attributes" &&
      !this.params["newPipeline"] &&
      !this.params["unsaved"]
    ) {
      this.attributes = this.mappings;
      this.checkToDisplayReadRulesTab();
    }
    this.navigateIt(tab);
  }

  async navigateIt(tab: string) {
    // if (this.importDesignerService.unsavedMappingData) {
    //   let data = {
    //     duration: 3000,
    //     type: "alert",
    //     message: "Please update all settings before switching to " + tab,
    //   };
    //   this.appService.showToast(data);
    //   return;
    // }
    if (tab == "mappings") {
      let attributesCheck = this.validationService.checkAttributes(
        this.attributes
      );
      if (attributesCheck) {
        this.resetFlags();
        this.updateEmitter.next(true);
        this.waitForApiResponse(tab);
      }
      //   let data = {
      //     duration: 3000,
      //     type: "error",
      //     message:
      //       "Please fill and save all the highlighted fields to go into Mappings.",
      //   };
      //   this.appService.showToast(data);
    } else if (tab == "readingRules") {
      if (this.isUpdated[tab]) {
        // this.getReadingRules();
      }
      let attributesCheck = this.validationService.checkAttributes(
        this.attributes
      );
      if (attributesCheck == true) {
        if (
          this.attributes["importReadRules"]["fileAttributes"]["fileFormat"] ==
          "Custom"
        ) {
          this.currentStep = tab;
        } else {
          let data = {
            duration: 3000,
            type: "error",
            message:
              "Read rules tab is only available when File Format is Custom",
          };
          this.appService.showToast(data);
        }
      } else {
        let data = {
          duration: 3000,
          type: "error",
          message: attributesCheck,
        };
        this.appService.showToast(data);
      }
    } else if (tab == "datExtention") {
      if (this.isUpdated[tab]) {
        this.getDATDetails();
      }
      let attributesCheck = this.validationService.checkAttributes(
        this.attributes
      );
      if (attributesCheck == true) {
        this.currentStep = tab;
      } else {
        let data = {
          duration: 3000,
          type: "error",
          message: attributesCheck,
        };
        this.appService.showToast(data);
      }
    } else {
      if (this.isUpdated[tab]) {
        this.getMappingDetails();
      }
      this.resetFlags();
      this.appService.setEventUpdate("update");
      this.waitForApiResponse(tab);
    }
  }

  setActiveStatus(event?) {
    event?.preventDefault();
    let self = this;
    let data = {
      importId: self.params["importId"],
      status: self.activeStatus ? "Y" : "N",
    };
    self.importDesignerService.setMapStatus(data).subscribe({
      next: (response) => {
        if (response) {
          let data = {
            duration: 3000,
            type: "success",
            message: response["message"],
          };
          self.appService.showToast(data);
        }
      },
      error: (err) => {
        this.activeStatus = !data["status"];
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          };
          this.appService.showToast(data);
        }
      },
    });
    // 	text: `Are you sure you want to ${this.activeStatus ? "disable" : "enable"
    // 		} the mapping ?`,
    // 	type: "confirm-warning",
    // 	callback: function (result) {
    // 		if (result) {
    // 			let data = {
    // 				importId: self.params["importId"],
    // 				status: self.activeStatus ? "N" : "Y",
    // 			};
    // 			self.importDesignerService.setMapStatus(data).subscribe({
    // 				next: (response) => {
    // 					if (response) {
    // 						let data = {
    // 							duration: 3000,
    // 							type: "success",
    // 							message: response["message"],
    // 						};
    // 						self.appService.showToast(data);
    // 						self.activeStatus = !self.activeStatus;
    // 					}
    // 				},
    // 				error: (err) => {
    // 					if (err.status !== 403) {
    // 						let data = {
    // 							duration: 3000,
    // 							type: "success",
    // 							message: err["error"]?.["message"] || err["message"] || err,
    // 						};
    // 						this.appService.showToast(data);
    // 					}
    // 				},
    // 			});
    // 		}
    // 	},
    // });
  }

  updateRequest(value) {
    // this.loaderService.loader.next(true);
    this.appService.setEventUpdate(value);
  }

  updateReadingRulesRequest(value) {
    // this.loaderService.loader.next(true);
    this.appService.setReadingRulesEventUpdate(value);
  }

  updateDATExtensionRequest(value) {
    this.appService.setDATExtentionEventUpdate(value);
  }

  updateAttributes(attributes) {
    // this.loaderService.loader.next(true);
    this.attributes = cloneDeep(attributes);
    this.mappings = cloneDeep(attributes);
    // this.getReadingRules();
    // this.attributes = Object.assign(this.attributes, attributes);
    // this.mappings = Object.assign(this.mappings, attributes);
  }
  invalidFolderId(id: any) {
    return id === undefined || id === null || id === "";
  }
  navigateTo(url) {
    localStorage.removeItem("pipelineaccess");
    const folderIds = this.folderId;
    if (!this.invalidFolderId(folderIds)) {
      url = url + `?folderId=${folderIds}`;
    }
    // this.loaderService.loader.next(true);
    if (
      this.router.parseUrl(this.router.url).queryParams.unsaved &&
      this.importDesignerService.unsavedMappingData
    ) {
      // let self = this;
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: "Mapping will be lost, Are you sure you want to continue?",
        navigateTo: "ture",
        headerText: "Confirmation",
        confirmButtonText: "Confirm",
        url: url,
        manageCallback: (value) => {
          if (value && value["isConfirm"] === "true") {
            this.confirmNavigationTo(value["url"]);
          }
          if (value && value["isConfirm"] === "false") {
            this.confirmNavigationTo();
          }
        },
      });
    }
    this.router.navigateByUrl(url).then((res) => {
      if (!res) {
        this.loaderService.loader.next(false);
      }
    });
  }
  confirmNavigationTo(url?: string) {
    if (url) {
      this.router.navigateByUrl(url).then((res) => {
        if (!res) {
          this.loaderService.loader.next(false);
        }
      });
    } else {
      this.loaderService.loader.next(false);
    }
  }

  getupdateStatus(event) {
    if (event) {
      const keys = Object.keys(event);

      this.isUpdated[keys[0]] = event[keys[0]];

      if (
        event.attributes ||
        event.mappings ||
        event.readingRules ||
        event.datExtention
      ) {
        this.canDeactivate = false;
      } else {
        this.canDeactivate = true;
      }
    } else {
      this.canDeactivate = true;
    }
  }

  disableClearButton(event: any) {
    this.isClearButtonDisabled = event;
  }

  disableAutoMappingButton(event: any) {
    this.disableAutoMapping = event;
  }

  updateAllAttributes() {
    if (this.attributes) {
      this.updateEmitter.next(true);
    }
  }

  ngOnDestroy() {
    this.ConfirmPopupService.confirmClickedEvent.next({ message: "" });
    if (this.confirmPopupSubscription) {
      this.confirmPopupSubscription?.unsubscribe();
    }
    this.paramsSub.unsubscribe();
    localStorage.removeItem("migrationType");
    localStorage.removeItem("migration");
    this.headerSubscription?.unsubscribe();
    this.unsubscribe$?.unsubscribe();
    this.migrationHeaderDetails = {};
    this.migrationFormDetails = this.fb.group({});
    this.targetFormDetails = this.fb.group({});
    this.migrationAttributes = {};
    this.migrationImportId = "";
    this.importId = "";
    this.params = {};
  }

  onApiSuccess(success: boolean) {
    this.resetFlags();
    this.apiCallCompleted = true;
    this.apiCallSuccess = success;
  }
  updateMappings(event) {
    this.attributes = event;
    this.mappings = event;
  }
  async waitForApiResponse(tab: any) {
    while (!this.apiCallCompleted) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    if (this.apiCallSuccess) {
      if (tab === "mappings") {
        this.updateAttributesDisabled.next(true);
        this.attributesComp?.setAttributes(this.attributes);
        this.currentStep = tab;
      }
      if (tab === "attributes") {
        this.attributesComp?.setAttributes(this.attributes);
        this.currentStep = tab;
      }
    } else {
      if (tab === "mappings") {
      }
      if (tab === "attributes") {
        this.attributes = this.mappings;
        this.attributesComp?.showErrorFields();
      }
    }
  }
  resetFlags() {
    this.apiCallCompleted = false;
    this.apiCallSuccess = false;
  }

  migrationSourceFiles(event: any) {
    this.migrationSourceFile = event;
    this.migrationSourceFileSubmit = true;
  }

  migrationValues(event: FormGroup) {
    const eventValues = event.getRawValue();
    const currentValues = this.migrationFormDetails.getRawValue();
    const areValuesPresent = Object.keys(eventValues).length > 0;
    if (areValuesPresent) {
      const areValuesSame =
        JSON.stringify(eventValues) === JSON.stringify(currentValues);
      if (!areValuesSame) {
        const newFormGroup = this.fb.group({});
        Object.keys(event.controls).forEach((controlName) => {
          const control = event.get(controlName);
          if (control) {
            newFormGroup.addControl(
              controlName,
              this.fb.control(control.value, control.validator)
            );
          }
        });
        this.migrationFormDetails = newFormGroup;
        this.migrationAttributesUpdated = false;
      }
    }
  }

  targetMigrationValues(event: FormGroup) {
    const eventValues = event.getRawValue();
    const currentValues = this.targetFormDetails?.getRawValue();
    const areValuesPresent = Object.keys(eventValues).length > 0;
    if (areValuesPresent) {
      const areValuesSame =
        JSON.stringify(eventValues) === JSON.stringify(currentValues);
      if (!areValuesSame) {
        const newFormGroup = this.fb.group({});
        Object.keys(event.controls).forEach((controlName) => {
          const control = event.get(controlName);
          if (control) {
            newFormGroup.addControl(
              controlName,
              this.fb.control(control.value, control.validator)
            );
          }
        });
        this.targetFormDetails = newFormGroup;
        this.migrationAttributesUpdated = false;
        this.showMigrationAlert = false;
      }
    }
    if (!this.enableSaveButton) {
      this.enableSaveButton = false;
    }
  }
  get checkForInvalidForm() {
    if (this.migrationFormDetails.invalid) {
      return true;
    }
    return false;
  }

  backToPipelines() {
    if (
      this.migrationType &&
      this.migrationImportId &&
      this.migrationSourceFile &&
      this.migrationSourceFileSubmit &&
      !this.migrationAttributesUpdated
    ) {
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: `You have unsubmitted file. Discard the file? `,
        activeStatus: "true",
        headerText: `Warning`,
        confirmButtonText: `Confirm`,
        manageCallback: (value: any) => {
          if (value && value["isConfirm"] === "true") {
            this.confirmBackToPipelines();
          }
          if (value && value["isConfirm"] === "false") {
          }
        },
      });
    } else if (this.migrationAttributesUpdated && !this.showMigrationAlert) {
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: `You have unsaved changes. Are you sure you want to continue?`,
        activeStatus: "true",
        headerText: `Warning`,
        confirmButtonText: `Confirm`,
        manageCallback: (value: any) => {
          if (value && value["isConfirm"] === "true") {
            this.confirmBackToPipelines();
          }
          if (value && value["isConfirm"] === "false") {
          }
        },
      });
    } else {
      this.confirmBackToPipelines();
    }
  }

  confirmBackToPipelines() {
    let migration: any = localStorage.getItem("migration");
    if (this.params["newPipeline"] && migration && migration === "add")
      this.importDesignerService.toggleMigrationNavigation("true");
    else this.importDesignerService.toggleMigrationNavigation("false");
    this.activatedRoute.queryParams.subscribe((params) => {
      const { importId, newPipeline, ...rest } = params;
      const updatedParams = {
        ...rest,
        viewType: this.params["viewType"],
      };
      this.router.navigate(["/pipelines"], { queryParams: updatedParams });
    });
    this.paramsSub.unsubscribe();
    this.headerSubscription?.unsubscribe();
    this.unsubscribe$.unsubscribe();
    localStorage.removeItem("migrationType");
    localStorage.removeItem("migration");
    this.migrationHeaderDetails = {};
    this.migrationFormDetails = this.fb.group({});
    this.migrationAttributes = {};
    this.migrationImportId = "";
    this.importId = "";
    this.params = {};
  }

  getHeaderTitle() {
    this.headerSubscription = this.appService.onHeaderTitleEdit
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res) {
          if (
            res?.title !== this.migrationHeaderDetails.title &&
            res.eventId === this.migrationImportId
          ) {
            this.migrationHeaderDetails = res;
            this.updateMigration();
          }
          this.migrationHeaderDetails = res;
        }
      });
  }

  async getMigrationDetails() {
    return new Promise<void>((resolve, reject) => {
      this.appService.getMigrationDetails(this.migrationImportId).subscribe({
        next: (response) => {
          this.migrationAttributes = response;
          this.migrationHeaderDetails.title =
            response["importProgramDefinitions"]["importName"];
          this.getHeaderUpdate(response);
          this.activeStatus =
            response["importProgramDefinitions"]["activeYn"] === "Y"
              ? true
              : false;
          resolve();
        },
        error: (err) => {
          if (err.status !== 403) {
            this.appService.showToast({
              duration: 3000,
              type: "error",
              message: err["error"]?.["message"] || err["message"] || err,
            });
          }
          reject(err);
        },
      });
    });
  }

  async getHeaderUpdate(response: any, noRoute?: string) {
    this.router.navigate(["pipelines/manage"], {
      queryParams: {
        importId: response["importProgramDefinitions"]["importId"],
        folderId: this.folderId,
        viewType: this.params["viewType"] ?? "grid",
      },
    });
    const existingFolderName =
      sessionStorage.getItem("currentFolderName") || "Pipelines";
    let currentHeaderTitle = "";
    if (existingFolderName) {
      let pathList = existingFolderName.split("/");
      let latestPath = pathList[pathList.length - 1];
      currentHeaderTitle =
        latestPath.trim() +
        " / " +
        response["importProgramDefinitions"]["importName"];
      if (pathList.length > 1) {
        currentHeaderTitle = ".../" + currentHeaderTitle;
      }
    }
    this.appService.setNewHeader({
      title: currentHeaderTitle,
      page: "pipelines/manage",
      isEvent: false,
      eventId: response["importProgramDefinitions"]["importId"],
    });
  }

  createMigration() {
    if (
      Object.keys(this.migrationHeaderDetails).length === 0 ||
      this.migrationHeaderDetails?.title.toLowerCase() === "unsaved pipeline" ||
      this.migrationHeaderDetails?.title === ""
    ) {
      this.appService.showToast({
        duration: 3000,
        type: "alert",
        message: "Please enter valid pipeline name!",
      });
      return;
    }
    if (this.migrationFormDetails.invalid || this.checkValidatorsAndValues()) {
      return;
    }
    const targetData: any = this.buildTargetData();
    let targetDetails = this.targetFormDetails?.getRawValue();
    if (
      this.targetFormDetails?.valid &&
      Object.keys(targetDetails).length > 0
    ) {
      targetData["targetMigrationDetails"] = targetDetails;
    }
    this.appService.createMigrationPipeline(targetData).subscribe({
      next: (response) => {
        this.migrationAttributes = response;
        this.migrationType === "edit";
        this.migrationAttributesUpdated = true;
        this.enableSaveButton = true;
        this.getHeaderUpdate(response);
        this.migrationImportId =
          response["importProgramDefinitions"]["importId"];
        this.appService.showToast({
          duration: 3000,
          type: "success",
          message: response["message"] || response,
        });
        this.activeStatus =
          response["importProgramDefinitions"]["activeYn"] === "Y"
            ? true
            : false;
      },
      error: (err) => {
        if (err.status !== 403) {
          this.appService.showToast({
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          });
        }
      },
    });
  }
  onFormUpdate(isUpdated: boolean) {
    if (this.migrationFormDetails.valid) {
    }
  }
  private buildTargetData() {
    const { email, sourceConnection, targetConnection, ...otherValues } =
      this.migrationFormDetails.getRawValue() ?? {};
    let data = {
      email: email ?? "",
      migrationType: this.migrationType?.name.replace("to", " - ") ?? "",
      pipelineName: this.migrationHeaderDetails?.title ?? "",
      parentFolderId: this.folderId ?? "",
      importType: "migration",
      connectionInfo: {
        ...otherValues,
        targetConnectionId: targetConnection,
      },
    };
    if (sourceConnection && !this.migrationSourceFile) {
      data["connectionInfo"]["sourceConnectionId"] = sourceConnection;
    }
    return data;
  }

  updateMigration() {
    if (this.migrationFormDetails.invalid || this.checkValidatorsAndValues()) {
      this.appService.showToast({
        duration: 3000,
        type: "error",
        message: "Some fields are empty",
      });
      return;
    }
    const details = this.migrationFormDetails.getRawValue();
    const { email, sourceConnection, targetConnection, ...filteredDetails } =
      details;
    const connections =
      this.migrationAttributes["importProgramDefinitions"]["attributes"][
        "connections"
      ];
    Object.assign(connections, filteredDetails);
    if (
      sourceConnection &&
      !this.migrationSourceFile &&
      sourceConnection !== 0
    ) {
      this.migrationAttributes["importProgramDefinitions"]["attributes"][
        "connections"
      ]["sourceConnectionId"] = sourceConnection;
    }
    this.migrationAttributes["importEmailNotifications"]["successEmail"] =
      email;
    this.migrationAttributes["importProgramDefinitions"]["attributes"][
      "connections"
    ]["targetConnectionId"] = targetConnection;
    this.migrationAttributes["importProgramDefinitions"]["attributes"][
      "connections"
    ]["sourceConnectionId"] = sourceConnection;
    this.migrationAttributes["importProgramDefinitions"]["importName"] =
      this.migrationHeaderDetails?.title;

    let updateMigrationPayload = {
      importProgramDefinitions:
        this.migrationAttributes["importProgramDefinitions"],
      migrationRules: this.migrationAttributes["migrationRules"],
      importEmailNotifications:
        this.migrationAttributes["importEmailNotifications"],
    };

    this.appService.updateMigrationPipeline(updateMigrationPayload).subscribe({
      next: (response) => {
        this.appService.showToast({
          duration: 3000,
          type: "success",
          message: response["message"] || response,
        });
        this.getHeaderUpdate(response);
        this.migrationAttributesUpdated = true;
        this.enableSaveButton = true;
      },
      error: (err) => {
        if (err.status !== 403) {
          this.appService.showToast({
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          });
        }
      },
    });
  }
  submitMigration() {
    const formData = this.buildSubmitMigrationFormData();
    this.appService.submitMigration(formData).subscribe({
      next: (response) => {
        this.appService.showToast({
          duration: 3000,
          type: "success",
          message: response["message"] || response,
        });
        this.migrationSourceFileSubmit = false;
        this.showMigrationAlert = true;
      },
      error: (err) => {
        if (err.status !== 403) {
          this.appService.showToast({
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          });
        }
      },
    });
  }

  private buildSubmitMigrationFormData(): FormData {
    const formData = new FormData();
    const sourceConnectionId =
      this.migrationAttributes?.importProgramDefinitions?.attributes
        ?.connections?.sourceConnectionId;
    if (
      !this.isValidSourceConnection(sourceConnectionId) &&
      this.migrationSourceFile
    ) {
      formData.append("migrationFile", this.migrationSourceFile);
    }
    formData.append("importId", this.migrationImportId ?? "");
    return formData;
  }

  private isValidSourceConnection(sourceConnectionId: any): boolean {
    return (
      sourceConnectionId !== undefined &&
      sourceConnectionId !== null &&
      sourceConnectionId !== 0
    );
  }
  migrationConnectionValueUpdated() {
    let sourceConnectionValue =
      this.migrationFormDetails.get("sourceConnection")?.value;
    let targetConnectionValue =
      this.migrationFormDetails.get("targetConnection")?.value;
    const migrationSourceValueUpdated =
      sourceConnectionValue ===
      this.migrationAttributes["importProgramDefinitions"]?.["attributes"][
        "connections"
      ]["sourceConnectionId"];
    const migrationTargetValueUpdated =
      targetConnectionValue ===
      this.migrationAttributes["importProgramDefinitions"]?.["attributes"][
        "connections"
      ]["targetConnectionId"];
    if (migrationSourceValueUpdated || migrationTargetValueUpdated) {
      return true;
    }
    return false;
  }

  handleDisableSaveMigration() {
    if (this.checkValidatorsAndValues()) {
      return true;
    }
    if (this.migrationFormDetails.invalid) {
      return true;
    }
    return (
      this.migrationHeaderDetails?.title?.toLowerCase() ===
        "Unsaved Pipeline" || this.migrationAttributesUpdated
    );
  }
  disableSubmitMigration() {
    const hasMigrationImportId = this.migrationImportId;
    const sourceConnectionId =
      this.migrationFormDetails.get("sourceConnection")?.value;
    if (
      hasMigrationImportId &&
      (sourceConnectionId || this.migrationSourceFile) &&
      this.enableSaveButton &&
      this.handleDisableSaveMigration() &&
      this.migrationFormDetails.valid
    ) {
      return false;
    }
    return true;
  }
  checkValidatorsAndValues(): boolean {
    const controls: any = this.targetFormDetails?.controls;
    if (!controls) {
      return false;
    }
    let hasErrors = false;
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      if (control?.validator) {
        if (
          this.hasValidator(control, Validators.required) &&
          !control?.value
        ) {
          hasErrors = true;
        } else {
        }
      }
    });
    return hasErrors;
  }

  private hasValidator(
    control: AbstractControl,
    validator: ValidatorFn
  ): boolean {
    const validatorFn = control.validator ? control.validator : () => null;
    const errors: ValidationErrors | null = validatorFn(control);
    if (errors === null || errors === undefined) {
      return false;
    }
    const validatorKeys = Object.keys(errors);
    return validatorKeys.includes(validator.name);
  }

  currentLine(event) {
    let { currentLine, mappings } = event;
    this.currentLine = currentLine;
    this.mappings = mappings;
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ToastTimer } from "src/app/core/models/toast-timer.model";
import { AppService } from "src/app/core/services/app.service";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private appService: AppService) {}

  public toasts: any[] = [];
  public toast: BehaviorSubject<ToastTimer[]> = new BehaviorSubject<
    ToastTimer[]
  >([]);
  getToasts(): Observable<ToastTimer[]> {
    return this.toast.asObservable();
  }
  private randomId() {
    return Math.random().toString(36).substring(2, 15);
  }
  showToast(data: ToastTimer) {
    // Check if there is more than one toast and if any has the same message
    if (this.toasts.length > 0) {
      const existingToastIndex = this.toasts.findIndex(
        (toast) => toast.message === data.message
      );
      if (existingToastIndex !== -1) {
        // Remove the existing toast with the same message
        const removedToast = this.toasts.splice(existingToastIndex, 1)[0];
        if (removedToast) {
          this.removeToast(removedToast.id);
        }
      }
    }
    // Check if the length exceeds 10 and remove the first 5 toasts
    if (this.toasts.length >= 8) {
      const toRemove = Math.min(this.toasts.length - 6, 6);
      for (let i = 0; i < toRemove; i++) {
        const removedToast = this.toasts.shift();
        if (removedToast) {
          this.removeToast(removedToast.id);
        }
      }
    }
    const toast: ToastTimer = {
      ...data,
      id: this.randomId(),
      startTime: Date.now(),
      remainingTime: data.duration,
      timeout: 0,
      // Initial time duration for the toast
    };
    this.toasts.push(toast);
    this.toast.next(this.toasts);
    this.startTimer(toast);
  }

  startTimer(toast: ToastTimer) {
    toast.timeout = setTimeout(() => {
      if (toast.id) this.removeToast(toast.id);
    }, toast.remainingTime);
  }
  pauseTimer(toast: ToastTimer) {
    if (toast.remainingTime) {
      clearTimeout(toast.timeout);
      if (toast.startTime) toast.remainingTime -= Date.now() - toast.startTime; // Update remaining time
      this.toast.next(this.toasts);
    }
  }
  resumeTimer(toast: ToastTimer) {
    if (toast.startTime) toast.startTime = Date.now(); // Reset start timethis.startTimer(toast); // Start with updated remaining time
    this.startTimer(toast);
  }
  removeToast(id: string) {
    this.toasts = this.toasts.filter((t) => t.id !== id);
    this.toast.next(this.toasts);
  }

  commonToastMethod(message: any, type: string, duration: number = 3000) {
    const data = {
      duration: duration,
      type: type,
      message: message,
    };
    this.appService.showToast(data);
  }
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { DropdownService } from "../../services/dropdown.service";
import {
  DropdownOptions,
  DropdownSearchType,
} from "../../models/globalDropdownOptions.model";
import { AppService } from "../../../core/services/app.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-global-dropdown",
  templateUrl: "./global-dropdown.component.html",
  styleUrls: ["./global-dropdown.component.scss"],
})
export class GlobalDropdownComponent implements OnInit, OnDestroy {
  options: any[] = []; // Your options here
  selectedOption: any;
  triggerPosition?: any;
  isOpen: boolean = false; // Track dropdown state
  public openUpward: boolean = false;
  searchable: boolean = false;
  public data?: DropdownOptions = {
    inputValue: "",
    placeholder: "",
    dataOptions: {},
    searchable: false,
    dropdownSearchType: DropdownSearchType.default,
  };
  inputValue: string = "das";
  public searchValue: string = "";
  public triggerId: string = "";
  placeholder: string = "Select an option";
  public showOptions: boolean = false;
  public isOptionClicked: boolean = false;
  public tirggerSubscription?: Subscription;
  public dropdownStateSubscription?: Subscription;
  public positionSubscription?: Subscription;
  constructor(
    private dropdownService: DropdownService,
    private el: ElementRef,
    private appService: AppService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tirggerSubscription = this.dropdownService.triggerData$.subscribe(
      ({ data, triggerId }) => {
        this.triggerDataHelperMethod(data, triggerId);
      }
    );
    this.dropdownStateSubscription =
      this.dropdownService.dropdownState$.subscribe((isOpen: string | null) => {
        this.isOpen = !!isOpen;
      });
    // Subscribe to position updates independently
    this.dropdownService.getPosition$.subscribe((position: any) => {
      this.triggerPosition = position; // Continuously update position only
    });
  }
  private triggerDataHelperMethod(data: DropdownOptions, triggerId: string) {
    this.searchValue = "";
    this.inputValue = "";
    this.data = data; // Load options from the trigger
    this.triggerId = triggerId;
    if (data.dropdownSearchType === DropdownSearchType.default) {
      this.searchValue = "";
      this.searchValue = data["inputValue"] ?? "";
    } else {
      this.searchValue = "";
      this.searchable = data["searchable"] ?? "";
    }
    this.inputValue = data["inputValue"] ?? "";
    this.placeholder = data["placeholder"];
    if (this.triggerId === triggerId) this.triggerId = triggerId;
    this.cd.detectChanges();
  }

  closeDropdown() {
    this.isOpen = false; // Close dropdown
  }
  onInputFocus(): void {
    this.showOptions = true;
  }

  onOptionMouseDown() {
    this.isOptionClicked = true;
  }
  onSelectChange(value: string, index: number, event?: any) {
    let data: any = {
      value: value,
      index: index,
    };
    const isCheckboxClick = (event.target as HTMLElement).tagName === "INPUT";
    if (isCheckboxClick) {
      const checkbox = event.target as HTMLInputElement;
      const isChecked = checkbox.checked;
      if (!isChecked) {
      }
    }
    if (!this.data?.multiSelectDropDown) {
      this.inputValue = value;
      if (value && this.data) {
        this.data.inputValue = value;
      }
    }
    let newoptions: any = this.data?.dataOptions.newOptions;
    this.emitValues(data);
    if (this.data?.isInputAsSearch) {
      this.isOpen = false;
    } else if (this.data && this.isNormalDropdown(this.data)) {
      this.isOpen = false;
    }
    this.cd.detectChanges();
  }
  emitValues(data?: any) {
    if (!this.data?.isInputAsSearch) {
      let position = this.calcPositionForDropdown();
      this.dropdownService.sendPositionUpdate(position);
    }
    this.dropdownService.sendSelectedOption(data, this.triggerId);
  }

  calcPositionForDropdown() {
    let elf: HTMLLIElement | any = document.getElementById(this.triggerId);
    const { left, top, width } = elf.getBoundingClientRect();
    const parentEl = this.el.nativeElement.parentElement;
    const { left: parentLeft, height: parentHeight } =
      parentEl.getBoundingClientRect();
    // Calculate the position for the dropdown
    const position = {
      x: left,
      y: top + parentHeight, // Adjust for the header height (top of the child + height of the parent )
      width: width,
    };
    return position;
  }
  isInvalid(value: string): boolean {
    return !value || value.trim() === "";
  }
  get filterOptions(): any {
    let matchingOptions: any;
    let nonMatchingOptions: any;
    // Get `dataOptions` and `newOptions` from `data`
    const dataOptions = this.data?.["dataOptions"]?.["options"] || [];
    const newOptions = this.data?.["dataOptions"]?.["newOptions"] || [];
    let searchValue = this.searchValue.toLowerCase()?.trim();
    // If `newOptions` exists, reorder `dataOptions` based on `newOptions`
    let orderedOptions: any[] = dataOptions;
    if (!searchValue) {
      // If input is empty, return the data as is
      return orderedOptions;
    }
    // Filter only to identify matching options but keep the full list
    matchingOptions = orderedOptions.filter((option) =>
      option.toLowerCase().includes(searchValue)
    );
    // matchingOptions = orderedOptions?.filter((option) =>
    //   option.toLowerCase().includes(this.searchValue.toLowerCase())
    // );
    const filteredOptions = [...matchingOptions];
    return filteredOptions.length > 0 ? filteredOptions : [];
    // Return the matching options first, followed by the non-matching options
  }

  isItemDisabled(item) {
    let data = this.data?.dataOptions.newOptions;
    return data.includes(item);
  }

  onInputChange(event) {
    let value: any = event?.target?.value;
    this.searchValue = value?.trim();
    this.inputValue = this.searchValue;
  }

  //   Check if an item is already selected
  isSelected(item: string): boolean {
    let data = this.data?.dataOptions?.newOptions;
    return data?.includes(item);
  }

  onInputBlur() {
    this.emitValues();
    setTimeout(() => {
      if (!this.el.nativeElement.contains(document.activeElement)) {
        this.showOptions = false;
      }
    }, 400);
    this.isOptionClicked = false;
  }

  onSelectChangeMappings(value: string): void {
    this.inputValue = value;
    this.showOptions = false;
    this.emitValuesMappings();
  }

  emitValuesMappings() {
    this.dropdownService.sendSelectedOption(this.inputValue, this.triggerId);
  }
  isEmptyValues() {
    return this.data?.dataOptions.isEmptyValues;
  }

  getDropdownStyles() {
    // if (!this.triggerPosition) return {};
    let adjustedLeft: any;
    let width: any;
    let top: any;
    let maxHeight = 200;
    let marginTop: any;
    let marginRight: any;
    let elf: HTMLLIElement | any = document.getElementById(
      `child${this.triggerId}`
    );
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const parentEl = this.el.nativeElement.parentElement;
    let plM = parentEl?.getBoundingClientRect();

    if (elf) {
      const {
        left: childLeft,
        top: childTop,
        width: childWidth,
        height: childHeight,
        bottom: childBottom,
      } = elf?.getBoundingClientRect();
      if (this.triggerId === this.triggerId) {
        if (this.triggerPosition?.belowParent) {
          marginTop = 300 - childHeight;
        } else {
          marginTop = 2;
        }
        // Calculate the adjusted left position based on sidebar toggle state
        adjustedLeft = this.appService.sideBarToggle
          ? this.triggerPosition?.x
          : this.triggerPosition?.x;
        width =
          this.triggerPosition && this.triggerPosition?.width < 50
            ? "max-content"
            : `${this.triggerPosition?.width}px`;
      }
    }
    if (!this.isOpen) {
      return {
        top: `0px`,
        left: `0px`,
        width: "0px",
        marginTop: `0px`,
        marginRight: `0px`,
      };
    }
    return {
      top: `${this.triggerPosition?.y}px`,
      left: `${adjustedLeft}px`,
      width: width,
      marginTop: `${marginTop}px`,
      marginRight: `${marginRight}px`,
    };
  }

  isNormalDropdownPosition(dropdownOptions: DropdownOptions) {
    return (
      !dropdownOptions?.multiSelectDropDown &&
      !dropdownOptions.dropdownSearchType
    );
  }
  isNormalDropdown(dropdownOptions: DropdownOptions) {
    return !dropdownOptions?.multiSelectDropDown;
  }
  isInputAsSearchDropdown(dropdownOptions: DropdownOptions) {
    return (
      !dropdownOptions?.multiSelectDropDown &&
      dropdownOptions.dropdownSearchType === DropdownSearchType.default
    );
  }
  isMuiltiSelectDropdown(dropdownOptions: DropdownOptions) {
    return (
      dropdownOptions?.multiSelectDropDown &&
      !dropdownOptions.dropdownSearchType
    );
  }
  isHighlighted(option: string) {
    return (
      this.searchValue &&
      option.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  dropdownType(data: any): string {
    if (this.isNormalDropdown(data)) return "normal";
    if (this.isMuiltiSelectDropdown(data)) return "multiSelect";
    if (this.isInputAsSearchDropdown(data)) return "inputSearch";
    return "";
  }

  ngOnDestroy() {
    this.isOpen = false;
    this.data = {
      inputValue: "",
      placeholder: "",
      dataOptions: {},
      searchable: false,
    };
    this.triggerId = "";
    this.tirggerSubscription?.unsubscribe();
    this.dropdownStateSubscription?.unsubscribe();
    this.positionSubscription?.unsubscribe();
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AppService } from "src/app/core/services/app.service";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-invoice-viewer",
  templateUrl: "./invoice-viewer.component.html",
  styleUrls: ["./invoice-viewer.component.scss"],
})
export class InvoiceViewerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id: string = "";
  @Input() data: any;
  @Input() pageIndex: number = 0;
  @Input() isEditable: boolean = false;
  @Input() events: BehaviorSubject<any> | null = null;
  @Input() staticKeys: any[] = [];
  @Input() staticLineItemKeys: any[] = [];
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataSave = new EventEmitter<any>();
  // public invoiceFormatGroup: FormGroup = this.fb.group({
  //   InvoiceId: [""],
  //   InvoiceDate: [""],
  //   PurchaseOrder: [""],
  //   DueDate: [""],
  // });
  // public invoiceSummaryFormGroup: FormGroup = this.fb.group({
  //   SubTotal: [""],
  //   TotalTax: [""],
  //   discount: [""],
  //   InvoiceTotal: [""],
  // });
  // public invoiceAddressFormGroup: FormGroup = this.fb.group({
  //   ShippingAddress: [""],
  //   BillingAddress: [""],
  // });
  public dynamicInputFormGroup: FormGroup = this.fb.group({});
  public dynamicTableFormGroup: FormGroup = this.fb.group({
    tables: this.fb.array([]),
  });
  public lineItemsFormGroup: FormGroup = this.fb.group({
    itemList: this.fb.array([]),
  });
  public vendorName: string = "";
  public itemsData: any[] = [];
  public tableData: any[] = [];
  public itemKeys: string[] = [];
  public itemKeysToDisplayName: any = {};
  public editableData: any;
  private subscriptionObject?: Subscription;
  public gstKeys: any[] = [
    "SummarizedCGSTRate",
    "SummarizedIGSTRate",
    "SummarizedSGSTRate",
    "SummarizedCGSTAmount",
    "SummarizedIGSTAmount",
    "SummarizedSGSTAmount",
  ];
  gstKeyLabels = {
    SummarizedCGSTRate: "CGST %",
    SummarizedIGSTRate: "IGST %",
    SummarizedSGSTRate: "SGST %",
    SummarizedCGSTAmount: "CGST Amount",
    SummarizedIGSTAmount: "IGST Amount",
    SummarizedSGSTAmount: "SGST Amount",
    // Add more mappings as needed
  };
  constructor(
    public appService: AppService,
    private fb: FormBuilder
  ) {}
  get itemsFormArray(): any {
    return (this.lineItemsFormGroup.get("itemList") as FormArray).controls;
  }
  ngOnInit() {
    this.editableData = JSON.parse(JSON.stringify(this.data));
    this.createInvoiceFormat();
    this.subscriptionObject = this.events?.subscribe((res) => {
      if (res["type"] && res["id"] == this.id) {
        if (res["type"] == "cancel") {
          this.editableData = JSON.parse(JSON.stringify(this.data));
          let itemSet = new Set<string>();
          this.itemsData.forEach((item) => {
            Object.keys(item).forEach((key) => {
              itemSet.add(key);
            });
          });
          this.itemKeys = Array.from(itemSet);
          this.itemsData = JSON.parse(JSON.stringify(this.data["Items"]));
          this.sortLineItemKeys();
        } else if (res["type"] == "save") {
          this.saveInvoice();
        }
      }
    });
  }

  removeNewLinesFromString(str?: string): any {
    return str ? str.replaceAll("\n", "") : str;
  }

  ngOnDestroy(): void {
    this.subscriptionObject?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["staticKeys"] &&
      changes["staticKeys"].currentValue &&
      Object.keys(changes["staticKeys"].currentValue).length
    ) {
      this.createInvoiceFormat();
    }
  }

  removeEmptyFormGroups() {
    const itemList = this.lineItemsFormGroup.get("itemList") as FormArray;
    for (let i = itemList.length - 1; i >= 0; i--) {
      const formGroup = itemList.at(i) as FormGroup;
      if (this.isFormGroupEmpty(formGroup)) {
        itemList.removeAt(i); // Remove empty FormGroup from itemList
      }
    }
    this.itemsData = this.itemsData.filter((obj) =>
      Object.values(obj).some(
        (value: any) =>
          value && value["value"] !== undefined && value["value"] !== ""
      )
    );
  }

  isFormGroupEmpty(formGroup: FormGroup): boolean {
    // Check if all controls in the FormGroup have empty values
    for (const controlName in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlName)) {
        const control = formGroup.get(controlName);
        if (control && control.value !== null && control.value !== "") {
          return false; // If any control has a non-empty value, return false
        }
      }
    }
    return true; // All controls have empty values
  }

  generateFormControls(formData: any) {
    Object.keys(formData).forEach((key) => {
      const control = formData[key];
      const validators = control.validators || [];
      const formControl = new FormControl(control.value || "", validators);
      this.dynamicInputFormGroup.addControl(key, formControl);
    });
  }

  sortLineItemKeys() {
    if (this.staticLineItemKeys.length === 0) return;
    this.itemKeys.sort((a, b) => {
      const aModified = a.replaceAll(" ", "");
      const bModified = b.replaceAll(" ", "");
      let aOrder =
        this.staticLineItemKeys.find(
          (item) =>
            item["elementName"] == aModified || item["displayName"] == aModified
        )?.["displayOrder"] ?? Number.MAX_SAFE_INTEGER;
      let bOrder =
        this.staticLineItemKeys.find(
          (item) =>
            item["elementName"] == bModified || item["displayName"] == bModified
        )?.["displayOrder"] ?? Number.MAX_SAFE_INTEGER;
      return aOrder - bOrder;
    });

    if (this.itemKeys.includes("Amount")) {
      this.itemKeys = this.itemKeys.filter((item) => item !== "Amount");
      this.itemKeys.push("Amount");
    }
  }

  // arrangeTableLineItems(
  //   itemsData: any[],
  //   staticLineItemKeys: { [key: string]: any }[]
  // ): any[] {

  //   const result = itemsData.map((item) =>
  //     staticLineItemKeys.reduce((rearrangedItemsData, keyValuePair) => {
  //       const key = Object.keys(keyValuePair)[0]; // Extract the key
  //       const value = keyValuePair[key]; // Extract the value
  //       if (item[value]) {
  //         rearrangedItemsData[value] = item[value]; // Push an object with the same key-value pair as itemsData
  //       }
  //       return rearrangedItemsData;
  //     }, {})
  //   );

  //   // Filter out empty objects
  //   const filteredResult = result.filter((obj) => Object.keys(obj).length > 0);

  //   // Add items from itemsData that were not included in filteredResult
  //   itemsData.forEach((item) => {
  //     if (!filteredResult.some((obj) => Object.values(obj).includes(item))) {
  //       filteredResult.push(item);
  //     }
  //   });

  //   // Check if filteredResult array is empty
  //   const isEmpty = filteredResult.every(
  //     (obj) => Object.keys(obj).length === 0
  //   );

  //   if (isEmpty) {
  //     return [];
  //   }

  //   return filteredResult;
  // }

  createInvoiceFormat() {
    this.itemsData = JSON.parse(JSON.stringify(this.data["Items"]));
    // const arrangedItems = this.arrangeTableLineItems(
    //   this.itemsData.slice(),
    //   this.staticLineItemKeys
    // );
    // this.itemsData = arrangedItems;
    // this.sortLineItemKeys();
    const keysToAssign = [
      "InvoiceId",
      "InvoiceDate",
      "PurchaseOrder",
      "DueDate",
      "ShippingAddress",
      "BillingAddress",
      "AmountDue",
    ];
    const keysToAssign1 = [
      "TotalTax",
      "SubTotal",
      "InvoiceTotal",
      "Discount",
      "VendorName",
      "displayOrder",
    ];
    const remainingKeys = Array.from(
      new Set([
        ...Object.keys(this.staticKeys).filter(
          (key) =>
            ![
              ...keysToAssign,
              ...keysToAssign1,
              this.gstKeys,
              "items",
              "Items",
            ].includes(key) &&
            (key["elementType"] === "field" || key["elementType"] === "Field")
        ),
        ...Object.keys(this.data).filter((key) => {
          const value = this.data[key];
          return (
            !Array.isArray(value) &&
            !keysToAssign.includes(key) &&
            !keysToAssign1.includes(key) &&
            !this.gstKeys.includes(key)
          );
        }),
      ])
    );

    remainingKeys.forEach((key) => {
      this.dynamicInputFormGroup.addControl(
        key,
        this.fb.control(this.data[key]?.value)
      );
    });

    // items loop
    let itemSet = new Set<string>();
    this.itemsData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        itemSet.add(key);
      });
    });
    this.itemKeys = Array.from(itemSet);
    const itemArray: any = this.lineItemsFormGroup.get("itemList") as FormArray;
    this.sortLineItemKeys();
    this.itemsData.forEach((item) => {
      const itemFormGroup = this.createItemFormGroup(item);
      itemArray.push(itemFormGroup);
    });
    this.vendorName = this.data["VendorName"]?.value;
  }
  addNewRow() {
    if (this.isEditable) {
      const itemFormGroup = this.createNewItemFormGroup(this.itemsData[0]); // Create an empty form group if no data
      const itemList = this.lineItemsFormGroup.get("itemList") as FormArray;
      itemList.push(itemFormGroup);
    }
  }

  createNewItemFormGroup(itemData: any) {
    const formGroup: { [key: string]: any } = {};
    const formGroup1: {} = {};
    Object.keys(itemData).forEach((key) => {
      formGroup[key] = [""];
      formGroup1[key] = { value: "" };
    });
    this.itemsData.push(formGroup1);
    return this.fb.group(formGroup);
  }

  createItemFormGroup(itemData: any): FormGroup {
    const formGroup: { [key: string]: any } = {};
    Object.keys(itemData).forEach((key) => {
      formGroup[key] = [itemData[key].value];
    });
    return this.fb.group(formGroup);
  }

  deleteLineItem(index: number) {
    if (this.isEditable && this.itemsData.length > 1) {
      this.itemsData.splice(index, 1);
    }
  }

  splitCamelCase(inputString: string): string {
    return inputString.replace(/([a-z])([A-Z])/g, "$1 $2").trim();
  }

  saveInvoice() {
    this.dataSave.emit({
      tab: this.id,
      pageIndex: this.pageIndex,
      data: this.editableData,
    });
  }

  changeValue(event: any, key: string, tableIndex: number = -1) {
    if (tableIndex < 0) {
      if (this.editableData[key]) {
        this.editableData[key]["value"] = event.target.innerText;
      } else {
        this.editableData[key] = {
          value: event.target.innerText,
          confidence: 0.0,
        };
      }
    } else {
      if (
        this.editableData["Items"][tableIndex] &&
        this.editableData["Items"][tableIndex][key]
      ) {
        this.editableData["Items"][tableIndex][key] = {
          ...this.editableData["Items"][tableIndex][key],
          value: event.target.innerText,
        };
      }
    }
  }

  checkGstKeysPresent(data: any): boolean {
    return this.gstKeys.some((key) => key in data);
  }

  getGstKeysWithValues(data: any) {
    const keysWithData: any[] = [];
    for (const key of this.gstKeys) {
      if (key in data && data[key]?.value) {
        keysWithData.push(key);
      }
    }
    return keysWithData;
  }

  getLabelForGstKey(key: string): string {
    return this.gstKeyLabels[key] || key; // Return user-friendly label if available, otherwise return the key itself
  }
}

<!-- <p style="margin-top: 30vh; font-size: 50px; text-align: center">Mappings</p> -->
<div class="view-wrapper">
  <ng-container *ngIf="!appService.isMobileView()">
    <div class="left-pane">
      <div class="pane-heading">FIELDS</div>
      <div class="content">
        <div class="tabs">
          <div class="bg" [ngClass]="targetOrSource"></div>
          <div
            class="tab"
            [ngClass]="{ active: targetOrSource === 'target' }"
            (click)="changeSelectionTab('target')">
            <span
              class="image"
              [ngClass]="{
                targetSelected: targetOrSource === 'target',
                targetUnselected: targetOrSource === 'source'
              }"></span>
            Target
          </div>
          <div
            class="tab"
            [ngClass]="{ active: targetOrSource === 'source' }"
            (click)="changeSelectionTab('source')">
            <span
              class="image"
              [ngClass]="{
                sourceSelected: targetOrSource === 'source',
                sourceUnselected: targetOrSource === 'target'
              }"></span>
            Source
          </div>
        </div>
        <ng-container *ngIf="targetOrSource === 'target'">
          <span class="hint">New Element</span>
          <br />
          <div
            cdkDropList
            id="newFieldList"
            cdkDropListSortingDisabled
            [cdkDropListConnectedTo]="['mappingsList']">
            <div class="draggable-field" cdkDrag (dblclick)="addNewField(0)">
              <img src="assets/images/settings/string.svg" />
              <span>String</span>
            </div>
            <div class="draggable-field" cdkDrag (dblclick)="addNewField(1)">
              <img src="assets/images/settings/number.svg" />
              <span>Numeric</span>
            </div>
            <div class="draggable-field" cdkDrag (dblclick)="addNewField(2)">
              <img src="assets/images/settings/calendar.svg" />
              <span style="margin-left: 7px">Date</span>
            </div>
            <div class="draggable-field" cdkDrag (dblclick)="addNewField(3)">
              <img src="assets/images/settings/hash.svg" />
              <span>Validation</span>
            </div>
            <div class="draggable-field" cdkDrag (dblclick)="addNewField(4)">
              <img src="assets/images/settings/box.svg" />
              <span>Variable</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="search-box">
            <i class="ti ti-search"></i>
            <input
              type="text"
              autocomplete="off"
              [(ngModel)]="searchText"
              (keyup)="searchElements($event)"
              name="search"
              placeholder="Search Field" />
          </div>
          <div
            class="hint"
            style="margin-top: 10px"
            *ngIf="
              targetOrSource === 'target' &&
              allMappingElementsLoaded &&
              filteredElements.length === 0
            ">
            Empty
          </div>
          <div
            *ngIf="targetOrSource === 'target'"
            class="list"
            id="fieldsList"
            cdkDropList
            [cdkDropListData]="filteredElements"
            [cdkDropListConnectedTo]="['mappingsList']"
            (scroll)="onScroll($event)"
            cdkDropListSortingDisabled>
            <div
              class="draggable-field"
              cdkDrag
              *ngFor="let element of filteredElements; let elementIndex = index"
              [ngClass]="{
                'element-selected':
                  elementIds.indexOf(element?.elementId) !== -1
              }"
              (dblclick)="manageElement(element)"
              [title]="element['elementLabel']"
              [cdkDragDisabled]="isElementInCurrentLineMappings(element)">
              <div class="item">
                <ng-container [ngSwitch]="element['datatype'].toLowerCase()">
                  <img
                    *ngSwitchCase="'numeric'"
                    src="assets/images/settings/number.svg" />
                  <img
                    *ngSwitchCase="'number'"
                    src="assets/images/settings/number.svg" />
                  <img
                    *ngSwitchCase="'varchar2'"
                    src="assets/images/settings/string.svg" />
                  <img
                    *ngSwitchCase="'date'"
                    src="assets/images/settings/calendar.svg" />
                  <img
                    *ngSwitchCase="'validation'"
                    src="assets/images/settings/hash.svg" />
                  <i *ngSwitchDefault class="ti ti-exclamation-circle"></i>
                </ng-container>
                <div class="item-name">{{ element["elementLabel"] }}</div>
              </div>
              <i
                class="ti ti-info-circle info-desc"
                [matTooltip]="element?.description || 'No description'"
                matTooltipPosition="above"></i>
            </div>
            <div class="inner-skeleton-div" *ngIf="!allMappingElementsLoaded">
              <div class="skeleton inner-mapping"></div>
              <div class="skeleton inner-mapping"></div>
              <div class="skeleton inner-mapping"></div>
            </div>
          </div>
        </ng-container>

        <ng-container style="padding: 8px" *ngIf="targetOrSource === 'source'">
          <div class="search-box" [style.margin-bottom]="'7px'">
            <i class="ti ti-search"></i>
            <input
              type="text"
              autocomplete="off"
              [(ngModel)]="searchText"
              (input)="filterSourceHeaders($event)"
              name="search"
              placeholder="Search Source Header" />
          </div>
          <div
            class="hint"
            style="margin-top: 10px"
            *ngIf="
              filteredInvoiceKeyList &&
              filteredInvoiceKeyList.length === 0 &&
              mappings['importReadRules']['fileType'] === 'PDF/IMAGE'
            ">
            Empty
          </div>
          <div
            class="hint"
            style="margin-top: 10px"
            *ngIf="
              searchSourceHeaders &&
              searchSourceHeaders.length === 0 &&
              mappings['importReadRules']['fileType'] !== 'PDF/IMAGE' &&
              kbrJSON === null
            ">
            Empty
          </div>
          <div class="ai-skeleton-div" *ngIf="fetchingSourceElements">
            <div class="skeleton ai-mapping"></div>
            <div class="skeleton ai-mapping"></div>
            <div class="skeleton ai-mapping"></div>
          </div>
          <div
            class="source-elements"
            id="invoiceKeysList"
            *ngIf="!fetchingSourceElements"
            cdkDropList
            [cdkDropListConnectedTo]="cdkConnected"
            cdkDropListSortingDisabled>
            <ng-container
              *ngIf="
                invoiceKeysList.length > 0 &&
                mappings['importReadRules']['fileType'] === 'PDF/IMAGE'
              ">
              <ng-container
                *ngFor="
                  let source of filteredInvoiceKeyList
                    | sortOrderBy: 'displayName';
                  let i = index
                ">
                <div
                  class="draggable-field"
                  cdkDrag
                  [ngClass]="{
                    tableDisabled: source['elementType'] === 'table'
                  }"
                  [cdkDragData]="source['displayName']"
                  [title]="
                    source['elementType'] === 'table'
                      ? source['description'] || 'No description'
                      : ''
                  "
                  [matTooltip]="
                    source['elementType'] !== 'table'
                      ? source['description'] || 'No description'
                      : ''
                  ">
                  <span>{{ source["displayName"] }}</span>
                  <ng-container
                    *ngIf="
                      source['elementType'] === 'table' &&
                      source['tableElements']
                    ">
                    <div (click)="showTableHeaders(i, source['displayName'])">
                      <i
                        class="ti ti-chevrons-down show-header"
                        *ngIf="!displayShowTableHeaders[source['displayName']]"
                        matTooltip="Expand"
                        matTooltipPosition="above"></i>
                      <i
                        class="ti ti-chevrons-up show-header"
                        *ngIf="displayShowTableHeaders[source['displayName']]"
                        matTooltip="Collapse"
                        matTooltipPosition="above"></i>
                      <span></span>
                    </div>
                  </ng-container>
                </div>
                <ng-container
                  *ngIf="
                    displayShowTableHeaders[source['displayName']] &&
                    source['tableElements']
                  ">
                  <div
                    *ngFor="
                      let header of source['tableElements']
                        | sortOrderBy: 'displayName'
                    "
                    class="draggable-field table-values"
                    cdkDrag
                    [cdkDragData]="
                      source['displayName'] + ':' + header['displayName']
                    ">
                    <span
                      [matTooltip]="header['description'] || 'No description'"
                      >{{ header["displayName"] }}</span
                    >
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                sourceFileHeaders &&
                searchSourceHeaders.length > 0 &&
                mappings['importReadRules']['fileType'] !== 'PDF/IMAGE' &&
                mappings['importReadRules']['fileAttributes'][
                  'fileFormat'
                ]?.toLowerCase() !== 'kbr proposal'
              ">
              <ng-container
                *ngFor="let header of searchSourceHeaders; let i = index">
                <div
                  class="draggable-field"
                  cdkDrag
                  [cdkDragData]="header"
                  [matTooltip]="header">
                  <span>{{ header }}</span>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                mappings['importReadRules']['fileType']?.toLowerCase() ===
                  'xls' &&
                mappings['importReadRules']['fileAttributes'][
                  'fileFormat'
                ]?.toLowerCase() === 'kbr proposal'
              ">
              <ng-container
                *ngFor="let sheet of kbrJSON['sheets']; let i = index">
                <div class="draggable-field">
                  <span>{{ sheet["sheetName"] }}</span>
                  <div>
                    <i
                      class="ti ti-chevrons-down show-header"
                      *ngIf="!sheetExpanded[i]?.['totalExpanded']"
                      (click)="expandSheet(i)"
                      matTooltip="Expand"
                      matTooltipPosition="above"></i>
                    <i
                      class="ti ti-chevrons-up show-header"
                      *ngIf="sheetExpanded[i]?.['totalExpanded']"
                      (click)="collapseSheet(i)"
                      matTooltip="Collapse"
                      matTooltipPosition="above"></i>
                    <span></span>
                  </div>
                </div>
                <ng-container *ngIf="sheetExpanded[i]?.['totalExpanded']">
                  <ng-container
                    *ngFor="let sheetData of sheet['sheetData']; let j = index">
                    <div
                      class="draggable-field"
                      style="width: calc(100% - 20px); margin-left: 15px">
                      <span>Table {{ j + 1 }}</span>
                      <i
                        class="ti ti-chevrons-down show-header"
                        *ngIf="
                          !sheetExpanded[i]?.['subSectionsExpanded'][
                            'table' + j
                          ]
                        "
                        matTooltip="Expand"
                        (click)="
                          sheetExpanded[i]?.['subSectionsExpanded'][
                            'table' + j
                          ] = true
                        "
                        matTooltipPosition="above"></i>
                      <i
                        class="ti ti-chevrons-up show-header"
                        *ngIf="
                          sheetExpanded[i]?.['subSectionsExpanded']['table' + j]
                        "
                        matTooltip="Collapse"
                        (click)="
                          sheetExpanded[i]?.['subSectionsExpanded'][
                            'table' + j
                          ] = false
                        "
                        matTooltipPosition="above"></i>
                    </div>
                    <ng-container
                      *ngIf="
                        sheetExpanded[i]?.['subSectionsExpanded']['table' + j]
                      ">
                      <ng-container
                        *ngFor="let firstKey of getSortedObjectKeys(sheetData)">
                        <div
                          class="draggable-field"
                          style="width: calc(100% - 30px); margin-left: 25px">
                          <span>{{ firstKey }}</span>
                          <i
                            class="ti ti-chevrons-down show-header"
                            *ngIf="
                              !sheetExpanded[i]?.['subSectionsExpanded'][
                                firstKey
                              ]
                            "
                            matTooltip="Expand"
                            (click)="
                              sheetExpanded[i]?.['subSectionsExpanded'][
                                firstKey
                              ] = true
                            "
                            matTooltipPosition="above"></i>
                          <i
                            class="ti ti-chevrons-up show-header"
                            *ngIf="
                              sheetExpanded[i]?.['subSectionsExpanded'][
                                firstKey
                              ]
                            "
                            matTooltip="Collapse"
                            (click)="
                              sheetExpanded[i]?.['subSectionsExpanded'][
                                firstKey
                              ] = false
                            "
                            matTooltipPosition="above"></i>
                        </div>
                        <ng-container
                          *ngIf="
                            sheetExpanded[i]?.['subSectionsExpanded'][firstKey]
                          ">
                          <ng-container
                            *ngFor="
                              let secondKey of sheetData[firstKey]['data']
                                | getObjectKeys
                            ">
                            <div
                              class="draggable-field"
                              style="
                                width: calc(100% - 40px);
                                margin-left: 35px;
                              ">
                              <span>{{ secondKey }}</span>
                              <i
                                class="ti ti-chevrons-down show-header"
                                *ngIf="
                                  !sheetExpanded[i]?.['subSectionsExpanded'][
                                    secondKey
                                  ]
                                "
                                matTooltip="Expand"
                                (click)="
                                  sheetExpanded[i]?.['subSectionsExpanded'][
                                    secondKey
                                  ] = true
                                "
                                matTooltipPosition="above"></i>
                              <i
                                class="ti ti-chevrons-up show-header"
                                *ngIf="
                                  sheetExpanded[i]?.['subSectionsExpanded'][
                                    secondKey
                                  ]
                                "
                                matTooltip="Collapse"
                                (click)="
                                  sheetExpanded[i]?.['subSectionsExpanded'][
                                    secondKey
                                  ] = false
                                "
                                matTooltipPosition="above"></i>
                            </div>
                            <ng-container
                              *ngIf="
                                sheetExpanded[i]?.['subSectionsExpanded'][
                                  secondKey
                                ]
                              ">
                              <ng-container
                                *ngFor="
                                  let thirdKey of sheetData[firstKey]['data'][
                                    secondKey
                                  ] | getObjectKeys
                                ">
                                <div
                                  class="draggable-field"
                                  style="
                                    width: calc(100% - 50px);
                                    margin-left: 45px;
                                  "
                                  cdkDrag
                                  [cdkDragData]="
                                    sheet['sheetName'] +
                                    '.' +
                                    'Table' +
                                    (j + 1) +
                                    '.' +
                                    firstKey +
                                    '.' +
                                    secondKey +
                                    '.' +
                                    thirdKey
                                  ">
                                  <span>{{ thirdKey }}</span>
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="center-pane">
    <div class="sheets-wrapper">
      <div
        *ngFor="
          let line of mappings.distinctLineTypes;
          let lineTypeIndex = index
        "
        class="sheet-item"
        [title]="line"
        [ngClass]="{ 'sheet-selected': currentLine === line }"
        (click)="selectLineType(line, lineTypeIndex, 'select')">
        <span class="sheet-name"
          ><b>{{ line }}</b>
        </span>
        <span
          *ngIf="currentLine === line"
          class="edit"
          (click)="$event.stopPropagation(); renameSheet(line, lineTypeIndex)"
          title="Rename Sheet"
          ><img src="assets/images/settings/pencil.svg" height="14px"
        /></span>
        <span
          class="close"
          (click)="
            $event.stopPropagation(); deleteLineType(line, lineTypeIndex)
          "
          title="Delete sheet">
          <i
            class="ti ti-x"
            [ngClass]="{
              defaultSheetIcon: mappings['distinctLineTypes'].length === 1
            }"></i>
          <!-- [title]="mappings['distinctLineTypes'].length === 1?'deletion not allowed':''" -->
        </span>
      </div>
      <div class="add-sheet" (click)="openAddSheet()">
        <!-- <span *ngIf="mappings.distinctLineTypes.length == 0" class="sheet-name"><b>Add Sheet</b> </span> -->
        <i class="ti ti-plus"></i>
      </div>
    </div>
    <div class="content">
      <div class="table" (click)="unselectColumn()">
        <div class="head">
          <div class="th" style="border: none">#</div>
          <div class="th">Target</div>
          <div class="th">Transformation</div>
          <div class="th">Source</div>
          <div class="th">Actions</div>
          <!-- <th></th> -->
        </div>
        <div class="line"></div>
        <div class="hint" *ngIf="!currentLine">
          Please add a sheet before adding fields.
        </div>
        <div class="ai-skeleton-div" *ngIf="isAiMappingFetching">
          <div class="skeleton ai-mapping"></div>
          <div class="skeleton ai-mapping"></div>
          <div class="skeleton ai-mapping"></div>
          <div class="skeleton ai-mapping"></div>
        </div>
        <div
          class="body"
          cdkDropList
          id="mappingsList"
          (cdkDropListDropped)="dragDrop($event)"
          *ngIf="currentLine && !isAiMappingFetching">
          <ng-container *ngIf="disableTargetMappingButton()">
            <div class="target-element-button">
              <div class="placeholder-Div">
                <p class="desc">
                  Drag and drop the target elements from the field section
                </p>
                <p class="desc">or</p>
                <button
                  class="button-common"
                  (click)="getTargetMappingElements()">
                  Fetch elements from target
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="mappings['mapping']?.['lines']?.length > 0">
            <div
              class="inputs-row"
              cdkDrag
              cdkDragLockAxis="y"
              cdkDragBoundary=".body"
              *ngFor="
                let mappingElement of mappings['mapping']?.['lines']?.[
                  selectedLineIndex
                ]?.mappingElements;
                let i = index
              "
              [ngClass]="{
                selected: selectedColumn === mappingElement,
                error: errorElementIndices.includes(i)
              }"
              tabindex="0"
              [id]="'inputs_row_' + i"
              (click)="
                $event.stopPropagation(); selectColumn(mappingElement, i)
              ">
              <div class="column serial">
                <div class="item map">
                  {{ i + 1 }}
                  <i
                    class="ti ti-asterisk"
                    *ngIf="mappingElement.required === 'Y'"></i>
                </div>
                <div class="info-description">
                  <i
                    class="ti ti-info-circle info-desc"
                    [matTooltip]="
                      mappingElement?.description || 'No description'
                    "
                    matTooltipPosition="above"></i>
                </div>
              </div>
              <div
                class="column title"
                *ngIf="!mappingElement.isNewField && !mappingElement.isEditing"
                (dblclick)="tryEdit(mappingElement, i)"
                [title]="mappingElement.elementLabel">
                <ng-container
                  [ngSwitch]="mappingElement['datatype'].toLowerCase()">
                  <img
                    *ngSwitchCase="'numeric'"
                    src="assets/images/settings/number.svg" />
                  <img
                    *ngSwitchCase="'number'"
                    src="assets/images/settings/number.svg" />
                  <img
                    *ngSwitchCase="'varchar2'"
                    src="assets/images/settings/string.svg" />
                  <img
                    *ngSwitchCase="'date'"
                    src="assets/images/settings/calendar.svg" />
                  <img
                    *ngSwitchCase="'validation'"
                    src="assets/images/settings/hash.svg" />
                  <i *ngSwitchDefault class="ti ti-exclamation-circle"></i>
                </ng-container>
                <div class="element-name">
                  {{ mappingElement.elementLabel }}
                </div>
              </div>
              <div
                class="column title"
                *ngIf="mappingElement.isNewField || mappingElement.isEditing">
                <input
                  type="text"
                  [(ngModel)]="mappingElement.elementLabel"
                  (keypress)="onLabelChange($event)"
                  (click)="clearNewFieldInput(mappingElement)" />
              </div>
              <div class="column">
                <ng-container
                  *ngIf="
                    mappingElement.elementLabel === 'Validation' &&
                    !mappingElement.elementLabel.includes('Variable')
                  ">
                  <app-custom-select
                    [searchable]="true"
                    [data]="functionParameters"
                    [inputValue]="mappingElement.function"
                    [placeholder]="'Select Validation'"
                    [type]="'VALIDATION'"
                    [dropdownId]="'functions-' + i"
                    [openDropdownId]="openDropdownId"
                    (handleDropdownToggle)="handleDropdownToggle($event)"
                    (valueChange)="
                      checkFunctionParameters($event, mappingElement)
                    "
                    (emitUpdateStatus)="emitUpdateStatus()"
                    (clearTransformationFunction)="
                      handleClearTransformationFunction($event)
                    "></app-custom-select>
                </ng-container>
                <ng-container
                  *ngIf="
                    mappingElement.elementLabel !== 'Validation' &&
                    !mappingElement.elementLabel.includes('Variable')
                  ">
                  <app-custom-select
                    [searchable]="true"
                    [data]="functionParameters"
                    [disabled]="mappingElement.isEditing"
                    [inputValue]="mappingElement.function"
                    [dropdownId]="'functions-' + i"
                    [openDropdownId]="openDropdownId"
                    (handleDropdownToggle)="handleDropdownToggle($event)"
                    [type]="mappingElement.datatype"
                    (valueChange)="
                      checkFunctionParameters($event, mappingElement)
                    "
                    (emitUpdateStatus)="emitUpdateStatus()"
                    (clearTransformationFunction)="
                      handleClearTransformationFunction($event)
                    "></app-custom-select>
                </ng-container>
                <ng-container
                  *ngIf="
                    mappingElement.elementLabel.includes('Variable') &&
                    mappingElement.elementLabel !== 'Validation'
                  ">
                  <app-custom-select
                    [searchable]="true"
                    [data]="functionParameters"
                    [inputValue]="mappingElement.function"
                    [type]="'VARIABLE'"
                    [dropdownId]="'functions-' + i"
                    [openDropdownId]="openDropdownId"
                    (handleDropdownToggle)="handleDropdownToggle($event)"
                    (valueChange)="
                      checkFunctionParameters($event, mappingElement)
                    "
                    (emitUpdateStatus)="emitUpdateStatus()"
                    (clearTransformationFunction)="
                      handleClearTransformationFunction($event)
                    "></app-custom-select>
                </ng-container>
              </div>
              <div
                class="column source-placeholder tooltip-common"
                cdkDropList
                (cdkDropListDropped)="newDrop($event, mappingElement)"
                [id]="
                  (mappingElement.elementLabel !== 'Validation'
                    ? 'sourceDrop'
                    : '') + i
                "
                [matTooltip]="
                  mappings['importReadRules']['fileType'] === 'PDF/IMAGE'
                    ? 'Drag & Drop Source Elements'
                    : ''
                ">
                <!-- <span
				  id="update"
				  class="tooltip-utils center"
				  *ngIf="mappings['importReadRules']['fileType'] === 'PDF/IMAGE'"
				  >Drag & Drop Source Elements</span
				> -->
                <input
                  matTooltipPosition="above"
                  [(ngModel)]="mappingElement.sourceFileColumnNumber"
                  [disabled]="
                    mappings['importReadRules']['fileType'] === 'PDF/IMAGE' ||
                    isSourceDisabled(mappingElement)
                  "
                  [ngClass]="{
                    'cursor-default':
                      mappings['importReadRules']['fileType'] === 'PDF/IMAGE'
                  }"
                  type="text"
                  placeholder="Specify Source"
                  (ngModelChange)="emitUpdateStatus()" />
              </div>
              <div class="column actions">
                <img
                  src="assets/Svgs_/Dashboard/Delete.svg"
                  alt=""
                  (click)="manageElement(mappingElement, 'delete', i)"
                  style="cursor: pointer" />
                <div class="move-handle" cdkDragHandle>
                  <img
                    src="assets/Svgs_/TransformationStudio/Grip.svg"
                    alt="" />
                </div>
              </div>
            </div>
          </ng-container>
          <div class="table-anchor" #tableAnchor></div>
        </div>
        <div class="notice" #centerNotice>{{ notice }}</div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!appService.isMobileView()">
    <div class="right-pane">
      <div class="pane-heading">PROPERTIES</div>
      <div class="not-selected-hint" *ngIf="!selectedColumn">
        Select any field to view properties.
      </div>
      <div class="content" *ngIf="selectedColumn">
        <div class="attr" *ngIf="!selectedColumn['isEditing']">
          <label>{{
            selectedColumn.elementLabel !== "Validation"
              ? "Required"
              : "Validation Required"
          }}</label>
          <select
            name="required"
            [(ngModel)]="selectedColumn.required"
            (ngModelChange)="emitUpdateStatus()"
            (change)="checkValidationRule(selectedColumn)">
            <option value="Y">Yes</option>
            <option value="N">No</option>
            <option value="C">Conditional</option>
          </select>
          <!-- <input [(ngModel)]="selectedColumn.required" type="radio" value="Y" (ngModelChange)="emitUpdateStatus()"/> Yes
		  <input [(ngModel)]="selectedColumn.required" type="radio" value="N" (ngModelChange)="emitUpdateStatus()"/> No -->
        </div>
        <div
          class="attr"
          *ngIf="
            selectedColumn.required === 'C' && !selectedColumn['isEditing']
          ">
          <label>Condition</label>
          <input
            [(ngModel)]="selectedColumn.validationExpression"
            type="text"
            (ngModelChange)="emitUpdateStatus()" />
        </div>

        <div class="attr" *ngIf="!selectedColumn['isEditing']">
          <label>{{
            selectedColumn.elementLabel !== "Validation"
              ? "Default Values"
              : "Validation Message"
          }}</label>
          <input
            [(ngModel)]="selectedColumn.defaultValue"
            type="text"
            [disabled]="isDefaultValueDisabled(selectedColumn)"
            (ngModelChange)="emitUpdateStatus()" />
        </div>
        <div class="attr">
          <label>Description</label>
          <textarea
            class="mapping-textarea"
            [(ngModel)]="selectedColumn.description"
            type="text"
            (ngModelChange)="emitUpdateStatus()"></textarea>
        </div>

        <ng-container
          *ngIf="showFunctionForm() && !selectedColumn['isEditing']">
          <div class="heading">
            <span>Transformation Parameters</span>
            <div class="line"></div>
          </div>

          <app-dynamic-form
            *ngIf="!showQueryForm(selectedColumn)"
            [form]="functionForm"
            [formGroup]="formGroup"
            [existingValues]="existingFormValue"
            [fieldTemplate]="funtionParameterElement">
          </app-dynamic-form>
          <ng-template #funtionParameterElement let-field>
            <div class="attr">
              <label *ngIf="field.type !== 'checkbox'"
                >{{ field.label }}
                <ng-container *ngIf="field.isRequired"
                  ><span class="required-star">*</span></ng-container
                >
                <span
                  class="tooltip-common lookup-tooltip-parent"
                  *ngIf="field.tooltip">
                  <i class="ti ti-help"></i>
                  <span class="tooltip-utils right lookup-tooltip-child">{{
                    field.tooltip
                  }}</span>
                </span>
              </label>
              <ng-container *ngTemplateOutlet="field.formField"></ng-container>
            </div>
          </ng-template>
        </ng-container>
        <div
          *ngIf="showQueryForm(selectedColumn) && !selectedColumn['isEditing']">
          <app-qeury-builder
            [groups]="ifExistCondtion"
            [isEmptyValues]="isExistEmptyValues"
            (updateValues)="
              updateQueryBuilderValues($event)
            "></app-qeury-builder>
        </div>

        <!-- <button *ngIf="!selectedColumn.isNewField && !selectedColumn.isEditing" (click)="unselectColumn()"><i class="ti ti-device-floppy"></i> <span>Update</span></button> -->
        <button
          class="button-common"
          *ngIf="selectedColumn.isNewField"
          (click)="saveNewField(selectedColumn, selectedColumnIndex)">
          <span>Save Element</span>
        </button>
        <button
          class="button-common"
          *ngIf="selectedColumn.isEditing"
          (click)="updateFieldName(selectedColumn, selectedColumnIndex)">
          Update Element
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="main-wrapper" *ngIf="false">
  <div class="sheets-wrapper">
    <div class="sheets-flex">
      <div
        *ngIf="
          mappings?.mapping?.mappingType === 'M' &&
          mappings.distinctLineTypes.length > 0
        "
        class="sheet-action-wrapper">
        <div
          *ngFor="
            let line of mappings.distinctLineTypes;
            let lineTypeIndex = index
          "
          class="sheet-item">
          <span
            class="sheet-name"
            [ngClass]="{ 'sheet-selected': currentLine === line }"
            (click)="selectLineType(line, lineTypeIndex, 'select')"
            ><b>{{ line }}</b>
          </span>
          <!-- *ngIf="mappings.distinctLineTypes.length > 1" -->
          <span class="close" (click)="deleteLineType(line, lineTypeIndex)">
            <!-- <img src="{{ assetsUrl }}/assets/images/Icon Cancel-white.svg" style="width: 12px;"> -->
            <i class="fas fa-times"></i>
            <!-- &#10006; -->
          </span>
        </div>
      </div>
      <!-- <button (click)="updateMapping()">update</button> -->
      <button
        *ngIf="!showLineTypeInput"
        class="add-btn"
        (click)="toggleLineType('showInput')">
        Add Sheet
      </button>
      <span *ngIf="showLineTypeInput" class="actions">
        <ng-container *ngIf="isSafari; else datalist0">
          <ng-select
            appendTo="body"
            class="form-control"
            [(ngModel)]="newLineType"
            (input)="checkLineType()"
            placeholder="Enter Sheet Name"
            name="sheetName1">
            <ng-option
              [value]="sheetName"
              *ngFor="let sheetName of sheetNameList">
              {{ sheetName }}
            </ng-option>
          </ng-select>
        </ng-container>

        <ng-template #datalist0>
          <input
            #lineTypeInput
            class="line-input"
            list="sheetNameList"
            [(ngModel)]="newLineType"
            type="text"
            (input)="checkLineType()"
            placeholder="Enter Sheet Name" />
          <datalist id="sheetNameList">
            <option *ngFor="let sheetName of sheetNameList">
              {{ sheetName }}
            </option>
          </datalist>
        </ng-template>
        <span (click)="toggleLineType('save')" class="true">&#10003;</span>
        <span (click)="toggleLineType('close')" class="false">&#10006;</span>
      </span>
    </div>
    <div class="action-icons">
      <div class="tooltip">
        <button class="validation-btn" (click)="addValidationElement()">
          Add Validation
        </button>
      </div>
      <div class="tooltip">
        <!-- <img
			class="mt-3"
			src="{{ assetsUrl }}/assets/images/Button Insert-blue.svg"
			(click)="openElementManager()"
		  /> -->
        <i
          class="far fa-plus-square icon-style"
          style="padding: 2px 8px"
          (click)="openElementManager()"></i>
        <span id="insert" class="insertTooltipText">Insert</span>
      </div>
      <div class="tooltip">
        <i class="far fa-edit icon-style" (click)="updateMapping()"></i>
        <span id="update" class="updateTooltipText">Update</span>
      </div>
      <div class="tooltip">
        <i
          class="fas fa-eraser icon-style"
          style="font-size: 24px; padding: 1px"
          (click)="showClearMapping()"></i>
        <span id="clear" class="clearTooltipText"></span>
      </div>
    </div>
  </div>

  <div class="mappings-wrapper">
    <div class="header-wrapper">
      <div class="column column-heading action">
        <span>#</span>
      </div>
      <div class="column column-heading">
        <span>Elements</span>
      </div>
      <div class="column column-heading">
        <span>Import Columns</span>
      </div>
      <div class="column column-heading">
        <span>Function</span>
      </div>

      <div class="column column-heading">
        <span>Function Parameters</span>
      </div>
      <div class="column column-heading">
        <span>Default Values</span>
      </div>
      <div class="column small column-heading">
        <span>Required</span>
      </div>
      <div class="column column-heading">
        <span>Validation Rule</span>
      </div>
      <div class="column action column-heading">Move</div>
      <div class="column action"></div>
    </div>
    <div
      class="elements-wrapper"
      *ngIf="mappings['mapping']?.['lines']?.length > 0">
      <div
        class="inputs-row"
        *ngFor="
          let mappingElement of mappings['mapping']?.['lines']?.[
            selectedLineIndex
          ]?.mappingElements;
          let i = index
        ">
        <div class="column action">
          {{ i + 1 }}
        </div>
        <div class="column title">
          <!-- title="{{ showDepElementsTitle(requiredDetail) }}" -->
          {{ mappingElement.elementLabel }}
        </div>
        <div class="column">
          <input
            [(ngModel)]="mappingElement.sourceFileColumnNumber"
            type="text"
            (ngModelChange)="emitUpdateStatus()" />
          <!-- (blur)="validateImportColumnValue(mappingElement)" -->
        </div>
        <div class="column">
          <select
            [(ngModel)]="mappingElement.function"
            (ngModelChange)="emitUpdateStatus()">
            <option [ngValue]="null">Select Function</option>

            <ng-container
              *ngIf="
                mappingElement.elementLabel.includes('Staging Element');
                else nonStaging
              ">
              <!-- <option *ngFor="let option of functions['VALIDATION']" [ngValue]="option['name']">
				  {{ option["name"] }}****
				</option> -->

              <!-- <ng-container *ngFor="let item of functions['TRANSFORMATION'][0] | getObjectKeys"> -->
              <option
                *ngFor="
                  let option of this.mappings?.functions | removeDuplicate
                "
                [ngValue]="option['name']">
                {{ option["name"] }}
              </option>
              <!-- </ng-container> -->
            </ng-container>

            <ng-template #nonStaging>
              <ng-container
                *ngIf="
                  mappingElement.elementLabel === 'Validation';
                  else transform
                ">
                <option
                  *ngFor="let option of functions['VALIDATION']"
                  [ngValue]="option['name']">
                  {{ option["name"] }}
                </option>
              </ng-container>
              <ng-template #transform>
                <option
                  *ngFor="let option of getFunction(mappingElement['datatype'])"
                  [ngValue]="option['name']">
                  {{ option["name"] }}
                </option>
              </ng-template>
            </ng-template>
          </select>
        </div>
        <div class="column">
          <input
            [(ngModel)]="mappingElement.functionParameters"
            type="text"
            (ngModelChange)="emitUpdateStatus()" />
        </div>
        <div class="column">
          <input
            [(ngModel)]="mappingElement.defaultValue"
            type="text"
            (ngModelChange)="emitUpdateStatus()" />
        </div>
        <div class="column small">
          <!-- <label class="toggle small">
			  <input
				[(ngModel)]="mappingElement.required"
				[checked]="mappingElement.required"
				type="checkbox"
			  />
			  <div>Status</div>
			</label> -->
          <select
            [(ngModel)]="mappingElement.required"
            (ngModelChange)="emitUpdateStatus()">
            <option
              *ngFor="let option of requiredOptions"
              [ngValue]="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
        <div class="column">
          <input
            [(ngModel)]="mappingElement.validationExpression"
            type="text"
            (ngModelChange)="emitUpdateStatus()" />
        </div>
        <div class="column action text-center">
          <div class="column action">
            <i
              class="flip-icons flip-chevron-up"
              style="position: relative; cursor: pointer; margin: -10px -5px"
              (click)="orderUp(mappingElement, i)"></i>
            <i
              class="flip-icons flip-chevron-down"
              style="
                position: relative;
                cursor: pointer;
                margin: 0px -5px;
                padding-top: 2px;
              "
              (click)="orderDown(mappingElement, i)"></i>
          </div>
        </div>
        <div class="column action text-center">
          <!-- <img
			  (click)="manageElement(mappingElement)"
			  src="{{ assetsUrl }}/assets/images/Button Delete-blue.svg"
			  alt=""
			/> -->
          <i
            class="far fa-trash-alt"
            style="font-size: 16px; color: #2f3251; cursor: pointer"
            (click)="manageElement(mappingElement, 'delete', i)"></i>
        </div>
      </div>
    </div>
  </div>
</div>

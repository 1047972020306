import { Subject } from "rxjs";
import { AppService } from "src/app/core/services/app.service";
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { HeaderTitleInfo } from "src/app/core/models/header-title-info.model";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() headerTitle: any;
  public headerTitleTooltip: any;
  @Input() assetsUrl: any;
  @Input() sideBarToggle: boolean = false;
  @ViewChild("headerEditBox") headerEditBox: ElementRef | null = null;
  unsubscribe = new Subject<void>();
  settingsSection = "chase";
  public showSystemTab: boolean = false;
  public departmentList: any[] = [];
  public selectedDept;
  public username: string = "";
  public folders: any[] = [];
  public showFoldersDropDown = false;
  public profile: any = null;
  public isEditing = false;
  private headerTitleInfo: HeaderTitleInfo | null = null;
  public isHeaderError = false;
  public headerTitleLength: number = 60;
  public pipelinePage: boolean = false;
  public pipelineName: string = "";
  public pipelinePath: string = "";
  public isNewPipeline: boolean = false;
  private isConfirming: boolean = false;
  private importNameEditToastArray = {
    duration: 2000,
    type: "alert",
    message: "Character limit exceeded (Maximum 60 characters allowed).",
  };
  constructor(
    public appService: AppService,
    public router: Router
  ) {
    this.appService
      .getNewHeader()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (typeof res === "string") {
          this.headerTitle = res;
          this.headerTitleTooltip = res;
          this.headerTitleInfo = null;
        } else {
          if (
            this.headerTitle != res.title &&
            this.router.url.includes(res.page)
          ) {
            this.headerTitle = res.title;
            this.headerTitleInfo = res;
          }
          if (res.title.endsWith("Unsaved Pipeline")) {
            this.isEditing = true;
            return;
          }
        }
        if (this.isEditing) {
          this.isEditing = false;
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.pipelinePage = this.router.url.includes("pipelines/manage");
    this.isNewPipeline = this.router.url.includes("newPipeline=true");
    if (this.pipelinePage) {
      let pathList = this.headerTitle.split("/");
      this.pipelineName = pathList[2].trim();
      if (pathList[0] === "...") {
        this.pipelinePath = pathList[0] + "/" + pathList[1] + " / ";
      } else {
        this.pipelinePath = pathList[0] + "/" + pathList[1];
      }
    }
    const currentUser = this.appService.getCurrentUser();
    this.getProfileData();
    if (currentUser) {
      this.username = currentUser["username"];
      // this.getDepartmentsForUser();
      // this.appService.setcurrentDepartment(this.selectedDept);
    }
    this.appService.currentSelectedDepartment.subscribe((res) => {
      if (res && res.departmentList) {
        this.departmentList = res.departmentList;
      }

      if (res && (res.selected || res.selected == 0)) {
        this.selectedDept = res.selected;
      }
    });

    this.appService.currentSettingsSection.subscribe((val) => {
      if (val != this.settingsSection) {
        this.settingsSection = val;
      }
    });

    this.appService.getProfileInfo().subscribe((res) => {
      this.profile = res;
    });

    this.appService.isHeaderTitelError.subscribe((res) => {
      this.isHeaderError = res;
    });

    // this.appService.isSettingsSystemTabVisible().subscribe((response) => {
    //   if (response['success'] === 'true') {
    //     if (response['visible'] === 'false') {
    //       this.showSystemTab = false;
    //     }
    //     else {
    //       this.showSystemTab = true;
    //     }
    //   }
    // })

    // this.appService.getFolders().subscribe((res) => {
    //   this.folders = res["teams"];
    // });
  }

  getProfileName(user: any) {
    if (user) {
      const updatedUser =
        user.firstName.charAt(0).toUpperCase() +
        user.lastName.charAt(0).toUpperCase();
      return updatedUser;
    } else {
      return "--";
    }
  }
  getDepartmentsForUser() {
    this.appService
      .getDepartments()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res) => {
          if (res) {
            if (res["departments"] && res["departments"].length == 2) {
              this.departmentList = res["departments"].filter(
                (f) => f.name !== "ALL"
              );
              this.selectedDept = this.departmentList[0].id;
              this.deptSelected();
            } else {
              this.departmentList = res["departments"];
              const all = this.departmentList
                .filter((f) => f.name === "ALL")
                .map((m) => m.id);
              if (all && all.length) {
                this.selectedDept = all[0];
                this.deptSelected();
              }
            }
          }
        },
        error: (err) => {
          this.appService.showToast({
            duration: 3000,
            type: "error",
            message: err.error?.message || err.message,
          });
        },
      });
  }

  deptSelected() {
    this.appService.setcurrentDepartment({
      selected: this.selectedDept,
      departmentList: this.departmentList,
    });
  }

  changeSection(event: string) {
    this.settingsSection = event;
    this.appService.changeCurrentSettingsSection(event);
  }

  get allFolders() {
    return [
      ...this.folders.filter((f) => f["name"] == "ALL"),
      ...this.folders.filter((f) => f["name"] != "ALL"),
    ];
  }

  get selectedDeptName() {
    return (
      this.departmentList.find((dept) => dept["id"] == this.selectedDept)?.[
        "name"
      ] ?? ""
    );
  }

  closeDropDown() {
    setTimeout(() => {
      this.showFoldersDropDown = false;
    }, 300);
  }

  isTabVisible(tab: string) {
    let role = "USER";
    // 1 -> USER
    // 2 -> DEVELOPER
    // 3 -> ANALYST
    // 4 -> ADMIN
    if (this.profile && this.profile.roles.length > 0) {
      role = this.profile.roles[0].roleName;
    }
    // Show only when role is DEVELOPER or ADMIN
    if (
      tab == "connections" &&
      role.toLowerCase() != "developer" &&
      role.toLowerCase() != "admin"
    ) {
      return false;
    }
    // Show only to ADMIN
    if (tab == "system" && role.toLowerCase() != "admin") {
      return false;
    }

    return true;
  }

  public toggleUserProfile: boolean = false;
  public submenus: any = [
    { key: "profile", name: "Profile Settings" },
    // { key: "changePassword", name: "Change Password" },
    { key: "logout", name: "Logout" },
  ];
  @Output() headerProfileAction = new EventEmitter();
  toggleSubmenu(event: any) {
    this.toggleUserProfile = !this.toggleUserProfile;
    this.isProfileSubmenuOpened();
  }
  checkActiveSubmenu(menu: string) {
    return this.router.url.includes(menu.toLowerCase().replaceAll(" ", "-"));
  }
  navigate(menu: any, action) {
    let data = {
      action: action,
      menu: menu,
    };
    // this.currentTab = menu.value;
    this.headerProfileAction.emit(data);
    this.toggleUserProfile = false;
  }

  @HostListener("document:click", ["$event.target"])
  clickOutside(targetElement: any) {
    if (targetElement.classList.contains("button-details")) {
    } else {
      this.toggleUserProfile = false;
    }
  }

  getProfileData() {
    this.appService.getUserData().subscribe({
      next: (res) => {
        this.profile = res;
        this.appService.setProfileInfo(res);
        localStorage.setItem(
          "currentUserFirstName",
          JSON.stringify(res["firstName"])
        );
        localStorage.setItem(
          "currentUserLastName",
          JSON.stringify(res["lastName"])
        );
        localStorage.setItem(
          "currentUserName",
          JSON.stringify(res["username"])
        );
      },
      error: (err) => {
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data);
        }
      },
    });
  }

  isEditableHeader() {
    this.pipelinePage = this.router.url.includes("pipelines/manage");
    this.isNewPipeline = this.router.url.includes("newPipeline=true");
    if (this.pipelinePage) {
      let pipelineAccess = JSON.parse(
        localStorage.getItem("pipelineaccess") || "false"
      );
      if (!pipelineAccess) {
        return false;
      }
      if (this.isNewPipeline) {
        this.isEditing = true;
      }
      let pathList = this.headerTitle.split("/");
      if (pathList[0] === "...") {
        this.pipelinePath = pathList[0] + "/" + pathList[1] + " / ";
        this.pipelineName = pathList[2]?.trim();
      } else {
        this.pipelinePath = pathList[0] + "/";
        this.pipelineName = pathList[1]?.trim();
      }
    }
    return (
      this.router.url.includes("/pipelines/manage") &&
      this.headerTitle &&
      this.headerTitle !== "Pipelines" &&
      sessionStorage.getItem("currentFolderName") !== this.headerTitle
    );
  }

  onHeaderEdit(event: any) {
    if (event.keyCode === 27) {
      if (this.isNewPipeline === true) {
        this.isEditing = true;
      } else {
        this.isEditing = false;
      }
    }
    if (
      event.target.innerText.length > this.headerTitleLength &&
      event.key.length === 1 &&
      !event.ctrlKey
    ) {
      event.preventDefault();
      this.isHeaderError = true;
      this.appService.showToast(this.importNameEditToastArray);
      return;
    }
    if (event.keyCode === 13) {
      const newTitle = this.headerEditBox?.nativeElement?.innerText;
      event.preventDefault();
      if (
        this.pipelineName?.length > this.headerTitleLength ||
        newTitle?.length > this.headerTitleLength
      ) {
        this.isHeaderError = true;
        return;
      } else {
        this.isHeaderError = false;
        this.confirmEditing();
      }
    }
  }

  editHeaderTitle() {
    this.isEditing = true;
    setTimeout(() => {
      this.headerEditBox?.nativeElement?.focus();
      document
        .getSelection()
        ?.setPosition(this.headerEditBox?.nativeElement, 1);
    }, 200);
  }

  closeEditing() {
    this.isEditing = false;
  }

  confirmEditing() {
    if (this.isConfirming) return; // Prevent multiple triggers
    this.isConfirming = true;
    const newTitle = this.headerEditBox?.nativeElement?.innerText;

    if (
      this.pipelineName?.length > this.headerTitleLength ||
      newTitle.length > this.headerTitleLength
    ) {
      this.isHeaderError = true;
      this.appService.showToast(this.importNameEditToastArray);
      this.isConfirming = false; // Reset the flag
      return;
    }
    if (newTitle && newTitle.trim() != this.pipelineName.trim()) {
      this.pipelineName = newTitle.trim();
      this.appService.onHeaderTitleEdit.next({
        title: this.pipelineName,
        page: this.headerTitleInfo?.page || "pipelines/manage",
        isEvent: true,
        eventId: this.headerTitleInfo?.eventId,
      });
    } else if (!this.router.url.includes("pipelines/manage?newPipeline=true")) {
      this.isEditing = false;
    }
    this.isConfirming = false; // Reset the flag after execution
  }

  toggleSidebar() {
    this.appService.sideBarToggle = !this.appService.sideBarToggle;
  }
  isProfileSubmenuOpened() {
    if (this.toggleUserProfile && this.appService.isMobileView())
      this.appService.sideBarToggle = false;
  }
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.isProfileSubmenuOpened();
  }
}

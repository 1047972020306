import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.scss"],
})
export class CustomSelectComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}
  public searchInput: string = "";
  public currentType: string = "";
  public openUpward: boolean = false;
  @Input() searchable: boolean = false;
  @Input() data: any[] = [];
  @Input() disabled: boolean = false;
  @Input() inputValue: string = "";
  @Input() type: string = "";
  @Input() placeholder: string = "Select Transformation";
  @Input() dropdownId: string = `${Math.random() * 100}`;
  @Input() openDropdownId: string | null = null;
  @Output() valueChange = new EventEmitter();
  @Output() emitUpdateStatus = new EventEmitter();
  @Output() handleDropdownToggle = new EventEmitter();
  @ViewChild("searchInputRef") searchInputElement!: ElementRef;
  @Input() nestedData: boolean = false;
  @Input() isEmptyValues: boolean = true;
  public showOptions: boolean = false;
  public isOptionClicked: boolean = false;
  public groups: any[] = [];
  public functions: any[] = [];
  public initialFunctions: any[] = [];
  @Output() clearTransformationFunction = new EventEmitter();
  @ViewChild("customSelectContent") parent!: ElementRef;
  ngOnInit(): void {
    if (!this.nestedData) {
      if (this.type.toLowerCase() === "varchar2") {
        this.currentType = "string";
      } else if (this.type.toLowerCase() === "numeric") {
        this.currentType = "numeric";
      } else if (this.type.toLowerCase() === "date") {
        this.currentType = "date";
      } else if (this.type.toLowerCase() === "validation") {
        this.currentType = "validation";
      } else {
        this.currentType = "variable";
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !this.nestedData) {
      this.mutateData();
    }
    if (changes.type && !this.nestedData) {
      if (this.type.toLowerCase() === "varchar2") {
        this.currentType = "string";
      } else if (this.type.toLowerCase() === "numeric") {
        this.currentType = "numeric";
      } else if (this.type.toLowerCase() === "date") {
        this.currentType = "date";
      } else if (this.type.toLowerCase() === "validation") {
        this.currentType = "validation";
      } else {
        this.currentType = "variable";
      }
      this.mutateData();
    }
  }
  get isDropdownOpened(): boolean {
    return this.dropdownId === this.openDropdownId;
  }
  mutateData() {
    this.functions = this.data;
    this.initialFunctions = this.data;
    const designatedFunctions = this.getDesignatedFunctions();
    if (!this.data) return;
    this.groups = this.data
      .map((item) => ({
        group: item.group,
        opened: false,
      }))
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.group === item.group) &&
          designatedFunctions.includes(item.group.toLowerCase())
      )
      .sort((a, b) => {
        if (a.group.toLowerCase() === this.currentType) return -1;
        if (b.group.toLowerCase() === this.currentType) return 1;
        return 0;
      });
    if (this.groups.length === 1) {
      this.groups[0].opened = true;
    }
  }
  getDesignatedFunctions() {
    if (this.currentType === "string") {
      return ["data manipulation", "data extraction", "conditional", "string"];
    } else if (this.currentType === "numeric") {
      return ["data manipulation", "data extraction", "conditional", "numeric"];
    } else if (this.currentType === "date") {
      return ["conditional", "date"];
    } else if (this.currentType === "validation") {
      return ["conditional validation"];
    } else {
      return this.data?.map((item) => item.group?.toLowerCase());
    }
  }
  onSearch(event: any) {
    if (!this.nestedData) {
      event.stopPropagation();
      const value = (event.target as HTMLInputElement).value.toLowerCase();
      this.searchInput = value;
      this.functions = this.initialFunctions?.filter((fn) =>
        fn.displayName.toLowerCase().includes(value)
      );
      if (!value) {
        this.groups = this.groups.map((item) => {
          return { ...item, opened: false };
        });
      } else {
        const groupsToOpen = new Set(this.functions.map((item) => item.group));
        this.groups.forEach((group) => {
          group.opened = groupsToOpen.has(group.group);
        });
      }
    }
  }
  toggleDropdown() {
    this.searchInput = "";
    if (!this.nestedData) {
      if (this.dropdownId !== this.openDropdownId) {
        this.handleDropdownToggle.emit(this.dropdownId);
      } else {
        this.handleDropdownToggle.emit(null);
      }
      let functions = this.initialFunctions?.filter(
        (fn) => fn.name === this.inputValue
      );
      if (!this.inputValue) {
        this.groups = this.groups.map((item) => {
          return { ...item, opened: false };
        });
      } else {
        const groupsToOpen = new Set(functions.map((item) => item.group));
        this.groups.forEach((group) => {
          group.opened = groupsToOpen.has(group.group);
        });
      }
      setTimeout(() => {
        this.scrollToActive();
      }, 200);
      setTimeout(() => {
        if (this.isDropdownOpened) {
          const parentRect = this.el?.nativeElement.getBoundingClientRect();
          const viewHeight = window.innerHeight;
          if (parentRect.top + 250 > viewHeight) {
            this.openUpward = true;
          } else {
            this.openUpward = false;
          }
        }
        const inputElement = this.renderer?.selectRootElement(
          this.searchInputElement?.nativeElement
        );
        inputElement.focus();
      });
    }
  }
  toggleSubDropdown(index: any) {
    this.groups[index].opened = !this.groups[index].opened;
  }
  getFunctions(group: string) {
    return this.functions?.filter((fn) => fn.group === group);
  }
  isGroupNameEmpty(group: string) {
    return group === "";
  }
  inputChanged(input: any, event: any) {
    event.stopPropagation();
    const value = input.name ? input.name : input;
    this.valueChange.emit(value);
    this.emitUpdateStatus.emit();
    this.handleDropdownToggle.emit(null);
  }
  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest("custom-select-content")) {
      this.handleDropdownToggle.emit(null);
    }
  }

  //   onInputFocus(): void {
  //     this.showOptions = true;
  //   }
  // Keep dropdown open when input is blurred, allowing clicks to be processed
  //   onInputBlur() {
  //     this.emitValues();
  //     setTimeout(() => {
  //       if (!this.el.nativeElement.contains(document.activeElement)) {
  //         this.showOptions = false;
  //       }
  //     }, 400);
  //     this.isOptionClicked = false;
  //   }
  //   onOptionMouseDown() {
  //     this.isOptionClicked = true;
  //   }
  //   onInputChange(value: string): void {
  //     this.inputValue = value;
  //     this.showOptions = true;
  //     this.emitValues();
  //   }

  onSelectChange(value: string): void {
    this.inputValue = value;
    this.showOptions = false;
    this.emitValues();
  }

  emitValues() {
    this.valueChange.emit(this.inputValue);
  }

  get filterOptions(): any[] {
    if (!this.inputValue) {
      // If input is empty, return the data as is
      return this.data;
    }
    // Filter only to identify matching options but keep the full list
    const matchingOptions = this.data?.filter((option) =>
      option.toLowerCase().includes(this.inputValue.toLowerCase())
    );
    // Move the matching options to the top without removing non-matching ones
    const nonMatchingOptions = this.data?.filter(
      (option) => !option.toLowerCase().includes(this.inputValue.toLowerCase())
    );
    // Return the matching options first, followed by the non-matching options
    return [...matchingOptions, ...nonMatchingOptions];
  }

  isInvalid(value: string): boolean {
    return !value || value.trim() === "";
  }

  inputValuedisplayName(value: string) {
    const displayItem: any = this.data?.find(
      (item) => item["name"]?.toLowerCase() === value?.toLowerCase()
    );
    return displayItem?.["displayName"] || displayItem?.["name"];
  }
  handleClearTransformationFunction(value: string) {
    this.clearTransformationFunction.emit(value);
  }
  scrollToActive() {
    const activeChild = this.functions.find(
      (item) => item.name === this.inputValue
    );
    if (activeChild) {
      let el = document.getElementById("customSelectContent");
      if (el) {
        const childElements = el.querySelectorAll(".values");
        const activeChild = Array.from(childElements).find((child) =>
          child.classList.contains("values-active")
        );
        if (activeChild) {
          activeChild.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }
}

<div class="main-Div">
  <app-lottie-flip-design></app-lottie-flip-design>
  <div class="right-div-Login">
    <div class="center-div">
      <div class="right-div register" *ngIf="!isRegisteredSuccess">
        <div class="header-div1">
          <label class="title space-grotesk">Create your account</label>
          <div class="desc-div">
            <label class="desc schibsted-grotesk"
              >Register by adding the details below</label
            >
          </div>
        </div>
        <div class="fields-div">
          <form
            class="form-div"
            [formGroup]="formGroup"
            (ngSubmit)="onSubmit()">
            <div *ngIf="registerError" class="errorField">
              <img src="assets/Svgs_/SideBar/Info.svg" />{{ registerError }}
            </div>
            <div class="login-input">
              <label class="title schibsted-grotesk">Username</label>
              <input
                type="text"
                name="username"
                formControlName="username"
                class="inputFields schibsted-grotesk"
                placeholder="name@company.com" />
              <div
                class="errorField"
                *ngIf="username?.touched && username?.errors">
                <img src="assets/Svgs_/SideBar/Info.svg" />
                {{
                  username?.errors && username?.errors?.["required"]
                    ? "Username is required"
                    : "Invalid username! Should be valid email"
                }}
              </div>
            </div>
            <div class="login-flex">
              <div class="login-input">
                <label for="firstName" class="title schibsted-grotesk"
                  >First Name</label
                >
                <input
                  type="text"
                  name="firstName"
                  formControlName="firstName"
                  class="inputFields schibsted-grotesk sub-inputFields" />
                <div
                  class="errorField"
                  *ngIf="firstName?.touched && firstName?.errors">
                  <img src="assets/Svgs_/SideBar/Info.svg" /> First Name is
                  required
                </div>
              </div>
              <div class="login-input">
                <label for="lastName" class="title schibsted-grotesk"
                  >Last Name</label
                >
                <input
                  type="text"
                  name="lastName"
                  formControlName="lastName"
                  class="inputFields schibsted-grotesk sub-inputFields" />
                <div
                  class="errorField"
                  *ngIf="lastName?.touched && lastName?.errors">
                  <img src="assets/Svgs_/SideBar/Info.svg" /> Last Name is
                  required
                </div>
              </div>
            </div>
            <div class="login-flex">
              <div class="login-input">
                <label for="password" class="title schibsted-grotesk"
                  >Password</label
                >
                <div class="input-div">
                  <input
                    class="inputFields schibsted-grotesk sub-inputFields"
                    [type]="isPasswordsVisible[0] ? 'text' : 'password'"
                    name="password"
                    autocomplete="new-password"
                    (keyup)="onPasswordChange($event)"
                    formControlName="password"
                    placeholder="*************" />
                  <div class="pass-icon">
                    <ng-container *ngIf="isPasswordsVisible[0]">
                      <img
                        src="assets/Svgs_/Login/HidePassword.svg"
                        alt="show"
                        (click)="togglePasswordVisibility(0)" />
                    </ng-container>
                    <ng-container *ngIf="!isPasswordsVisible[0]">
                      <img
                        src="assets/Svgs_/Login/ShowPassword.svg"
                        alt="Hide"
                        (click)="togglePasswordVisibility(0)" />
                    </ng-container>
                  </div>
                </div>
                <div
                  *ngIf="
                    password?.invalid && (password?.dirty || password?.touched)
                  ">
                  <div *ngIf="password?.errors?.required" class="errorField">
                    <img src="assets/Svgs_/SideBar/Info.svg" /> Password is
                    required.
                  </div>
                  <div *ngIf="password?.errors?.pattern" class="errorField">
                    <img src="assets/Svgs_/SideBar/Info.svg" />Invalid password
                    : 8-16 char, uppercase, num & special character.
                  </div>
                </div>
              </div>
              <div class="login-input">
                <label for="confirmPassword" class="title schibsted-grotesk"
                  >Confirm Password</label
                >
                <div class="input-div">
                  <input
                    [type]="isPasswordsVisible[1] ? 'text' : 'password'"
                    autocomplete="off"
                    (keyup)="onPasswordChange($event)"
                    name="confirmPassword"
                    formControlName="confirmPassword"
                    placeholder="*************"
                    class="inputFields schibsted-grotesk sub-inputFields" />
                  <div class="pass-icon">
                    <ng-container *ngIf="isPasswordsVisible[1]">
                      <img
                        src="assets/Svgs_/Login/HidePassword.svg"
                        alt="show"
                        (click)="togglePasswordVisibility(1)" />
                    </ng-container>
                    <ng-container *ngIf="!isPasswordsVisible[1]">
                      <img
                        src="assets/Svgs_/Login/ShowPassword.svg"
                        alt="Hide"
                        (click)="togglePasswordVisibility(1)" />
                    </ng-container>
                  </div>
                </div>
                <div
                  *ngIf="
                    confirmPassword?.touched &&
                    (confirmPassword?.errors || isNotSamePasswords)
                  ">
                  <div
                    *ngIf="confirmPassword?.errors?.['required']"
                    class="errorField">
                    <img src="assets/Svgs_/SideBar/Info.svg" /> Confirm password
                    is required
                  </div>
                  <div
                    *ngIf="
                      !confirmPassword?.errors?.['required'] &&
                      isNotSamePasswords
                    "
                    class="errorField">
                    <img src="assets/Svgs_/SideBar/Info.svg" /> Passwords does
                    not match
                  </div>
                </div>
              </div>
            </div>
            <div class="login-input">
              <label for="userName" class="title schibsted-grotesk"
                >Tenant</label
              >
              <input
                class="inputFields schibsted-grotesk"
                type="text"
                name="userName"
                formControlName="tenant"
                placeholder="E.g. Kanerika"
                autocomplete="off" />
              <div *ngIf="tenant?.touched && tenant?.errors" class="errorField">
                <img src="assets/Svgs_/SideBar/Info.svg" />Tenant is required
              </div>
            </div>
            <input
              type="submit"
              value="Create"
              class="button-common schibsted-grotesk"
              [ngClass]="{
                gradient: !(
                  formGroup.invalid ||
                  password?.value !== confirmPassword?.value
                )
              }"
              [disabled]="
                formGroup.invalid || password?.value !== confirmPassword?.value
              " />
            <div class="context">
              <label class="schibsted-grotesk">Already have an account?</label>
              <a routerLink="/login" class="schibsted-grotesk">Sign In</a>
            </div>
          </form>
        </div>
      </div>
      <div class="right-div register" *ngIf="isRegisteredSuccess">
        <div class="reset-div">
          <img src="../../assets/Svgs_/Login/EmailConformation.svg" />
          <label class="desc schibsted-grotesk"
            >An email with a link has been sent to your registered email address
            to confirm your registration. Kindly check your inbox or spam
            folder.</label
          >
          <button class="button-common schibsted-grotesk">
            Request a New Link
          </button>
        </div>
      </div>
    </div>
    <div class="footer-div">
      <div class="desc-div" *ngIf="!isRegisteredSuccess">
        <label class="desc schibsted-grotesk"
          >By Signing up for a Flip account you agree to our
          <a href="https://kanerika.com/privacy-policy/" target="_blank"
            >Privacy Policy</a
          >
          and
          <a [href]="authPageService.termsOfServiceLink" target="_blank"
            >Terms of Service</a
          >.
        </label>
      </div>
      <label *ngIf="isRegisteredSuccess" class="schibsted-grotesk">
        © Copyright 2024 - FLIP - Kanerika
      </label>
    </div>
  </div>
</div>

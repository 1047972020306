<div class="main-Div">
  <app-lottie-flip-design></app-lottie-flip-design>
  <div class="right-div-Login">
    <div class="center-div">
      <div class="right-div" *ngIf="!emailConfirmation">
        <div class="header-div1">
          <label class="title space-grotesk">Reset your password</label>
          <div class="desc-div">
            <label class="desc schibsted-grotesk"
              >Enter the email address associated with your account.</label
            >
            <label class="desc schibsted-grotesk"
              >If you have an account, we’ll send a reset link to your
              email.</label
            >
          </div>
        </div>
        <div class="fields-div">
          <form (ngSubmit)="onSubmit()" class="form-div">
            <div class="errorField" *ngIf="errorText">
              <i class="flip-ions flip-info"></i> {{ errorText }}
            </div>
            <div class="login-input">
              <input
                type="text"
                [(ngModel)]="username"
                placeholder="username@company.com"
                name="username"
                class="inputFields schibsted-grotesks" />
            </div>
            <button class="button-common gradient schibsted-grotesk">
              Send Reset Link
            </button>
            <div class="context center gradient schibsted-grotesk">
              <a (click)="router.navigate(['login'])">Back to Sign In</a>
            </div>
          </form>
        </div>
      </div>
      <div class="right-div" *ngIf="emailConfirmation">
        <div class="reset-div">
          <img src="../../assets/Svgs_/Login/EmailConformation.svg" />
          <label class="desc schibsted-grotesk"
            >Reset password link has been sent to your mail address. Please
            check your inbox.</label
          >
          <div class="context center gradient schibsted-grotesk">
            <a (click)="router.navigate(['login'])">Back to Sign In</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-div schibsted-grotesk">
      <label> © Copyright 2024 - FLIP - Kanerika </label>
    </div>
  </div>
</div>

<div
  (click)="$event.stopPropagation()"
  [ngClass]="{ filterBySelected: showFilter, filterBy: !showFilter }"
  style="border: none">
  <div
    [ngClass]="{ filterBySelected: filterApplied, filterBy: !filterApplied }"
    (click)="showHideFilterIp()">
    <img
      *ngIf="!filterApplied"
      src="assets/Svgs_/Monitor/Filter.svg"
      alt=""
      height="16px"
      width="16px" />
    <img
      *ngIf="filterApplied"
      src="assets/images/settings/filterSelected.svg"
      alt=""
      height="16px"
      width="16px" />
    <p [ngStyle]="{ color: filterApplied ? '#F36F21' : '' }">Filter</p>
  </div>
  <img
    *ngIf="showFilter"
    src="assets/images/settings/triangle.svg"
    class="triangle" />
  <div *ngIf="showFilter" class="filterBox">
    <div class="filterSection">
      <div class="heading">
        <p>Filter</p>
        <div class="tooltip-common">
          <img
            src="assets/images/settings/close.svg"
            (click)="showHideFilterIp()" />
          <span class="tooltip-utils right">Close</span>
        </div>
      </div>
      <div class="search">
        <div class="searchText">
          <h1>Search by Keywords</h1>
        </div>
        <div class="global-search-content filter-search">
          <img src="assets/Svgs_/Monitor/Search.svg" />
          <input
            type="search"
            class="search"
            placeholder="Search here"
            style="font-size: 12px; font-weight: 400"
            [ngModel]="filterSearch"
            (input)="changeFilterSearch($event)" />
        </div>
      </div>
      <div class="filterOptions">
        <div *ngFor="let filterOption of filterArray; let i = index">
          <div
            [ngClass]="{
              accordionOpened: filterOption.opened,
              filterAccordion: !filterOption.opened
            }"
            class="filterAccordion"
            (click)="openAccordion(i)">
            {{ filterOption.name }}
            <img
              *ngIf="!filterOption.opened"
              src="assets/images/settings/filterPlus.svg" />
            <img
              *ngIf="filterOption.opened"
              src="assets/images/settings/filterMinus.svg" />
          </div>
          <div *ngIf="filterOption.opened" class="accordionData">
            <div *ngIf="filterOption.data.length > 0">
              <div
                *ngFor="let data of filterOption.data; let j = index"
                class="data">
                <div
                  class="checkboxIcon"
                  (click)="filterCheckbox(data.name, j)"
                  *ngIf="filterOption.name !== 'Date'">
                  <img
                    *ngIf="data.selected"
                    src="assets/Svgs_/Monitor/Checkbox-Select.svg" />
                  <img
                    *ngIf="!data.selected"
                    src="assets/Svgs_/Monitor/CheckBox-UnSelected.svg" />
                </div>
                <div
                  class="radioButtonGroup"
                  *ngIf="filterOption.name === 'Date'">
                  <input
                    type="radio"
                    [checked]="data.selected"
                    (input)="radioButtonsClicked(filterOption.data, j)" />
                </div>
                <p>{{ data.name }}</p>
              </div>

              <div class="customDateGroup" *ngIf="filterOption.id === 'date'">
                <div *ngIf="customRangeSelected" class="customDate">
                  <label>Start Date</label>
                  <input
                    type="date"
                    [ngModel]="startDate"
                    (input)="dateInputsChanged('start', $event)" />
                </div>
                <div *ngIf="customRangeSelected" class="customDate">
                  <label>End Date</label>
                  <input
                    type="date"
                    [ngModel]="endDate"
                    (input)="dateInputsChanged('end', $event)" />
                </div>
              </div>
            </div>
            <div *ngIf="filterOption.data.length === 0">
              <p>No search results</p>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="submitSection">
      <button class="button-common secondary-btn" (click)="resetFiltering()">
        Reset
      </button>
      <button
        class="button-common primary-btn"
        (click)="applyFiltering()"
        [disabled]="!isAnyFilterSelected()">
        Apply
      </button>
    </div>
  </div>
</div>

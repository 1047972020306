import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-excel-viewer",
  templateUrl: "./excel-viewer.component.html",
  styleUrls: ["./excel-viewer.component.scss"],
})
export class ExcelViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string = "";
  @Input() data: any;
  @Input() sheetNames: string[] = [];
  @Input() editable: boolean = false;
  @Input() events: BehaviorSubject<any> | null = null;
  @Output() dataSave = new EventEmitter<any>();
  @Output() scrollEnd = new EventEmitter<any>();
  public currentSheetName = "";
  public maxOfColumns = 0;
  public selectedCellIndices: [number, number] = [-1, -1];
  public isEditingTheSelectedCell = false;
  public headers: string[] = [];
  public sheetData: any[] = [];
  public isSheetDataChanged = false;
  private backupSheetData: any[] = [];
  public modifiedRows: number[] = [];
  private renderedPages = 1;
  private lastScrollTop = 0;
  private unsubscribe$ = new Subject<void>();
  constructor() {}

  ngOnInit(): void {
    if (this.data != null) {
      this.currentSheetName = this.sheetNames[0];
      if (this.data[this.currentSheetName]) {
        this.calculateMaxColumns();
        this.generateHeaders();
        this.generateSheetData();
      }
    }
    if (this.events != null) {
      this.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
        if (event.id == this.id) {
          if (event.type == "cancel") {
            this.sheetData = [];
            this.sheetData = JSON.parse(JSON.stringify(this.backupSheetData));
            this.isSheetDataChanged = false;
            this.modifiedRows = [];
          } else if (event.type == "save") {
            let payload = {};
            // this.dataSave.emit(this.sheetData);
            for (let rowIndex of this.modifiedRows) {
              payload[`${rowIndex + 1}`] = this.sheetData[rowIndex]
                .map((row) => row["value"])
                .filter((row) => row != undefined && row != null);
            }
            this.dataSave.emit({
              tab: this.id,
              sheet: this.currentSheetName,
              data: payload,
            });
          } else if (event.type == "saved") {
            for (let rowIndex of this.modifiedRows) {
              this.data[rowIndex] = this.sheetData[rowIndex]
                .map((row) => row["value"])
                .filter((row) => row != undefined && row != null);
              this.backupSheetData[rowIndex] = JSON.parse(
                JSON.stringify(this.sheetData[rowIndex])
              );
            }
          } else if (event.type == "new_data") {
            this.calculateMaxColumns();
            this.generateHeaders();
            this.generateSheetData();
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["data"] &&
      changes["data"].previousValue != changes["data"].currentValue
    ) {
      this.calculateMaxColumns();
      this.generateHeaders();
      this.generateSheetData();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  calculateMaxColumns() {
    this.maxOfColumns = 0;
    if (this.data[this.currentSheetName])
      this.data[this.currentSheetName].forEach((row) => {
        if (row.length > this.maxOfColumns) {
          this.maxOfColumns = row.length;
        }
      });
  }

  generateHeaders() {
    let headers: string[] = [];
    for (let i = 0; i < this.maxOfColumns; i++) {
      let header = "";
      let num = i;
      while (num >= 0) {
        let remainder = num % 26;
        header = String.fromCharCode(65 + remainder) + header;
        num = Math.floor(num / 26) - 1;
      }
      headers.push(header);
    }
    this.headers = headers;
  }

  generateSheetData() {
    if (
      this.currentSheetName &&
      this.data &&
      this.data[this.currentSheetName]
    ) {
      let data: any[] = [];
      for (let d = 0; d < this.data[this.currentSheetName].length; d++) {
        for (let i = 0; i < this.maxOfColumns; i++) {
          data[d] ??= [];
          data[d][i] = { value: this.data[this.currentSheetName][d][i] };
        }
      }
      this.sheetData = data;
      this.backupSheetData = JSON.parse(JSON.stringify(data));
      return;
    }
    this.sheetData = [];
    this.backupSheetData = [];
  }

  setSelectedSheetName(sheet: string) {
    if (this.isSheetDataChanged) {
      alert("Save sheet before switching");
      return;
    }
    this.currentSheetName = sheet;
    this.calculateMaxColumns();
    this.generateHeaders();
    this.generateSheetData();
  }

  selectCell(i, j) {
    if (
      (this.selectedCellIndices[0] == i && this.selectedCellIndices[1] == j) ||
      i > this.sheetData.length - 1 ||
      j > this.maxOfColumns - 1
    ) {
      (
        document.querySelector(
          `#input-${this.selectedCellIndices[0]}-${this.selectedCellIndices[1]}`
        ) as any
      ).focus();
      return;
    }
    i = i >= 0 ? i : 0;
    j = j >= 0 ? j : 0;
    this.selectedCellIndices = [i, j];
    (document.querySelector(`#input-${i}-${j}`) as any).focus();
  }

  onDataChanges(event: any, i: number, j: number) {
    if (!this.editable) return;
    if (this.backupSheetData[i][j]["value"] != event.target.innerText) {
      this.sheetData[i][j]["value"] = event.target.innerText;
      if (!this.modifiedRows.includes(i)) {
        this.modifiedRows.push(i);
      }
      if (!this.isSheetDataChanged) {
        this.isSheetDataChanged = true;
      }
    }
  }

  @HostListener("keypress", ["$event"])
  onKeyPress(event: any) {
    if (event.which == 13) {
      event.preventDefault();
    }
  }

  onScroll(event: any) {
    if (event.target.scrollTop > this.lastScrollTop) {
      if (
        Math.ceil(event.target.offsetHeight + event.target.scrollTop) >=
        event.target.scrollHeight
      ) {
        this.scrollEnd.emit({
          tab: this.id,
          pageNo: this.renderedPages++,
          sheet: this.currentSheetName,
        });
      }
      this.lastScrollTop = event.target.scrollTop;
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: any) {
    if (event.key?.includes("Arrow")) {
      const key = event.key;
      let i = this.selectedCellIndices[0];
      let j = this.selectedCellIndices[1];
      event.target.blur();
      if (key == "ArrowUp") {
        i--;
      } else if (key == "ArrowDown") {
        i++;
      } else if (key == "ArrowLeft") {
        if (window.getSelection()?.anchorOffset == 0) j--;
      } else if (key == "ArrowRight") {
        if (
          (window.getSelection()?.anchorOffset ?? 0) >=
          event.target.innerText.length
        )
          j++;
      }
      this.selectCell(i, j);
    }
  }

  formatCellValue(value: string): string {
    // Replace spaces with non-breaking spaces for preserving whitespace
    return value?.replace(/ /g, "&nbsp;");
  }
}

export const homePageOptions: any = [
  "Home",
  "Dashboard",
  "Pipelines",
  "Monitor",
  "AI Workbench",
  "Settings",
  "Profile",
];

export const addAdminHomePageOptions = ["Admin Console"];

export const defaultHomePage: string = "Home";

export const pages = {
  home: "Home",
  dashboard: "Dashboard",
  pipelines: "Pipelines",
  monitor: "Monitor",
  AIWorkbench: "AI Workbench",
  settings: "Settings",
  profile: "Profile",
  adminConsole: "Admin Console",
};

export const userRoles = {
  admin: "ADMIN",
  developer: "DEVELOPER",
  user: "USER",
};

export enum preferenceTypes {
  landingPage = "landing_page",
  dashboardPage = "dashboard_page",
}

<div class="main-Div">
  <app-lottie-flip-design></app-lottie-flip-design>
  <div class="right-div-Login">
    <div class="center-div">
      <div
        class="right-div"
        *ngIf="isConfirmationIdValid && !successText && !loading">
        <div class="header-div1">
          <label class="title space-grotesk">Reset your password</label>
        </div>
        <div class="fields-div">
          <form
            [formGroup]="resetForm"
            class="form-div"
            *ngIf="
              isConfirmationIdValid && !successText && !loading && !linkExpired
            ">
            <!-- <form [formGroup]="resetForm"  class="form-div"> -->
            <div class="errorField" *ngIf="errorText">
              <img src="assets/Svgs_/SideBar/Info.svg" />{{ errorText }}
            </div>
            <!-- <div class="login-input">
                  <label for="userName" class="title">Username</label>
                  <input
                    class="inputFields"
                    type="text"
                    name="userName"
                    formControlName="userName"
                    placeholder="username@company.com"
                  />
                  <div
                    *ngIf="userName?.touched && userName?.errors"
                    class="errorField"
                  >
                    <img src="assets/Svgs_/SideBar/Info.svg" />Username is required
                  </div>
              </div> -->
            <div class="login-input">
              <label for="password" class="title schibsted-grotesk"
                >New Password</label
              >
              <div class="input-div">
                <input
                  type="password"
                  class="inputFields"
                  name="password"
                  formControlName="password"
                  (input)="clearValue()"
                  [type]="isConfirmPasswordVisible ? 'text' : 'password'" />
                <div class="pass-icon">
                  <ng-container *ngIf="isConfirmPasswordVisible">
                    <img
                      src="assets/Svgs_/Login/HidePassword.svg"
                      alt="show"
                      (click)="toggleConfirmPasswordVisibility()" />
                  </ng-container>
                  <ng-container *ngIf="!isConfirmPasswordVisible">
                    <img
                      src="assets/Svgs_/Login/ShowPassword.svg"
                      alt="Hide"
                      (click)="toggleConfirmPasswordVisibility()" />
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="
                  password?.invalid && (password?.dirty || password?.touched)
                "
                class="errorField">
                <img src="assets/Svgs_/SideBar/Info.svg" />
                <label *ngIf="password?.errors?.required"
                  >Password is Required</label
                >
                <label *ngIf="password?.errors?.pattern"
                  >Password must be 8-16 chars, with upper, lower, number, and
                  special symbol</label
                >
              </div>
            </div>
            <div class="login-input">
              <label for="confirmPassword" class="title schibsted-grotesk"
                >Confirm Password</label
              >
              <div class="input-div">
                <input
                  class="inputFields"
                  [type]="isPasswordVisible ? 'text' : 'password'"
                  name="confirmPassword"
                  (input)="clearValue()"
                  formControlName="confirmPassword" />
                <div class="pass-icon">
                  <ng-container *ngIf="isPasswordVisible">
                    <img
                      src="assets/Svgs_/Login/HidePassword.svg"
                      alt="show"
                      (click)="togglePasswordVisibility()" />
                  </ng-container>
                  <ng-container *ngIf="!isPasswordVisible">
                    <img
                      src="assets/Svgs_/Login/ShowPassword.svg"
                      alt="Hide"
                      (click)="togglePasswordVisibility()" />
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="
                  confirmPassword?.invalid &&
                  (confirmPassword?.dirty || confirmPassword?.touched)
                "
                class="errorField">
                <img src="assets/Svgs_/SideBar/Info.svg" />
                <label *ngIf="confirmPassword?.errors?.required"
                  >Confirm Password is Required</label
                >
                <label
                  *ngIf="
                    password?.value !== confirmPassword?.value &&
                    password?.valid &&
                    !confirmPassword?.errors?.required
                  ">
                  Password does not match
                </label>
              </div>
            </div>
            <button
              class="button-common schibsted-grotesk"
              [ngClass]="{
                gradient: !(
                  resetForm.invalid ||
                  password?.value !== confirmPassword?.value
                )
              }"
              (click)="reset()"
              [disabled]="
                resetForm.invalid || password?.value !== confirmPassword?.value
              ">
              Change Password
            </button>
          </form>
        </div>
      </div>
      <div
        class="right-div"
        *ngIf="!isConfirmationIdValid && !loading && linkExpired">
        <div class="reset-div">
          <img src="assets/Svgs_/Login/LinkExpired.svg" />
          <label class="title schibsted-grotesk">Link has expired</label>
          <label class="desc schibsted-grotesk"
            >For security reasons, password links expire after some time. You
            can still reset your password by requesting a new reset link.</label
          >
          <button class="button-common gradient schibsted-grotesk">
            Request a New Link
          </button>
        </div>
      </div>
    </div>
    <div class="footer-div schibsted-grotesk">
      <label> © Copyright 2024 - FLIP - Kanerika </label>
    </div>
  </div>
</div>

<div class="main-Div">
  <app-lottie-flip-design></app-lottie-flip-design>
  <div class="right-div-Login">
    <div class="center-div">
      <div
        class="right-div"
        *ngIf="!linkExpired && !updatedPassword && !linkInvalid">
        <div class="header-div1">
          <label class="title space-grotesk">Set your password</label>
        </div>
        <div class="fields-div">
          <form
            class="form-div"
            (ngSubmit)="setPassword()"
            [formGroup]="setPasswordForm">
            <div class="login-input">
              <label for="userName" class="title schibsted-grotesk"
                >Username</label
              >
              <input
                class="inputFields"
                type="text"
                name="userName"
                formControlName="username"
                placeholder="username@company.com"
                [disabled]="true" />
            </div>
            <div class="login-input">
              <label for="password" class="title schibsted-grotesk"
                >Password</label
              >
              <div class="input-div">
                <input
                  class="inputFields"
                  [type]="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  formControlName="password"
                  placeholder="*************"
                  autocomplete="new-password"
                  (keyup)="onPasswordChange($event)" />
                <div class="pass-icon">
                  <ng-container *ngIf="isPasswordVisible">
                    <i
                      class="flip-icons flip-eye-off"
                      alt="show"
                      (click)="togglePasswordVisibility()"></i>
                  </ng-container>
                  <ng-container *ngIf="!isPasswordVisible">
                    <i
                      class="flip-icons flip-eye"
                      alt="Hide"
                      (click)="togglePasswordVisibility()"></i>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="password?.touched && password?.errors"
                class="errorField">
                <i class="flip-icons flip-info"></i>
                <ng-container *ngIf="password?.errors?.['required']">
                  Password is required.
                </ng-container>
                <ng-container *ngIf="password?.errors?.['pattern']">
                  Password must be 8-16 chars, with upper, lower, number, and
                  special symbol
                </ng-container>
              </div>
            </div>
            <div class="login-input">
              <label for="password" class="title schibsted-grotesk"
                >Confirm Password</label
              >
              <div class="input-div">
                <input
                  class="inputFields"
                  [type]="isConfirmPasswordVisible ? 'text' : 'password'"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                  placeholder="*************"
                  autocomplete="new-password"
                  (keyup)="onPasswordChange($event)" />
                <div class="pass-icon">
                  <ng-container *ngIf="isConfirmPasswordVisible">
                    <i
                      class="flip-icons flip-eye-off"
                      alt="show"
                      (click)="toggleConfirmPasswordVisibility()"></i>
                  </ng-container>
                  <ng-container *ngIf="!isConfirmPasswordVisible">
                    <i
                      class="flip-icons flip-eye"
                      alt="Hide"
                      (click)="toggleConfirmPasswordVisibility()"></i>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="confirmPassword?.touched && confirmPassword?.errors"
                class="errorField">
                <i class="flip-icons flip-info"></i>Confirm Password is required
              </div>
              <div
                *ngIf="
                  !confirmPassword?.errors?.['required'] && isNotSamePasswords
                "
                class="errorField">
                <i class="flip-icons flip-info"></i>Passwords do not match
              </div>
            </div>

            <button
              class="button-common schibsted-grotesk"
              [ngClass]="{
                gradient: !(
                  setPasswordForm.invalid ||
                  password?.value !== confirmPassword?.value
                )
              }"
              [disabled]="disableConfirmButton()">
              Confirm Password
            </button>
          </form>
        </div>
      </div>
      <div
        class="right-div"
        *ngIf="linkExpired && !updatedPassword && !linkInvalid">
        <div class="reset-div">
          <img src="assets/Svgs_/Login/LinkExpired.svg" />
          <label class="title">Link has expired</label>
          <label class="desc schibsted-grotesk"
            >For security reasons, password links expire after some time. You
            can still set your password by requesting a new reset link.</label
          >
          <div
            class="button-common gradient schibsted-grotesk"
            (click)="requestForNewLink()">
            <a>Request a New Link</a>
          </div>
        </div>
      </div>
      <div
        class="right-div"
        *ngIf="!linkExpired && updatedPassword && !linkInvalid">
        <div class="reset-div">
          <img src="assets/Svgs_/Login/EmailVerified.svg" />
          <label class="title space-grotesk">Password Updated </label>
          <label class="desc schibsted-grotesk"
            >Your password has been updated successfully. Please login to
            continue using FLIP.</label
          >
          <div
            class="button-common gradient schibsted-grotesk"
            (click)="router.navigate(['login'])">
            <a>Back to Sign In</a>
          </div>
        </div>
      </div>
      <div
        class="right-div"
        *ngIf="!linkExpired && !updatedPassword && linkInvalid">
        <div class="reset-div">
          <img src="assets/Svgs_/Login/LinkInvalid.svg" />
          <label class="title">Invalid or Expired link</label>
          <div
            class="button-common gradient schibsted-grotesk"
            (click)="router.navigate(['login'])">
            <a>Back to Sign In</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-div">
      <label class="schibsted-grotesk">
        © Copyright 2024 - FLIP - Kanerika
      </label>
    </div>
  </div>
</div>

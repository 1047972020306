<div
  [ngClass]="{
    'main-sidebar': !sideBarToggle,
    'main-sidebar-maximized': sideBarToggle
  }">
  <section
    class="sidebar"
    [ngClass]="sideBarToggle ? 'activeSidebar' : ''"
    #sidebar>
    <ul class="menu-group-top">
      <ng-container *ngFor="let menu of menus?.top">
        <ng-container *ngIf="menu['newTabLink']">
          <li class="menu-item" [class.active]="menu.value === currentTab">
            <a [href]="menu['newTabLink']" target="_blank" class="anchor-link">
              <ng-container
                *ngTemplateOutlet="
                  menuItem;
                  context: {
                    $implicit: menu
                  }
                "></ng-container>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="!menu['newTabLink']">
          <li
            class="menu-item"
            (click)="navigate(menu, 'mainMenu')"
            [class.active]="menu.value === currentTab">
            <ng-container
              *ngTemplateOutlet="
                menuItem;
                context: { $implicit: menu }
              "></ng-container>
          </li>
        </ng-container>
      </ng-container>

      <ng-template #menuItem let-menu>
        <div class="align-icon-text-center tooltip-common">
          <img
            *ngIf="menu.key !== 'toggle'"
            src="{{
              menu.value === currentTab && menu.value !== 'Logo'
                ? menu.activeIcon
                : menu.icon
            }}"
            [ngClass]="menu.key === 'logo' ? 'logo' : 'menu-icon'" />
          <div
            *ngIf="sideBarToggle && menu.key === 'toggle'"
            class="logo-container">
            <img
              src="../../../assets/images/logos/favicon.png"
              class="flip-logo" />
            <img
              src="{{
                menu.value === currentTab && menu.value !== 'Logo'
                  ? menu.activeIcon
                  : menu.icon
              }}"
              [ngClass]="menu.key === 'logo' ? 'logo' : 'menu-icon'"
              (click)="changeToggle(menu)"
              style="margin-right: -20px" />
          </div>
          <img
            *ngIf="menu.key === 'toggle' && !sideBarToggle"
            src="{{
              menu.value === currentTab && menu.value !== 'Logo'
                ? menu.activeIcon
                : menu.icon
            }}"
            [ngClass]="menu.key === 'logo' ? 'logo' : 'menu-icon'"
            (click)="changeToggle(menu)" />
          <span
            *ngIf="
              menu.key !== 'toggle' &&
              !sideBarToggle &&
              !appService.isMobileView()
            "
            [ngClass]="{
              'tooltip-utils right-side sidebar-tooltip-right-side':
                menu.key !== 'toggle'
            }"
            >{{ menu.value }}</span
          >
          <span
            *ngIf="menu.key !== 'toggle' && sideBarToggle"
            [ngClass]="{
              menuTextMaximized: menu.value === currentTab,
              menuTextMinimized: menu.value !== currentTab
            }"
            >{{ menu.value }}</span
          >
        </div>
      </ng-template>
    </ul>
    <ul class="menu-group-bottom">
      <li
        class="menu-item"
        *ngFor="let menu of menus?.bottom"
        [class.active]="menu.value === currentTab && menu.value !== 'Logo'">
        <div class="align-icon-text-center tooltip-common">
          <div
            *ngIf="menu.key === 'user' && !profile.image"
            class="user-icon"
            (click)="toggleSubmenu($event)">
            {{ menu.icon }}
          </div>
          <img
            *ngIf="menu.key === 'user' && profile.image"
            class="user-icon"
            width="40px"
            height="40px"
            (click)="toggleSubmenu($event)"
            [src]="profile.image"
            alt="Profile Picture" />
          <span
            *ngIf="menu.key !== 'logo' && !sideBarToggle"
            class="tooltip-utils right-side sidebar-tooltip-right-side">
            {{ menu.value }}
          </span>
          <img
            *ngIf="menu.key !== 'user'"
            src="{{ menu.icon }}"
            class="menu-icon" />
          <span
            *ngIf="sideBarToggle"
            [ngClass]="{
              menuTextMaximized: menu.value === currentTab,
              menuTextMinimized: menu.value !== currentTab
            }">
            {{ menu.name }}
          </span>
        </div>
      </li>
    </ul>
  </section>
</div>
<!-- <img src="/src/assets/Svgs_/SideBar/ActiveAiWorkbench.svg" alt=""> -->

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { AppService } from "src/app/core/services/app.service";
import { Subscription } from "rxjs/internal/Subscription";
import { distinctUntilChanged } from "rxjs/operators";
import { ConnectionsService } from "src/app/features/settings/services/connections.service";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() form: any; // Will be json string in the form of "{form: []}"
  @Input() fieldTemplate: TemplateRef<any> | undefined;
  @Input() existingValues: any = {};
  @Input() prefixNewControlNames: string = "";
  @Output() formChanged = new EventEmitter<void>();
  @Input() showErrors: boolean = true;
  @Output() formErrors = new EventEmitter<any>();
  private formSubscription?: Subscription;
  renderableFields: any[] = [];
  multitextFields: any = {};
  public backupForm: any;
  constructor(
    private fb: FormBuilder,
    private connectionsService: ConnectionsService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.backupForm = this.form;
    this.initFormGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.form != this.backupForm) {
      this.removeOldFields();
      this.initFormGroup();
      this.backupForm = this.form;
    }
    if (changes?.["formGroup"]) {
      if (this.formSubscription) {
        this.formSubscription?.unsubscribe();
      }
      //   if(!this.showErrors){
      // this.checkForErrors();
      //   }
      this.subscribeToFormChanges();
    }
  }

  private subscribeToFormChanges() {
    this.formSubscription = this.formGroup?.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(() => {
        this.formChanged.emit();
        // if(!this.showErrors){
        // 	this.checkForErrors();
        //   }
      });
  }
  private checkForErrors(): void {
    const errors = this.getFormErrors();
    const formTouched = this.formGroup.touched;

    // Check if form is touched and errors exist
    if (formTouched && errors) {
      // Emit errors only if form controls are touched and have errors
      this.formErrors.emit(errors);
    }
  }
  private getFormErrors(): any {
    const errors = {};
    // Iterate over form controls
    Object.keys(this.formGroup.controls).forEach((key) => {
      const control = this.formGroup.get(key);

      // Check if control exists, is touched, and has errors
      if (control && control.touched && control.errors) {
        errors[key] = control.errors;
      }
    });
    return Object.keys(errors).length ? errors : null;
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription?.unsubscribe();
    }
  }

  removeOldFields() {
    if (this.backupForm) {
      this.renderableFields = JSON.parse(this.backupForm)["form"];
      for (let field of this.renderableFields) {
        this.formGroup.removeControl(field.id);
      }
    }
  }

  initFormGroup() {
    if (this.form) {
      const parsedForm = JSON.parse(this.form)["form"];
      this.renderableFields = parsedForm;
      for (let field of this.renderableFields) {
        let validators: any[] = [];
        if (field.validators.required) {
          validators.push(Validators.required);
        }
        if (field.validators.minlength) {
          validators.push(Validators.minLength(field.validators.minlength));
        }
        if (field.validators.maxlength) {
          validators.push(Validators.maxLength(field.validators.maxlength));
        }
        if (field.validators.min) {
          validators.push(Validators.min(field.validators.min));
        }
        if (field.validators.max) {
          validators.push(Validators.max(field.validators.max));
        }
        let defaultValue;
        if (field.type === "checkbox") {
          defaultValue = this.existingValues?.[field.id] ?? false;
        } else {
          defaultValue =
            this.existingValues?.[field.id] ?? field.defaultValue ?? "";
        }
        // this.formGroup.addControl(
        //   field.id,
        //   this.fb.control(
        //     this.existingValues[field.id] ??
        //       field.defaultValue ??
        //       (field.type == "checkbox" ? false : ""),
        //     validators
        //   )
        // );
        this.addControlWithDefaults(field.id, defaultValue, validators);
      }
      for (let i = 0; i < this.renderableFields.length; i++) {
        let field = this.renderableFields[i];
        if (
          (field["type"] == "radio" || field["type"] == "select") &&
          this.existingValues[field["id"]]
        ) {
          let key = this.existingValues[field["id"]];
          if (Array.isArray(field[key])) {
            let newEls: any[] = [];
            for (let o of field[key]) {
              let prefixedControlName = o.id.startsWith(
                this.prefixNewControlNames
              )
                ? o.id
                : this.prefixNewControlNames + o.id;
              newEls.push({ ...o, id: prefixedControlName });
              let validators: any[] = [];
              if (o.validators.required) {
                validators.push(Validators.required);
              }
              if (o.validators.minlength) {
                validators.push(Validators.minLength(o.validators.minlength));
              }
              if (o.validators.maxlength) {
                validators.push(Validators.maxLength(o.validators.maxlength));
              }
              if (o.validators.min) {
                validators.push(Validators.min(o.validators.min));
              }
              if (o.validators.max) {
                validators.push(Validators.max(o.validators.max));
              }
              this.addControlWithDefaults(
                prefixedControlName,
                this.existingValues[prefixedControlName] ??
                  o.defaultValue ??
                  "",
                validators
              );
            }
            this.renderableFields.splice(i + 1, 0, ...newEls);
          }
        }

        if (field["type"] == "multitext") {
          this.multitextFields[field["id"]] = Array.isArray(
            this.existingValues[field["id"]]
          )
            ? this.existingValues[field["id"]]
            : [""];
        }
        if (field["type"] == "switch") {
          if (!this.formGroup.get(field.id)?.value) {
            for (let f of field["enabled"]) {
              this.formGroup.removeControl(f?.id);
              this.renderableFields = this.renderableFields.filter(
                (item) => item.id !== f.id
              );
            }
          } else {
            const newData = this.addValidatorsToSwitch(field);
            this.renderableFields.splice(i + 1, 0, ...newData);
          }
        }
      }
    }
  }
  // Utility function to add controls with default values and validators
  addControlWithDefaults(
    controlId: string,
    defaultValue: any,
    validators: ValidatorFn[] = []
  ) {
    if (!this.formGroup.contains(controlId)) {
      this.formGroup.addControl(
        controlId,
        this.fb.control(defaultValue, validators)
      );
    } else {
      const control = this.formGroup.get(controlId);
      if (control) {
        control.patchValue(defaultValue);
        control.setValidators(validators);
        control.updateValueAndValidity();
      }
    }
  }

  addMultiTextValue(id: string) {
    this.multitextFields[id]?.push("");
  }

  addValidatorsToSwitch(data: any) {
    let newEls: any[] = [];
    for (let t of data["enabled"]) {
      let prefixedControlName = t.id.startsWith(this.prefixNewControlNames)
        ? t.id
        : this.prefixNewControlNames + t.id;
      newEls.push({ ...t, id: prefixedControlName });
      const control = this.formGroup.get(prefixedControlName || t.id);
      if (!control) {
        let validators: any[] = [];
        if (t.validators.required) {
          validators.push(Validators.required);
        }
        if (t.validators.minlength) {
          validators.push(Validators.minLength(t.validators.minlength));
        }
        if (t.validators.maxlength) {
          validators.push(Validators.maxLength(t.validators.maxlength));
        }
        if (t.validators.min) {
          validators.push(Validators.min(t.validators.min));
        }
        if (t.validators.max) {
          validators.push(Validators.max(t.validators.max));
        }
        // this.formGroup.addControl(
        //   prefixedControlName,
        //   this.fb.control(
        //     this.existingValues[prefixedControlName] ?? t.defaultValue ?? "",
        //     validators
        //   )
        // );
        this.addControlWithDefaults(
          prefixedControlName,
          this.existingValues[prefixedControlName] ?? t.defaultValue ?? "",
          validators
        );
      }
    }
    return newEls;
  }

  removeMultiTextValue(id: string, index: number) {
    this.multitextFields[id]?.splice(index, 1);
    this.updateFormGroupForMultiText(id);
  }

  onMultiTextValueChange(event: any, id: string, index: number) {
    this.multitextFields[id][index] = event.target.value;
    this.updateFormGroupForMultiText(id);
  }

  markMultiTextAsTouched(id: string) {
    this.formControlNode(id)?.markAsTouched();
    this.formControlNode(id)?.markAsDirty();
  }

  updateFormGroupForMultiText(id: string) {
    this.formControlNode(id)?.setValue(
      this.multitextFields[id].filter((val: string) => val)
    );
  }

  getMultiSelectOptions(options: string[]) {
    return options.map((option, index) => ({
      item_id: index + 1,
      item_text: option,
    }));
  }

  formControlNode(id: any) {
    return this.formGroup.get(id);
  }

  onFileSelect(event: any, fieldId: any) {
    if (event.target.files.length > 0) {
      const [file] = event.target.files;
      let fd = new FormData();
      fd.append("file", file);
      this.connectionsService.uploadFile(fd).subscribe({
        next: (res) => {
          this.formGroup.get(fieldId)?.setValue(res["filePath"]);
        },
        error: (err) => {
          if (err.status !== 403) {
            let data = {
              duration: 3000,
              type: "error",
              message: err["error"]?.["message"] || err["message"],
            };
            this.appService.showToast(data);
          }
        },
      });
    } else {
      this.formGroup.get(fieldId)?.setValue("");
    }
  }

  getSelectedFileName(fieldId: any) {
    if (this.formGroup.get(fieldId)?.value) {
      let paths: string[] = this.formGroup.get(fieldId)?.value.split("/");
      return paths[paths.length - 1];
    }
    return "No file chosen";
  }

  onRadioValueChange(fieldId: string, key: string) {
    const objIndex = this.renderableFields.findIndex(
      (input) => input.id == fieldId
    );
    if (objIndex == -1) {
      // console.log("Error: can't find field with " + fieldId);
      return;
    }
    const obj = this.renderableFields[objIndex];
    const removeable = obj.options.filter((opt) => opt != key);
    removeable.forEach((r) => {
      if (Array.isArray(obj[r]))
        obj[r].forEach((o) => {
          this.renderableFields = this.renderableFields.filter(
            (r) => r.id != this.prefixNewControlNames + o.id
          );
          if (this.formGroup.get(this.prefixNewControlNames + o.id)) {
            this.formGroup.removeControl(this.prefixNewControlNames + o.id);
          } else if (this.formGroup.get(o.id)) {
            this.formGroup.removeControl(o.id);
          }
          if (["select", "radio"].includes(o.type)) {
            o.options.forEach((opt) => {
              if (o[opt]) {
                o[opt].forEach((field) => {
                  this.renderableFields = this.renderableFields.filter(
                    (r) => r.id != this.prefixNewControlNames + field.id
                  );
                  if (
                    this.formGroup.get(this.prefixNewControlNames + field.id)
                  ) {
                    this.formGroup.removeControl(
                      this.prefixNewControlNames + field.id
                    );
                  } else if (this.formGroup.get(field.id)) {
                    this.formGroup.removeControl(field.id);
                  }
                });
              }
            });
          }
        });
    });
    if (Array.isArray(obj[key])) {
      let newEls: any[] = [];
      for (let o of obj[key]) {
        let prefixedControlName = o.id.startsWith(this.prefixNewControlNames)
          ? o.id
          : this.prefixNewControlNames + o.id;
        newEls.push({ ...o, id: prefixedControlName });
        let validators: any[] = [];
        if (o.validators.required) {
          validators.push(Validators.required);
        }
        if (o.validators.minlength) {
          validators.push(Validators.minLength(o.validators.minlength));
        }
        if (o.validators.maxlength) {
          validators.push(Validators.maxLength(o.validators.maxlength));
        }
        if (o.validators.min) {
          validators.push(Validators.min(o.validators.min));
        }
        if (o.validators.max) {
          validators.push(Validators.max(o.validators.max));
        }
        // this.formGroup.addControl(
        //   prefixedControlName,
        //   this.fb.control(o.defaultValue ?? "", validators)
        // );
        this.addControlWithDefaults(
          prefixedControlName,
          o.defaultValue ?? "",
          validators
        );
      }
      this.renderableFields.splice(objIndex + 1, 0, ...newEls);
      // Remove duplicates from renderableFields array
      this.renderableFields = this.renderableFields.filter(
        (field, index) =>
          this.renderableFields.findIndex((f) => f.id === field.id) === index
      );
    }
    let prefixedControlName = fieldId.startsWith(this.prefixNewControlNames)
      ? fieldId
      : this.prefixNewControlNames + fieldId;
    if (this.formGroup.get(prefixedControlName)) {
      this.formGroup.removeControl(prefixedControlName);
    } else if (this.formGroup.get(fieldId)) {
      this.formGroup.removeControl(fieldId);
    }
    this.formGroup.addControl(prefixedControlName, this.fb.control(key, []));
  }

  onSelectValueChange(fieldId: string, event: any) {
    // remove the analytics id form the form
    const switchObject = this.renderableFields.find(
      (item) => item.type === "switch"
    );
    if (switchObject) {
      const enabledIds = switchObject.enabled.map((item: any) => item.id);
      this.renderableFields = this.renderableFields.filter(
        (item) => !enabledIds.includes(item.id) && item.type !== "switch"
      );
    }
    // const obj = this.renderableFields.find(input => input.id == fieldId);
    const objIndex = this.renderableFields.findIndex(
      (input) => input.id == fieldId
    );
    if (objIndex == -1) {
      // console.log("Error: can't find field with " + fieldId);
      return;
    }
    let key: string =
      event.target.value || this.formGroup.get(fieldId)?.value || "";
    const obj = this.renderableFields[objIndex];
    const removeable = obj.options.filter((opt) => opt != key);
    removeable.forEach((r) => {
      if (Array.isArray(obj[r]))
        obj[r].forEach((o) => {
          this.renderableFields = this.renderableFields.filter(
            (r) => r.id != this.prefixNewControlNames + o.id
          );
          if (this.formGroup.get(this.prefixNewControlNames + o.id)) {
            this.formGroup.removeControl(this.prefixNewControlNames + o.id);
          } else if (this.formGroup.get(o.id)) {
            this.formGroup.removeControl(o.id);
          }
          if (["select", "radio"].includes(o.type)) {
            o.options.forEach((opt) => {
              if (o[opt]) {
                o[opt].forEach((field) => {
                  this.renderableFields = this.renderableFields.filter(
                    (r) => r.id != this.prefixNewControlNames + field.id
                  );
                  if (
                    this.formGroup.get(this.prefixNewControlNames + field.id)
                  ) {
                    this.formGroup.removeControl(
                      this.prefixNewControlNames + field.id
                    );
                  } else if (this.formGroup.get(field.id)) {
                    this.formGroup.removeControl(field.id);
                  }
                });
              }
            });
          }
        });
    });
    if (Array.isArray(obj[key])) {
      let newEls: any[] = [];
      for (let o of obj[key]) {
        let prefixedControlName = o.id.startsWith(this.prefixNewControlNames)
          ? o.id
          : this.prefixNewControlNames + o.id;
        newEls.push({ ...o, id: prefixedControlName });
        let validators: any[] = [];
        if (o.validators.required) {
          validators.push(Validators.required);
        }
        if (o.validators.minlength) {
          validators.push(Validators.minLength(o.validators.minlength));
        }
        if (o.validators.maxlength) {
          validators.push(Validators.maxLength(o.validators.maxlength));
        }
        if (o.validators.min) {
          validators.push(Validators.min(o.validators.min));
        }
        if (o.validators.max) {
          validators.push(Validators.max(o.validators.max));
        }
        // this.formGroup.addControl(
        //   prefixedControlName,
        //   this.fb.control(o.defaultValue ?? "", validators)
        // );
        this.addControlWithDefaults(
          prefixedControlName,
          o.defaultValue ?? "",
          validators
        );
      }
      this.renderableFields.splice(objIndex + 1, 0, ...newEls);
      // Remove duplicates from renderableFields array
      this.renderableFields = this.renderableFields.filter(
        (field, index) =>
          this.renderableFields.findIndex((f) => f.id === field.id) === index
      );
    }
    let prefixedControlName = fieldId.startsWith(this.prefixNewControlNames)
      ? fieldId
      : this.prefixNewControlNames + fieldId;
    if (this.formGroup.get(prefixedControlName)) {
      this.formGroup.removeControl(prefixedControlName);
    } else if (this.formGroup.get(fieldId)) {
      this.formGroup.removeControl(fieldId);
    }
    this.formGroup.addControl(prefixedControlName, this.fb.control(key, []));
  }

  onFieldValueChange(event: any, field: any) {
    if (field.type == "checkbox") {
      this.formGroup.get(field.id)?.setValue(event.target.checked);
    }
    if (field.type === "switch") {
      if (!this.formGroup.get(field.id)?.value) {
        for (let f of field["enabled"]) {
          this.formGroup.removeControl(f?.id);
          this.renderableFields = this.renderableFields.filter(
            (item) => item.id !== f.id
          );
        }
      } else {
        this.formGroup.get(field.id)?.setValue(event.target.checked);
        const objIndex = this.renderableFields.findIndex(
          (input) => input.id == field.id
        );
        if (objIndex == -1) {
          return;
        }
        const newData = this.addValidatorsToSwitch(field);
        this.renderableFields.splice(objIndex + 1, 0, ...newData);
      }

      // Remove duplicates from renderableFields array
      this.renderableFields = this.renderableFields.filter(
        (field, index) =>
          this.renderableFields.findIndex((f) => f.id === field.id) === index
      );
    }
  }

  getNumberPlaceholder(field: any) {
    if (
      field.type == "number" &&
      (field.validators?.min || field.validators?.max)
    ) {
      let min = field.validators.min ?? "Infinite ";
      let max = field.validators.max ?? " Infinite";
      return `${min}-${max}`;
    }
    return "";
  }
}

import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService } from "src/app/core/services/app.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public submenuExpanded: boolean = false;
  @Input() menus: any;
  @Output() sideMenuAction = new EventEmitter();
  @Input() sideBarToggle: boolean = false;
  @Output() changeSideBar = new EventEmitter<boolean>();
  currentTab: string = "Home";
  private unsubscribe$: Subject<void> = new Subject<void>();
  public profile: any = {};
  public showImage = true;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    public appService: AppService
  ) {
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setCurrentTab(val.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    // this.getProfileData();
    this.appService
      .getProfileInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        this.profile = val;
      });
  }
  setCurrentTab(url: string) {
    if (url.includes("home")) {
      this.currentTab = "Home";
    } else if (url.includes("dashboard")) {
      this.currentTab = "Dashboard";
    } else if (url.includes("pipelines")) {
      this.currentTab = "Pipelines";
    } else if (url.includes("monitor")) {
      this.currentTab = "Monitor";
    } else if (url.includes("settings")) {
      this.currentTab = "Settings";
    } else if (url.includes("user-profile")) {
      this.currentTab = "User";
    } else if (url.includes("manage-users")) {
      this.currentTab = "Admin Console";
    } else if (url.includes("ai-workbench")) {
      this.currentTab = "AI Workbench";
    } else if (url.includes("migration-workbench")) {
      this.currentTab = "Migration Workbench";
    }
  }

  toggleSubmenu(event: any) {
    this.submenuExpanded = !this.submenuExpanded;
  }

  navigate(menu: any, action) {
    let data = {
      action: action,
      menu: menu,
    };
    if (menu["key"] === "toggle") {
      return;
    } else {
      this.sideMenuAction.emit(data);
    }
    sessionStorage.setItem("currentFolderName", "Pipelines");
    // this.currentTab = menu.value;
  }
  changeToggle(menu: any) {
    this.changeSideBar.emit();
  }
  @HostListener("document:click", ["$event.target"])
  clickOutside(targetElement: any) {
    const clickedOnUser =
      this.elementRef.nativeElement.contains(targetElement) &&
      targetElement.classList.contains("user-icon");
    if (this.submenuExpanded && !clickedOnUser) {
      this.submenuExpanded = false;
    }
    if (
      this.sideBarToggle &&
      this.appService.isMobileView() &&
      !targetElement.classList.contains("flip-menu")
    ) {
      if (
        !document
          .querySelector(".main-sidebar-maximized")
          ?.contains(targetElement)
      ) {
        this.appService.sideBarToggle = false;
      }
    }
  }

  checkActiveSubmenu(menu: string) {
    return this.router.url.includes(menu.toLowerCase().replaceAll(" ", "-"));
  }

  getProfileData() {
    this.appService.getUserData().subscribe((res) => {
      this.profile = res["user"];
      const cache = JSON.parse(localStorage.getItem("currentUser") ?? "{}");
      if (!cache.username || cache.username != this.profile.username) {
        const profile = { username: this.profile.username };
        localStorage.setItem("currentUser", JSON.stringify(profile));
        this.appService.setCurrentUser(profile);
      }
      this.appService.setProfileInfo(res["user"]);
    });
  }
}

import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AppService } from "./app.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private appService: AppService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.appService.getCurrentUser();
    if (currentUser) {
      if (!this.appService.canUserNavigate(route.url.toString())) {
        this.router.navigate(["/home"]);
        return false;
      }
      return true;
    }

    if (window.location.hash) {
      this.router.navigate(["/login"], {
        queryParams: { redirectUrl: window.location.hash },
      });
    } else {
      this.router.navigate(["/login"]);
    }

    return false;
  }
}

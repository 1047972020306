import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/core/services/app.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AuthPageService } from "../../services/auth-page.service";
import { ToastService } from "src/app/shared/services/toast.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  public errorText = "";
  public username = "";
  public url: any;
  public loading = false;
  public emailConfirmation: boolean = false;
  constructor(
    private appService: AppService,
    public router: Router,
    private authPageService: AuthPageService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    let currentUser: any = this.appService.getCurrentUser();
    if (
      currentUser?.username ||
      localStorage.getItem("currentUser")?.includes("username")
    ) {
      this.router.navigate(["home"]);
      return;
    }
    let url = `${window.location.protocol}//${window.location.host}/#/reset-password`;
    this.url = url;
  }

  onSubmit() {
    this.loading = true;
    if (!this.username) {
      this.errorText = "Username is required";
      return;
    }
    this.errorText = "";
    this.authPageService
      .forgotPassword({ username: this.username, callback_url: this.url })
      .subscribe({
        next: (response) => {
          if (response["success"] === "true") {
            // this.router.navigate(["login"]);
            this.loading = false;
            this.emailConfirmation = true;
            this.toastService.commonToastMethod(
              "Password reset link sent",
              "success"
            );
          }
        },
        error: (err) => {
          this.emailConfirmation = false;
          if (err.status !== 403) {
            this.toastService.commonToastMethod(
              err["error"]?.["message"] || err["message"] || err,
              "error"
            );
          }
        },
      });
  }
}

import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ShareFolderPopupService } from "./sharefolderpopup.service";
import { AppService } from "src/app/core/services/app.service";
import { ConfirmationDialogData } from "../confirm-popup/confirmpopup.service";
import { ImportDesignerService } from "src/app/features/pipelines/services/import-designer.service";
import {
  DropdownOptions,
  DropdownSearchType,
} from "../../models/globalDropdownOptions.model";

@Component({
  selector: "app-share-folder-popup",
  templateUrl: "./share-folder-popup.component.html",
  styleUrls: ["./share-folder-popup.component.scss"],
})
export class ShareFolderPopupComponent implements OnInit, OnDestroy {
  public subscription?: Subscription;
  public messageObject: ConfirmationDialogData = {
    message: "",
  };
  public buttonText: string = "";
  public folderSelected: any;
  public headerText: string = "";
  public closeMessage: boolean = false;
  public sharedUsersList: any = [];
  public isCopyDataPipelines: boolean = false;
  public isCopyImportName: string = "";
  public showError: boolean = false;
  public sheetList: any = {};
  public showDuplicateError: boolean = false;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  public usersWithAccess: any = [];
  public deletedUsersWithAccess: any = [];
  public usersWithAccessBackup: any = [];
  public userList: any = [];
  public callback?: (result: boolean) => void;
  public sortByOpened: boolean = false;
  public addUsersToggleOpened: boolean = false;
  public userSelected: Object = { username: "Select User", userId: -1 };
  public objectId: string = "";
  public sortByOptions: any = [
    { name: "Read", id: "read" },
    { name: "Edit", id: "edit" },
  ];
  // Method to return dropdown options
  public getDropdownOptions(): DropdownOptions {
    return {
      searchable: false,
      placeholder: "",
      inputValue: "Read", // Set default input value to the first option
      multiSelectDropDown: false,
      dataOptions: {
        options: ["Read", "Edit"],
      },
    };
  }
  public getDropdownOptionsForUsersList(): DropdownOptions {
    return {
      searchable: false,
      placeholder: "Select User",
      inputValue: "", // Set default input value to the first option
      multiSelectDropDown: false,
      dropdownSearchType: DropdownSearchType.insideDropdown,
      dataOptions: {
        options: [...this.returnAllUSersList().map((user) => user?.username)],
      },
    };
  }
  public getDropdownOptionsForAddedUsers(user): DropdownOptions {
    return {
      searchable: false,
      placeholder: "Select USer",
      inputValue: user.editAccess ? "Edit" : "Read", // Set default input value to the first option
      multiSelectDropDown: false,
      dataOptions: {
        options: ["Read", "Edit"],
        isPeopleWithAccess: true,
      },
    };
  }
  returnAllUSersList() {
    let allUsers: any = JSON.parse(localStorage.getItem("allUsers") || "[]");
    let filteredArray: any;
    if (allUsers.length > 0) {
      filteredArray = allUsers.filter((item1) =>
        this.usersWithAccess.every((item2) => item1.userId !== item2.userId)
      );
    }
    return filteredArray || this.userList;
  }
  public currentUser: any;
  public owner: any = {};
  public searchText: string = "";
  public permissionSelected: Object = { name: "Read", id: "read" };
  constructor(
    private ShareFolderPopupService: ShareFolderPopupService,
    private importDesignerService: ImportDesignerService,
    public appService: AppService
  ) {}

  ngOnInit() {
    this.subscription = this.ShareFolderPopupService.getconfirmMessage()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          this.messageObject = { message: "" };
          this.messageObject = res;
          this.closeMessage = true;
          this.buttonText = res.confirmButtonText;
          this.folderSelected = res.folderSelected;
          this.headerText = res.headerText;
          this.isCopyDataPipelines = res.isDataPipelines;
          this.isCopyImportName = res.isName;
          this.sheetList = res.sheetList;
          this.userList = res.userList;
          this.owner = res.owner;
          this.usersWithAccess = res.sharedUsersList;
          this.usersWithAccessBackup = res.sharedUsersList;
          this.objectId = res.objectId;
        }
      });
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser') || "{}");
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.ShareFolderPopupService.confirmClickedEvent.complete();
  }
  get filteredUsers() {
    return this.returnAllUSersList().filter((user) =>
      user.username.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
  copyImportChange(val: any) {
    this.isCopyImportName = val["target"]["value"];
    if (val["target"]["value"] === "") {
      this.showError = true;
      this.showDuplicateError = false;
    } else {
      this.showError = false;
      this.showDuplicateError = false;
    }
  }
  sortByToggle($event) {
    $event.stopPropagation();
    this.sortByOpened = !this.sortByOpened;
  }
  sortByUpdateToggle($event, index) {
    this.usersWithAccess[index].opened = !this.usersWithAccess[index].opened;
    $event.stopPropagation();
  }
  addUsersToggle($event) {
    $event.stopPropagation();
    this.addUsersToggleOpened = !this.addUsersToggleOpened;
  }
  changeExistingPermission(event, index): void {
    let option = event?.options?.value?.toLowerCase();
    let updatedUsersWithAccess = this.usersWithAccess.map((user, i) => {
      if (i === index) {
        return {
          ...user,
          editAccess: option === "edit",
          readAccess: option === "read",
          opened: false,
        };
      }
      return user;
    });
    this.usersWithAccess = updatedUsersWithAccess;
    this.updateButtonText();
  }
  changePermission(event) {
    let option = event?.options?.value;
    this.permissionSelected = { name: option, id: option.toLowerCase() };
  }

  private compareUsersWithAccess(): boolean {
    return (
      JSON.stringify(this.usersWithAccess) ===
      JSON.stringify(this.usersWithAccessBackup)
    );
  }

  private updateButtonText(): void {
    if (this.compareUsersWithAccess()) {
      this.buttonText = "Share Folder";
    } else {
      this.buttonText = "Save Changes";
    }
  }
  deleteSharedAccess(deletedIndex: number) {
    const user = this.usersWithAccess[deletedIndex];
    let payload = {
      objectId: this.objectId,
      userId: user.userId,
      objectType: "folder",
    };
    this.importDesignerService
      .deletePermissions(payload)
      .pipe()
      .subscribe({
        next: (response) => {
          let data = {
            duration: 3000,
            type: "success",
            message: response["message"],
          };
          this.appService.showToast(data);
          this.usersWithAccessBackup = this.usersWithAccessBackup.filter(
            (currentUser) => currentUser !== user
          );
          this.usersWithAccess = this.usersWithAccess.filter(
            (currentUser) => currentUser !== user
          );
        },
        error: (err) => {
          if (err.status !== 403) {
            let data = {
              duration: 3000,
              type: "error",
              message: err["error"]?.["message"] || err["message"] || err,
            };
            this.appService.showToast(data);
          }
        },
      });
  }
  changeUser($event) {
    let userName = this.returnAllUSersList().filter(
      (u) => u.username.toLowerCase() === $event?.options?.value
    );
    this.userSelected = userName[0];
  }
  getProfileName(user: any) {
    if (user) {
      const updatedUser =
        user.firstName?.charAt(0).toUpperCase() +
        user.lastName?.charAt(0).toUpperCase();
      return updatedUser;
    } else {
      return "";
    }
  }
  confirmPopup() {
    if (!this.compareUsersWithAccess()) {
      let updatedMessage = "";
      let error = false;
      for (let i = 0; i < this.usersWithAccess.length; i++) {
        const user = this.usersWithAccess[i];
        let payload = {
          objectId: this.objectId,
          userId: user.userId,
          objectType: "folder",
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          isReadAllowed: user.readAccess,
          isEditAllowed: user.editAccess,
          parentId: user.parentId,
        };
        this.importDesignerService
          .updatePermissions(payload)
          .pipe()
          .subscribe({
            next: (response) => {
              if (response) {
                updatedMessage = response["message"];
                error = false;
              }
            },
            error: (err) => {
              error = true;
              if (err.status !== 403) {
                updatedMessage =
                  err["error"]?.["message"] || err["message"] || err;
              }
            },
            complete: () => {
              if (i === this.usersWithAccess.length - 1) {
                if (error) {
                  let data = {
                    duration: 3000,
                    type: "error",
                    message: updatedMessage,
                  };
                  this.appService.showToast(data);
                } else {
                  let data = {
                    duration: 3000,
                    type: "success",
                    message: updatedMessage,
                  };
                  this.appService.showToast(data);
                }
              }
            },
          });
      }
    }
    if (this.userSelected["userId"] !== -1) {
      let payload = {
        userId: this.userSelected["userId"],
        username: this.userSelected["username"],
        firstName: this.userSelected["firstName"],
        lastName: this.userSelected["lastName"],
        folderId: this.folderSelected?.["id"],
        owner: this.folderSelected?.["owner"],
        parentObjectId: this.folderSelected?.["parentFolderId"],
        isReadAllowed: this.permissionSelected["id"] === "read",
        isEditAllowed: this.permissionSelected["id"] === "edit",
      };
      this.importDesignerService
        .shareFolder(payload)
        .pipe()
        .subscribe({
          next: (response) => {
            if (response) {
              let data = {
                duration: 3000,
                type: "success",
                message: response["message"],
              };
              this.appService.showToast(data);
            }
          },
          error: (err) => {
            if (err.status !== 403) {
              let data = {
                duration: 3000,
                type: "error",
                message: err["error"]?.["message"] || err["message"] || err,
              };
              this.appService.showToast(data);
            }
          },
        });
    }
    this.closePopup();
  }

  closePopup() {
    this.showError = false;
    this.closeMessage = false;
    this.userSelected = { username: "Select User", userId: -1 };
    this.permissionSelected = { name: "Read", id: "read" };
    this.showDuplicateError = false;
    this.isCopyDataPipelines = false;
    this.messageObject["isConfirm"] = "false";
    if (this.messageObject["callback"]) {
      this.messageObject.callback(false);
    }
    if (this.messageObject["manageCallback"]) {
      this.messageObject.manageCallback(this.messageObject);
    }
  }
  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest(".sortBy")) {
      this.sortByOpened = false;
    }
    if (!targetElement.closest("sortByUsersWithAccess")) {
      this.usersWithAccess?.map((item) => (item.opened = false));
    }
    if (!targetElement.closest(".sortByUser")) {
      this.addUsersToggleOpened = false;
    }
    if (targetElement.closest(".sortByBox")) {
      event.stopPropagation();
    }
  }
}

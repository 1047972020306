import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
// import * as moment from 'moment';
import { AppService } from "src/app/core/services/app.service";
import { compareAsc, parse } from "date-fns";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ConnectionsService } from "src/app/features/settings/services/connections.service";

@Component({
  selector: "app-custom-datatable",
  templateUrl: "./custom-datatable.component.html",
  styleUrls: ["./custom-datatable.component.scss"],
})
export class CustomDatatableComponent implements OnInit, OnChanges {
  @Input() rows: any;
  @Input() columns: any;
  @Input() change: any;
  @Input() tableOptions: any;
  @Output() rowSelection = new EventEmitter();
  @Output() rowCount = new EventEmitter();
  @Output() itemClicked = new EventEmitter();
  @Output() overlayPanelItemClicked = new EventEmitter();
  @Input() overlayPanelStatus!: boolean;
  @Input() filter!: boolean;
  @Input() currentsection?: string = "";
  @Input() checkOnRowClick?: boolean = true;
  @Output() iconClickEvent = new EventEmitter<string>();
  @Output() verificationSent = new EventEmitter<any>();
  public isascOrder: boolean = false;
  public sortField = "";
  public reverse: boolean = true;
  public asc!: boolean;
  public dsc!: boolean;
  public sortDirection = "";
  sortedTableData: any;
  @ViewChild("dataTable") dataTable!: ElementRef;
  @ViewChild("headerColumn") headerColumn!: ElementRef;
  @ViewChild("centerCheckbox") centerCheckbox!: ElementRef;
  public headerWidth!: number;
  public isStickyHeader: boolean = true;
  public currentPageNumber: number = 1; // Current page number
  itemsPerPage: number = 12; // Number of items per page;
  @Input() searchText: string | any[] | any = "";
  @Input() getUrl: (value: any) => string = (v) => "";
  @Input() showTableFolder: boolean = true;
  public selectedRows: any[] = [];
  @Output() monitorPagination = new EventEmitter();
  // @Input() inputData!:{
  //   searchQuery ?: string,
  //   sendSearchFilterText ?: string,
  //   searchArrayFilterData ?: any
  // };
  // @Input() searchFilterText: string | any[] | any = '';
  constructor(
    private element: ElementRef,
    public router: Router,
    private appService: AppService,
    private connectionservice: ConnectionsService
  ) {}
  // public searchText = new FormControl();
  public filterRows: any[] = [];
  public filterColumns: any[] = [];

  @Input() isloading: any;
  public skeletonColNum: any = Array.from({ length: 3 }, (_, i) => i);
  public skeletonRowNum: any = Array.from({ length: 3 }, (_, i) => i);
  @Input() isMonitorPageload: boolean = false;
  @Input() usersList: any = {};

  ngOnInit() {
    // this.filterRows = JSON.parse(JSON.stringify(this.rows));
    // this.filterColumns = JSON.parse(JSON.stringify(this.columns));
    // this.getTagsColumnContent(this.sortedTableData);
    this.sortedTableData?.map((item) => (item["checked"] = false));
    this.itemsPerPage = Math.round(window.innerHeight / 54);
  }
  ngOnChanges(change: SimpleChanges) {
    if (this.rows) {
      // this.currentPageNumber = 1;
      this.sortedTableData = this.rows;
    }
    if (!this.overlayPanelStatus) {
      this.sortedTableData?.map((item) => (item["checked"] = false));
      const allCheckedFalse = this.sortedTableData?.every(
        (item) => !item["checked"]
      );
      if (allCheckedFalse) {
        this.selectedRows = [];
      }
    }
    if (change["isMonitorPageload"] && this.currentsection === "monitor") {
      this.skeletonColNum = Array.from({ length: 5 }, (_, i) => i);
    }
  }

  isDateFormat(value: any): boolean {
    try {
      // Attempt to split the value
      const splitDate = value?.split("T")[0];
      // Check if the value matches the date format regex
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateFormatRegex.test(splitDate)) {
        return true;
      }
    } catch (error) {
      return false;
    }
    const formats = [
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY/MM/DD",
      "MM/DD/YYYY",
      "DD/MM/YYYY",
      "YYYY MM DD",
      "MM DD YYYY",
      "DD MM YYYY",
      // Add more formats as needed
    ];
    const datePart = value?.split(" ")[0];
    for (const format of formats) {
      if (moment(datePart, format, true).isValid()) {
        return true; // If any format is valid, return true
      }
    }
    return false;
  }

  redirect(row): any {
    if (this.router.url.includes("manage-users")) {
      return;
    } else if (this.router.url.includes("monitor")) {
      this.router.navigate(["/monitor/viewer"], {
        queryParams: {
          eventId: row["id"],
          viewType: "list",
        },
        queryParamsHandling: "merge",
      });
    } else if (this.router.url.includes("pipelines")) {
      if (row.importType === "migration") {
        this.setMigrationModelDetails(row);
      }
      this.router.navigate(["pipelines/manage"], {
        queryParams: {
          importId: row["importId"],
          viewType: "list",
        },
        queryParamsHandling: "merge", // Merge with other query params like 'importId'
      });
    }
  }

  async setMigrationModelDetails(selectedRow) {
    let migrationType = "edit";
    let sessionMigrationDetails: any = sessionStorage.getItem("migrationList");
    if (!sessionMigrationDetails || sessionMigrationDetails.length === 0) {
      return this.setMigrationModelDetails(selectedRow);
    }
    if (sessionMigrationDetails && sessionMigrationDetails !== "undefined") {
      sessionMigrationDetails = JSON.parse(sessionMigrationDetails);
      const areKeysGreaterThan = sessionMigrationDetails?.some(
        (obj) => Object.keys(obj).length > 0
      );
      if (areKeysGreaterThan) {
        const migrationItem = sessionMigrationDetails.filter((item: any) => {
          let migrateType = selectedRow["migrationType"]?.replace(" - ", "to");
          return item?.name?.toLowerCase() === migrateType?.toLowerCase();
        });
        localStorage.setItem("migrationType", JSON.stringify(migrationItem[0]));
        localStorage.setItem("migration", migrationType);
      }
    }
  }

  redirectThroughCustomTableButtons(event, type, data) {
    event?.stopPropagation();
    this.iconClickEvent.next(data);
  }

  hoveredColumnIndex: number | null = null;
  sortTableData(field: any, type: any) {
    if (this.sortField === field) {
      this.sortDirection =
        this.sortDirection === "asc"
          ? "desc"
          : this.sortDirection === "desc"
            ? ""
            : "asc";
    } else {
      this.sortField = field;
      this.sortDirection = "asc"; // By default, set the direction to ascending when a new column is clicked
    }

    this.sortedTableData = [...this.rows]; // Reset to the original data order

    if (this.sortDirection) {
      this.sortedTableData.sort((a, b) => {
        let valueA: any;
        let valueB: any;
        let condition =
          field === "createdBy" && this.currentsection === "manageUsers";
        valueA = condition ? a["firstName"] || a["lastName"] : a[field];
        valueB = condition ? b["firstName"] || b["lastName"] : b[field];

        if (Array.isArray(valueA) && Array.isArray(valueB)) {
          // Handle arrays
          const stringA = valueA.join(", ");
          const stringB = valueB.join(", ");
          return stringA.localeCompare(stringB) * this.getSortMultiplier();
        } else if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
          // Handle numbers in string format
          return (Number(valueA) - Number(valueB)) * this.getSortMultiplier();
        } else if (this.isDate(valueA) && this.isDate(valueB)) {
          // Handle dates in string format
          const dateA = this.parseDateString(valueA);
          const dateB = this.parseDateString(valueB);
          return compareAsc(dateA, dateB) * this.getSortMultiplier();
        } else {
          // Fallback: Regular string comparison
          return valueA?.localeCompare(valueB) * this.getSortMultiplier();
        }
      });
    }
  }

  private isDate(value: any): boolean {
    // You can use a regular expression or any other logic to determine if the value is a date string
    // For simplicity, let's assume the date strings have a common format: MM.dd.yyyy HH:mm
    const dateRegex = /^\d{2}\.\d{2}\.\d{4}\s\d{2}:\d{2}$/;
    return typeof value === "string" && dateRegex.test(value);
  }

  private parseDateString(dateString: string): Date {
    return parse(dateString, "MM.dd.yyyy HH:mm", new Date());
  }

  private getSortMultiplier() {
    return this.sortDirection === "desc" ? -1 : 1;
  }

  rowItemClicked(row, checked) {
    const allChecked = this.sortedTableData.every((item) => !item["checked"]);
    this.selectedRows = this.selectedRows.filter((item) => item.checked);
    if (allChecked) {
      this.selectedRows = [];
    }
    if (checked) {
      this.selectedRows.push(row);
      this.selectedRows = this.removeDuplicates(this.selectedRows);
      this.itemClicked.emit(this.selectedRows);
    } else {
      const getIndexSelected = this.selectedRows.findIndex(
        (item) => item === row
      );
      if (getIndexSelected !== -1) {
        this.selectedRows.splice(getIndexSelected, 1);
      }
      this.selectedRows = this.removeDuplicates(this.selectedRows);
      this.itemClicked.emit(this.selectedRows);
    }
    if (this.selectedRows.length === 0) {
      this.itemClicked.emit([]);
    }
    // const getIndex = this.sortedTableData.findIndex((item) => item === row);
    // if (this.sortedTableData[getIndex]["checked"]) {
    //  this.sortedTableData.map((item) => (item["checked"] = false));
    //  this.sortedTableData[getIndex]["checked"] = true;
    //  const data = JSON.parse(JSON.stringify(row));
    //  this.itemClicked.emit(data);
    // } else {
    //  this.sortedTableData.map((item) => (item["checked"] = false));
    //  this.itemClicked.emit({});
    // }
  }

  openOverlay(row) {
    if (this.checkOnRowClick) {
      const getIndex = this.sortedTableData.findIndex((item) => item === row);
      this.sortedTableData.map((item) => (item["checked"] = false));
      this.sortedTableData[getIndex]["checked"] = true;
      const data = JSON.parse(JSON.stringify(row));
      this.overlayPanelItemClicked.emit(data);
    }
  }

  // searchFilter(searchText, i) {
  //   this.sortedTableData = this.rows.filter((item) => {
  //     return item[i].toLowerCase().indexOf(searchText.toLowerCase()) !== -1
  //   })
  // }
  isValueArray(value: any): boolean {
    return value instanceof Array;
  }
  isStickyHeaderMethod(index: number) {
    // Return true if the element index is less than or equal to 4 (first 4 elements)
    return index <= 3;
  }

  // Generate HTML content for the "tags" column based on row data
  getTagsColumnContent(rowData: any): string {
    if (rowData && rowData.tags && rowData.tags.length > 0) {
      const tagsIcon = '<i class="fas fa-tags"></i>';
      // return rowData.tags.map(() => tagsIcon).join(',');
      return tagsIcon;
    }
    return "";
  }
  isHtmlContent(content: string): boolean {
    const htmlPattern = /<\/?[a-z][\s\S]*>/i;
    return htmlPattern.test(content);
  }
  // public isSelectAllCheckBox: boolean = false
  // selectAll(event: any) {
  //   const isChecked = event.target.checked;
  //   this.sortedTableData.forEach(row => {
  //     row.checked = isChecked; // Set the checkbox state in the respective column
  //     // this.isSelectAllCheckBox = true;
  //   });
  // }
  get totalPages(): number {
    return Math.ceil(this.sortedTableData.length / 10);
  }

  get filteredItems(): any {
    // if ((typeof this.inputData ?.searchArrayFilterData === 'object' && Object.keys(this.inputData.searchArrayFilterData).length === 0) && !this.inputData.searchQuery && !this.inputData.sendSearchFilterText) {
    //   return this.sortedTableData
    // }
    // else if ((typeof this.inputData?.searchArrayFilterData === 'object' && Object.keys(this.inputData.searchArrayFilterData).length > 0)) {
    //   const filteredData = this.filterItems(this.sortedTableData, this.inputData.searchArrayFilterData, this.inputData.sendSearchFilterText);
    //   return filteredData ? filteredData : []
    // }
    // else if (this.inputData ?.searchQuery) {
    //   const searchTextLowerCase = this.inputData ?.searchQuery.toLowerCase();
    //   return this.sortedTableData.filter(item => JSON.stringify(item).toLowerCase().includes(searchTextLowerCase));
    // }
    // else if (this.inputData?.sendSearchFilterText) {
    //   const searchTextLowerCase = this.inputData ?.sendSearchFilterText.toLowerCase();
    //   return this.sortedTableData.filter(item => JSON.stringify(item).toLowerCase().includes(searchTextLowerCase));
    // }
    // else {
    //   // return
    // }
    return this.sortedTableData;
  }

  // Function to check if a date matches today
  // isToday(date: Date): boolean {
  //   const today = new Date();
  //   today.setHours(0, 0, 0, 0);
  //   const dateToCompare = new Date(date);
  //   dateToCompare.setHours(0, 0, 0, 0);
  //   return dateToCompare.getTime() === today.getTime();
  // }

  // Function to check if a date matches yesterday
  // isYesterday(date: Date): boolean {
  //   const today = new Date();
  //   today.setHours(0, 0, 0, 0);
  //   const yesterday = new Date(today);
  //   yesterday.setDate(yesterday.getDate() - 1);
  //   const dateToCompare = new Date(date);
  //   dateToCompare.setHours(0, 0, 0, 0);
  //   return dateToCompare.getTime() === yesterday.getTime();
  // }

  // Function to check if a date is in the current month
  // isInCurrentMonth(date: Date): boolean {
  //   const today = new Date();
  //   const dateToCompare = new Date(date);
  //   return dateToCompare.getFullYear() === today.getFullYear() && dateToCompare.getMonth() === today.getMonth();
  // }

  // Function to check if a date falls within the previous month
  // isLastMonth(date: Date): boolean {
  //   const today = new Date();
  //   const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  //   const startDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
  //   const endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  //   return date >= startDate && date <= endDate;
  // }

  // filterItems(items: any[], filterObject: any, additionalFilter?: string): any[] {
  //   const applyFilterCriteria = (item: any, key: string, values: any): boolean => {
  //     if (!key || !values) {
  //       return true; // If key or values are missing, return true to keep the item
  //     }

  //     let itemValue = item[key];

  //     // Convert values to lowercase if they are strings
  //     const filterValues = Array.isArray(values) ? values.map(value => typeof value === 'string' ? value.toLowerCase() : value) : [values];

  //     // For nested objects, recursively apply the filter criteria
  //     if (typeof itemValue === 'object' && !Array.isArray(itemValue)) {
  //       return Object.entries(itemValue).some(([nestedKey, nestedValue]) => {
  //         return applyFilterCriteria({ [nestedKey]: nestedValue }, nestedKey, values);
  //       });
  //     }

  //     // For arrays, apply the filter criteria recursively to each element
  //     if (Array.isArray(itemValue)) {
  //       return itemValue.some(subItem => applyFilterCriteria({ [key]: subItem }, key, values));
  //     }

  //     // Convert itemValue to lowercase if it's a string
  //     if (typeof itemValue === 'string') {
  //       itemValue = itemValue.toLowerCase();
  //     }

  //     // Special handling for the 'modifiedDate' key
  //     if (key === 'modifiedDate') {
  //       // Convert itemValue to Date object if it's a timestamp
  //       if (typeof itemValue === 'number') {
  //         itemValue = new Date(itemValue);
  //       }
  //       const itemDate = new Date(itemValue);
  //       return filterValues.some(filterValue => {
  //         switch (filterValue) {
  //           case 't':
  //             return this.isToday(itemDate);
  //           case 'y':
  //             return this.isYesterday(itemDate);
  //           case 'lm':
  //             return this.isLastMonth(itemDate);
  //           case 'm':
  //             return this.isInCurrentMonth(itemDate);
  //           case 'lst':
  //             const thirtyDaysAgo = new Date();
  //             thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  //             return itemDate >= thirtyDaysAgo;
  //           case 'range':
  //             const minDate = values.minDate ? new Date(values.minDate) : null;
  //             const maxDate = values.maxDate ? new Date(values.maxDate) : null;
  //             if (minDate && itemDate < minDate) return false;
  //             if (maxDate && itemDate > maxDate) return false;
  //             return true;
  //           default:
  //             return false;
  //         }
  //       });
  //     }

  //     // For other keys, perform normal comparison
  //     return filterValues.some(filterValue => itemValue === filterValue);
  //   };

  //   let filteredItems = items.filter(item => {
  //     return Object.entries(filterObject).every(([key, values]) => {
  //       return applyFilterCriteria(item, key, values);
  //     });
  //   });

  //   if (additionalFilter) {
  //     const searchTextLowerCase = additionalFilter.toLowerCase();
  //     filteredItems = filteredItems.filter(item => JSON.stringify(item).toLowerCase().includes(searchTextLowerCase));
  //   }

  //   return filteredItems;
  // }

  // compareDates(itemDate: string, filterDate: string) {
  //   // Convert both dates to the "MM-DD-YYYY HH:MM" format for comparison
  //   const itemDateString = new Date(itemDate).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }).replace(/,/g, '');
  //   return itemDateString === filterDate;
  // }
  // Helper function to check primitive values
  // private checkPrimitiveValues(value: any, filterValues: any[]): boolean {
  //   if (typeof value === 'string' || typeof value === 'number') {
  //     return filterValues.includes(value);
  //   }
  //   return false;
  // }

  // Helper function to check object values
  // private checkObjectValues(object: any, filterValues: any[]): boolean {
  //   for (const key in object) {
  //     if (object.hasOwnProperty(key)) {
  //       const value = object[key];
  //       if (Array.isArray(value)) {
  //         // Check each value in the array
  //         if (value.some(subValue => this.checkPrimitiveValues(subValue, filterValues))) {
  //           return true;
  //         }
  //       } else if (typeof value === 'object') {
  //         // Recursively check nested objects
  //         if (this.checkObjectValues(value, filterValues)) {
  //           return true;
  //         }
  //       } else {
  //         // Check primitive value
  //         if (this.checkPrimitiveValues(value, filterValues)) {
  //           return true;
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // }

  // sanitizeHtml(html: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(html);
  // }

  // Method to return color based on status value
  getColor(value, status): any {
    if (typeof value === "string") {
      if (status.toLowerCase() === "status") {
        const val = value.toLowerCase();
        const lowercaseStatus = status.toLowerCase();
        if (lowercaseStatus === "status") return "status";
        // switch (val) {
        //   case "completed":
        //     return "completed";
        //   case "pending":
        //     return "pending";
        //   case "exception":
        //     return "exception";
        //   case "rejected":
        //     return "rejected";
        //   case "resolved":
        //     return "resolved";
        //   default:
        //     return "";
        // }
      }
    }
  }

  getDynamicClass() {
    if (
      this.router.url.includes("pipelines") ||
      (this.currentsection === "datapipelines" && this.showTableFolder)
    ) {
      return "custom-height-box-datapipelines";
    } else if (
      this.router.url.includes("pipelines") ||
      (this.currentsection === "datapipelines" && !this.showTableFolder)
    ) {
      return "custom-height-box-datapipelines-hideFolders";
    } else if (this.currentsection === "master-tables") {
      return "custom-height-box-masterdata";
    } else if (this.currentsection === "chase") {
      return "custom-height-box-chase";
    } else if (this.currentsection === "schedules") {
      return "custom-height-box-schedules";
    } else if (this.currentsection === "connections") {
      return "custom-height-connections ";
    } else if (this.currentsection === "manageUsers") {
      return "custom-height-manageUsers";
    } else if (this.currentsection === "monitor") {
      return "custom-height-monitor";
    } else {
      return "custom-height-box";
    }
  }

  // convertToLowerCase(obj: any): any {
  //   const newObj = {};
  //   Object.keys(obj).forEach(key => {
  //     newObj[key.toLowerCase()] = obj[key].toLowerCase();
  //   });
  //   return newObj;
  // }

  // filterItemsByDate(items: any[], today: string): any[] {
  //   return items.filter(item => {
  //     const itemDateKey = Object.keys(item).find(key => typeof item[key] === 'string' && item[key].includes(today));
  //     return !!itemDateKey;
  //   });
  // }

  getPaginationText() {
    const totalItems = this.filteredItems.length;
    const startIndex = (this.currentPageNumber - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, totalItems);
    const itemsOnPage = endIndex - startIndex;
    return itemsOnPage;
  }
  removeDuplicates(arr: any[]): any[] {
    return Array.from(new Set(arr));
  }
  getProfileName(user: any) {
    if (user?.firstName || user?.lastName) {
      const updatedUser =
        user?.firstName.charAt(0).toUpperCase() +
        user?.lastName.charAt(0).toUpperCase();
      return updatedUser;
    } else {
      return "--";
    }
  }

  handleVerification(row: any) {
    this.verificationSent.emit(row);
  }
  onScroll(event: any) {
    const element = event?.target;
    const threshold = 2;
    const atBottom =
      element.scrollHeight - element.scrollTop <
      element.clientHeight + threshold;
    if (this.currentsection === "monitor" && atBottom) {
      this.monitorPagination.emit(event);
    }
  }

  getMonitorProfileName(item: any, key: string) {
    const getUser = this.usersList?.filter(
      (user: any) => user?.userId === item?.createdBy
    );
    if (key === "icon") {
      return this.getProfileName(getUser[0]);
    } else if (key === "firstName") {
      return getUser["0"]?.["firstName"];
    } else {
      return getUser["0"]?.["lastName"];
    }
  }

  isEmptyRow(row: any, column: any) {
    if (column.id === "expired" && this.currentsection === "schedules") {
      return false;
    }
    return (
      row?.[column["id"]]?.length === 0 ||
      row["attributes"]?.[column["id"]]?.length === 0 ||
      row?.[column.id] === "" ||
      (!row?.[column.id] && this.currentsection === "chase") ||
      (!row?.[column.id] &&
        this.currentsection === "datapipelines" &&
        (!row["attributes"]?.[column["id"]] ||
          row["attributes"]?.[column["id"]]?.length === 0)) ||
      (!row?.[column.id] &&
        column.id !== "tags" &&
        !(
          this.currentsection === "manageUsers" &&
          (column.id === "createdBy" || column.id === "verified")
        ))
    );
  }
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ValidationService {
  private fileReadErrorMessage: any;
  private emailErrorMessage: any;
  private fileHeaderErrorMessage: any;
  private calcFunctionErrorMessage: any;
  private count = 0;

  constructor() {}

  importReadSettings(fileAttribute) {
    this.fileReadErrorMessage = "";
    let errors: string[] = [];
    if (
      fileAttribute["fileAttributes"].fileFormat == "" ||
      fileAttribute["fileAttributes"].fileFormat == null
    ) {
      errors.push("File Format");
    }
    if (fileAttribute.fileType == "" || fileAttribute.fileType == null) {
      if (
        !["Vector Structured", "Vector UnStructured"].includes(
          fileAttribute["fileAttributes"].fileFormat
        )
      ) {
        errors.push("File Type");
      }
    }
    if (
      fileAttribute.fileType === "PDF/IMAGE" &&
      fileAttribute["fileAttributes"].fileFormat === "Invoice" &&
      (fileAttribute.processingMode == "" ||
        fileAttribute.processingMode == null)
    ) {
      errors.push("pdf processing data  is required");
    }
    if (
      (fileAttribute["sheetMetadata"]["sheetNames"] == "" ||
        fileAttribute["sheetMetadata"]["sheetNames"] == null) &&
      fileAttribute["fileAttributes"].multipleSheetIndicator !== "N"
    ) {
      errors.push("Sheet Names");
    }
    if (
      (fileAttribute["fileAttributes"].fileDelimiter == "" ||
        fileAttribute["fileAttributes"].fileDelimiter == null) &&
      fileAttribute.fileType != "XLS" &&
      fileAttribute.fileType != "XLSX" &&
      fileAttribute.fileType != "DAT" &&
      fileAttribute.fileType != "PDF/IMAGE"
    ) {
      errors.push("File Delimiter");
    }
    if (
      fileAttribute["fileAttributes"].targetFormat === "" ||
      fileAttribute["fileAttributes"].targetFormat === null
    ) {
      if (
        !["Vector Structured", "Vector UnStructured"].includes(
          fileAttribute["fileAttributes"].fileFormat
        )
      ) {
        errors.push("Target Format");
      }
    }
    if (
      fileAttribute["fileAttributes"].noOfLinesToSkip === null ||
      (fileAttribute["fileAttributes"].noOfLinesToSkip === "" &&
        fileAttribute.fileType !== "PDF/IMAGE")
    ) {
      errors.push("No Of Lines To Skip");
    }
    if (errors.length) {
      if (errors.length == 4) {
        return "Please fill all required fields.";
      }
      return (
        errors.join(", ") +
        (errors.length > 1 ? " are required." : " is required.")
      );
    } else {
      return true;
    }
  }

  emailSettings(emails) {
    this.emailErrorMessage = "";
    if (emails.successEmail == "") {
      this.emailErrorMessage =
        this.emailErrorMessage + "Please enter email address. \n";
    } else if (!this.isValidEmailAddress(emails.successEmail ?? "")) {
      this.emailErrorMessage =
        this.emailErrorMessage + "Please enter a valid email address. \n";
    }

    if (this.emailErrorMessage) {
      return this.emailErrorMessage;
    } else {
      return true;
    }
  }

  fileHeader(fileHeader) {
    this.fileHeaderErrorMessage = "";
    let errors: string[] = [];
    if (!fileHeader.importName) {
      // this.fileHeaderErrorMessage =
      //   this.fileHeaderErrorMessage + 'Import Name is required field. \n';
      errors.push("Pipeline Name");
    } else if (
      "Unsaved Pipeline"
        .toLowerCase()
        .includes(fileHeader.importName.toLowerCase().trim())
    ) {
      return "Please enter a valid pipeline name";
    }

    if (errors.length) {
      if (errors.length === 4) {
        return "Please fill all required fields.";
      }
      return (
        errors.join(", ") +
        (errors.length > 1 ? " are required." : " is required.")
      );
    } else {
      return true;
    }
  }

  checkReadRulesEmptyImportColumns(mapping, currentLine) {
    let haveMappings = true;
    mapping.forEach((line) => {
      if (line.sheetName == currentLine) {
        line.readRules.forEach((element) => {
          if (
            element.sourceFileColumnNumber == "" ||
            element.sourceFileColumnNumber == null
          ) {
            haveMappings = false;
          }
          return haveMappings;
        });
      }
    });
    return haveMappings;
  }

  checkDATExtensionEmptyImportColumns(mapping, currentLine) {
    let haveMappings = true;
    mapping.forEach((line) => {
      if (line.sheetName == currentLine) {
        line.mappings.forEach((element) => {
          if (
            element.START_POSITION == "" ||
            element.START_POSITION == null ||
            element.END_POSITION == "" ||
            element.END_POSITION == null
          ) {
            haveMappings = false;
          }
          return;
        });
      }
    });
    return haveMappings;
  }

  validateSpecialElementsFunction(mapping: any, currentLine: any) {
    let invalidElements: number[] = [];
    const currentMapping = mapping.lines.find(
      (line) => line.lineType == currentLine
    );
    if (currentMapping) {
      let i = 0;
      for (let element of currentMapping.mappingElements) {
        if (
          element.elementLabel.toLowerCase() == "validation" ||
          element.elementLabel.startsWith("Variable ")
        ) {
          if (!element.function) {
            invalidElements.push(i);
          }
        }
        i++;
      }
    }
    return invalidElements;
  }

  validateSpecialElementsFP(mapping: any, currentLine: any) {
    let invalidElements: number[] = [];
    const currentMapping = mapping.lines.find(
      (line) => line.lineType == currentLine
    );
    if (currentMapping) {
      let i = 0;
      for (let element of currentMapping.mappingElements) {
        if (
          element.elementLabel.toLowerCase() == "validation" ||
          !element.elementLabel.startsWith("Variable ")
        ) {
          if (!element.functionParameters) {
            invalidElements.push(i);
          }
        }
        i++;
      }
    }
    return invalidElements;
  }

  checkEmptyImportColumns(
    mapping: any,
    currentLine: string,
    sourceNotRequiredFunctions: string[],
    defaultValueNotRequiredFunctions: string[]
  ) {
    let invalidElements: number[] = [];
    const currentMapping = mapping.lines.find(
      (line) => line.lineType == currentLine
    );
    if (currentMapping) {
      let i = 0;
      for (let element of currentMapping.mappingElements) {
        if (
          element.elementLabel.toLowerCase() != "validation" &&
          !element.elementLabel.startsWith("Variable ")
        ) {
          if (
            !element.sourceFileColumnNumber &&
            !sourceNotRequiredFunctions.includes(element.function)
          ) {
            if (
              !element.defaultValue &&
              !defaultValueNotRequiredFunctions.includes(element.function)
            ) {
              invalidElements.push(i);
            }
          }
        }
        i++;
      }
    }
    return invalidElements;
    // if (element.elementLabel.toLowerCase() == "validation") {
    //   element.function && element.functionParameters ? haveMappings = true : haveMappings = false;
    // }
    // if ( (element.required == "N" || !element.required) || ((element.defaultValue) || (element.function === 'IF-ELSEIF' ||
    //   element.function === 'LINE_SEQUENCE' || element.function === 'IMS_LOOKUP' || element.function === 'AGGREGATE')) &&
    //   element.elementLabel.toLowerCase() != "validation") {
    //   haveMappings = true;
    // } else {
    //   if (element.elementLabel.toLowerCase() != "validation") {
    //     if (
    //       element.sourceFileColumnNumber == '' ||
    //       element.sourceFileColumnNumber == null
    //     ) {
    //       haveMappings = false;
    //     }
    //   }
    // }
    // return;
    // });
    // }
    //   }
    // // });
    // }
    // return haveMappings;
  }

  checkAttributes(mappings) {
    if (!mappings["importReadRules"]["fileType"]) {
      return "Please update File Type to proceed.";
    } else if (!mappings["emails"]["successEmail"]) {
      return "Please update Emails to proceed.";
    } else if (!mappings["importReadRules"]["fileAttributes"]["targetFormat"]) {
      return "Please updare Target File Format to proceed.";
    } else {
      return true;
    }
  }

  validateReadRules(mappings, currentLine) {
    let mappingValidation: any;
    if (this.checkReadRulesEmptyImportColumns(mappings["rules"], currentLine)) {
      let requiredElementsValidation = this.checkRequiredReadRulesElements(
        mappings["rules"],
        currentLine
      );
      // if (requiredElementsValidation == true) {
      //   return true;
      // } else {
      //   return requiredElementsValidation;
      // }
      return requiredElementsValidation;
    } else {
      mappingValidation = "Please map all source columns.";
    }

    return mappingValidation;
  }

  validateDATExtension(mappings, currentLine) {
    let mappingValidation: any;
    if (this.checkDATExtensionEmptyImportColumns(mappings, currentLine)) {
      let requiredElementsValidation = this.checkRequiredDATExtensionElements(
        mappings,
        currentLine
      );
      if (requiredElementsValidation == true) {
        return true;
      } else {
        return requiredElementsValidation;
      }
    } else {
      mappingValidation = "Please map all source columns.";
    }

    return mappingValidation;
  }

  checkUnsavedElements(mappings, currentLine) {
    const selectedLine = mappings["mapping"]["lines"].find(
      (line) => line["lineType"] == currentLine
    );
    if (selectedLine) {
      for (let li of selectedLine["mappingElements"]) {
        if (li["isNewField"]) {
          return "There is an unsaved new field";
        }
      }
    }
    return true;
  }

  validateImportSheetNames(mappings, currentLine) {
    let invalidElements: number[] = [];
    let sheetNames = new Set();
    mappings["distinctLineTypes"].map((sheet) => sheetNames.add(sheet));
    mappings["importReadRules"]["sheetMetadata"]["sheetNames"]
      .split(",")
      .map((sheet) => sheetNames.add(sheet.trim()));
    let mappingsElements =
      mappings["mapping"]["lines"][
        mappings["distinctLineTypes"].indexOf(currentLine)
      ]["mappingElements"];
    let i = 0;
    for (let el of mappingsElements) {
      if (
        el["sourceFileColumnNumber"] &&
        el["sourceFileColumnNumber"].includes(":") &&
        mappings["importReadRules"]["fileType"] !== "PDF/IMAGE"
      ) {
        let multisheet =
          mappings["importReadRules"]["fileAttributes"]
            ?.multipleSheetIndicator === "Y"
            ? true
            : false;
        let sheet = el["sourceFileColumnNumber"].split(":")[0];
        if (
          sheet &&
          !sheetNames.has(sheet) &&
          !sheet.startsWith("$") &&
          multisheet
        ) {
          // return `Invalid sheet name (${sheet}) for element ${i+1}.${el['elementLabel']}`;
          invalidElements.push(i);
        }
      }
      i++;
    }
    return invalidElements;
  }

  // for CONDITIONAL, IF-ELSEIF, SQL function, IF-EXIST
  extractSheetNames(pattern: string) {
    const regex = /\{([^{}]*:[^{}]*)\}/g;
    const names: Set<string> = new Set();

    let match;
    while ((match = regex.exec(pattern)) != null) {
      names.add(match[1]);
    }

    let result: string[] = [];
    for (let n of names) {
      if (/^[a-zA-Z0-9_: ]+$/.test(n)) {
        result.push(n);
      }
    }
    return result;
  }

  //for IMS-LOOKUP function
  extractSheetNames2(pattern: string) {
    pattern = JSON.parse(pattern.replaceAll(`\\`, `\\\\`));
    // pattern = JSON.parse(pattern);
    let total: string[] = [];
    for (let val of Object.values(pattern)) {
      let names: Set<string> = new Set();
      if (!val.includes("{") && val.includes(",")) {
        total.push(...val.split(","));
      } else {
        const result = this.extractSheetNames(val);
        if (result.length > 0) {
          result.forEach((r) => names.add(r));
          total.push(...names);
        }
      }
    }
    return [...new Set(total)];
  }

  //for ONE-TO-MANY SPLIT function
  extractSheetNames3(pattern: string) {
    pattern = JSON.parse(pattern.replaceAll(`\\`, `\\\\`));
    let total: string[] = [];
    for (let val of Object.values(pattern)) {
      let names: Set<string> = new Set();
      for (let innerVal of Object.values(val)) {
        if (!innerVal.includes("{") && innerVal.includes(",")) {
          total.push(...innerVal.split(","));
        } else {
          const result = this.extractSheetNames(innerVal);
          if (result.length > 0) {
            result.forEach((r) => names.add(r));
            total.push(...names);
          }
        }
      }
    }
    return [...new Set(total)];
  }

  //for CONCAT, FORMULAE
  extractSheetNames4(pattern: string, func: string) {
    if (func == "CONCAT") {
      return pattern.split(",").map((p) => p.trim());
    }
    if (func == "FORMULAE") {
      pattern = pattern.replaceAll("+", "#");
      pattern = pattern.replaceAll("-", "#");
      pattern = pattern.replaceAll("/", "#");
      pattern = pattern.replaceAll("*", "#");
      pattern = pattern.replaceAll("%", "#");
      return pattern.split("#").map((p) => p.trim());
    }
    return [];
  }

  checkFPSheetNames(mappings, currentLine) {
    let invalidElements: number[] = [];
    let sheetNames = new Set();
    mappings["distinctLineTypes"].map((sheet) => sheetNames.add(sheet));
    mappings["importReadRules"]["sheetMetadata"]["sheetNames"]
      .split(",")
      .map((sheet) => sheetNames.add(sheet.trim()));
    let mappingsElements =
      mappings["mapping"]["lines"][
        mappings["distinctLineTypes"].indexOf(currentLine)
      ]["mappingElements"];
    let i = 0;
    for (let el of mappingsElements) {
      if (el["function"] && el["functionParameters"]) {
        let sheets: any[] = [];
        const firstFunction = [
          "CONDITIONAL",
          "IF-ELSEIF",
          "SQL_FUNCTION",
          "IF_EXISTS",
          "VALIDATE_EXT_POLICY",
        ];
        if (firstFunction.includes(el["function"])) {
          sheets = this.extractSheetNames(el["functionParameters"]);
        }

        const secondFunction = ["IMS_LOOKUP", "MULTI_SHEET_IF_EXIST"];
        if (secondFunction.includes(el["function"])) {
          try {
            sheets = this.extractSheetNames2(el["functionParameters"]);
          } catch (error) {
            return {
              invalidElements: [i],
              message:
                "Please enter valid transformation parameters for highlighted rows",
            };
          }
        }

        const thirdFunction = ["ONE_TO_MANY_SPLIT"];
        if (thirdFunction.includes(el["function"])) {
          try {
            sheets = this.extractSheetNames3(el["functionParameters"]);
          } catch (error) {
            return {
              invalidElements: [i],
              message:
                "Please enter valid transformation parameters for highlighted rows",
            };
          }
        }

        const fourthFunction = ["CONCAT", "FORMULAE"];
        if (fourthFunction.includes(el["function"])) {
          sheets = this.extractSheetNames4(
            el["functionParameters"],
            el["function"]
          );
        }

        if (el["function"] == "OUTLIERS_CHECK") {
          // OUTLIERS is a special condition
          let sheets = el["functionParameters"].split(",");
          for (let j = 1; j < sheets.length; j++) {
            //j[0] will be elementLabel, so skip it
            if (!sheetNames.has(sheets[j]) && !invalidElements.includes(i)) {
              invalidElements.push(i);
            }
          }
        } else {
          for (let sheet of sheets) {
            if (sheet.includes(":") && !sheetNames.has(sheet.split(":")[0])) {
              if (!invalidElements.includes(i)) {
                invalidElements.push(i);
              }
            }
          }
        }
      }
      i++;
    }
    return { invalidElements, message: null };
  }

  checkForUnsavedElements(mapping: any, currentLine: any) {
    let invalidElements: number[] = [];
    const currentMapping = mapping.lines.find(
      (line) => line.lineType == currentLine
    );
    if (currentMapping) {
      let i = 0;
      for (let element of currentMapping.mappingElements) {
        if (element.IS_EDITING || element.isNewField) {
          invalidElements.push(i);
        }
        i++;
      }
    }
    return invalidElements;
  }

  validateMapping(
    mappings: any,
    currentLine: string,
    sourceNotRequiredFunctions: string[],
    defaultValueNotRequiredFunctions: string[]
  ) {
    const unsavedElements = this.checkForUnsavedElements(
      mappings["mapping"],
      currentLine
    );
    if (unsavedElements.length) {
      return {
        indices: unsavedElements,
        message:
          "Please save the unsaved elements before updating the mapping.",
      };
    }

    const emptyImportColumns = this.checkEmptyImportColumns(
      mappings["mapping"],
      currentLine,
      sourceNotRequiredFunctions,
      defaultValueNotRequiredFunctions
    );
    if (emptyImportColumns.length) {
      return {
        indices: emptyImportColumns,
        message: "Please fill Source or Default Value for highlighted rows",
      };
    }

    const invalidSheetNames = this.validateImportSheetNames(
      mappings,
      currentLine
    );
    if (invalidSheetNames.length) {
      return {
        indices: invalidSheetNames,
        message: "Please enter valid sheet names for highlighted rows",
      };
    }

    const invalidSpecialElementFunction = this.validateSpecialElementsFunction(
      mappings["mapping"],
      currentLine
    );
    if (invalidSpecialElementFunction.length) {
      return {
        indices: invalidSpecialElementFunction,
        message: "Please select any transformation for highlighted rows",
      };
    }

    const invalidFunctions = this.checkFunctions(mappings, currentLine);
    if (invalidFunctions.length) {
      return {
        indices: invalidFunctions,
        message: "Please enter transformation parameters for highlighted rows",
      };
    }

    const invalidFPSheetNames = this.checkFPSheetNames(mappings, currentLine); // FP -> function parameters;
    if (invalidFPSheetNames.invalidElements.length) {
      return {
        indices: invalidFPSheetNames.invalidElements,
        message:
          invalidFPSheetNames.message ??
          "Please check sheet names in transformation parameters for highlighted rows",
      };
    }

    const invalidConditionalExpression = this.checkRequiredElements(
      mappings["mapping"],
      currentLine
    );
    if (invalidConditionalExpression.length) {
      return {
        indices: invalidConditionalExpression,
        message: "Please enter validation expression for highlighted rows",
      };
    }

    return { indices: [], message: "valid" };
    // let mappingValidation: any = this.checkEmptyImportColumns(mappings['mapping'], currentLine);
    // if (mappingValidation == true) {
    //   let unsavedElements = this.checkUnsavedElements(mappings, currentLine);
    //   if(unsavedElements != true){
    //     return unsavedElements;
    //   }
    //   let functionsValidation = this.checkFunctions(mappings, currentLine);
    //   if (functionsValidation == true) {
    //     let requiredElementsValidation = this.checkRequiredElements(
    //       mappings['mapping'],
    //       currentLine
    //     );
    //     if (requiredElementsValidation == true) {
    //       return true;
    //     } else {
    //       return requiredElementsValidation;
    //     }
    //   } else {
    //     return functionsValidation;
    //   }
    // } else {
    //   mappingValidation = mappingValidation == false ? 'Please map all source columns.' : mappingValidation;
    // }
    // return mappingValidation;
  }

  checkRequiredReadRulesElements(mapping, currentLine) {
    let isExpressionRequired: any = true;
    mapping.forEach((line) => {
      if (line["sheetName"] == currentLine) {
        line["readRules"].forEach((element) => {
          if (
            element["required"] == "Conditional" &&
            (element["validationExpression"] == "" ||
              element["validationExpression"] == null)
          ) {
            isExpressionRequired = `Validation Rule required for ${element["elementLabel"]}`;
          }
        });
      }
    });
    return isExpressionRequired;
  }

  checkRequiredDATExtensionElements(mapping, currentLine) {
    let isExpressionRequired: any = true;
    mapping.forEach((line) => {
      if (line["sheetName"] == currentLine) {
        line["mappings"].forEach((element) => {
          if (
            element["required"] == "Conditional" &&
            (element["validationExpression"] == "" ||
              element["validationExpression"] == null)
          ) {
            isExpressionRequired = `Validation Rule required for ${element["elementLabel"]}`;
          }
        });
      }
    });
    return isExpressionRequired;
  }

  checkRequiredElements(mapping, currentLine) {
    let invalidElements: number[] = [];
    let isExpressionRequired: any = true;
    const currentMapping = mapping.lines.find(
      (line) => line.lineType == currentLine
    );
    if (currentMapping) {
      let i = 0;
      for (let element of currentMapping["mappingElements"]) {
        if (element["required"] == "C" && !element["validationExpression"]) {
          invalidElements.push(i);
        }
        i++;
      }
    }
    return invalidElements;
    // let isExpressionRequired: any = true;
    // mapping['lines'].forEach((line) => {
    //   if (line['lineType'] == currentLine) {
    //     line['mappingElements'].forEach((element) => {
    //       if (
    //         element['required'] == 'Conditional' &&
    //         (element['validationExpression'] == '' ||
    //           element['validationExpression'] == null)
    //       ) {
    //         isExpressionRequired = `Validation Rule required for ${element['elementLabel']}`;
    //       }
    //     });
    //   }
    // });
    // return isExpressionRequired;
  }

  checkFunctions(mappings, currentLine) {
    let invalidElements: number[] = [];
    // let functionErrorMessage: any = true;
    const currentMapping = mappings["mapping"].lines.find(
      (line) => line.lineType == currentLine
    );
    if (currentMapping) {
      let i = 0;
      for (let element of currentMapping["mappingElements"]) {
        if (element["function"] != "" || element["function"] != null) {
          if (
            this.checkParameterRequired(
              mappings["functions"],
              element["function"]
            ) &&
            (element["functionParameters"] == "" ||
              element["functionParameters"] == null)
          ) {
            invalidElements.push(i);
          }
        }
        i++;
      }
    }
    return invalidElements;
    // let functionErrorMessage: any = true;
    // mappings['mapping']['lines'].forEach((line) => {
    //   if (line['lineType'] == currentLine) {
    //     line['mappingElements'].forEach((element) => {
    //       if (element['function'] != '' || element['function'] != null) {
    //         if (
    //           this.checkParameterRequired(
    //             mappings['functions'],
    //             element['function']
    //           ) &&
    //           (element['functionParameters'] == '' ||
    //             element['functionParameters'] == null)
    //         ) {
    //           functionErrorMessage = `Please enter parameters to the ${element['function']} for ${element['elementLabel']}`;
    //         }
    //       }
    //     });
    //   }
    // });
    // return functionErrorMessage;
  }

  checkParameterRequired(functions, selectedFunction) {
    let isParameterRequired = false;
    functions?.forEach((functionObj) => {
      if (functionObj["name"] == selectedFunction) {
        isParameterRequired =
          functionObj["parameters_required"] == "t" &&
          selectedFunction != "NUMERIC";
      }
    });
    return isParameterRequired;
  }

  checkCalcFunction(elements) {
    this.calcFunctionErrorMessage = "";
    elements.forEach((element) => {
      if (element.sourceFileColumnNumber && element.function == "CALC") {
        if (
          element.sourceFileColumnNumber.split(",").length !=
          element.functionParameters.split(",").length - 1
        ) {
          this.calcFunctionErrorMessage = `${this.calcFunctionErrorMessage} Import column mapping did not match the count of the parameters available in ${element.elementLabel}`;
        }
      }
    });
    return this.calcFunctionErrorMessage;
  }

  validExcelColumn(value) {
    if (value) {
      value = value.toUpperCase();
      var ret = true;
      var pattern = new RegExp(/^[A-Z]{1,3}$/);
      let values = value.split(",");
      values.forEach((value) => {
        if (ret) {
          if (!pattern.test(value.trim())) {
            ret = false;
          }
        }
      });
      return ret;
    } else {
      return true;
    }
  }

  isValidEmailAddress(emailAddress) {
    var ret = true;
    var pattern = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let emails = emailAddress.split(",");
    emails.forEach((email) => {
      if (ret) {
        if (!pattern.test(email.trim())) {
          ret = false;
        }
      }
    });
    return ret;
  }

  validateImportColumnValue(mappingElement, mapping) {
    let alreadyExist = [];
    alreadyExist = mapping.filter(
      (x) => x.sourceFileColumnNumber == mappingElement.sourceFileColumnNumber
    );
    if (alreadyExist.length > 1) {
      // console.log(alreadyExist);
      return true;
    } else {
      return false;
    }
  }
}

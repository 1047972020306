import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

interface SectionExpandInfo {
  totalExpanded: boolean;
  paragraphsExpanded: boolean;
  summaryExpanded: boolean;
  tablesExpanded: boolean;
}

interface ExcelSectionExpandInfo {
  totalExpanded: boolean;
  subSectionsExpanded: any;
}

@Component({
  selector: "app-section-viewer",
  templateUrl: "./section-viewer.component.html",
  styleUrls: ["./section-viewer.component.scss"],
})
export class SectionViewerComponent implements OnInit {
  @Input() sections: any = {};
  @Input() isEditable: boolean = false;
  @Input() events: BehaviorSubject<any> | null = null;
  @Input() id: string = "";
  sectionsOpened: any = {};
  sectionsBackup: any[] = [];
  isBannerVisible = true;
  bannerLabel = "KBR";
  isPDF = true;
  tempObj: any = {};
  private subscriptionObject?: Subscription;
  constructor() {}

  ngOnInit(): void {
    if (this.sections["sheets"]) {
      this.isPDF = false;
    } else {
      this.sections = this.sections.extractedInfo;
      this.sections = this.sections.filter(
        (section: any) => Object.values(section).join("").trim().length > 0
      );
    }
    this.sectionsBackup = JSON.parse(JSON.stringify(this.sections));
    this.subscriptionObject = this.events?.subscribe((res) => {
      if (res["type"] && res["id"] == this.id) {
        if (res["type"] == "cancel") {
          this.sections = JSON.parse(JSON.stringify(this.sectionsBackup));
        } else if (res["type"] == "save") {
        }
      }
    });
  }

  expandSection(index: number) {
    if (!this.sectionsOpened[index]) {
      let temp: SectionExpandInfo | ExcelSectionExpandInfo;
      if (this.isPDF) {
        temp = {
          totalExpanded: true,
          paragraphsExpanded: false,
          summaryExpanded: false,
          tablesExpanded: false,
        };
      } else {
        temp = {
          totalExpanded: true,
          subSectionsExpanded: {},
        };
      }

      this.sectionsOpened[index] = temp;
    } else {
      this.sectionsOpened[index].totalExpanded = true;
    }
  }

  expandInnerSection(
    index: number,
    section: "summary" | "paragraphs" | "tables"
  ) {
    if (this.sectionsOpened[index]) {
      switch (section) {
        case "summary":
          this.sectionsOpened[index].summaryExpanded = true;
          break;
        case "paragraphs":
          this.sectionsOpened[index].paragraphsExpanded = true;
          break;
        case "tables":
          this.sectionsOpened[index].tablesExpanded = true;
          break;
        default:
          break;
      }
    }
  }

  expandExcelInnerSection(index: number, name: string) {
    this.sectionsOpened[index].subSectionsExpanded[name] = true;
  }

  collapseExcelInnerSection(index: number, name: string) {
    if (this.sectionsOpened[index].subSectionsExpanded[name]) {
      this.sectionsOpened[index].subSectionsExpanded[name] = false;
    }
  }

  collapseInnerSection(
    index: number,
    section: "summary" | "paragraphs" | "tables"
  ) {
    if (this.sectionsOpened[index]) {
      switch (section) {
        case "summary":
          this.sectionsOpened[index].summaryExpanded = false;
          break;
        case "paragraphs":
          this.sectionsOpened[index].paragraphsExpanded = false;
          break;
        case "tables":
          this.sectionsOpened[index].tablesExpanded = false;
          break;
        default:
          break;
      }
    }
  }

  collapseSection(index: number) {
    this.sectionsOpened[index].totalExpanded = false;
  }

  getSectionExpandInfo(index: number): SectionExpandInfo | null {
    return this.sectionsOpened[index] || null;
  }

  getTables(index: number) {
    return (this.sections[index]["tables"] || []).map((tableObj: any) => [
      tableObj["table"],
    ]);
  }

  tableDataToMatrix(table: any[]) {
    let currentHeaders: string[] = [];
    let finalTable: { rowType: "header" | "body"; cells: string[] }[] = [];
    let i = 0;
    while (i < table.length) {
      let row = table[i];
      if (currentHeaders.length == 0) {
        currentHeaders = Object.keys(row);
        finalTable.push({ rowType: "header", cells: currentHeaders });
      }
      let cells: string[] = [];
      for (let header of currentHeaders) {
        cells.push(row[header]);
      }
      if (cells.length > 0) {
        finalTable.push({ rowType: "body", cells });
        i++;
      } else {
        currentHeaders = [];
      }
    }
    return finalTable;
  }

  getSheetNames() {
    return this.sections["sheets"].map((sheet: any) => sheet["sheetName"]);
  }

  getTablesFromSheets(sheetName: string) {
    return this.sections["sheets"].find(
      (sheet: any) => sheet["sheetName"] == sheetName
    )["sheetData"];
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj).sort(
      (a, b) =>
        (obj[a].position_index ?? Number.MAX_SAFE_INTEGER) -
        (obj[b].position_index ?? Number.MAX_SAFE_INTEGER)
    );
  }
}

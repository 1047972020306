import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConnectionsService {
  constructor(private httpClient: HttpClient) {}

  // public getConnections(){
  //   return this.httpClient
  //     .get(`${environment.apiUrl}/api/getConnectionEvents`);
  // }
  public getConnectionsTypes() {
    return this.httpClient
      .get(`${environment.apiUrl}/api/connection/types`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public getNewConnectionTypes() {
    return this.httpClient
      .get(`${environment.apiUrl}/api/connection/connectionTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public getConnectionsTypesFields(value: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/connection/type/${value}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public testConnection(value) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/testConnection`, value, {
        responseType: "text",
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public getConnectionEvents(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/getConnectionEvents`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateConnection(details: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/updateConnectionDetails`,
      details
    );
  }

  public editConnection(details: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/editConnection`,
      details
    );
  }

  public deleteConnections(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/deleteConnection`, data, {
        responseType: "text",
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public addConnection(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/addConnection`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public uploadFile(data: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/api/connection/uploadFile`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppService } from "src/app/core/services/app.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit, OnChanges {
  @ViewChild("renameInput") renameInput!: ElementRef;
  @Input() row: any;
  @Input() index: any;
  @Input() dropzone = false;
  @Input() page: string = "Transformation";
  @Input() currentUserId: string = "";
  @Output() nagivateToMappings = new EventEmitter<any>();
  @Output() navigateToFolders = new EventEmitter<any>();
  @Output() exportMapping = new EventEmitter<any>();
  @Output() copyMapping = new EventEmitter<any>();
  @Output() deleteMapping = new EventEmitter<any>();
  @Output() deleteFolder = new EventEmitter<any>();
  @Output() openConnectionsDetails = new EventEmitter<any>();
  @Output() importMap = new EventEmitter<any>();
  @Output() addMap = new EventEmitter<any>();
  @Output() fileDrop = new EventEmitter<any>();
  @Output() monitorResolve = new EventEmitter<any>();
  @Output() monitorReprocess = new EventEmitter<any>();
  @Input() getUrl: (value: any) => string = (v) => "";
  @Output() openConfigDetails = new EventEmitter<any>();
  @Output() deleteConfig = new EventEmitter<any>();
  @Output() cardSelection = new EventEmitter();
  @Output() overlayPanelItemClicked = new EventEmitter();
  @Output() showDropdownSection = new EventEmitter();
  @Input() folderList: any;
  @Output() saveFolder = new EventEmitter();
  @Output() renameFolder = new EventEmitter();
  @Output() shareFolder = new EventEmitter();
  isMenuOpened = false;
  isDragging = false;
  public renameFolderRef: boolean = false;
  public previousFolderName: string = "";
  public isShared: boolean = false;
  public isNew: boolean = false;
  @Input() isloading: boolean = false;
  @Input() isFolderloading: boolean = false;
  public migrationDetails: any = {};
  @Input() usersList: any = {};
  @Output() handleRequestStatusMethod = new EventEmitter<any>();
  public access: boolean = false;
  private isRenameCompleted = false;
  constructor(
    private renderer: Renderer2,
    public appService: AppService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {}

  get url(): string {
    return this.getUrl(this.row);
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.access = JSON.parse(
        localStorage.getItem("pipelineaccess") || "false"
      );
    });
    if (this.row?.isNew) {
      // If it's a new folder, enable rename mode immediately
      this.isNew = true;
      this.enableRename();
    } else {
      this.isNew = false;
    }
    if (this.currentUserId !== this.row?.owner) {
      this.isShared = true;
    } else {
      this.isShared = false;
    }
    if (this.row?.["importType"] === "migration") {
      let sessionMigrationDetails: any = JSON.parse(
        sessionStorage.getItem("migrationList") ?? "[]"
      );
      this.migrationDetails = sessionMigrationDetails?.filter((item: any) => {
        let migrateType = this.row["migrationType"]?.replace(" - ", "to");
        return item?.name?.trim().toLowerCase() === migrateType?.toLowerCase();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.access = JSON.parse(
        localStorage.getItem("pipelineaccess") || "false"
      );
    }
  }
  openMappings() {
    this.nagivateToMappings.emit(this.row);
  }
  openFolders() {
    if (this.isMenuOpened || this.renameFolderRef) {
      return;
    }
    this.navigateToFolders.emit(this.row);
  }
  openOverlay(data) {
    this.overlayPanelItemClicked.emit(data);
  }
  showMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }
  showFolderMenu($event?) {
    $event.stopPropagation();
    this.isMenuOpened = !this.isMenuOpened;
  }
  shareSelectedFolder($event?: Event) {
    $event?.stopPropagation();
    this.shareFolder.emit(this.row);
  }
  folderDeletion($event?: Event) {
    this.deleteFolder.emit(this.row);
  }
  enableRename($event?: Event) {
    $event?.stopPropagation();
    this.previousFolderName = this.row.folderName;
    this.renameFolderRef = true;
    this.isRenameCompleted = false;
    setTimeout(() => {
      const inputElement = this.renderer.selectRootElement(
        this.renameInput.nativeElement
      );
      inputElement.select();
      inputElement.focus();
    });
  }
  isValidFolderName(folderName: string) {
    const regex = /^[a-zA-Z0-9_ -]+$/;
    return regex.test(folderName);
  }
  disableRename(event?: Event) {
    event?.stopPropagation();

    // If the rename has already been completed (via Enter or Escape), prevent further actions
    if (this.isRenameCompleted) {
      return;
    }

    if (event instanceof KeyboardEvent) {
      // Handle Escape key based on whether it's a new folder or not
      if (event.key === "Escape" && this.row.isNew) {
        this.saveFolder.emit(undefined);
        this.isRenameCompleted = true; // Mark rename as completed
        return;
      } else if (event.key === "Escape" && !this.row.isNew) {
        this.row.folderName = this.previousFolderName;
        this.renameFolderRef = false;
        this.isRenameCompleted = true; // Mark rename as completed
        return;
      }

      // Handle Enter key
      if (event.key !== "Enter") {
        return;
      }
    }

    // Handle save for new folder or existing folder
    if (this.row.isNew) {
      if (this.row.folderName?.toLowerCase()?.trim() === "untitled folder") {
        this.saveFolder.emit(undefined);
        this.enableRename();
        this.isRenameCompleted = true; // Mark rename as completed
        return;
      }
      this.saveFolder.emit(this.row);
      this.row.isNew = false;
      this.renameFolderRef = false;
      if (this.row.folderName.trim() === this.previousFolderName.trim()) {
        return;
      }
    } else {
      if (this.row.folderName?.toLowerCase()?.trim() === "untitled folder") {
        this.renameFolder.emit(null);
        this.row.folderName = this.previousFolderName;
        this.enableRename();
        this.isRenameCompleted = true; // Mark rename as completed
        return;
      } else if (this.row.folderName?.trim() === "") {
        this.renameFolder.emit("");
        this.row.folderName = this.previousFolderName;
        this.enableRename();
        this.isRenameCompleted = true; // Mark rename as completed
        return;
      } else if (!this.isValidFolderName(this.row.folderName?.trim())) {
        this.renameFolder.emit(undefined);
        this.row.folderName = this.previousFolderName;
        this.enableRename();
        this.isRenameCompleted = true; // Mark rename as completed
        return;
      } else if (this.row.folderName.length > 30) {
        this.renameFolder.emit("limit-exceeded");
        this.row.folderName = this.previousFolderName;
        this.enableRename();
        this.isRenameCompleted = true; // Mark rename as completed
        return;
      }
      this.renameFolder.emit(this.row);
    }

    // Mark rename as completed
    this.isRenameCompleted = true;
  }
  hasEditAccess(editAccess: boolean, readAccess: boolean) {
    return editAccess;
  }
  isOwner(objectOwner: string) {
    return objectOwner === this.currentUserId;
  }
  hideMenu() {
    setTimeout(() => {
      this.isMenuOpened = false;
    }, 250);
  }

  exportMap() {
    this.exportMapping.emit(this.row);
  }

  copyMap() {
    this.copyMapping.emit(this.row);
  }

  deleteMap() {
    this.deleteMapping.emit(this.row);
  }

  openConnections() {
    this.isMenuOpened = false;
    // this.openConnectionsDetails.emit(this.row);
    const data = JSON.parse(JSON.stringify(this.row));
    this.cardSelection.emit(data);
    this.showDropdownSection?.emit(2);
  }
  openTags() {
    this.isMenuOpened = false;
    // this.openConnectionsDetails.emit(this.row);
    const data = JSON.parse(JSON.stringify(this.row));
    this.cardSelection.emit(data);
    this.showDropdownSection?.emit(1);
  }

  deleteConfigFun() {
    this.deleteConfig.emit(this.row);
  }
  editCardConfigFun() {
    this.openConfigDetails.emit(this.row);
  }

  onDragOver(e) {
    e.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(e) {
    e.preventDefault();
    this.isDragging = false;
  }

  onDrop(e) {
    e.preventDefault();
    this.isDragging = false;
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 1) {
        return;
      }
      const item = e.dataTransfer.items[0];
      if (item.kind === "file") {
        const file = item.getAsFile();
        this.fileDrop.emit(file);
      }
    }
  }

  @HostListener("window:click", ["$event"])
  handleKeyDown(event: any) {
    if (
      !event.target.classList.contains("ti-dots-vertical") &&
      this.isMenuOpened
    ) {
      this.isMenuOpened = false;
    }
  }

  getMonitorIcon(status: string) {
    status = status.toLowerCase();
    if (status.includes("rejected")) {
      return "ti-ban";
    }
    if (status.includes("pending") || status.includes("inprogress")) {
      return "ti-clock-hour-3";
    }
    if (status.includes("exception")) {
      return "ti-alert-triangle";
    }
    if (status == "resolved") {
      return "ti-file-check";
    }
    return "ti-checks";
  }

  resolve() {
    this.monitorResolve.emit(this.row);
  }

  reprocess() {
    this.monitorReprocess.emit(this.row);
  }

  // getDataSourceIcon() {
  //   switch (this.row["connectionType"]) {
  //     case "Database":
  //       return "Database.png";
  //     case "GoogleDrive":
  //       return "GoogleDrive.png";
  //     case "OneDrive":
  //       return "OneDrive.png";
  //     case "SFTP":
  //       return "SFTP.png";
  //     case "FTP":
  //       return "FTP.png";
  //     case "Azure Blob Storage":
  //       return "Azure Blob Storage.png";
  //     case "Microsoft Mail":
  //       return "Microsoft Mail.png";
  //     case "Amazon S3":
  //       return "Amazon S3.png";
  //     case "File System":
  //       return "File System.png";
  //     default:
  //       return "unidentified.svg";
  //   }
  // }

  addMapping() {
    this.addMap.emit();
  }
  getProfileName(user: any) {
    if (user?.firstName || user?.lastName) {
      const updatedUser =
        user?.firstName.charAt(0).toUpperCase() +
        user?.lastName.charAt(0).toUpperCase();
      return updatedUser;
    } else {
      return "--";
    }
  }
  sanitizeImageUrl(imageUrl: string | null | undefined): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      imageUrl || "assets/images/ai_no_icon.svg"
    );
  }

  getMonitorProfileName(item: any, key: string) {
    const getUser = this.usersList?.filter(
      (user: any) => user?.userId === item?.createdBy
    );
    if (key === "icon") {
      return this.getProfileName(getUser[0]);
    } else if (key === "firstName") {
      return getUser["0"]?.["firstName"];
    } else {
      return getUser["0"]?.["lastName"];
    }
  }
  sendConfirmMessage(selectedRow: any) {
    this.handleRequestStatusMethod.emit(selectedRow);
  }
  isZipFile(filename: string): boolean {
    const fileExtension = filename?.split(".")?.pop()?.toLowerCase();
    return fileExtension === "zip" || fileExtension === "xml";
  }
}

<div class="app">
  <app-header
    *ngIf="isLoggedIn"
    [headerTitle]="headerTitle"
    [assetsUrl]="assetsUrl"
    (headerProfileAction)="sideMenuAction($event)"
    [sideBarToggle]="appService.sideBarToggle"></app-header>
  <main>
    <aside *ngIf="isLoggedIn">
      <app-sidebar
        [menus]="menus"
        (sideMenuAction)="sideMenuAction($event)"
        [sideBarToggle]="appService.sideBarToggle"
        (changeSideBar)="changeSideBar()"></app-sidebar>
    </aside>
    <div
      class="content"
      [ngClass]="{
        marginLeft: !appService.isMobileView() && isLoggedIn,
        marginLeftMaximizedSidebar:
          !appService.isMobileView() && appService.sideBarToggle
      }">
      <div *ngIf="loaderService.loader | async" class="loader">
        <img src="assets/images/logos/favicon.png" />
      </div>
      <app-toast></app-toast>
      <router-outlet></router-outlet>
      <app-confirm-popup></app-confirm-popup>
      <app-share-folder-popup></app-share-folder-popup>
      <app-sheet-header-list-popup></app-sheet-header-list-popup>
      <app-global-dropdown></app-global-dropdown>
    </div>
  </main>
  <ng-container *ngIf="canShowHeader()">
    <!-- <footer>
      <img src="assets/images/logos/200x200-01.png" height="36px" style=" position:fixed;
        right:20px;
        top: 10px;">
    </footer> -->
  </ng-container>
  <ng-container *ngIf="appService.isSessionExpired">
    <div class="session-popup">
      <div class="Session-flex">
        <app-lottie-flip-design></app-lottie-flip-design>
        <div class="right-div">
          <div style="padding-bottom: 50px">
            <div class="sessionImg">
              <img src="assets/Svgs_/Login/SessionExpired.svg" alt="Exp" />
            </div>
            <label class="schibsted-grotesk">Your session has expired</label>
            <p class="schibsted-grotesk">
              Your session has expired and you have been logged out. Please
              login again to continue.
            </p>
            <button
              (click)="logoutAndLogin()"
              class="button-common gradient schibsted-grotesk">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<app-tour></app-tour>
<div class="blankpage" *ngIf="params['redirectUrl'] && isLoggedIn"></div>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AppService } from "src/app/core/services/app.service";
import { ImportDesignerService } from "src/app/features/pipelines/services/import-designer.service";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { TokenStorageService } from "src/app/core/services/token.service";
import { AnimationOptions } from "ngx-lottie";
import { AuthPageService } from "../../services/auth-page.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm = new FormGroup({
    username: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
    // tenant: new FormControl('', Validators.required),
  });
  public loginError: any = "";
  public assetsUrl: any;
  public isPasswordVisible = false;
  public redirectUrl: any;
  public lottieOptions: AnimationOptions = {
    path: "assets/lottie/login.json",
  };

  private unsubscribe: Subject<void> = new Subject<void>();
  constructor(
    public appService: AppService,
    private router: Router,
    private tokenService: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    public authPageService: AuthPageService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams["redirectUrl"]) {
        this.redirectUrl = null;
        this.redirectUrl = queryParams["redirectUrl"];
      } else {
        this.redirectUrl = null;
      }
    });
    let currentUser: any = this.appService.getCurrentUser();
    if (
      currentUser?.username ||
      localStorage.getItem("currentUser")?.includes("username")
    ) {
      this.router.navigate(["home"]);
    }
    this.assetsUrl = environment["assetsUrl"];
    this.appService.isSessionExpired = false;
  }

  login() {
    if (this.loginForm.pristine || this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    if (this.loginForm.invalid) {
      return;
    }
    let loginInfo = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
      // organizationId: this.loginForm.value.tenant
    };
    this.authPageService.login(loginInfo).subscribe({
      next: (response: any) => {
        // if (response && response['status'].toLowerCase()==="success") {
        const { errorMessage, userId, ...tokenDetails } = response;
        let user = {
          username: this.loginForm.value.username,
          userId,
          // refresh_token: response['refresh_token'],
        };
        // localStorage.setItem('token', JSON.stringify(response));
        localStorage.setItem("currentUser", JSON.stringify(user));
        if (tokenDetails["accessToken"]) {
          this.tokenService.setToken(response);
        }
        this.appService.setCurrentUser(user);
        this.loginError = "";
        this.loginForm.reset();
        if (this.redirectUrl) {
          this.navigateToUrl(this.redirectUrl);
        } else {
          this.router.navigate(["home"]);
        }
        // }else{
        //   this.loginError = response['message'];
        // }
      },
      error: (err) => {
        this.loginError = err["error"]
          ? typeof err["error"] == "string"
            ? err["error"]
            : err["error"]["errorMessage"]
          : err["message"];
      },
    });
  }

  get username() {
    return this.loginForm.get("username");
  }

  get password() {
    return this.loginForm.get("password");
  }
  // get tenant(){
  //   return this.loginForm.get('tenant');
  // }

  // changePassword() {
  //   alert('hey');
  // }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  navigateToUrl(url) {
    window.open(url, "_self");
  }
}

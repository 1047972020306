import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDuplicate'
})
export class RemoveDuplicatePipe implements PipeTransform {

  transform(list: any) {
    if (list) {
      let data=list.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
      return data;
    }
  }

}

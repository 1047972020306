<ng-container *ngIf="messageObject['message'] && closeMessage">
  <div class="confirm-popup">
    <div class="main">
      <div class="modal-header">
        <strong>{{
          headerText === "" ? "Automap with AI" : headerText
        }}</strong>
        <div class="tooltip-common">
          <img
            src="/assets/Svgs_/Dashboard/CrossForModel.svg"
            (click)="closePopup(true)" />
          <span class="tooltip-utils center">Close</span>
        </div>
      </div>
      <div
        class="modal-body confirmation-body"
        style="max-height: 400px; max-width: 490px">
        <div class="heading-m">
          <div class="info-icon">
            <span>Missing source for below target headers</span>
          </div>
          <div class="my-checkbox select-all" style="display: none">
            <input
              style="display: none"
              class="globalCheckbox"
              type="checkbox"
              id="select-all-global"
              [checked]="areAllSheetsHeadersSelected()"
              (change)="toggleSelectAll($event)" />
            <label for="select-all-global">Select All</label>
          </div>
        </div>

        <div class="wrapper">
          <ng-container *ngFor="let sheet of xlHeaders">
            <div>
              <!-- Sheet Header Template -->

              <div
                *ngIf="sheet.sheetName"
                (click)="toggleSheet(sheet.sheetName)"
                class="sheet-header"
                [ngClass]="{
                  'active-sheet-header': isExpanded(sheet.sheetName)
                }">
                <div class="my-checkbox" (click)="$event.stopPropagation()">
                  <input
                    style="display: none"
                    class="globalCheckbox"
                    type="checkbox"
                    [id]="'sheet-checkbox-' + sheet.sheetName"
                    [checked]="areAllHeadersSelected(sheet.sheetName)"
                    [indeterminate]="areSomeHeadersSelected(sheet.sheetName)"
                    (change)="toggleSelectSheetAll(sheet.sheetName, $event)"
                    [disabled]="isSheetCheckboxDisabled(sheet.sheetName)" />
                  <!-- <label for=" my-checkbox" class="my-checkbox__label"
                    >Select All</label
                  > -->
                  <div
                    class="indeterminate-mark"
                    *ngIf="areSomeHeadersSelected(sheet.sheetName)">
                    <!-- This div will display the CSS-based minus sign -->
                  </div>
                  {{ sheet.sheetName }}
                </div>

                <i
                  class="ti chevron-icon"
                  [ngClass]="{
                    'ti-chevron-down': !isExpanded(sheet.sheetName),
                    'ti-chevron-up': isExpanded(sheet.sheetName),
                    'active-icon': isExpanded(sheet.sheetName)
                  }">
                </i>
              </div>

              <!-- Conditional Header Template -->
              <div *ngIf="isExpanded(sheet.sheetName)" class="header-grid">
                <ng-container
                  *ngFor="let header of getUniqueHeaders(sheet.headers)">
                  <div
                    [ngClass]="{
                      'header-item': true,
                      'disabled-header': isHeaderDisabled(header)
                    }">
                    <div class="my-checkbox" (click)="$event.stopPropagation()">
                      <input
                        style="display: none"
                        class="globalCheckbox"
                        type="checkbox"
                        [id]="'sheet-checkbox-' + sheet.sheetName"
                        [checked]="isSelected(sheet.sheetName, header)"
                        (change)="selectHeader(sheet.sheetName, header)"
                        [disabled]="isHeaderDisabled(header)" />
                      <!-- <label for=" my-checkbox" class="my-checkbox__label"
						  >Select All</label
						> -->
                    </div>
                    {{ header }}
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer confirmation">
          <button
            class="confirmation-outlined-btn"
            type="button"
            (click)="cancelRequest()">
            {{ cancelButtonText || "Cancel" }}
          </button>
          <button
            style="display: none"
            class="confirmation-outlined-btn"
            type="button"
            (click)="addREquest()"
            [disabled]="true">
            <!-- [disabled]="!isAnyHeaderSelected()" -->
            {{ confirmButtonText || "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { DropdownOptions } from "../models/globalDropdownOptions.model";
interface DropdownPosition {
  x: number;
  y: number;
}
interface DropDownpositionExtra extends DropdownPosition {
  width: number;
  height?: number;
  left?: number;
  top?: number;
  belowParent?: boolean;
  leftParent?: boolean;
  parentHeight?: number;
}

@Injectable({
  providedIn: "root",
})
export class DropdownService {
  constructor() {}
  private triggerDataSource = new Subject<{
    data: DropdownOptions;
    triggerId: string;
  }>();
  private selectedOptionSubject = new Subject<any>();
  public position = new Subject<DropDownpositionExtra>();
  private openedDropdownIdSource = new Subject<string | null>(); // To manage open dropdowns by ID
  private dropdownStateSource = new BehaviorSubject<string | null>(""); // Store the ID of the open dropdown
  public triggerData$ = this.triggerDataSource.asObservable();
  public getSelectedOption$ = this.selectedOptionSubject.asObservable();
  public getPosition$ = this.position.asObservable();
  public openDropdownId$ = this.openedDropdownIdSource.asObservable();
  public dropdownState$ = this.dropdownStateSource.asObservable();

  sendTriggerData(data: DropdownOptions, triggerId: string) {
    this.triggerDataSource.next({ data, triggerId });
  }
  sendPositionUpdate(position: DropDownpositionExtra) {
    this.position.next(position); // Emit updated position independently
  }
  sendSelectedOption(option: any, triggerId: string) {
    let data = {
      options: option,
      triggerId: triggerId,
    };
    this.selectedOptionSubject.next(data);
  }

  handleDropdownState(triggerId: string) {
    if (this.dropdownStateSource.value === triggerId) {
      // Close if already open
      this.dropdownStateSource.next(null);
    } else {
      // Open the clicked dropdown and close others
      this.dropdownStateSource.next(triggerId);
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
} from "@angular/core";
import { ImportDesignerService } from "../../../../services/import-designer.service";
import { ValidationService } from "../../../../services/validation.service";
import { Subject, Subscription } from "rxjs";
import { cloneDeep } from "lodash";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { debounceTime, takeUntil } from "rxjs/operators";
import { SortObjectByPipe } from "src/app/shared/pipes/sort-object-by.pipe";
import { FormGroup } from "@angular/forms";
import { AppService } from "src/app/core/services/app.service";
import { IfExistConditionGroup } from "src/app/shared/components/qeury-builder/qeury-builder.component";
import { ConfirmPopupService } from "src/app/shared/components/confirm-popup/confirmpopup.service";
import { SheetHeaderService } from "src/app/shared/components/sheet-header-list-popup/sheet-header.service";
import {
  MappingElement,
  NewMappingElement,
  sourceSupportedConnection,
} from "src/app/features/pipelines/models/mapping-element.model";
@Component({
  selector: "app-mappings",
  templateUrl: "./mappings.component.html",
  styleUrls: ["./mappings.component.scss"],
})
export class MappingsComponent implements OnInit, OnDestroy, OnChanges {
  public formGroup: FormGroup = new FormGroup({});
  public testData: {} = { delimiter: "TAB", requiredPart: "2" };
  public selectedLineIndex: any = -1;
  public functions: any = {};
  public currentLine: any;
  public showLineTypeInput: boolean = false;
  public newLineTypes: any = [];
  public newLineType: any = "";
  public searchText: string = "";
  public elementIds: any = [];
  public allElements: MappingElement[] = [];
  public filteredElements: MappingElement[] = [];
  public requiredOptions: any = [];
  public validateElement: MappingElement = {
    elementId: 111,
    elementLabel: "Validation",
    elementName: "Validation",
    elementLevel: "TRANSFORMATION",
    dataLength: 20,
    datatype: "validation",
    required: null,
    function: null,
    functionParameters: null,
    elementAttributes: null,
    description: null,
    createdBy: null,
    createdDate: null,
    modifiedBy: null,
    modifiedDate: null,
    sourceFileColumnNumber: null,
    defaultValue: null,
    validationExpression: null,
    orderNumber: null,
    startPosition: null,
    endPosition: null,
  };
  public sheetNameList: any = [];
  public defaultMapping: any = [];
  public pipeRefreshCounter = 0;
  private eventsSubscription?: Subscription;
  public isSafari: boolean = false;
  public selectedColumn: MappingElement | null = null;
  public existingFormValue: any = {};
  public selectedColumnIndex = -1;
  public targetOrSource = "target";
  public invoiceKeysList: any[] = [];
  public sourceElementsNameToDisplayName: any = {};
  public sourceElementsDisplayNameToName: any = {};
  public displayShowTableHeaders: { [key: string]: boolean } = {};
  public filteredInvoiceKeyList: any[] = [];
  public notice = "";
  selectedDept: any;
  public updatedTabs: any;
  private unsubscribe = new Subject<void>();
  public errorElementIndices: number[] = [];
  private invalidFuncParamsEleIndices: number[] = [];
  private invalidFuncParamsReasons: string[] = [];
  public existingSheetName?: string;
  public aiMappedElements: any[] = [];
  @Input() importId;
  @Input() mappings;
  @Input() currentLineValue: string = "";
  @Input() assetsUrl;
  @Input() access: any;
  @Output() updateCurrentLine = new EventEmitter();
  @Output() isUpdated = new EventEmitter();
  @Output() mappingsApiSuccess = new EventEmitter<boolean>();
  @Output() isUpdateMappings = new EventEmitter<any>();
  public subscribtion!: Subscription;
  public sourceFileHeaders: any[] = [];
  @Output() disableClearButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() disableAutoMappingButton: EventEmitter<any> =
    new EventEmitter<any>();
  @ViewChild("clearMappingsPopModal") clearMappingsPopModal: ElementRef | any;
  @ViewChild("lineTypeInput") lineTypeInput: ElementRef | any;
  @ViewChild("manageElements") manageElements: ElementRef | any;
  // @ViewChild("addSheet") addSheet: ElementRef | any;
  // @ViewChild("renameSheetModal") renameSheetModal: ElementRef | any;
  @ViewChild("tableAnchor") tableAnchor: ElementRef | any;
  @ViewChild("centerNotice") centerNotice: ElementRef | any;
  public sheetNameError: boolean = false;
  public openDropdownId: string | null = null;
  public sheetText: string = "";
  public currentFunctionParameter: string = "";
  public functionForm: string | null = null;
  public functionParameters: any;
  public transitionFuntionDataTypes = ["Date", "Numeric", "VARCHAR2"];
  private tempMappings: any;
  public sourceNotRequiredFunctions: string[] = [];
  public defaultValueNotRequiredFunctions: string[] = [];
  public transformationParameterfunction: string[] = [
    "if_exists",
    "skip_if_exists",
  ];
  public isExistEmptyValues: boolean = false;
  public hasAutoMappingChanges: boolean = false;
  public autoMapData: any = {};
  public ifExistCondtion: IfExistConditionGroup[] = [
    {
      conditions: [
        {
          customRegex: "",
          operator: "AND",
          sourceMapping: "",
          sequenceNumber: 1,
        },
      ],
      operator: "",
      sequenceNumber: 1,
    },
  ];
  public isAiMappingFetching: boolean = false;
  public checkForDefaultSheet: boolean = true;
  public missingSourceHeaders: any[] = [];
  public isMapMissingHeaders: boolean = false;
  public alreadyaddedMissingHeadersList: any[] = [];
  public kbrJSON: any;
  public sheetExpanded: any = {};
  public autoTargetData: any = {};
  public isTargetMappingCleared: boolean = false;
  public sourceFileHeadersFromAutoMap: any;
  public allMappingElementsLoaded = false;
  public fetchingSourceElements: boolean = false;
  public saveButtonClicked: boolean = false;
  public stopFetchingMappingElements = false;
  public allMappingElementsPageNo = 0;
  public pageSize = 25;
  public searchText$ = new Subject<string>();

  showClearMapping() {
    (this.clearMappingsPopModal as any).show();
  }
  cancelClearMapping() {
    (this.clearMappingsPopModal as any).hide();
  }
  parse(inputString: any) {
    if (inputString && typeof inputString === "string") {
      return JSON.parse(inputString);
    }
  }
  constructor(
    public appService: AppService,
    private importDesignerService: ImportDesignerService,
    private validationService: ValidationService,
    private ConfirmPopupService: ConfirmPopupService,
    private sortByAlpha: SortObjectByPipe,
    private SheetHeaderService: SheetHeaderService
  ) {
    let userAgentString = navigator.userAgent;
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    this.isSafari = userAgentString.indexOf("Safari") > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && this.isSafari) this.isSafari = false;

    this.eventsSubscription = this.appService
      .getEventUpdate()
      .subscribe((response) => {
        if (response === "update") {
          this.updateMapping();
        } else if (response === "mappingWithAi") {
          this.handleMappingWithAi();
        } else {
          this.confirmClearMapping();
        }
      });
  }

  ngOnInit(): void {
    this.importDesignerService.getTransformationFunctions().subscribe({
      next: (functionResponse) => {
        let functions = functionResponse;
        this.functionParameters = functionResponse;
        this.mappings = {
          ...this.mappings,
          functions,
        };
        this.setFunctions();
      },
      error: (err) => {
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          };
          this.appService.showToast(data);
        }
      },
    });
    this.requiredOptions = [
      { key: "Y", value: "Yes" },
      { key: "N", value: "No" },
      { key: "C", value: "Conditional" },
    ];
    this.tempMappings = cloneDeep(this.mappings["mapping"]?.["lines"]);
    // this.appService.currentSelectedDepartment.subscribe((data) => {
    // 	if (data["departmentList"]) {
    // 		this.selectedDept = data["departmentList"].find(
    // 			(d) => d.id == data.selected
    // 		);
    // 	}
    // });
    // this.generateSourceElements();
    this.isGetSourceElements();
    this.getMappingElements();
    let self = this;
    this.subscribtion = this.isUpdated
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.updatedTabs = res;
        if (this.mappings?.["distinctLineTypes"].length === 0) {
          this.newLineType = "Default";
          this.addLineType();
          this.disableAutoMappingButton.next(true);
        }
      });
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage =
        "You have unsaved changes. Please save or changes will be lost!";
      if (
        window.location.href.includes("/pipelines/manage") &&
        self.updatedTabs &&
        Object.keys(self.updatedTabs).length &&
        Object.keys(self.updatedTabs).some((key) => self.updatedTabs[key])
      ) {
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
      return undefined;
    });

    if (this.mappings["distinctLineTypes"].length === 0) {
      this.newLineType = "Default";
      this.addLineType();
      this.disableAutoMappingButton.next(true);
    }
    this.sourceFileHeaders = this.getSourceFileHeaders();
    this.searchText$
      .pipe(debounceTime(1000), takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.allMappingElementsPageNo = 0;
        this.getMappingElements();
      });
  }
  confirmClearMapping() {
    const secondConfirm = (type: string) => {
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: "Are you sure you want to clear the mappings ?",
        clearMappings: "true",
        headerText: "Clear Mappings",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        manageCallback: (value) => {
          if (value && value["isConfirm"] === "true") {
            if (type == "current") {
              this.handleClearMappings();
            } else {
              this.handleClearAllSheetMappings();
            }
          }
        },
      });
    };

    if (this.mappings["distinctLineTypes"].length > 1) {
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: "Do you want to clear only the current sheet or all sheets?",
        confirmButtonText: "Current Sheet",
        cancelButtonText: "All Sheets",
        manageCallback: (value: any) => {
          const type =
            value && value["isConfirm"] === "true" ? "current" : "all";
          secondConfirm(type);
        },
      });
    } else {
      secondConfirm("current");
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["mappings"] &&
      Object.keys(changes["mappings"]?.["currentValue"]).length
    ) {
      this.setFunctions();
      this.setLineIndex();
      this.filteredElements = JSON.parse(JSON.stringify(this.allElements));
      this.setSelectedElements();
      this.tempMappings = cloneDeep(this.mappings["mapping"]?.["lines"]);
      this.emitUpdateStatus();
      this.sourceFileHeaders = this.getSourceFileHeaders();
    }
    if (
      changes["mappings"] ||
      changes["currentLine"] ||
      this.currentLine ||
      this.mappings
    ) {
      this.updateCurrentLine.emit({
        currentLine: this.currentLine || "",
        mappings: this.mappings,
      });
    }
  }

  isElementInCurrentLineMappings(element: any) {
    return (
      this.mappings["mapping"]["lines"][this.selectedLineIndex]?.[
        "mappingElements"
      ]?.find((lt) => lt["elementId"] == element["elementId"]) != null
    );
  }
  handleDropdownToggle(id: string) {
    this.openDropdownId = id;
  }
  // getAllElementsDeptWise() {
  //   // return this.allElements.filter((el) => {
  //   //   if (this.selectedLineIndex == -1) return true;
  //   //   if (this.selectedDept.name == "ALL") return true;
  //   //   let temp = JSON.parse(el["elementAttributes"]);
  //   //   if (!temp || !temp["departments"]) {
  //   //     return false;
  //   //   } else {
  //   //     return temp["departments"].includes(this.selectedDept.name);
  //   //   }
  //   // });
  //   return this.filteredElements;
  // }

  // generateSourceElements() {
  //   let temp: any[] = [];
  //   if (this.mappings["sourceFileHeaders"]) {
  //     if (typeof this.mappings["sourceFileHeaders"] == "string") {
  //       this.mappings["sourceFileHeaders"] = JSON.parse(
  //         this.mappings["sourceFileHeaders"]
  //       );
  //     }
  //     if (!Array.isArray(this.mappings["sourceFileHeaders"])) {
  //       return;
  //     }
  //     for (let sheet of this.mappings["sourceFileHeaders"]) {
  //       for (let header of sheet["headers"]) {
  //         temp.push({ sheetName: sheet["sheetName"], header: header });
  //       }
  //     }
  //   }
  //   this._sourceElements = temp;
  // }

  async updateMapping() {
    this.saveButtonClicked = true;
    if (
      this.formGroup.dirty &&
      this.selectedLineIndex >= 0 &&
      this.selectedColumnIndex >= 0
    ) {
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
        this.selectedColumnIndex
      ].functionParameters = JSON.stringify(this.formGroup.value);
    }
    if (
      (this.selectedColumn?.elementLabel === "Validation" &&
        this.currentFunctionParameter?.toLowerCase() === "if_exists") ||
      this.currentFunctionParameter?.toLowerCase() === "skip_if_exists"
    ) {
      let emptyValues: any = [];
      this.ifExistCondtion.forEach((res, i) => {
        res["conditions"].forEach((condition: any) => {
          for (const key in condition) {
            if (key !== "operator" && condition[key] === "") {
              emptyValues.push({ key, value: condition[key], condition });
            }
          }
        });
      });
      if (emptyValues.length > 0) {
        this.isExistEmptyValues = true;
        let data = {
          duration: 3000,
          type: "error",
          message:
            "Please fill the required fields in Transformation Parameters",
        };
        this.appService.showToast(data);
        this.mappingsApiSuccess.emit(false);
        return;
      } else {
        this.isExistEmptyValues = false;
      }
      this.ifExistCondtion.forEach((item, i) => {
        if (item.conditions.length > 0) {
          item.conditions[0].operator = "";
        }
        if (i === 0) {
          item.operator = "";
        }
      });
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
        this.selectedColumnIndex
      ].functionParameters = JSON.stringify({
        IF_EXISTS: this.ifExistCondtion,
      });
    }
    if (this.selectedColumnIndex > -1) {
      if (this.formGroup.invalid) {
        let errorTypes = new Set<string>();
        for (let control in this.formGroup.controls) {
          if (this.formGroup.get(control)?.errors) {
            for (let err in this.formGroup.get(control)!.errors) {
              errorTypes.add(err);
            }
          }
        }
        if (!errorTypes.has("required")) {
          this.invalidFuncParamsReasons = Array.from(errorTypes);
        } else {
          this.invalidFuncParamsReasons = [];
        }
        this.invalidFuncParamsEleIndices.push(this.selectedColumnIndex);
      } else if (
        this.invalidFuncParamsEleIndices.includes(this.selectedColumnIndex)
      ) {
        this.invalidFuncParamsEleIndices =
          this.invalidFuncParamsEleIndices.filter(
            (index) => index != this.selectedColumnIndex
          );
        this.errorElementIndices = this.invalidFuncParamsEleIndices.filter(
          (index) => index != this.selectedColumnIndex
        );
      }
    }
    try {
      await this.multiAddElementIdsToCurrentLineType();
    } catch (error: any) {
      this.appService.showToast({
        duration: 3000,
        type: "error",
        message: error["error"]["message"] || error["message"] || error,
      });
      return;
    }
    let data = cloneDeep(this.mappings["distinctLineTypes"]);
    let noErrors: any = [];
    let allData: any = [];
    for (const sheetItem of data) {
      const mapping = this.mappings["mapping"]["lines"].find(
        (line) => line["lineType"] === sheetItem
      );
      if (!mapping) {
        continue;
      }
      mapping["mappingElements"]?.forEach((element, i) => {
        element["orderNumber"] = i + 1;
      });
      if (mapping["mappingElements"].length === 0) {
        this.appService.showToast({
          duration: 3000,
          type: "error",
          message: "Please add at least one mapping",
        });
        this.mappingsApiSuccess.emit(false);
        return;
      }
      const validateMapping = this.validationService.validateMapping(
        this.mappings,
        sheetItem,
        this.sourceNotRequiredFunctions,
        this.defaultValueNotRequiredFunctions
      );
      if (validateMapping.indices.length === 0) {
        const updatedSourceFileColumnNumberMapping =
          this.updateSourceFileColumnNumber(mapping);
        const data = updatedSourceFileColumnNumberMapping;
        allData.push(data);
      } else {
        noErrors.push(sheetItem);
        this.appService.showToast({
          duration: 3000,
          type: "error",
          message: validateMapping.message,
        });
        setTimeout(() => {
          this.currentLine = sheetItem;
          this.setLineIndex();
          this.errorElementIndices = validateMapping.indices;
          document
            .querySelector("#inputs_row_" + this.errorElementIndices[0])
            ?.scrollIntoView({ behavior: "smooth" });
        }, 20);
        return;
      }
    }
    if (noErrors.length === 0) {
      let data = {
        importId: this.importId,
        lineList: allData,
      };
      this.importDesignerService.updateSheetMapping(data).subscribe({
        next: async (response) => {
          if (response) {
            this.newLineTypes = [];
            if (this.existingSheetName) {
              this.existingSheetName = undefined;
            }
            let data = {
              duration: 3000,
              type: "success",
              message: response["message"],
            };
            this.appService.showToast(data);
            this.isUpdateMappings.next(this.mappings);
            this.selectedColumn = null;
            this.selectedColumnIndex = -1;
            this.saveButtonClicked = false;
            const linetypes = this.mappings["mapping"]["lines"]
              .filter((line) => line["mappingElements"].length > 0)
              .map((line) => line["lineType"]);
            this.mappings["distinctLineTypes"] = linetypes;
            this.hasAutoMappingChanges = false;
            this.disableAutoMappingButton.emit(true);
            this.mappings["disableAutoMapped"] = true;
            this.autoMapData = {};
            this.missingSourceHeaders = [];
            this.alreadyaddedMissingHeadersList = [];
            this.autoTargetData = {};
          } else {
            let data = {
              duration: 3000,
              type: "error",
              message: response["message"],
            };
            this.appService.showToast(data);
          }
          this.tempMappings = cloneDeep(this.mappings["mapping"]["lines"]);
          this.emitUpdateStatus();
          this.importDesignerService.unsavedMappingData = null;
          this.mappingsApiSuccess.next(true);
          this.errorElementIndices = [];
        },
        error: (err) => {
          this.mappingsApiSuccess.next(false);
          let data = {
            duration: 3000,
            type: "error",
            message: err["message"],
          };
          this.appService.showToast(data);
        },
      });
    }
  }

  setLineIndex() {
    if (!this.currentLine)
      this.currentLine = this.mappings["distinctLineTypes"][0];
    this.mappings?.["mapping"]?.["lines"].forEach((line, lineIndex) => {
      if (line["lineType"] == this.currentLine) {
        this.selectedLineIndex = lineIndex;
        this.importDesignerService.selectedLineIndex = lineIndex;
      }
    });
  }
  selectLineType(line, index, action) {
    if (this.currentLine === line && action === "select") {
      // If the clicked line is already selected, do nothing
      return;
    }
    let selectedMappings =
      this.mappings["mapping"]["lines"][this.selectedLineIndex];
    if (selectedMappings) {
      selectedMappings = cloneDeep(selectedMappings);
      selectedMappings["mappingElements"].map((element: any) => {
        element["sourceFileColumnNumber"] =
          this.sourceElementsNameToDisplayName[
            element["sourceFileColumnNumber"]
          ] || element["sourceFileColumnNumber"];
      });
    }
    if (
      (this.newLineTypes.length && action != "check") ||
      (!this.newLineTypes.length &&
        JSON.stringify(selectedMappings) !=
          JSON.stringify(this.tempMappings[this.selectedLineIndex]))
    ) {
      let validateSheetLength =
        this.mappings["mapping"]["lines"][
          this.mappings["distinctLineTypes"].indexOf(this.currentLine)
        ]["mappingElements"].length === 0;
      if (
        Object.keys(this.autoTargetData!).length > 0 &&
        !validateSheetLength
      ) {
        this.isSelectLineTypeChangeAutoTargetFetch(line);
      } else {
        let data = {
          duration: 3000,
          type: "alert",
          message: "Please update your changes",
        };

        this.appService.showToast(data);
      }
    } else if (this.newLineTypes.length && action == "check") {
      this.selectedColumn = null;
      this.setLineIndex();
    } else if (this.errorElementIndices.length) {
      let data = {
        duration: 3000,
        type: "alert",
        message: "Please update your changes",
      };
      this.appService.showToast(data);
    } else {
      let data = {
        importId: this.importId,
        lineType: line,
      };
      if (action == "select") {
        this.selectedColumn = null;
        this.existingSheetName = undefined;
        this.currentLine = line;
        this.setSelectedElements();
        this.mappings["mapping"]["lines"].forEach((line, lineIndex) => {
          if (line["lineType"] == this.currentLine) {
            this.selectedLineIndex = lineIndex;
            this.importDesignerService.selectedLineIndex =
              this.selectedLineIndex;
            this.changeSourceFileColumnNumber();
          }
        });
        this.errorElementIndices = [];
      }
    }
    this.isClearMappingButtonDisable(line);
  }

  deleteLineType(line, index: number) {
    if (this.mappings["distinctLineTypes"].length === 1) {
      return;
    }
    this.ConfirmPopupService.confirmMessageEvent.next({
      message: `Are you sure you want to delete the ${line} ?`,
      line: line,
      index: index,
      headerText: "Delete Sheet",
      confirmButtonText: "Delete",
      manageCallback: (value) => {
        if (value && value["isConfirm"] === "true") {
          this.confirmDeleteLineType(value["line"], value["index"]);
        }
        if (value && value["isConfirm"] === "false") {
        }
      },
    });
  }

  confirmDeleteLineType(line: string, index: number) {
    if (line && index >= 0) {
      if (this.newLineTypes.length) {
        this.deleteNewMapping(line, index);
      } else {
        this.deleteExistingMapping(line, index);
      }
      this.emitUpdateStatus();
    }
  }

  deleteNewMapping(line: string, index: number) {
    if (this.newLineTypes.indexOf(this.currentLine) != -1) {
      this.sheetNameList.push(line);
      this.mappings.mapping["lines"].splice(index, 1);
      this.mappings.distinctLineTypes.splice(index, 1);
      this.currentLine =
        this.mappings.distinctLineTypes.length > 0
          ? this.mappings["distinctLineTypes"][0]
          : "";
      this.mappings.mapping["lines"].forEach((element, idx) => {
        element.sequenceNumber = idx + 1;
      });
      this.setLineIndex();
      this.newLineTypes = [];
    }
  }

  deleteExistingMapping(line: string, index: number) {
    let data = {
      importId: this.importId,
      lineType: line,
    };
    this.importDesignerService.deleteSheetMapping(data).subscribe({
      next: (response) => {
        if (response) {
          this.mappings.mapping["lines"].splice(index, 1);
          this.mappings.distinctLineTypes.splice(index, 1);
          this.sheetNameList.push(line);
          if (this.currentLine == line) {
            this.currentLine =
              this.mappings.distinctLineTypes.length > 0
                ? this.mappings["distinctLineTypes"][0]
                : "";
          }
          let data = {
            duration: 3000,
            type: "success",
            message: response["message"],
          };
          this.appService.showToast(data);
          this.mappings.mapping["lines"].forEach((element, idx) => {
            element.sequenceNumber = idx + 1;
          });
          this.setLineIndex();
        }
        this.tempMappings = cloneDeep(this.mappings["mapping"]["lines"]);
        this.emitUpdateStatus();
      },
      error: (err) => {
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data);
        }
      },
    });
  }

  toggleLineType(action) {
    if (action == "showInput") {
      if (
        this.newLineTypes.length ||
        JSON.stringify(this.mappings["mapping"]["lines"]) !=
          JSON.stringify(this.tempMappings)
      ) {
        this.showLineTypeError();
      } else {
        this.showLineTypeInput = true;
      }
    }

    if (action == "save") {
      if (this.newLineTypes.length) {
        this.showLineTypeError();
      } else {
        this.addLineType();
      }
    }

    if (action == "close") {
      this.showLineTypeInput = false;
      this.clearLineTypeInputs();
    }
    this.emitUpdateStatus();
  }

  checkLineType() {}

  addLineType() {
    if (this.newLineType) {
      const sheetName = this.newLineType.toLowerCase();
      if (
        this.mappings?.["distinctLineTypes"]
          .map((lineType) => lineType.toLowerCase())
          .indexOf(sheetName) == -1
      ) {
        this.pipeRefreshCounter++;
        this.newLineTypes.push(this.newLineType);
        this.mappings.distinctLineTypes.push(this.newLineType);
        let defaultMappingForSheet = [];
        this.defaultMapping.forEach((element) => {
          if (element.sheet_name == this.newLineType) {
            defaultMappingForSheet = element.default_elements;
          }
        });
        this.mappings.mapping["lines"].push({
          lineType: this.newLineType,
          sequenceNumber: this.mappings.mapping["lines"].length + 1,
          mappingElements:
            defaultMappingForSheet && defaultMappingForSheet.length
              ? defaultMappingForSheet
              : [],
        });
        if (this.sheetNameList.length) {
          this.sheetNameList = this.sheetNameList.filter(
            (x) => x !== this.newLineType
          );
        }
        this.currentLine = this.newLineType;
        this.selectLineType(
          this.newLineType,
          this.mappings.distinctLineTypes.length - 1,
          "check"
        );
        this.setSelectedElements();
        this.showLineTypeInput = false;
        this.clearLineTypeInputs();
        // this.closeAddSheet();
      } else {
        this.sheetNameError = true;
        this.sheetText = "Sheet name already exists!";
      }
    } else {
      this.sheetNameError = true;
      this.sheetText = "Please enter the Sheet name.";
    }
  }

  clearLineTypeInputs() {
    if (this.lineTypeInput) {
      this.lineTypeInput.nativeElement.value = "";
      this.lineTypeInput.nativeElement.autofocus = true;
    }

    this.newLineType = "";
  }

  showLineTypeError() {
    let data = {
      duration: 3000,
      type: "alert",
      message: "Please update your changes",
    };
    this.appService.showToast(data);
  }

  setSelectedElements() {
    this.elementIds = [];
    if (this.mappings.mapping?.["lines"].length) {
      this.mappings.mapping?.["lines"].forEach((line) => {
        line["mappingElements"]?.forEach((element) => {
          if (!this.elementIds.includes(element["elementId"])) {
            this.elementIds.push(element["elementId"]);
          }
        });
        return;
      });
    } else {
      this.defaultMapping.forEach((element) => {
        this.elementIds.push(element["elementId"]);
      });
    }
  }

  setFunctions() {
    this.functions = {
      TRANSFORMATION: [
        {
          date: [],
          numeric: [],
          varchar2: [],
        },
      ],
      VALIDATION: [],
    };
    if (this.mappings && this.mappings?.functions) {
      this.mappings["functions"].forEach((value) => {
        if (value["optionLevel"].toUpperCase() == "TRANSFORMATION") {
          if (
            value["datatype"].toLowerCase() == "date" ||
            value["datatype"].toLowerCase() == "all"
          ) {
            this.functions["TRANSFORMATION"][0]["date"].push(value);
          }
          if (
            value["datatype"].toLowerCase() == "numeric" ||
            value["datatype"].toLowerCase() == "all"
          ) {
            this.functions["TRANSFORMATION"][0]["numeric"].push(value);
          }
          if (
            value["datatype"].toLowerCase() == "varchar2" ||
            value["datatype"].toLowerCase() == "all"
          ) {
            this.functions["TRANSFORMATION"][0]["varchar2"].push(value);
          }
        } else if (value["optionLevel"].toUpperCase() == "VALIDATION") {
          this.functions["VALIDATION"].push(value);
        }
        if (
          value["dropDownOptionAttributes"]?.["isSourceMappingRequired"] ===
          "false"
        ) {
          this.sourceNotRequiredFunctions.push(value["name"]);
        }
        if (
          value["dropDownOptionAttributes"]?.["isDefaultValueRequired"] ===
          "false"
        ) {
          this.defaultValueNotRequiredFunctions.push(value["name"]);
        }
      });
    }
    if (this.mappings?.udfFunctions) {
      let i = 1000;
      this.mappings["udfFunctions"].forEach((value: string) => {
        const func = {
          id: i++,
          name: value.toUpperCase(),
          parameters_required: "t",
          FUNCTION_TYPE: "UDF",
        };
        this.functions["TRANSFORMATION"][0]["date"].push(func);
        this.functions["TRANSFORMATION"][0]["numeric"].push(func);
        this.functions["TRANSFORMATION"][0]["varchar2"].push(func);
      });
    }
  }
  clearMapping() {
    this.errorElementIndices = [];
    this.mappings.mapping.lines.forEach((line) => {
      if (line.lineType == this.currentLine) {
        line["mappingElements"].forEach((element) => {
          element.sourceFileColumnNumber = null;
          element.function = null;
          element.functionParameters = null;
          element.defaultValue = null;
          element.required = "N";
          element.validationExpression = null;
        });
      }
    });
  }

  setRequiredStatus(event, element, index) {
    this.mappings.mapping["lines"][index]["mappingElements"].forEach(
      (mapping) => {
        if (element["elementId"] == mapping["elementId"]) {
          mapping["required"] = event.target.value == "on" ? "Y" : "N";
          return;
        }
      }
    );
  }

  getFunction(element: string): any {
    return this.groupBy(
      this.functions["TRANSFORMATION"][0][element?.toLowerCase() ?? ""] ?? [],
      "datatype"
    );
  }

  groupBy(arr: any[], key: string) {
    let map = {};
    for (let i = 0; i < arr.length; i++) {
      map[arr[i][key]] = [...(map[arr[i][key]] ?? []), arr[i]];
    }
    return Array.from(Object.values(map).flatMap((a) => a));
  }
  openElementManager() {
    if (this.mappings.mapping["lines"].length > 0) {
      this.setSelectedElements();
      (this.manageElements as any).show();
    } else {
      let data = {
        duration: 3000,
        type: "info",
        message: "Please create a sheet",
      };
      this.appService.showToast(data);
    }
  }

  closeElemetManager() {
    (this.manageElements as any).hide();
    this.emitUpdateStatus();
  }

  manageElement(element: any, value?: any, i?: any) {
    this.ifExistCondtion = [
      {
        conditions: [
          {
            customRegex: "",
            operator: "AND",
            sourceMapping: "",
            sequenceNumber: 1,
          },
        ],
        operator: "",
        sequenceNumber: 1,
      },
    ];
    this.currentFunctionParameter = "";
    if (value == "delete") {
      let eleIdIndex = this.elementIds.indexOf(element.elementId);
      let errorIndex = this.errorElementIndices.indexOf(i);
      if (errorIndex > -1) {
        this.errorElementIndices.splice(errorIndex, 1);
        this.errorElementIndices = this.errorElementIndices.map((index) =>
          index > i ? index - 1 : index
        );
      }
      if (eleIdIndex > -1 || element.isNewField) {
        this.mappings.mapping.lines.forEach((line) => {
          if (line.lineType == this.currentLine) {
            element["required"] = "N";
            if (element.elementLabel.toLowerCase() == "validation") {
              line.mappingElements.splice(i, 1);
              this.elementIds.splice(eleIdIndex, 1);
            } else {
              line.mappingElements.forEach((ele, index) => {
                if (
                  element.elementLabel === ele.elementLabel &&
                  element.elementId === ele.elementId
                ) {
                  if (
                    Object.keys(this.autoMapData!)?.length > 0 &&
                    element.isNewField
                  ) {
                    this.alreadyaddedMissingHeadersList.splice(
                      this.alreadyaddedMissingHeadersList.indexOf(
                        element.elementLabel
                      ),
                      1
                    );
                  }
                  line.mappingElements.splice(index, 1);
                  this.elementIds.splice(eleIdIndex, 1);
                }
              });
            }
          }
        });
      }
      // let disableAutoMappingValue = this.mappings["disableAutoMapped"]
      //   ? typeof this.mappings["disableAutoMapped"] === "boolean"
      //     ? this.mappings["disableAutoMapped"]
      //     : JSON.parse(this.mappings["disableAutoMapped"])
      //   : false;
      // if (
      //   this.mappings["mapping"]["lines"][
      //     this.mappings["distinctLineTypes"].indexOf(this.currentLine)
      //   ]["mappingElements"].length == 0 &&
      //   !disableAutoMappingValue
      // ) {
      //   this.disableAutoMappingButton.next(false);
      //   this.hasAutoMappingChanges = false;
      //   this.alreadyaddedMissingHeadersList = [];
      // } else {
      //   // this.checkToDisableAutoMapButtonForMissingHeaders();
      // }
    } else {
      this.elementIds.push(element.elementId);
      this.mappings.mapping.lines.forEach((line) => {
        if (line.lineType == this.currentLine) {
          element["required"] = "N";
          line.mappingElements.push(element);
          this.selectedColumn = element;
        }
      });
      setTimeout(() => {
        this.tableAnchor.nativeElement.scrollIntoView({
          behavior: "smooth",
        });
      }, 250);
    }
    // if ((this.elementIds.indexOf(element.elementId) == -1 || element.elementLabel.toLowerCase() == "validation") && !(value == 'delete' && element.elementLabel.toLowerCase() == "validation")) {
    //   this.elementIds.push(element.elementId);
    //   this.mappings.mapping.lines.forEach((line) => {
    //     if (line.lineType == this.currentLine) {
    //       element['required'] = 'N';
    //       line.mappingElements.push(element);
    //       this.selectedColumn = element;
    //     }
    //   });
    //   setTimeout(() => {
    //     this.tableAnchor.nativeElement.scrollIntoView({behavior: "smooth"});
    //   }, 250)
    // } else {
    //   let eleIdIndex
    //   (value == 'delete' && element.elementLabel.toLowerCase() == "validation") ? eleIdIndex = i : eleIdIndex = this.elementIds.indexOf(element.elementId);
    //   if (eleIdIndex > -1) {
    //     this.elementIds.splice(eleIdIndex, 1);
    //     this.mappings.mapping.lines.forEach((line) => {
    //       if (line.lineType == this.currentLine) {
    //         element['required'] = 'N';
    //         if (element.elementLabel.toLowerCase() == "validation") {
    //           line.mappingElements.splice(i, 1);
    //         } else {
    //           line.mappingElements.forEach((ele, index) => {

    //             if (
    //               element.elementLabel === ele.elementLabel &&
    //               element.elementId === ele.elementId
    //             ) {
    //               line.mappingElements.splice(index, 1);
    //             }
    //           });
    //         }
    //       }
    //     });
    //   }
    // }
    this.emitUpdateStatus();
  }

  searchElements(event: any) {
    // if (this.searchText) {
    //   this.filteredElements = this.allElements.filter((f) =>
    //     f["elementLabel"]?.toLowerCase().includes(this.searchText.toLowerCase())
    //   );
    // } else {
    //   this.filteredElements = this.allElements;
    // }
    this.allMappingElementsLoaded = false;
    this.stopFetchingMappingElements = false;
    this.filteredElements = [];
    this.allElements = [];
    this.searchText$.next(this.searchText);
  }

  ngOnDestroy() {
    this.unsubscribe.complete();
    this.subscribtion?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
    this.emitUpdateStatus();
  }

  orderUp(mappingElement: any, i: number) {
    if (i != 0) {
      let tempValue: any;
      tempValue =
        this.mappings["mapping"]["lines"][this.selectedLineIndex]
          .mappingElements[i - 1];
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
        i - 1
      ] = mappingElement;
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
        i
      ] = tempValue;
    }
    this.emitUpdateStatus();
  }

  orderDown(mappingElement, i) {
    if (
      i !=
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements
        .length -
        1
    ) {
      let tempValue;
      tempValue =
        this.mappings["mapping"]["lines"][this.selectedLineIndex]
          .mappingElements[i + 1];
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
        i + 1
      ] = mappingElement;
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
        i
      ] = tempValue;
    }
    this.emitUpdateStatus();
  }

  addValidationElement() {
    if (this.validateElement) {
      this.allElements.push(this.validateElement);
      this.elementIds.push(this.validateElement.elementId);
      this.mappings.mapping.lines.forEach((line) => {
        if (line.lineType == this.currentLine) {
          this.validateElement["required"] = "Y";
          line.mappingElements.push(
            JSON.parse(JSON.stringify(this.validateElement))
          );
        }
      });
    }
    this.emitUpdateStatus();
  }
  transformationInputsChanged($event, j) {
    if (!$event.target.value) {
      return;
    }
    // this.selectedLineIndex Sheet Selected
    // this.selectedColumnIndex Mapping Selected
    let selectedValue = $event.target.value;
    let functionParameter = this.existingFormValue;
    functionParameter[j].value = selectedValue;
    this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
      this.selectedColumnIndex
    ].functionParameters = JSON.stringify(functionParameter);
    if (this.selectedColumn)
      this.selectedColumn.functionParameters =
        JSON.stringify(functionParameter);
    this.emitUpdateStatus();
  }
  emitUpdateStatus() {
    this.isUpdated.emit({
      mappings:
        JSON.stringify(this.mappings["mapping"]["lines"]) !=
        JSON.stringify(this.tempMappings),
    });
    this.isClearMappingButtonDisable();
    this.disableAutoMapWithAiButton();
  }

  selectColumn(element: any, index: number, tryEdit?: boolean) {
    if (index === this.selectedColumnIndex) return;
    this.unselectColumn();
    setTimeout(() => {
      this.currentFunctionParameter = element?.function?.toLowerCase();
      this.selectedColumn = element as MappingElement;
      if (tryEdit) {
        this.selectedColumn["isEditing"] = true;
      }
      this.existingFormValue = this.selectedColumn.functionParameters
        ? JSON.parse(this.selectedColumn.functionParameters)
        : {};
      if (
        (element?.elementLabel === "Validation" &&
          element?.function === "IF_EXISTS") ||
        element?.function === "SKIP_IF_EXISTS"
      ) {
        const ele: any = element.functionParameters
          ? JSON.parse(element.functionParameters)
          : {};
        this.ifExistCondtion = [
          {
            conditions: [
              {
                customRegex: "",
                operator: "AND",
                sourceMapping: "",
                sequenceNumber: 1,
              },
            ],
            operator: "",
            sequenceNumber: 1,
          },
        ];
        if (ele?.IF_EXISTS) {
          this.ifExistCondtion = ele?.IF_EXISTS;
        } else {
          ele.IF_EXISTS = null;
        }
      }
      this.selectedColumnIndex = index;
      this.functionForm = this.functionParameters?.find(
        (item) => item.name?.toLowerCase() === element.function?.toLowerCase()
      )?.formAttributes;
      // if (this.functionForm) {
      //   this.functionForm = JSON.stringify(this.functionForm);
      // }
      this.formGroup = new FormGroup({});
      setTimeout(() => {
        if (this.invalidFuncParamsEleIndices.includes(index)) {
          this.formGroup.markAllAsTouched();
        }
      }, 150);
    }, 10);
  }

  unselectColumn(column?: string) {
    if (
      this.formGroup.dirty &&
      this.selectedLineIndex >= 0 &&
      this.selectedColumnIndex >= 0
    ) {
      this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
        this.selectedColumnIndex
      ].functionParameters = JSON.stringify(this.formGroup.value);
    }
    if (this.selectedColumnIndex > -1) {
      if (this.formGroup.invalid) {
        let errorTypes = new Set<string>();
        for (let control in this.formGroup.controls) {
          if (this.formGroup.get(control)?.errors) {
            for (let err in this.formGroup.get(control)!.errors) {
              errorTypes.add(err);
            }
          }
        }
        if (!errorTypes.has("required")) {
          this.invalidFuncParamsReasons = Array.from(errorTypes);
        } else {
          this.invalidFuncParamsReasons = [];
        }
        this.invalidFuncParamsEleIndices.push(this.selectedColumnIndex);
      } else if (
        this.invalidFuncParamsEleIndices.includes(this.selectedColumnIndex)
      ) {
        this.invalidFuncParamsEleIndices =
          this.invalidFuncParamsEleIndices.filter(
            (index) => index != this.selectedColumnIndex
          );
        this.errorElementIndices = this.invalidFuncParamsEleIndices.filter(
          (index) => index != this.selectedColumnIndex
        );
      }
    }
    if (!column) {
      this.selectedColumn = null;
      this.selectedColumnIndex = -1;
    }
    this.functionForm = null;
    this.existingFormValue = {};
  }

  dragDrop(event: CdkDragDrop<string[]>) {
    this.currentFunctionParameter = "";
    this.ifExistCondtion = [
      {
        conditions: [
          {
            customRegex: "",
            operator: "AND",
            sourceMapping: "",
            sequenceNumber: 1,
          },
        ],
        operator: "",
        sequenceNumber: 1,
      },
    ];
    if (event.previousContainer.id == event.container.id) {
      moveItemInArray(
        this.mappings["mapping"]["lines"][this.selectedLineIndex][
          "mappingElements"
        ],
        event.previousIndex,
        event.currentIndex
      );
      if (this.errorElementIndices.includes(event.previousIndex)) {
        // let index = this.errorElementIndices.indexOf(event.previousIndex);
        // this.errorElementIndices[index] = event.currentIndex;
        if (event.currentIndex > event.previousIndex) {
          this.errorElementIndices = this.errorElementIndices.map((index) =>
            index <= event.previousIndex &&
            index >= event.currentIndex &&
            index > 0
              ? index - 1
              : index
          );
        } else if (event.currentIndex < event.previousIndex) {
          this.errorElementIndices = this.errorElementIndices.map((index) =>
            index >= event.previousIndex && index <= event.currentIndex
              ? index + 1
              : index
          );
        }
      } else if (this.errorElementIndices.includes(event.currentIndex)) {
        if (event.previousIndex > event.currentIndex) {
          this.errorElementIndices = this.errorElementIndices.map((index) =>
            index >= event.currentIndex && index <= event.previousIndex
              ? index + 1
              : index
          );
        } else if (event.previousIndex < event.currentIndex) {
          this.errorElementIndices = this.errorElementIndices.map((index) =>
            index <= event.currentIndex &&
            index >= event.previousIndex &&
            index > 0
              ? index - 1
              : index
          );
        }
      }
    } else {
      if (event.previousContainer.id == "newFieldList") {
        let newTypes = [
          "VARCHAR2",
          "Numeric",
          "Date",
          "Validation",
          "Variable",
        ];
        if (newTypes[event.previousIndex] == "Validation") {
          this.addNewValidationElement(event.currentIndex);
          return;
        } else if (newTypes[event.previousIndex] == "Variable") {
          this.addNewVariable(event.currentIndex);
          return;
        }
        let name = `New ${
          newTypes[event.previousIndex] == "VARCHAR2"
            ? "string"
            : newTypes[event.previousIndex].toLowerCase()
        } field`;
        let el = {
          elementId: Date.now(),
          elementName: name,
          elementLabel: name,
          datatype: newTypes[event.previousIndex],
          required: "N",
          isNewField: true,
          function: null,
          functionParameters: null,
        };
        this.selectedColumn = el as MappingElement;
        this.elementIds.push(this.selectedColumn["elementId"]);
        this.mappings["mapping"]["lines"][this.selectedLineIndex][
          "mappingElements"
        ].splice(event.currentIndex, 0, el);
        this.errorElementIndices = this.errorElementIndices.map((index) =>
          index >= event.currentIndex ? index + 1 : index
        );
      } else if (event.previousContainer.id == "fieldsList") {
        this.selectedColumn = this.filteredElements[event.previousIndex];
        this.selectedColumn["required"] = "N";
        this.elementIds.push(this.selectedColumn["elementId"]);
        this.mappings["mapping"]["lines"][this.selectedLineIndex][
          "mappingElements"
        ].splice(
          event.currentIndex,
          0,
          JSON.parse(JSON.stringify(this.selectedColumn))
        );
        this.errorElementIndices = this.errorElementIndices.map((index) =>
          index >= event.currentIndex ? index + 1 : index
        );
      }
    }
    this.functionForm = null;
    this.emitUpdateStatus();
  }

  addNewValidationElement(index: number, scrollToEnd = false) {
    let name = "Validation";
    let el = {};
    el = { ...this.validateElement };
    el["elementName"] = el["elementLabel"] = name;
    this.elementIds.push(this.validateElement.elementId);
    this.mappings.mapping.lines.forEach((line) => {
      if (line.lineType == this.currentLine) {
        el["required"] = "N";
        line.mappingElements.splice(
          index == -1 ? line.mappingElements.length : index,
          0,
          el
        );
      }
    });
    this.selectedColumn = el as MappingElement;
    this.emitUpdateStatus();
    if (scrollToEnd) {
      setTimeout(() => {
        this.tableAnchor.nativeElement.scrollIntoView({
          behavior: "smooth",
        });
      }, 250);
    }
    this.currentFunctionParameter = "";
    // this.disableAutoMapWithAiButton();
  }

  addNewVariable(index, scrollToEnd = false) {
    let num = 1;
    let el = {};
    this.mappings["mapping"]["lines"][
      this.mappings["distinctLineTypes"].indexOf(this.currentLine)
    ]["mappingElements"].forEach((el) => {
      if (
        el["elementLabel"].startsWith("Variable") &&
        isFinite(el["elementLabel"].split("Variable")[1])
      ) {
        let temp = parseInt(el["elementLabel"].split("Variable")[1]);
        if (temp >= num) {
          num = temp + 1;
        }
      }
    });
    let name = "Variable";
    name += ` ${num}`;
    el = { ...this.validateElement };
    el["elementName"] = el["elementLabel"] = name;
    this.elementIds.push(this.validateElement.elementId);
    this.mappings.mapping.lines.forEach((line) => {
      if (line.lineType == this.currentLine) {
        el["required"] = "N";
        line.mappingElements.splice(
          index == -1 ? line.mappingElements.length : index,
          0,
          el
        );
      }
    });
    this.selectedColumn = el as MappingElement;
    if (scrollToEnd) {
      setTimeout(() => {
        this.tableAnchor.nativeElement.scrollIntoView({
          behavior: "smooth",
        });
      }, 250);
    }
    this.emitUpdateStatus();
    // this.disableAutoMapWithAiButton();
  }

  addNewField(index: number) {
    if (!this.currentLine) return;
    let newTypes = ["VARCHAR2", "Numeric", "Date", "Validation", "Variable"];
    if (newTypes[index] == "Validation") {
      this.addNewValidationElement(-1, true);
      return;
    } else if (newTypes[index] == "Variable") {
      this.addNewVariable(-1, true);
      return;
    } else {
      let name = `New ${
        newTypes[index] == "VARCHAR2" ? "string" : newTypes[index].toLowerCase()
      } field`;
      let el = {
        elementId: Date.now(),
        elementName: name,
        elementLabel: name,
        datatype: newTypes[index],
        required: "N",
        isNewField: true,
        function: null,
      };
      // this.selectedColumn = el;
      // this.elementIds.push(this.selectedColumn['elementId']);
      this.manageElement(el);
      // this.disableAutoMapWithAiButton();
    }
  }

  openAddSheet() {
    if (
      this.newLineTypes.length > 0 ||
      JSON.stringify(this.mappings["mapping"]["lines"]) !=
        JSON.stringify(this.tempMappings)
    ) {
      let data = {
        duration: 3000,
        type: "alert",
        message: "Please update your changes",
      };
      this.appService.showToast(data);
    } else {
      this.newLineType = "";
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: `Add Sheet`,
        activeStatus: "true",
        confirmButtonText: "Add",
        headerText: "Add Sheet",
        isDataPipelines: true,
        isName: "",
        sheetList: this.mappings["distinctLineTypes"],
        manageCallback: (value) => {
          if (value && value["isConfirm"] === "true") {
            this.newLineType = value.sheetName;
            this.addSheetAndClosePopup();
          }
          if (value && value["isConfirm"] === "false") {
          }
        },
      });
    }
  }
  addSheetAndClosePopup() {
    this.toggleLineType("save");
  }

  changeSelectionTab(tab: string) {
    this.targetOrSource = tab;
    this.searchText = "";
    this.filteredInvoiceKeyList = this.invoiceKeysList;
    this.searchElements({});
  }

  saveNewField(el: any, index: number, unselect = false) {
    if (!this.access) {
      let data = {
        duration: 3000,
        type: "alert",
        message: "Access Denied: You do not have permission for this action.",
      };
      this.appService.showToast(data);
      return;
    }
    let departments = this.appService.currentSelectedDepartment.value;
    if (
      el.elementLabel == "New string field" ||
      el.elementLabel == "New numeric field" ||
      el.elementLabel == "New date field" ||
      !el.elementLabel
    ) {
      let data = {
        duration: 3000,
        type: "error",
        message: "Please give a valid name",
      };
      this.appService.showToast(data);
      return;
    }
    let tempEl = JSON.parse(JSON.stringify(el));
    el = {
      datatype: el["datatype"],
      elementLabel: el["elementLabel"],
      elementName: el["elementLabel"],
    };
    el["elementLevel"] = "TRANSFORMATION";
    el["elementAttributes"] = null;
    // el["elementAttributes"] = JSON.stringify({
    //   departments:
    //     departments["selected"] > -1
    //       ? [
    //         departments["departmentList"].find(
    //           (d) => d.id == departments["selected"]
    //         )?.name ?? "",
    //       ]
    //       : departments["departmentList"]
    //         .filter((e) => e["name"] != "ALL")
    //         .map((e) => e["name"]),
    // });
    el.functionParameters = JSON.stringify(this.formGroup.value);
    if (
      this.currentFunctionParameter?.toLowerCase() === "if_exists" ||
      this.currentFunctionParameter?.toLowerCase() === "skip_if_exists"
    ) {
      this.ifExistCondtion.forEach((item, i) => {
        if (item.conditions.length > 0) {
          item.conditions[0].operator = "";
        }
        if (i === 0) {
          item.operator = "";
        }
      });
      el.functionParameters = JSON.stringify({
        IF_EXISTS: this.ifExistCondtion,
      });
    }
    this.importDesignerService.saveNewMappingField(el).subscribe({
      next: (data) => {
        if (data) {
          let newEl = data["mappingElements"];
          newEl["required"] = "N";
          for (let key of Object.keys(newEl)) {
            if (key == "required" && newEl[key] != tempEl[key]) {
              newEl[key] = tempEl[key];
            } else {
              newEl[key] ??= tempEl[key];
            }
          }
          let removeIndex = this.elementIds.indexOf(tempEl["elementId"]);
          if (removeIndex > -1) {
            this.elementIds.splice(removeIndex, 1);
          }
          this.mappings["mapping"]["lines"][this.selectedLineIndex][
            "mappingElements"
          ].splice(index, 1, newEl);
          if (!unselect) {
            this.selectedColumn = newEl;
          }
          this.allElements.push(newEl);
          this.elementIds.splice(index, 0, newEl["elementId"]);
          let data1 = {
            duration: 3000,
            type: "success",
            message: data["message"],
          };
          this.appService.showToast(data1);
        }
      },
      error: (err) => {
        if (err.status !== 403) {
          let data1 = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data1);
        }
      },
    });
    if (unselect) {
      this.unselectColumn();
    }
  }

  updateFieldName(el: MappingElement, index: number) {
    let preEl = cloneDeep(el);
    if (
      el.elementLabel == "New string field" ||
      el.elementLabel == "New numeric field" ||
      el.elementLabel == "New date field" ||
      !el.elementLabel
    ) {
      let data1 = {
        duration: 3000,
        type: "error",
        message: "Please give a valid name",
      };
      this.appService.showToast(data1);
      return;
    }
    if (el["elementName"].trim() == el["elementLabel"].trim()) {
      if (el["elementName"] != el["elementLabel"]) {
        el["elementName"] = el["elementLabel"] = el["elementLabel"].trim();
      }
      delete el["isEditing"];
      return;
    }
    el["elementName"] = el["elementLabel"] = el["elementLabel"].trim();
    this.importDesignerService.updateMappingField(el).subscribe({
      next: (data) => {
        if (data) {
          let newEl = data["mappingElements"];
          // this.mappings["mapping"]["lines"][this.selectedLineIndex][
          //   "mappingElements"
          // ].splice(index, 1, newEl);
          delete el.isEditing;
          let data1 = {
            duration: 3000,
            type: "success",
            message: data["message"],
          };
          const ell = this.allElements.find(
            (ell) => ell.elementId === el.elementId
          );
          if (ell) {
            ell.elementName = ell.elementLabel = el.elementLabel;
            this.searchElements(null);
          }
          this.appService.showToast(data1);
          this.selectedColumn = null;
          this.selectedColumnIndex = -1;
        }
      },
      error: (err) => {
        if (err.status !== 403) {
          let data1 = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"],
          };
          this.appService.showToast(data1);
          preEl["elementLabel"] = preEl["elementName"].trim();
          this.mappings["mapping"]["lines"][this.selectedLineIndex][
            "mappingElements"
          ].splice(index, 1, preEl);
          this.selectedColumnIndex = -1;
          this.selectColumn(preEl, index);
        }
      },
    });
  }

  clearNewFieldInput(mappingElement: any) {
    if (
      mappingElement.elementLabel == "New string field" ||
      mappingElement.elementLabel == "New numeric field" ||
      mappingElement.elementLabel == "New date field"
    ) {
      mappingElement.elementLabel = "";
    }
  }

  tryEdit(mappingElement, index: number) {
    if (
      mappingElement["createdBy"] != -1 &&
      mappingElement["createdBy"] != null
    ) {
      mappingElement.isEditing = true;

      this.currentFunctionParameter = "";
      this.ifExistCondtion = [
        {
          conditions: [
            {
              customRegex: "",
              operator: "AND",
              sourceMapping: "",
              sequenceNumber: 1,
            },
          ],
          operator: "",
          sequenceNumber: 1,
        },
      ];
      this.selectColumn(mappingElement, index, true);
    } else {
      this.showCenterNotice("Non-editable field name");
    }
  }
  showCenterNotice(note: string) {
    this.notice = note;
    this.centerNotice.nativeElement.classList.add("active");
    setTimeout(() => {
      this.centerNotice.nativeElement.classList.remove("active");
    }, 1500);
  }
  checkFunctionParameters(input: any, mapping: any) {
    try {
      this.ifExistCondtion = [
        {
          conditions: [
            {
              customRegex: "",
              operator: "AND",
              sourceMapping: "",
              sequenceNumber: 1,
            },
          ],
          operator: "",
          sequenceNumber: 1,
        },
      ];
      this.currentFunctionParameter = "";
      this.currentFunctionParameter = input
        ?.split(":")
        .pop()
        .trim()
        .toLowerCase();
      this.functionForm = this.functionParameters.find(
        (item) =>
          item.name?.toLowerCase() ===
          this.currentFunctionParameter?.toLowerCase()
      )?.formAttributes;
      this.formGroup = new FormGroup({});
      mapping.function = input;
      if (!mapping["function"]) {
        mapping["functionParameters"] = null;
        return;
      } else {
        if (
          this.sourceNotRequiredFunctions.includes(mapping["function"].trim())
        ) {
          mapping["sourceFileColumnNumber"] = null;
        }
        if (
          this.defaultValueNotRequiredFunctions.includes(
            mapping["function"].trim()
          )
        ) {
          mapping["defaultValue"] = null;
        }
      }
    } catch (error) {}
    // const value = mapping["function"];
    // if(value && mapping.elementLabel != 'Validation'){
    //   if(!this.mappings.functions.find(func => func.name == value) && this.mappings.udfFunctions.find((func: string) => func.toUpperCase() == value)){
    //     mapping['FUNCTION_TYPE']='UDF';
    //   }else if(mapping['FUNCTION_TYPE']){
    //     delete mapping['FUNCTION_TYPE'];
    //   }
    // }
  }

  checkValidationRule(mapping: any) {
    if (mapping["required"] != "C") {
      mapping["validationExpression"] = null;
    }
  }

  newDrop(event: CdkDragDrop<string>, mappingElement: any) {
    let previousMappingElement = mappingElement.sourceFileColumnNumber;
    if (previousMappingElement !== event.item.data) {
      mappingElement.sourceFileColumnNumber = event.item?.data;
      this.emitUpdateStatus();
    }
  }

  get cdkConnected() {
    if (
      this.mappings &&
      this.mappings["mapping"]["lines"].length &&
      this.selectedLineIndex > -1
    ) {
      return this.mappings["mapping"]["lines"][
        this.selectedLineIndex
      ].mappingElements.map((m, i) => "sourceDrop" + i);
    }
    return [];
  }

  isClearMappingButtonDisable(line?: any) {
    let currentsheet: any = line ?? this.currentLine;
    if (!currentsheet || (this.mappings["mapping"]["lines"] ?? []).length == 0)
      return;
    let mapLength = this.mappings["mapping"]["lines"]
      .filter((res) => res["lineType"] === currentsheet)
      .map((res) => {
        return res["mappingElements"].length;
      });
    this.disableClearButton.emit(mapLength[0] == 0);
  }

  renameSheet(line: any, lineTypeIndex: any) {
    this.newLineType = line;
    // this.openRenameSheet();
    this.ConfirmPopupService.confirmMessageEvent.next({
      message: `Rename Sheet`,
      activeStatus: "true",
      confirmButtonText: "Rename",
      headerText: "Rename Sheet",
      isDataPipelines: true,
      isName: line,
      sheetList: this.mappings["distinctLineTypes"],
      manageCallback: (value) => {
        if (value && value["isConfirm"] === "true") {
          this.newLineType = value.sheetName;
          this.renameSheetAndClosePopup();
        }
        if (value && value["isConfirm"] === "false") {
        }
      },
    });
  }
  renameSheetAndClosePopup() {
    if (this.newLineType) {
      const sheetName = this.newLineType.toLowerCase();
      if (
        this.mappings["distinctLineTypes"]
          .map((lineType) => lineType.toLowerCase())
          .indexOf(sheetName) == -1
      ) {
        if (!this.existingSheetName) {
          this.existingSheetName = this.currentLine;
        }
        this.mappings["distinctLineTypes"][this.selectedLineIndex] =
          this.newLineType;
        this.mappings.mapping["lines"][this.selectedLineIndex]["lineType"] =
          this.newLineType;
        this.currentLine = this.newLineType;
        this.setSelectedElements();
        this.showLineTypeInput = false;
        this.clearLineTypeInputs();
        // this.closeRenameSheet();
      } else if (
        this.mappings["distinctLineTypes"]
          .map((lineType) => lineType.toLowerCase())
          .indexOf(sheetName) == this.selectedLineIndex
      ) {
        // this.closeRenameSheet();
      } else {
        this.sheetNameError = true;
        this.sheetText = "Sheet name already exists!";
      }
    } else {
      this.sheetNameError = true;
      this.sheetText = "Please enter the Sheet name.";
    }
  }
  //   get searchSourceElements() {
  //     if (!this.searchText) {
  //       return this.invoiceKeysList;
  //     }
  //     return this.invoiceKeysList.filter((el, key) =>
  //       el["displayName"].toLowerCase().includes(this.searchText.toLowerCase())
  //     );
  //   }

  filterSourceHeaders(event: Event) {
    const searchText = (event.target as HTMLInputElement).value
      .toLowerCase()
      .trim();
    if (!searchText) {
      this.filteredInvoiceKeyList = this.invoiceKeysList;
      return;
    }
    this.filteredInvoiceKeyList = this.invoiceKeysList
      .map((el) => {
        const matchesMain = el["displayName"]
          .toLowerCase()
          .includes(searchText);
        let filteredTableElements = [];
        // Only filter tableElements if elementType is 'table' and displayName does not match
        if (el["elementType"] === "table" && !matchesMain) {
          filteredTableElements =
            el["tableElements"]?.filter((header) =>
              header["displayName"].toLowerCase().includes(searchText)
            ) || [];
        }
        // If the main displayName matches or filtered tableElements exist, expand
        if (matchesMain || filteredTableElements.length > 0) {
          if (el["elementType"] !== "table")
            this.displayShowTableHeaders[el.displayName] = true; // Expand on match
          return {
            ...el,
            ...(el["elementType"] === "table"
              ? {
                  tableElements:
                    filteredTableElements.length > 0
                      ? filteredTableElements
                      : el["tableElements"],
                }
              : {}),
          };
        }

        return null; // Return null for non-matching elements
      })
      .filter((el) => el !== null); // Filter out the null values
  }
  get searchSourceHeaders() {
    if (!this.searchText) {
      return this.sourceFileHeaders; // Return a shallow copy of the original array
    }

    return this.sourceFileHeaders.filter((header) =>
      header.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  getSourceFileHeaders() {
    const headers = JSON.parse(
      JSON.stringify(this.mappings["sourceFileHeaders"] ?? [])
    );

    const sheetNameWithHeaders: string[] = [];
    const fileType = this.mappings?.["importReadRules"]?.["fileType"];
    for (const sheet of headers) {
      const sortedHeaders = sheet.headers.sort((a, b) => a.localeCompare(b));
      for (const header of sortedHeaders) {
        if (this.isAddSheetNameValidFunc(fileType)) {
          const headerText = sheet["sheetName"]
            ? `${sheet["sheetName"]}:${header}`
            : header;
          sheetNameWithHeaders.push(headerText);
        } else {
          const headerText = header;
          sheetNameWithHeaders.push(headerText);
        }
      }
    }
    if (sheetNameWithHeaders) return sheetNameWithHeaders;
    else return [];
  }
  isGetSourceElements() {
    if (
      this.mappings?.["importReadRules"]?.["fileType"]?.toLowerCase() ===
        "xls" &&
      this.mappings?.["importReadRules"]?.["fileAttributes"]?.[
        "fileFormat"
      ]?.toLowerCase() === "kbr proposal"
    ) {
      this.appService.getKbrJson().subscribe({
        next: (res: any) => {
          this.kbrJSON = res;
        },
      });
      return;
    }
    this.fetchingSourceElements = true;
    let data = {
      importId: this.importId,
      sourceFileType:
        this.mappings["importReadRules"]["fileAttributes"]["fileFormat"],
      sourceFileFormat: this.mappings["importReadRules"]["fileType"],
    };
    if (
      ["NET SUITE", "GOOGLE ADS"].includes(
        this.mappings["sourceConnectionType"]
      )
    ) {
      data["connectionType"] = this.mappings["sourceConnectionType"];
      data["tableName"] =
        this.mappings["pipelineAttributes"]["connections"]?.["sourceQuery"];
    }
    this.importDesignerService.getSourceElements(data).subscribe({
      next: (response) => {
        if (response) {
          if (response["sourceFileHeadersList"]) {
            this.sourceFileHeaders = response["sourceFileHeadersList"].flatMap(
              (item: any) => item.headers
            );
            this.mappings["sourceFileHeaders"] =
              response["sourceFileHeadersList"];
          }
          this.invoiceKeysList = this.sortSourceElementsByDisplayOrder(
            response["invoiceKeysList"] ?? []
          );
          this.filteredInvoiceKeyList = this.invoiceKeysList;
          const data: any[] = JSON.parse(
            JSON.stringify(response["invoiceKeysList"] ?? [])
          );
          const copiedResponse: any[] =
            this.addDisplayNametoTableLineElement(data);
          let source1 = {};
          let source2 = {};
          for (let element of copiedResponse) {
            source1[element["elementName"]] = element["displayName"];
            source2[element["displayName"]] = element["elementName"];
          }
          this.sourceElementsNameToDisplayName = source1;
          this.sourceElementsDisplayNameToName = source2;
          this.changeSourceFileColumnNumber();
        }
        this.fetchingSourceElements = false;
      },
      error: (err) => {
        this.fetchingSourceElements = false;
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          };
          this.appService.showToast(data);
        }
      },
    });
  }

  changeSourceFileColumnNumber() {
    this.mappings["mapping"]["lines"].forEach((line: any) => {
      line["mappingElements"].forEach((element: any) => {
        element["sourceFileColumnNumber"] =
          this.sourceElementsNameToDisplayName[
            element["sourceFileColumnNumber"]?.trim()
          ] || element["sourceFileColumnNumber"];
      });
    });
    this.tempMappings = cloneDeep(this.mappings["mapping"]["lines"]);
  }
  updateSourceFileColumnNumber(data: any) {
    let updatedData = JSON.parse(JSON.stringify(data));
    updatedData["mappingElements"].forEach((element: any) => {
      element["sourceFileColumnNumber"] =
        this.sourceElementsDisplayNameToName[
          element["sourceFileColumnNumber"]?.trim()
        ] || element["sourceFileColumnNumber"];
    });
    return updatedData;
  }

  showTableHeaders(index: number, name: string) {
    if (
      this.filteredInvoiceKeyList[index]["tableElements"] === null ||
      this.filteredInvoiceKeyList[index]["tableElements"].length === 0
    ) {
      return;
    }
    this.displayShowTableHeaders[name] = !this.displayShowTableHeaders[name];
  }

  expandSheet(index: number) {
    if (!this.sheetExpanded[index]) {
      this.sheetExpanded[index] = {
        totalExpanded: true,
        subSectionsExpanded: {},
      };
    } else {
      this.sheetExpanded[index]["totalExpanded"] = true;
    }
  }

  collapseSheet(index: number) {
    if (this.sheetExpanded[index]) {
      this.sheetExpanded[index]["totalExpanded"] = false;
    }
  }

  getSortedObjectKeys(obj: any) {
    return Object.keys(obj).sort(
      (a, b) =>
        (obj[a].position_index ?? Number.MAX_SAFE_INTEGER) -
        (obj[b].position_index ?? Number.MAX_SAFE_INTEGER)
    );
  }

  sortSourceElementsByDisplayOrder(elements): any[] {
    const sortedElements = [...elements];
    sortedElements.forEach((element) => {
      if (element.elementType === "table") {
        if (element["tableElements"].length > 0) {
          element.tableElements.sort((a, b) => {
            const idA = a["displayOrder"] ?? Number.MAX_SAFE_INTEGER;
            const idB = b["displayOrder"] ?? Number.MAX_SAFE_INTEGER;
            return idA - idB;
          });
        }
      }
    });
    sortedElements.sort((a, b) => {
      const idA = a["displayOrder"] ?? Number.MAX_SAFE_INTEGER;
      const idB = b["displayOrder"] ?? Number.MAX_SAFE_INTEGER;
      return idA - idB;
    });
    return sortedElements;
  }

  addDisplayNametoTableLineElement(data) {
    const copiedResponse = [...data];
    let tableElements: any[] = [];
    let remaningElements: any[] = [];
    copiedResponse.forEach((item) => {
      if (item["elementType"] === "table" && item["tableElements"]) {
        item["tableElements"].forEach((element) => {
          element["elementName"] =
            item["elementName"] + ":" + element["elementName"];
          element["displayName"] =
            item["displayName"] + ":" + element["displayName"];
        });
        tableElements.push(...item.tableElements);
        item.tableElements = null;
      }
      remaningElements.push(item);
    });
    const allData = remaningElements.concat(tableElements);
    return allData;
  }

  isSourceDisabled(mappingElement: any) {
    return (
      mappingElement["function"] &&
      this.sourceNotRequiredFunctions.includes(mappingElement["function"])
    );
  }

  isDefaultValueDisabled(mappingElement: any) {
    return (
      mappingElement["function"] &&
      this.defaultValueNotRequiredFunctions.includes(mappingElement["function"])
    );
  }

  showFunctionForm() {
    if (this.functionForm) {
      const form = JSON.parse(this.functionForm);
      if (
        (form["form"]?.length || 0) > 0 ||
        this.transformationParameterfunction.some(
          (transform) =>
            transform.toLowerCase() ===
            this.currentFunctionParameter.toLowerCase()
        )
      ) {
        return true;
      }
    }
    return false;
  }
  showQueryForm(selectColumn: any) {
    return (
      (selectColumn.elementLabel === "Validation" &&
        selectColumn.function?.toLowerCase() === "if_exists") ||
      selectColumn.function?.toLowerCase() === "skip_if_exists"
      // (selectColumn?.datatype === "VARCHAR2" &&
      //   selectColumn.function?.toLowerCase() === "skip_if_exists")
    );
  }

  handleMappingWithAi() {
    this.isAiMappingFetching = true;
    // if (!this.hasAutoMappingChanges) {
    //   let disableAutoMappingValue = this.mappings["disableAutoMapped"]
    //     ? typeof this.mappings["disableAutoMapped"] === "boolean"
    //       ? this.mappings["disableAutoMapped"]
    //       : JSON.parse(this.mappings["disableAutoMapped"])
    //     : false;
    //   if (
    //     this.mappings["distinctLineTypes"]?.length > 0 &&
    //     !disableAutoMappingValue &&
    //     this.checkForDefaultSheet
    //   ) {
    // this.ConfirmPopupService.confirmMessageEvent.next({
    //   message: "The existing sheet(s) will be overwritten.",
    //   clearMappings: "true",
    //   headerText: "Automap with AI",
    //   confirmButtonText: "Confirm",
    //   cancelButtonText: "Cancel",
    //   manageCallback: (value) => {
    //     if (value && value["isConfirm"] === "true") {
    //       this.autoMapWithConfirmation();
    //     } else {
    //       this.isAiMappingFetching = false;
    //     }
    //   },
    // });

    // } else {
    //   this.autoMapWithConfirmation();
    // }
    // } else {
    //   if (this.mappings["mapping"]["lines"]) {
    //     this.isAiMappingFetching = false;
    //     if (!this.isMapMissingHeaders) {
    //       // this.addMissingHeadersPopupMethod();
    //     }
    //   } else {
    //     this.isAiMappingFetching = false;
    //     let data = {
    //       duration: 3000,
    //       type: "alert",
    //       message: "Ai mappings already avaliable",
    //     };
    //     this.appService.showToast(data);
    //   }
    // }
    this.autoMapWithConfirmation();
    this.isAiMappingFetching = false;
  }

  async autoMapWithConfirmation() {
    try {
      await this.multiAddElementIdsToCurrentLineType();
    } catch (error: any) {
      this.appService.showToast({
        duration: 3000,
        type: "error",
        message: error["error"]["message"] || error["message"] || error,
      });
      return;
    }
    let data = this.mappings?.["sourceFileHeaders"] || [];
    if (
      (this.mappings["sourceFileHeaders"] ?? []).length === 0 &&
      this.mappings["importReadRules"]["fileType"] === "PDF/IMAGE" &&
      this.invoiceKeysList.length > 0
    ) {
      data = [
        {
          headers: this.invoiceKeysList.map((key) => key.elementName),
          sheetName: "",
        },
      ];
    }
    if (
      this.mappings["importReadRules"]?.["fileAttributes"]?.["fileFormat"] ==
      "KBR Proposal"
    ) {
      data = [
        {
          headers: this.invoiceKeysList.map((key) => key.elementName),
          sheetName: "",
        },
      ];
    }

    let formData = new FormData();
    formData.append("importId", this.importId);
    formData.append("sourceSheetHeaderList", JSON.stringify(data));
    let mappingData = this.mappings["mapping"]["lines"];
    const unMappedTargetElements: any[] = [];
    for (const sheet of mappingData) {
      if (sheet["lineType"] && Object.keys(sheet).length > 0) {
        let data = sheet["mappingElements"].filter(
          (item) => !item?.["sourceFileColumnNumber"] && !item["function"]
        );
        unMappedTargetElements.push({
          sheetName: sheet["lineType"],
          mappingElements: data,
        });
      }
    }
    formData.append(
      "targetMappingElementsBySheet",
      JSON.stringify(unMappedTargetElements)
    );
    formData.append(
      "connectionType",
      this.mappings["sourceConnectionType"] || ""
    );
    if (
      this.mappings["sourceFileHeaders"]?.length > 0 ||
      this.invoiceKeysList.length > 0 ||
      this.sourceFileHeaders?.length > 0 ||
      sourceSupportedConnection.includes(this.mappings["sourceConnectionType"])
    ) {
      this.autoMapWithAiApiCall(formData);
    } else if (
      (this.mappings["sourceFileHeaders"] ?? []).length === 0 &&
      this.invoiceKeysList.length === 0
    ) {
      if (this.mappings["importReadRules"]["fileType"] === "PDF/IMAGE") {
        this.appService.showToast({
          duration: 3000,
          type: "info",
          message: `Unsupported file type!`,
        });
        return;
      } else {
        this.ConfirmPopupService.confirmMessageEvent.next({
          message: "Drag & drop a source file ",
          headerText: "File Upload",
          fileUpload: true,
          fileType: this.mappings["importReadRules"]["fileType"],
          connectionType: this.mappings["sourceConnectionType"],
          type: "source",
          cancelButtonText: "Cancel",
          manageCallback: (value: any) => {
            if (value && value["isConfirm"] === "true") {
              if (value?.["targetFile"]) {
                const fileName = value?.["targetFile"]?.name;
                const fileExtension = fileName
                  ?.split(".")
                  ?.pop()
                  ?.toLowerCase();
                formData.append("sourceFile", value?.["targetFile"]);
                formData.append("fileExtension", fileExtension);
                this.autoMapWithAiApiCall(formData);
              } else {
                let configureConnectionSourcePayload = {
                  importId: this.importId,
                  connectionId: Number(
                    value["connectionDetails"]?.["connectionId"]
                  ),
                  truncate: value["connectionDetails"]?.["sourceTruncate"],
                  query: value["connectionDetails"]?.["sourceQuery"],
                  sourceDateRange:
                    value["connectionDetails"]?.["sourcesourceDateRange"],
                };
                this.getAutoTargetElementsFromConfigConnection(
                  configureConnectionSourcePayload,
                  "SOURCE",
                  unMappedTargetElements
                );
              }
            }
          },
        });
      }
    }
  }
  async autoMapWithAiApiCall(data?: any) {
    this.isAiMappingFetching = true;
    this.importDesignerService.getAutoMappingsWithAi(data).subscribe({
      next: (response) => {
        this.isAiMappingFetching = false;
        this.autoMapData = {};
        if (
          response["autoMappedElements"]?.["autoMappingData"]?.["lines"]
            ?.length > 0
        ) {
          this.autoMapData = cloneDeep(response);
          this.checkForDefaultSheet = false;
          //   this.mappings["sourceFileHeaders"]=response['sourceFileHeaders'];
          //   this.sourceFileHeaders = this.getSourceFileHeaders();
          this.sortMappingElements(
            response["autoMappedElements"],
            response["message"]
          );
          this.sourceFileHeadersFromAutoMap =
            response["autoMappedElements"]["sourceSheetHeaderList"];
          this.sourceFileHeaders = response["autoMappedElements"][
            "sourceSheetHeaderList"
          ].flatMap((item: any) => item.headers);
          this.mappings["sourceFileHeaders"] =
            response["autoMappedElements"]["sourceSheetHeaderList"];
        } else {
          let data = {
            duration: 3000,
            type: "info",
            message: response["message"],
          };
          this.appService.showToast(data);
        }
      },
      error: (err) => {
        this.isAiMappingFetching = false;
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          };
          this.appService.showToast(data);
        }
      },
    });
  }

  handleClearMappings() {
    this.isTargetMappingCleared = false;
    this.aiMappedElements = [];
    this.mappings["mapping"]["lines"][this.selectedLineIndex][
      "mappingElements"
    ] = [];
    this.errorElementIndices = [];
    let data = {
      duration: 3000,
      type: "info",
      message: "Mapping elements are cleared. Please save the changes!",
    };
    this.appService.showToast(data);
    let disableAutoMappingValue = this.mappings["disableAutoMapped"]
      ? typeof this.mappings["disableAutoMapped"] === "boolean"
        ? this.mappings["disableAutoMapped"]
        : JSON.parse(this.mappings["disableAutoMapped"])
      : false;

    if (
      this.mappings["mapping"]["lines"][
        this.mappings["distinctLineTypes"].indexOf(this.currentLine)
      ]["mappingElements"].length > 1 &&
      !disableAutoMappingValue &&
      this.isTargetMappingCleared
    ) {
      this.disableAutoMappingButton.next(false);
      this.hasAutoMappingChanges = false;
      this.isMapMissingHeaders = true;
      const currentSheet = this.missingSourceHeaders.find(
        (sheet) => sheet?.sheetName === this.currentLine
      );
      this.alreadyaddedMissingHeadersList =
        this.alreadyaddedMissingHeadersList.filter(
          (header) => !currentSheet?.headers.includes(header)
        );
    } else {
      this.disableAutoMappingButton.next(true);
      this.isMapMissingHeaders = false;
    }
    this.unselectColumn();
    this.disableAutoMapWithAiButton();
  }
  sortMappingElements(aiMappings: any, message: string) {
    const data = JSON.parse(JSON.stringify(aiMappings));
    const missingHeaders = data?.["unmappedTargetElements"];
    const autoMappingLines = data["autoMappingData"]["lines"] || [];
    const newSheetNames: string[] = [];
    const newlines: any[] = [];
    for (const sheet of autoMappingLines) {
      if (sheet["lineType"] && Object.keys(sheet).length > 0) {
        newSheetNames.push(sheet["lineType"]);
        newlines.push(sheet);
      } else {
        this.appService.showToast({
          duration: 3000,
          type: "info",
          message: "Empty response",
        });
        return;
      }
    }
    if (newSheetNames.length > 0) {
      const nonEmptyLines = newlines.filter(
        (line) => line["mappingElements"]?.length > 0
      );
      if (nonEmptyLines.length > 0) {
        // this.mappings["mapping"]["lines"] = nonEmptyLines;
        // this.mappings["distinctLineTypes"] = Array.from(
        //   new Set(nonEmptyLines.map((line) => line["lineType"]))
        // );
        const linesMapping = this.mappings["mapping"]["lines"];
        for (let line of linesMapping) {
          if (
            line?.["mappingElements"] &&
            Array.isArray(line["mappingElements"])
          ) {
            for (let i = 0; i < line["mappingElements"].length; i++) {
              const mappingElement = line["mappingElements"][i];
              const matchingElementFromNonEmptyLines = nonEmptyLines
                .flatMap(
                  (responseElement) =>
                    responseElement?.["mappingElements"] || []
                )
                .find(
                  (resElement) =>
                    resElement["elementLabel"] ===
                      mappingElement["elementLabel"] &&
                    !mappingElement.sourceFileColumnNumber
                );
              if (matchingElementFromNonEmptyLines) {
                line["mappingElements"][i] = {
                  ...mappingElement,
                  sourceFileColumnNumber:
                    matchingElementFromNonEmptyLines.sourceFileColumnNumber,
                };
              }
            }
          }
        }
        this.mappings["mapping"]["lines"][this.selectedLineIndex][
          "mappingElements"
        ].forEach((item) => {
          this.elementIds.push(item["elementId"]);
        });
        // this.currentLine = this.mappings["distinctLineTypes"][0];
        this.newLineTypes = this.mappings["distinctLineTypes"];
        this.hasAutoMappingChanges = true;
        this.unselectColumn();
        // Transform and filter missing headers
        this.missingSourceHeaders = this.transformHeaders(
          missingHeaders,
          this.currentLine
        ).filter((sheet) => sheet?.headers?.length > 0);
        // Show the missing headers popup if necessary or success message
        if (this.missingSourceHeaders.length > 0) {
          this.addMissingHeadersPopupMethod(true);
        } else {
          this.disableAutoMappingButton.next(true);
          let data = {
            duration: 3000,
            type: "Success",
            message: message,
          };
          this.appService.showToast(data);
        }
      } else {
        this.appService.showToast({
          duration: 3000,
          type: "info",
          message: "Empty response",
        });
      }
    } else {
      this.appService.showToast({
        duration: 3000,
        type: "info",
        message: "Empty response",
      });
    }
    this.checkErrorElementIndices();
  }

  aiMappings(element: any) {
    for (let item of this.aiMappedElements) {
      if (
        item["elementName"]?.toLowerCase() ===
        element["elementName"]?.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  }

  handleClearAllSheetMappings() {
    this.mappings["mapping"]["lines"] = this.mappings["mapping"][
      "lines"
    ].filter((lineItem) => {
      lineItem["mappingElements"] = [];
      // if (lineItem["lineType"] !== this.currentLine) {
      //   return false;
      // }
      return true;
    });
    let disableAutoMappingValue = this.mappings["disableAutoMapped"]
      ? typeof this.mappings["disableAutoMapped"] === "boolean"
        ? this.mappings["disableAutoMapped"]
        : JSON.parse(this.mappings["disableAutoMapped"])
      : false;

    if (
      this.mappings["mapping"]["lines"][
        this.mappings["distinctLineTypes"].indexOf(this.currentLine)
      ]["mappingElements"].length == 0 &&
      !disableAutoMappingValue
    ) {
      this.disableAutoMappingButton.next(false);
      this.hasAutoMappingChanges = false;
      this.isMapMissingHeaders = true;
      this.alreadyaddedMissingHeadersList = [];
    } else {
      this.disableAutoMappingButton.next(true);
      this.isMapMissingHeaders = false;
    }
    this.unselectColumn();
    this.disableAutoMapWithAiButton();
    this.autoTargetData = {};
    this.errorElementIndices = [];
  }

  mapMissingHeadersToLineType(headers: any) {
    const headersArray = Array.from(new Set(Object.values(headers).flat()));
    this.alreadyaddedMissingHeadersList = Array.from(
      new Set([...this.alreadyaddedMissingHeadersList, ...headersArray])
    );

    let hasAddedNewElement = false;
    const fileType = this.mappings["importReadRules"]["fileType"];
    Object.entries(headers).forEach(([sheetName, headersArray]) => {
      if (!Array.isArray(headersArray)) return;

      if (
        (!sheetName || sheetName === "") &&
        this.mappings["distinctLineTypes"].length === 1
      ) {
        sheetName = this.currentLine;
      }
      headersArray.forEach((header) => {
        this.mappings?.mapping?.lines.forEach((line) => {
          if (line["lineType"] === sheetName) {
            const existingElements = line["mappingElements"];

            // Check if the element name/label already exists
            const elementDoesNotExist = existingElements?.every(
              (e: any) =>
                e?.elementName !== header || e?.elementLabel !== header
            );

            // Check if the source file column number is valid
            const sourceFileColumnNumberValid = existingElements?.every(
              (e: any) =>
                e.sourceFileColumnNumber !==
                (["XLS", "XLSX"].includes(fileType) &&
                this.isAddSheetNameValidFunc(fileType)
                  ? `${sheetName}:${header}`
                  : header)
            );

            // Add the element only if both conditions are true
            if (elementDoesNotExist && sourceFileColumnNumberValid) {
              const newElement = {
                elementId: Date.now(),
                elementName: header,
                elementLabel: header,
                datatype: "VARCHAR2",
                required: "N",
                isNewField: true,
                function: null,
                functionParameters: "{}",
                sourceFileColumnNumber: this.isAddSheetNameValidFunc(fileType)
                  ? `${sheetName}:${header}`
                  : header,
              };
              line["mappingElements"]?.push(newElement);
              this.elementIds = [
                ...new Set([...this.elementIds, newElement.elementId]),
              ];
              hasAddedNewElement = true;
            } else {
              this.appService.showToast({
                duration: 3000,
                type: "info",
                message: `${header} with same source or target already exists`,
              });
              hasAddedNewElement = false;
              this.isMapMissingHeaders = true;
            }
          }
        });
      });
    });
    // Check if all missing headers are already added
    const allMissingHeaders = this.missingSourceHeaders?.flatMap(
      (sheet) => sheet?.headers
    );
    const allHeadersPresent = allMissingHeaders?.every((header) =>
      this.alreadyaddedMissingHeadersList.includes(header)
    );

    if (allHeadersPresent) {
      this.isMapMissingHeaders = true;
      this.disableAutoMappingButton.next(true);
      this.alreadyaddedMissingHeadersList = [];
    } else {
      this.disableAutoMappingButton.next(false);
      this.isMapMissingHeaders = false;
    }
    if (!hasAddedNewElement) {
      this.disableAutoMappingButton.next(true);
      this.alreadyaddedMissingHeadersList = [];
    }

    // Scroll if new element added
    if (hasAddedNewElement) {
      setTimeout(() => {
        this.tableAnchor.nativeElement.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
    this.isAiMappingFetching = false;
  }

  transformHeaders(headers: any[], sheetName): any[] {
    if (
      Array.isArray(headers) &&
      headers.every((item) => typeof item === "string")
    ) {
      return [
        {
          headers: headers,
          sheetName: sheetName,
        },
      ];
    }
    return headers;
  }
  addMissingHeadersPopupMethod(value?: boolean) {
    let missingHeaders = this.missingSourceHeaders.filter(
      (sheet) => sheet.headers.length > 0
    );
    if (missingHeaders.length > 0) {
      this.SheetHeaderService.missingSheetHeaders.next({
        message:
          "Do you want to add the missing headers into the selected sheet'(s)",
        clearMappings: "true",
        headerText: "Automap with AI",
        confirmButtonText: "Confirm",
        cancelButtonText: "Close",
        xlHeaders: this.missingSourceHeaders,
        sheetName: this.currentLine,
        addedHeaders:
          this.alreadyaddedMissingHeadersList?.length > 0
            ? this.alreadyaddedMissingHeadersList
            : [],
        manageCallback: (value) => {
          if (value && value["isConfirm"] === "true") {
            this.mapMissingHeadersToLineType(value["headers"]);
          } else {
            this.checkToDisableAutoMapButtonForMissingHeaders();
          }
        },
      });
    } else {
      this.disableAutoMappingButton.next(true);
    }
  }

  checkToDisableAutoMapButtonForMissingHeaders() {
    const alreadyAddedSet = new Set(this.alreadyaddedMissingHeadersList);
    const allMissingHeaders = this.missingSourceHeaders.flatMap(
      (sheet) => sheet?.headers
    );
    const allHeadersPresent = allMissingHeaders?.every((header: any) =>
      alreadyAddedSet.has(header)
    );
    if (
      allHeadersPresent &&
      this.alreadyaddedMissingHeadersList?.length &&
      this.missingSourceHeaders?.length
    ) {
      this.isMapMissingHeaders = true;
      this.disableAutoMappingButton.next(true);
      this.alreadyaddedMissingHeadersList = [];
    } else {
      this.disableAutoMappingButton.next(false);
      this.isMapMissingHeaders = false;
    }
  }
  multiAddElementIdsToCurrentLineType(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const seenElementNames = new Set<string>();
      const seenElementLabels = new Set<string>();

      // Filter new and unique elements based on name and label
      const multiEl = (this.mappings?.["mapping"]?.["lines"] || [])
        .flatMap((lineItem) => lineItem?.["mappingElements"] || [])
        .filter((element) => {
          const { elementLabel: name, isNewField: isNew } = element;
          if (isNew && !seenElementLabels.has(name)) {
            seenElementNames.add(name);
            return true;
          }
          return false;
        })
        .map((element) => ({
          datatype: element["datatype"] || null,
          elementLabel: element["elementLabel"] || "",
          elementName: element["elementLabel"] || "",
          elementLevel: "TRANSFORMATION",
          elementAttributes: null,
          functionParameters: "{}",
        }));

      // If no new elements, resolve immediately
      if (multiEl.length === 0) {
        return resolve();
      }

      try {
        const data: any = await this.multiElementsApiCall(multiEl);

        if (data["mappingElements"].length === 0) {
          return reject(
            new Error("No mapping elements were returned from the API.")
          );
        }

        // Update existing elements with API response
        this.mappings["mapping"]["lines"].forEach((lineItem) => {
          lineItem["mappingElements"]?.forEach((element, i) => {
            const newEl = data["mappingElements"].find(
              (el) =>
                el["elementLabel"] === element["elementLabel"] ||
                el["elementName"] === element["elementName"]
            );

            if (newEl) {
              Object.keys(newEl).forEach((key) => {
                if (newEl[key] !== null && newEl[key] !== undefined) {
                  element[key] = newEl[key];
                }
              });
              delete element["isNewField"];
              lineItem["mappingElements"][i] = element;
            }
          });
        });

        this.appService.showToast({
          duration: 3000,
          type: "success",
          message: data["message"],
        });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  multiElementsApiCall(data: any) {
    return this.importDesignerService
      .addMulitpleMappingElements(data)
      .toPromise();
  }

  // Internal function to check if the array is valid
  private isAddSheetNameValidFunc(fileType): boolean {
    const multiSheet =
      this.mappings?.["importReadRules"]?.["fileAttributes"]?.[
        "multipleSheetIndicator"
      ];
    return ["XLS", "XLSX"].includes(fileType) && multiSheet === "Y";
  }

  updateQueryBuilderValues(event: any) {
    this.mappings["mapping"]["lines"][this.selectedLineIndex].mappingElements[
      this.selectedColumnIndex
    ].functionParameters = JSON.stringify({
      IF_EXISTS: event,
    });
  }
  getTargetMappingElements() {
    if (this.mappings["targetConnectionType"]?.toLowerCase() === "database") {
      this.getTargetElements();
    } else if (
      this.mappings["targetConnectionType"]?.toLowerCase() !== "database"
    ) {
      this.ConfirmPopupService.confirmMessageEvent.next({
        message: "Drag & drop a target file ",
        headerText: "File Upload",
        fileType:
          this.mappings["importReadRules"]?.["fileAttributes"]?.[
            "targetFormat"
          ],
        fileUpload: true,
        connectionType: this.mappings["targetConnectionType"],
        cancelButtonText: "Cancel",
        type: "target",
        manageCallback: (value: any) => {
          if (value && value["isConfirm"] === "true") {
            if (value?.["targetFile"]) {
              this.getTargetElements(value?.["targetFile"]);
            } else {
              let configureConnectionTargetPayload = {
                importId: this.importId,
                mode: "TARGET",
                connectionId: Number(
                  value["connectionDetails"]["connectionId"]
                ),
                truncate: value["connectionDetails"]["targetTruncate"],
                query: value["connectionDetails"]["targetQuery"],
              };
              this.getAutoTargetElementsFromConfigConnection(
                configureConnectionTargetPayload,
                "TARGET"
              );
            }
          }
        },
      });
    }
  }

  getAutoTargetElementsFromConfigConnection(
    configureConnectionPayload: any,
    mode: string,
    unMappedTargetElements?: any
  ) {
    this.isAiMappingFetching = true;
    let connectionType: any;
    connectionType = this.getConnectionType(
      configureConnectionPayload.connectionId
    );
    this.importDesignerService
      .configureConnectionForAutoElements(configureConnectionPayload, mode)
      .subscribe({
        next: (res) => {
          if (configureConnectionPayload.mode === "TARGET") {
            this.getTargetElements(configureConnectionPayload);
            this.mappings["targetConnectionType"] =
              connectionType?.connectionType;
          } else {
            let sourceSheet = this.mappings?.["sourceFileHeaders"] || [];
            let formData = new FormData();
            formData.append("importId", this.importId);
            formData.append(
              "connectionType",
              connectionType?.connectionType || ""
            );
            formData.append(
              "targetMappingElementsBySheet",
              JSON.stringify(unMappedTargetElements)
            );
            formData.append(
              "sourceSheetHeaderList",
              JSON.stringify(sourceSheet)
            );
            this.mappings["sourceConnectionType"] =
              connectionType?.connectionType;
            this.autoMapWithAiApiCall(formData);
          }
        },
        error: (err) => {
          this.isAiMappingFetching = false;
          if (err.status !== 403) {
            let data = {
              duration: 3000,
              type: "error",
              message: err["error"]?.["message"] || err["message"] || err,
            };
            this.appService.showToast(data);
          }
        },
      });
  }
  getTargetElements(file?: any) {
    this.isAiMappingFetching = true;
    let formData = new FormData();
    let connectionType: any;
    formData.append("importId", this.importId);
    if (this.mappings["targetConnectionType"]) {
      formData.append(
        "connectionType",
        this.mappings["targetConnectionType"] || ""
      );
    }
    if (file && file?.["connectionId"]) {
      connectionType = this.getConnectionType(file.connectionId);
      formData.append("connectionType", connectionType?.connectionType || "");
    }
    if (file && !connectionType) {
      const fileName = file?.name;
      const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();
      formData.append("targetFile", file || "");
      formData.append("fileExtension", fileExtension || "");
    }
    this.importDesignerService.getAutoTargetElements(formData).subscribe({
      next: (res) => {
        this.autoTargetData = cloneDeep(res["targetMappingElementsBySheet"]);
        this.getTargetMappings(res);
        this.isAiMappingFetching = false;
      },
      error: (err) => {
        this.isAiMappingFetching = false;
        if (err.status !== 403) {
          let data = {
            duration: 3000,
            type: "error",
            message: err["error"]?.["message"] || err["message"] || err,
          };
          this.appService.showToast(data);
        }
      },
    });
  }

  getConnectionType(connectionId: number) {
    let connections: any = sessionStorage.getItem("createdConnections");
    const connnectionType = JSON.parse(connections).filter(
      (item: any) => item.id === connectionId
    );
    return connnectionType[0];
  }

  getTargetMappings(res: any) {
    const newSheetNames: string[] = [];
    const newlines: any[] = res["targetMappingElementsBySheet"]
      ?.map((sheet) => {
        if (sheet["sheetName"] && Object.keys(sheet).length > 0) {
          newSheetNames.push(sheet["sheetName"]);
          return {
            lineType: sheet["sheetName"],
            mappingElements: sheet["mappingElements"],
          };
        } else {
          this.appService.showToast({
            duration: 3000,
            type: "info",
            message: "Empty response",
          });
          return null;
        }
      })
      .filter((line) => line !== null); // Filter out any null values
    if (newlines.length === 0) {
      return;
    }
    const linesMapping = this.mappings["mapping"]["lines"];
    if (newSheetNames.length > 0) {
      const nonEmptyLines = newlines.filter(
        (line) => line["mappingElements"]?.length > 0
      );
      if (nonEmptyLines.length > 0) {
        newlines.forEach((newLine) => {
          const { lineType, mappingElements } = newLine;
          // Check if lineType exists in linesMapping
          const existingLine = linesMapping.find(
            (line) => line["lineType"] === lineType
          );
          if (existingLine) {
            // lineType exists
            if (existingLine["mappingElements"].length === 0) {
              // Condition 2: Existing lineType with empty mappingElements
              // Add all mappingElements from newLine
              existingLine["mappingElements"] = [...mappingElements];
            } else {
              // Condition 3: Existing lineType with non-empty mappingElements
              // Keep existing elements, add only truly new (non-duplicate) elements from newLine
              const existingElements: any = existingLine["mappingElements"];
              const newElements = mappingElements.filter((newElement) => {
                return existingElements.every((existingElement) => {
                  return (
                    existingElement["elementLabel"] !==
                    newElement["elementLabel"]
                  );
                });
              });
              existingLine["mappingElements"] = [
                ...existingLine["mappingElements"],
                ...newElements,
              ];
            }
          } else {
            // Condition 1: lineType does not exist in linesMapping
            // Add newLine to linesMapping
            linesMapping.push(newLine);
            this.mappings["distinctLineTypes"].push(lineType);
          }
        });
        // Remove empty LINE_TYPEs from distinctLineTypes and linesMapping
        linesMapping.filter((line) => {
          if (line["mappingElements"].length === 0) {
            this.appService.showToast({
              duration: 3000,
              type: "info",
              message: `${line["lineType"]} has been removed due to empty mapping elements!`,
            });
          }
        });
        // Filter out items with empty mappingElements from the line array
        this.mappings["mapping"]["lines"] = this.mappings["mapping"][
          "lines"
        ].filter((item) => item["mappingElements"].length > 0);
        this;
        this.mappings["distinctLineTypes"] = Array.from(
          new Set(
            this.mappings["mapping"]["lines"].map((line) => line["lineType"])
          )
        );
        this.currentLine =
          this.mappings["distinctLineTypes"][this.selectedLineIndex] ||
          this.mappings["distinctLineTypes"][0];
        this.newLineTypes = this.mappings["distinctLineTypes"];
        // Loop through all lines
        this.mappings["mapping"]["lines"]?.forEach((line) => {
          line["mappingElements"]?.forEach((item) => {
            if (!this.elementIds.includes(item["elementId"])) {
              this.elementIds.push(item["elementId"]);
            }
          });
        });
        this.mappings.mapping["lines"].forEach((element, idx) => {
          element.sequenceNumber = idx + 1;
        });
        this.unselectColumn();
        this.disableAutoMappingButton.next(false);
        this.setLineIndex();
        this.emitUpdateStatus();
        let data = {
          duration: 3000,
          type: "success",
          message: res?.["message"] || res,
        };
        this.appService.showToast(data);
      } else {
        this.appService.showToast({
          duration: 3000,
          type: "info",
          message: "Empty response",
        });
      }
    } else {
      this.appService.showToast({
        duration: 3000,
        type: "info",
        message: "Empty response",
      });
    }
  }
  conditionForTargetElementButton() {
    return !this.mappings["mapping"]?.["lines"];
  }

  disableTargetMappingButton() {
    return (
      this.mappings["mapping"]?.["lines"]?.[
        this.mappings["distinctLineTypes"]?.indexOf(this.currentLine)
      ]?.["mappingElements"]?.length === 0
    );
  }

  disableAutoMapWithAiButton() {
    let data =
      this.mappings["mapping"]?.["lines"]?.[
        this.mappings["distinctLineTypes"]?.indexOf(this.currentLine)
      ]?.["mappingElements"];

    let functions = ["IF-ELSEIF", "SKIP_IF_EXISTS"];

    let isColumns = data?.some(
      (item) =>
        !item["sourceFileColumnNumber"] && !functions.includes(item["function"])
    );

    if (
      (!isColumns && !this.disableTargetMappingButton()) ||
      this.disableTargetMappingButton()
    ) {
      this.disableAutoMappingButton.next(true);
    } else {
      this.disableAutoMappingButton.next(false);
    }
  }

  handleClearTransformationFunction(value: string) {
    if (
      value?.toLowerCase() === this.selectedColumn?.["function"]?.toLowerCase()
    ) {
      this.mappings["mapping"]["lines"][this.selectedLineIndex]?.[
        "mappingElements"
      ]?.filter((item) => {
        if (
          item["function"] === this.selectedColumn?.["function"] &&
          item["elementId"] === this.selectedColumn?.["elementId"]
        ) {
          item["function"] = null;
          item["functionParameters"] = null;
          this.unselectColumn("transformationFunction");
        }
      });
    }
  }

  getMappingElements() {
    let payload = {
      pageNo: this.allMappingElementsPageNo,
      search: this.searchText,
      noOfElements: this.pageSize,
      specificationsRequired: this.searchText.trim().length > 0,
    };
    this.importDesignerService.getAllMappingElementsPerPage(payload).subscribe({
      next: (res) => {
        if (res.allMappingElements.length < this.pageSize) {
          this.stopFetchingMappingElements = true;
        }
        this.filteredElements.push(...cloneDeep(res.allMappingElements));
        this.allElements.push(...cloneDeep(res.allMappingElements));
        this.allMappingElementsLoaded = true;
      },
      error: (err) => {
        this.allMappingElementsLoaded = true;
      },
    });
  }

  onLabelChange(event: KeyboardEvent) {
    if (event.key == "Enter" && this.selectedColumn) {
      if (this.selectedColumn.isEditing) {
        this.updateFieldName(this.selectedColumn, this.selectedColumnIndex);
      } else if (this.selectedColumn.isNewField) {
        this.saveNewField(this.selectedColumn, this.selectedColumnIndex);
      }
    }
  }

  isSelectLineTypeChangeAutoTargetFetch(line: string) {
    this.currentLine = line;
    this.setSelectedElements();
    this.mappings["mapping"]["lines"].forEach((line, lineIndex) => {
      if (line["lineType"] == this.currentLine) {
        this.selectedLineIndex = lineIndex;
        this.importDesignerService.selectedLineIndex = this.selectedLineIndex;
        this.changeSourceFileColumnNumber();
      }
    });
    this.disableAutoMapWithAiButton();
    this.checkErrorElementIndices();
  }
  checkErrorElementIndices() {
    let validateMapping = this.validationService.validateMapping(
      this.mappings,
      this.currentLine,
      this.sourceNotRequiredFunctions,
      this.defaultValueNotRequiredFunctions
    );
    if (
      validateMapping.indices &&
      validateMapping.message &&
      this.saveButtonClicked
    ) {
      this.errorElementIndices = validateMapping.indices;
    }
  }

  onScroll(event: any) {
    if (!this.allMappingElementsLoaded) {
      return;
    }
    const element = event?.target;
    const threshold = 2;
    const atBottom =
      element.scrollHeight - element.scrollTop <=
      element.clientHeight + threshold;
    if (
      atBottom &&
      element.scrollTop > threshold &&
      !this.stopFetchingMappingElements
    ) {
      this.allMappingElementsPageNo++;
      this.allMappingElementsLoaded = false;
      this.getMappingElements();
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emaildomainSplitter'
})
export class EmaildomainSplitterPipe implements PipeTransform {

  transform(emailDomain: string, emailSplitterRefresh: number): String[] {
    if(emailDomain && JSON.parse(emailDomain).email_domain){
      return JSON.parse(emailDomain).email_domain.split(',')
    }
    return [];
  }

}
